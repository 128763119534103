import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { RootState } from "../..";
import { ChangeCurrentCategory, SetGoBack } from "../../Redux/Actions/CategoryActions";
import { ToggleSideMenu } from "../../Redux/Actions/HeaderActions";
import { GetCategoriesList } from "../../Redux/Actions/ProductListActions";
import {
    findCategoryFromId,
    findParent,
    useCategoryRouteObjects,
    useCurrentCategory,
    useDefaultParent,
    useMatchingCategory,
} from "../../Utils/CategoriesHelper";
import { useIsWindowSmall } from "../../Utils/Global/UseWindowSizeHook";
import { CategoryType } from "../PageElements/Products/ProductCategoryFilter";

import "./Styles/_NavMenu.scss";

const NavigationMenu = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const matchingCategory = useMatchingCategory();
    const currentCategory = useCurrentCategory();
    const categoryRouteObjects = useCategoryRouteObjects();
    const smallScreen = useIsWindowSmall();
    const defaultParent = useDefaultParent();

    const auth = useSelector((state: RootState) => state.authReducer);
    const productsList = useSelector((state: RootState) => state.productListReducer);
    const goBack = useSelector((state: RootState) => state.categoryReducer.goBack);

    const [grandparent, setGrandparent] = useState<CategoryType>();

    // Set the first parent to be the parent of the current page found with query params
    const initialParent = useMemo(() => {
        if (matchingCategory) {
            const initialCategory = findCategoryFromId(defaultParent, matchingCategory.id);
            return initialCategory && initialCategory.subCategories.length > 0
                ? initialCategory
                : findParent(defaultParent, matchingCategory.id);
        }
    }, [matchingCategory, defaultParent]);

    const [currentParent, setCurrentParent] = useState<CategoryType>(initialParent || defaultParent);

    const scrollingElement = document.getElementById("Sidebar");

    // If menu page matches the actual page but the parent category is not the category in redux, update redux
    useEffect(() => {
        if (
            currentParent.id === matchingCategory?.id &&
            JSON.stringify(currentCategory) !== JSON.stringify(currentParent)
        ) {
            dispatch(ChangeCurrentCategory(currentParent));
        }
    }, [currentParent, matchingCategory?.id, currentCategory, dispatch]);

    // If categories in redux change, set the parent to All Products
    useEffect(() => {
        if (defaultParent && currentParent.subCategories.length < 1) {
            setCurrentParent(defaultParent);
        }
    }, [defaultParent, currentParent.subCategories.length]);

    // When we change page, make sure the initial menu category is the correct one
    useEffect(() => {
        if (currentCategory.id === matchingCategory?.id && currentCategory.subCategories.length > 0) {
            setCurrentParent(currentCategory);
        }
    }, [currentCategory, matchingCategory?.id]);

    // When currentParent changes, set the new grandparent
    useEffect(() => {
        if (currentParent) {
            const newGrandparent = findParent(defaultParent, currentParent.id);
            if (newGrandparent) setGrandparent(newGrandparent);
        }
    }, [currentParent, defaultParent]);

    // If the url changes, update the redux category state
    useEffect(() => {
        if (matchingCategory?.id) {
            const newMatch = findCategoryFromId(defaultParent, matchingCategory.id);
            if (newMatch && newMatch.id !== currentCategory.id) dispatch(ChangeCurrentCategory(newMatch));
        }
    }, [matchingCategory?.id, defaultParent, currentCategory, dispatch]);

    // On load, fetch the categories if not already done or in progress
    useEffect(() => {
        if (
            (productsList.categoriesFetched && productsList.numberOfCategories !== null) ||
            productsList.isFetchingCategories
        )
            return;
        dispatch(GetCategoriesList(auth.subscribedToken));
    }, [
        dispatch,
        auth.subscribedToken,
        productsList.categoriesFetched,
        productsList.isFetchingCategories,
        productsList.numberOfCategories,
    ]);

    const onClickCategoryMenuItem = async (childCategory?: CategoryType, toPath?: string, back?: boolean) => {
        if (childCategory) {
            if (childCategory.subCategories.length === 0 || childCategory.id === currentParent.id) {
                const newPath = categoryRouteObjects.find((routeObject) => routeObject.id === childCategory.id)?.path;
                history.push(`/products/?t=${newPath}`);
                smallScreen && dispatch(ToggleSideMenu());
            } else {
                // Someone tell me why this works
                await dispatch(SetGoBack(back || false));
                setCurrentParent(childCategory);
                scrollingElement?.scrollTo({ behavior: "auto", top: 0, left: 0 });
            }
        } else if (toPath) {
            switch (toPath) {
                case "/products/":
                    await dispatch(SetGoBack(back || false));
                    history.push(toPath);
                    dispatch(ChangeCurrentCategory(defaultParent));
                    setCurrentParent(defaultParent);
                    smallScreen && dispatch(ToggleSideMenu());
                    break;
                default:
                    await dispatch(SetGoBack(back || false));
                    history.push("/products");
                    dispatch(ChangeCurrentCategory(defaultParent));
                    setCurrentParent(defaultParent);
                    smallScreen && dispatch(ToggleSideMenu());
                    break;
            }
        }
    };

    const onClickGoBack = async () => {
        const newParent = grandparent;
        if (newParent) {
            // Someone tell me why this works
            await dispatch(SetGoBack(true));
            setCurrentParent(newParent);
        }
    };

    const Menu = (
        <>
            {!smallScreen && (
                <li
                    onClick={() => onClickCategoryMenuItem(undefined, "/products/", true)}
                    className={`menu-item top-item ${matchingCategory?.id === -1 && "current"} ${
                        currentParent.id !== defaultParent.id && "desktop-only"
                    }`}
                >
                    {"All Products"}
                </li>
            )}
            <TransitionGroup transitionname="catnav" className="transition-group">
                <CSSTransition
                    key={currentParent.id}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, true);
                    }}
                    timeout={500}
                    classNames={`animate-menu-${goBack ? "backwards" : "forwards"}`}
                >
                    <div className={`navigation-menu-list`}>
                        {smallScreen && (
                            <li
                                onClick={() => onClickCategoryMenuItem(undefined, "/products/")}
                                className={`menu-item top-item ${matchingCategory?.id === -1 && "current"} ${
                                    currentParent.id !== defaultParent.id && "desktop-only"
                                }`}
                            >
                                {"All Products"}
                            </li>
                        )}
                        {grandparent && currentParent.id >= 0 && (
                            <>
                                {!smallScreen && <hr />}
                                <li className={`menu-item back top-item`} onClick={() => onClickGoBack()}>
                                    {<i className="fa fa fa-chevron-left"></i>}
                                    {"Back"}
                                </li>
                            </>
                        )}
                        <hr />
                        {smallScreen && currentParent.id >= 0 && (
                            <li className={`menu-item title`}>{currentParent.name}</li>
                        )}
                        {currentParent.id >= 0 && (
                            <li
                                className={`menu-item ${matchingCategory?.id === currentParent.id && "current"}`}
                                onClick={() => onClickCategoryMenuItem(currentParent)}
                            >
                                {`All ${currentParent.name}`}
                            </li>
                        )}
                        {currentParent.subCategories.map((child) => {
                            return (
                                <li
                                    className={`menu-item ${matchingCategory?.id === child.id && "current"}`}
                                    onClick={() => onClickCategoryMenuItem(child)}
                                    key={child.id}
                                >
                                    {child.name}
                                    {child.subCategories.length !== 0 && <i className="fa fa fa-chevron-right"></i>}
                                </li>
                            );
                        })}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );

    return productsList.categoriesFetched && productsList.categories.length === 0 ? (
        <div className={"empty-nav-menu"}></div>
    ) : (
        <div className={"nav-menu"}>
            <ul>{Menu}</ul>
        </div>
    );
};

export default NavigationMenu;
