import React, { SyntheticEvent } from "react";
import { useHistory } from "react-router";
import { CategoriesRoute } from "../../../Routes";

// Styles
import "./Styles/_ProductDetailsContent.scss";
import "../../../Components/Global/Styles/_Buttons.scss";
import { getOrderReducer } from "../../../Redux/Selectors/OrderSelectors";
import { useSelector } from "react-redux";
import { getProductDetailsProductId } from "../../../Redux/Selectors/ProductDetailsSelectors";
import { GetTotalProductOptionPrice } from "../../../Utils/ProductsHelper";
import PriceDisplay from "../../Global/PriceDisplay";

type Props = {
    version: "mobile" | "desktop";
    CloseAction?: (e: SyntheticEvent<Element, Event>, productId: number | null) => void;
    goBackSettings?: {
        specificRoute: string;
        linkText: string;
    };
};

const ProductDetailsStickyFooter = (props: Props) => {
    const history = useHistory();

    const onClickKeepShopping = (e: SyntheticEvent) => {
        if (window.location.pathname === "/cart" && props.CloseAction) {
            history.push(CategoriesRoute);
            history.go(0);
        } else if (props.CloseAction) {
            props.CloseAction(e, null);
        } else if (props.goBackSettings) {
            history.push(props.goBackSettings.specificRoute);
        } else {
            history.goBack();
        }
    };

    const onClickCheckout = (e: SyntheticEvent) => {
        history.push("/cart");
        if (props.CloseAction) {
            props.CloseAction(e, null);
        }
    };

    let findIncrementGroupName = -1;
    const productId = useSelector(getProductDetailsProductId);
    const cartItems = useSelector(getOrderReducer);
    const orderItems = cartItems.orderItems;
    if ( orderItems.length > 0) {
        findIncrementGroupName = orderItems.findIndex((x: {productId: number}) => x.productId === productId);
    }
    let totalItems = 0;
    let totalPrice = 0;
    
    if (findIncrementGroupName !== -1) {
        totalItems = orderItems[findIncrementGroupName].totalItems;
        orderItems[findIncrementGroupName].productOptions.forEach((item: any) => {
            totalPrice += GetTotalProductOptionPrice(item);
        });
    }

    return (
        <div className={`product-details-sticky-footer-${props.version}`}>
            {props.version === "mobile" && <div className="itemTotals">
                <div>{totalItems} items</div>
                <div><PriceDisplay value={totalPrice} /></div>
            </div>}
            <div className={"sticky-buttons"}>
                <button className={"default-button outline"} onClick={(e) => onClickKeepShopping(e)}>
                    Keep Shopping
                </button>
                <button className={"default-button filled"} onClick={(e) => onClickCheckout(e)}>
                    Checkout
                </button>
            </div>
        </div>
    );
};

export default ProductDetailsStickyFooter;
