import React, { Component } from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentState {
    strokeColor: string;
}

class QuoteIcon extends Component<componentProps, componentState> {
    constructor(props: Readonly<componentProps>) {
        super(props);
        this.state = {
            strokeColor: '#000'
        }
    }

    componentDidMount() {
        if(this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }
    
    render() { 
        const {strokeColor} = this.state;
          
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                <path stroke={strokeColor} d="M20.5 2C20.5 1.72386 20.2761 1.5 20 1.5H4C3.72386 1.5 3.5 1.72386 3.5 2V22C3.5 22.2761 3.72386 22.5 4 22.5H20C20.2761 22.5 20.5 22.2761 20.5 22V2Z" strokeLinecap="round" strokeLinejoin="round" />
                <path stroke={strokeColor} d="M6.5 4.5H9.5M14.5 19.5H17.5M6.5 9.5H17.5M6.5 12.5H17.5M6.5 15.5H17.5" strokeLinecap="round" />
            </svg>
        );
    }
}
 
export default QuoteIcon;