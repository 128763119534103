export const LoginPageContent = {
    default_buttonLabel: "Login",
    isAuthenticating_buttonLabel: "Authenticating..."
}

export const ForgotPasswordContent = {
    default_buttonLabel: "Send link",
    isSending_buttonLabel: "Sending...",
    return_linkLabel: "Return to login"
}

export const ResetPasswordContent = {
    default_buttonLabel: "Reset password",
    isUpdating_buttonLabel: "Resetting..."
}

export const RegistrationPageContent = {
    default_buttonLabel: "Create an account",
    default_sectionLegend: "Please enter a password.",
    default_tc_0: "By creating an account you are agreeing to our",
    default_tc_1: "Terms of Service",
    default_tc_2: "and",
    default_tc_3: "Privacy Policy",
    process_buttonLabel: "Processing..."
}

export const ForgotPasswordConfirmationContent = {
    default_message: "If the email doesn’t show up soon, check your spam folder. We sent it to",
    default_buttonLabel: "Return to login"
}

export const ValidationMessages = {
    error_PasswordValidation: "Try making your password longer, for example, using random words combined into a phrase.",
    error_emailAsPassword: "Looks like you've entered an email. Please enter a new password instead.",
    invalid_EmailAddress: "Invalid email address"
}

export const B2BPageTitles = {
    root_title: "Cin7 B2B",
    registration_title: "Create an account",
    products_list_title: "Products List",
    product_details_title: "Product Details",
    order_review_title: "Order Review",
    order_cart_title: "Cart",
    order_checkout_title: "Checkout",
    order_completion_title: "Order Complete",
    seller_terms_and_conditions_title: "Terms and Conditions",
    my_account_title: "My Account",
    order_history_title: "Order History",
    custom_page_1_title: "About Us",
    custom_page_2_title: "Contact Us"
}

export const GlobalElementContent = {
    default_returnLoginLabel: "Return to login",
    default_tryAgainLabel: "Try Again"
}

export const ProductsListPageContent = {
    default_noResultsLabel: "No products found."
}

export const OrderHistoryListPageContent = {
    default_noResultsLabel: "No orders found."
}

export const ProductDetailsPageContent = {
    default_backButtonLabel: "Back"
}

export const ProductActionsContent = {
    default_resetQuantityLabel: "Reset all quantities",
    default_openNewTabLabel: "Open in new tab"
}

export const HeaderContent = {
    default_miniCartTaxLabel: "Tax"
}

export const OrderFooterContent = {
    default_totalQuantityLabel: "Items",
    default_totalDiscountLabel: "Discount",
    default_totalPriceLabel: "Total",
    default_totalFreightLabel: "Freight",
    default_totalTaxLabel: "Tax"
}

export const OrderReviewPageContent = {
    default_quantityTitle: "Qty",
    default_priceTitle: "Price",
    default_discountTitle: "Discount",
    default_totalTitle: "Total",
    default_totalQuantityLabel: "Total Qty",
    default_totalPriceLabel: "Product Total",
    default_noResultsLabel: "No items added to order.",
    default_emptyCartTitle: "Your cart is empty",
    default_emptyCartMessage: "Browse the store to find things to buy, or upload a bulk order if you already know what you want.",
    default_returntohomepageButtonLabel: "Return to homepage",
    default_browseProductsButtonLabel: "Browse products",
    default_uploadBulkOrderButtonLabel: "Upload bulk order",
}

export const OrderCheckoutPageContent = {
    default_totalQuantityLabel: "Total Qty",
    default_totalDiscountLabel: "Discount",
    default_totalTaxLabel: "Tax",
    default_grandTotalLabel: "Total to Pay",
    default_paymentLabel: "Payment",
    default_freightLabel: "Freight Options",
    default_deliveryAddressLabel: "Delivery Address",
    default_billingAddressLabel: "Billing Address",
    default_editDeliveryAddressMessageLabel: "This will not change the address on file - please contact the seller for this."
}

export const OrderCompletionPageContent = {
    default_returntohomepageButtonText: "Continue to homepage",
    default_returnButtonText: "Continue to Product List",
    default_orderSuccessMessage: "Order Successfully Placed"
}

export const AccountDetailsPageContent = {
    default_detailsSectionHeading: "Personal Information",
    default_detailsSectionNote: "Contact the seller to update your details."
}

export const AccountOnHoldContent = {
    default_accountOnHoldMessage: "Account on hold - please contact us to continue buying.",
    default_accountOnHoldPaymentsPortalMessage: "Account on hold - please contact us to continue buying on credit.",
    default_accountOverCreditLimitMessage: "Credit limit reached - please contact us to continue buying.",
    default_accountOverCreditLimitPaymentsPortalMessage: "Credit limit reached - please contact us to continue buying on credit.",
    default_orderTotalOverCreditLimitMessage: "Order total is over credit limit - please contact us to continue buying.",
    default_orderTotalOverCreditLimitPaymentsPortalMessage: "Order total is over credit limit - please contact us to continue buying on credit."
}

export const OrderNotesModalContent = {
    default_addNoteHeader: "Add Note",
    default_editNoteHeader: "Edit Note",
    default_saveNoteButtonLabel: "Save Note",
    default_cancelButtonLabel: "Cancel",
    default_removeNoteButtonLabel: "Remove Note",
}

export const OrderNotesButtonContent = {
    default_editNote: "Edit Note",
    default_addNote: "Add Note",
}

export const UploadBulkOrderModalContent = {
    default_header: "Upload a bulk order",
    default_getHelp: "Get Help",
    default_cancel: "Cancel",
    default_prepareYourOrderTitle: "Step 1: Prepare your order",
    default_prepareYourOrderMessage: "Download the CSV template and paste in the SKUs/Codes/Barcodes for the products you want, along with how much you want to buy.",
    default_downloadTemplateButtonLabel: "Download Template",
    default_uploadYourOrderTitle: "Step 2: Upload your order",
    default_uploadYourOrderMessageDragAndDrop: "When you are ready, save your order file and drag it to the box below. You can also click on the upload file button to continue.",
    default_uploadYourOrderMessageUpload: "When you are ready, save your order file and click on the upload file button to continue.",
    default_uploadPrompt: "Drag your order here to upload it",
    default_uploadPromptSubText: "or click the upload file button to continue",
    default_uploadButtonLabel: "Upload File",
    default_uploadingButtonLabel: "Cancel",
    default_uploadingTitle: "Uploading....",
}

export const ReviewBulkOrderContent = {
    default_header: "Check your bulk order",
    default_subtitle: "You can double-check this before you checkout",
    default_addToOrderButtonLabel: "Add to Order",
    default_removeButtonLabel: "Remove",
}

export const ConfirmBulkOrderModalContent = {
    default_header: "Confirm bulk order",
    default_subtitle: "How do you want to continue?",
    default_cancelButtonLabel: "Cancel",
    default_reuploadButtonLabel: "Re-upload file",
    default_reuploadTitle: "Re-upload File",
    default_reuploadMessage: "This will allow you to upload a new CSV.",
    default_addButtonLabel: "Add",
    default_replaceButtonLabel: "Replace",
    default_replaceTitle: "Replace Cart",
    default_addTitle: "Add to your Cart",
    default_addMessage: "This will add the items you imported into your Cart",
    default_reuploadFileButtonLabel: "Re-upload file",
    default_issuesTitle: "Issues",
    default_warningsTitle: "Warning",
    default_csvReadErrorMessage: "We could not import the following rows to your order - please check the data format and try again",
    default_importErrorMessage: "We could not add the items below to your order - please check your SKUs/Codes/Barcodes and try again",
    default_stockErrorMessage: "No stock available - the following items will not be added into your Cart",
    default_stockWarningMessage: "Insufficient quantity - the following items will be added with the currently available stock",
}

export const UploadBulkOrderContent = {
    default_fileError: "Unable to import the products in the uploaded file - please check the file or contact support",
}

export const SideMenuContent = {
    default_bulkOrderUpload: "Upload a Bulk Order"
}

export const ReorderModalContent = {
    default_retryButtonLabel: 'Retry',
    default_cancelButtonLabel: 'Cancel',
    default_confirmButtonLabel: 'Confirm',
    default_doneButtonLabel: 'Done',
    default_addToCartNote: 'This will add to existing items in your cart.',
}

export const SearchModal = {
    default_autocompleteError: 'Sorry, we were unable to retrieve products.'
}