import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

//Redux
import { connect } from 'react-redux';
import { RegistrationClearState } from '../../../Redux/Actions/IdentityActions';

interface componentProps {
    registration: {
        emailAddress: string;
    }
    RegistrationClearState: () => void;
}

interface componentStates { }

class CreatePasswordConfirmation extends Component<componentProps, componentStates> {

    componentDidMount() {
        this.props.RegistrationClearState();
    }

    render() {
        const { registration } = this.props;

        return (
            <>
                <div className="side-image-theme-text-content">
                    The password for <span className="emphasis email-display">{registration.emailAddress}</span> has been created.
                </div>
                <NavLink href={`/login`}
                    className="login-pages-link">
                    Proceed to login
                </NavLink>
            </>
        );
    }
}

const MapStateToProps = (state: { identityReducer: any }) => ({
    registration: state.identityReducer
});

export default connect(MapStateToProps, { RegistrationClearState })(CreatePasswordConfirmation);