import React, {Component} from 'react';

//Redux
import { connect } from 'react-redux';
import { FormatAmount } from '../../../Utils/OrderHelper';

interface componentProps {
    product: any;
    productOptionId: number;
    cartItems: {
        orderItems: any;
    }
}

interface componentState {}

class ProductOptionSectionInfo extends Component<componentProps, componentState> {

    render() {
        const { cartItems, product, productOptionId } = this.props;

        let findIncrementGroupName = cartItems.orderItems.findIndex((x: {productId: number}) => x.productId === product.id);
        let totalItems:number = 0;

        if (findIncrementGroupName !== -1) {
            let optionGroup = cartItems.orderItems[findIncrementGroupName].productOptions.filter((x: { optionId: number }) => x.optionId === productOptionId);
            if (optionGroup) {
                totalItems = optionGroup.reduce((totalQuantity: number, item: { productQuantity: number; }) => {
                    if(item?.productQuantity){
                        return totalQuantity + item?.productQuantity;
                    }
                    return totalQuantity;
                }, 0);
            }
        }

        return (
            <div className="product-option-quantity">{FormatAmount(totalItems)}</div>
        )

    }
}

const MapStateToProps = (state: {orderReducer: any;}) => ({
    cartItems: state.orderReducer
});

export default connect(MapStateToProps)(ProductOptionSectionInfo);