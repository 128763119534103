import React from 'react';
import { Dialog } from "@cin7/ui"
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetailsId, showProductDetailsModal } from '../../Redux/Selectors/ProductDetailsSelectors';
import ProductDetailsModalContent from '../PageElements/Products/ProductDetailsModal';
import { HideProductDetailsModal } from '../../Redux/Actions/ProductDetailsActions';

export const ProductDetailsModal = () => {
    const visible = useSelector(showProductDetailsModal);
    const productId = useSelector(getProductDetailsId);
    const dispatch = useDispatch();
    const hideModal = () => dispatch(HideProductDetailsModal)

    if (!visible) return null;

    return (
        <Dialog open onClose={(e, reason) => hideModal()} className="product" fullWidth maxWidth={'lg'}>
            <div className="product-details-modal-content">
                <ProductDetailsModalContent
                    productId={productId}
                    CloseAction={hideModal}
                />
            </div>
        </Dialog>
    )
}