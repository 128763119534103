import axios from "axios";
import APIHost from "../APIHost";
import ApiUtil from "../Utils/ApiUtil";

export async function ApproveAndProcessOrder(orderId: number): Promise<boolean> {
    const headers = new ApiUtil().DefaultHeaders('');

    if (headers === null) {
        throw new Error ('Unauthenticated');
    }

    try {
        const result = await axios.post(new APIHost().CheckoutAPI("ApproveOrder"), { moduleId: new ApiUtil().GetModuleId(), orderId: orderId }, headers);
        return result.data;
    } catch (e) {
        return Promise.reject(e);
    }
}