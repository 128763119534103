import { useSelector } from "react-redux";
import APIHost from "../APIHost";
import { HeaderContent } from "../Language/Default_Settings";
import { getCartItemsTotal, getOrderReducer } from "../Redux/Selectors/OrderSelectors";
import { getBranchInfo, getUserInfo, getUserPriceTier } from "../Redux/Selectors/UserSelectors";
import { useCurrentFreightPrice } from "./FreightHelper";
import { GetTotalProductOptionPrice } from "./ProductsHelper";

export const FormatMoney = (value: number, currencyCode: string) => {

    value = Number(value?.toFixed(6));
    
    var locale;

    // Get the locale based on the currencyCode. This is to stop the region prefixing the value e.g. $10 NZD displays as "NZ$10.00" instead of just "$10.00" if the locale is not "en-NZ".
    // As a future improvement we should either get the locale from Cin7 or lookup the locale using an extension.
    switch (currencyCode) {
        case "NZD":
            locale = "en-NZ";
            break;
        case "AUD":
            locale = "en-AU";
            break;
        case "CAD":
            locale = "en-CA";
            break;
        default:
            locale = "en-US";
            break;
    }

    return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode, minimumFractionDigits: 2 }).format(value);
};

export const FormatAmount = (value:number) => {
    return new Intl.NumberFormat('en-US').format(value);
}

export const UndoFormatAmount = (value:string) => {
    return Number(value.replace(/,/g, ''));
}

export const GetOrderTotalTax = (orderItems: any[], userInfo: any, branchInfo: any, freightPrice: number) => {

    let totalTax = 0;
    
    let taxRate = userInfo.priceTier.defaultTaxRate;

    if (userInfo.taxRate != null) {
        // Member level tax rate.
        taxRate = userInfo.taxRate;
    } else if (branchInfo != null && branchInfo.taxRate != null) {
        // Branch level tax rate.
        taxRate = branchInfo.taxRate;
    }

    let taxStatus = userInfo.priceTier.taxStatus;

    orderItems.forEach((item: {product: {taxRate: number | null}, productOptions: []}) => {
        totalTax += item.productOptions.reduce((totalOptionTax: number, option: any) => {
            let productTaxRate = taxRate;

            if (item.product.taxRate != null) {
                productTaxRate = item.product.taxRate;
            }

            let optionTotal = GetTotalProductOptionPrice(option);

            switch (taxStatus) {
                case "Excl":
                    totalOptionTax += optionTotal * productTaxRate;
                    break;
                case "Incl":
                    totalOptionTax += optionTotal - (optionTotal / (1 + productTaxRate));
                    break;
                default:
                    break;
            }

            return totalOptionTax;
        }, 0)
    }, 0);

    switch (taxStatus) {
        case "Excl":
            totalTax += freightPrice * taxRate;
            break;
        case "Incl":
            totalTax += freightPrice - (freightPrice / (1 + taxRate));
            break;
        default:
            break;
    }

    return totalTax;
};

export const GetTaxLabel = (taxStatus: string) => {
    let taxLabel = HeaderContent.default_miniCartTaxLabel + " " + taxStatus;

    switch (taxStatus) {
        case "Excl":
        case "Incl":
            taxLabel += ".";
            break;
        default:
            break;
    }

    return taxLabel;
}

export const FormatDate = (value: string | null) => {

    if (value) {
        let date = new Date( Date.parse(value) );

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    
        return `${da} - ${mo} - ${ye}`;
    }

    return "";
};

export const GetPaymentsPortalAddress = () => {
    const apiHost = new APIHost();
    const appSettings = apiHost.GetEnvironmentConfig();
    return appSettings.PaymentsPortalAddress;
}

export const getIsPayAccountOnHold = (userIsOnHold: boolean, remainingCreditLimit: number, cartTotal: number) => {
    if (userIsOnHold) {
        return true;
    }
    else if (remainingCreditLimit && cartTotal > remainingCreditLimit) {
        return true;
    }
    return false;
}

export const useCartTaxTotal = () => {
    const cart = useSelector(getOrderReducer);
    const orderItems = cart.orderItems;
    const userInfo = useSelector(getUserInfo);
    const branchInfo = useSelector(getBranchInfo);
    const priceTier = useSelector(getUserPriceTier);

    const freightPrice = useCurrentFreightPrice();

    let totalTax = 0;

    if (priceTier) {
        totalTax = GetOrderTotalTax(orderItems, userInfo, branchInfo, freightPrice);
    }

    return totalTax;
}

export const useCartGrandTotal = () => {
    const cart = useSelector(getOrderReducer);
    const orderItems = cart.orderItems;
    const userInfo = useSelector(getUserInfo);
    const branchInfo = useSelector(getBranchInfo);
    const priceTier = useSelector(getUserPriceTier);

    const totalItemsPrice = useSelector(getCartItemsTotal);

    const freightPrice = useCurrentFreightPrice();
    
    let totalTax = 0;

    if (priceTier) {
        totalTax = GetOrderTotalTax(orderItems, userInfo, branchInfo, freightPrice);
    }

    let grandTotal = totalItemsPrice + freightPrice;

    if (totalTax && priceTier && priceTier.taxStatus === "Excl") {
        grandTotal = totalItemsPrice + freightPrice + totalTax;
    }

    return grandTotal;
};
