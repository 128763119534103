import React from 'react';
import { useSelector } from 'react-redux';
import { AccountOnHoldContent } from '../../../Language/Default_Settings';
import { getCartItemsTotal } from '../../../Redux/Selectors/OrderSelectors';
import { getIsPaymentsPortalEnabled, getUserIsOnHold, getUserRemainingCreditLimit } from '../../../Redux/Selectors/UserSelectors';
import Cin7ConfirmationBox from '../Cin7ConfirmationBox';

import './Styles/_AccountOnHoldBanner.scss';

export const AccountOnHoldBanner: React.FC = () => {

    const userIsOnHold = useSelector(getUserIsOnHold);
    const userRemainingCreditLimit = useSelector(getUserRemainingCreditLimit);
    const cartItemsTotal = useSelector(getCartItemsTotal);
    const isPaymentsPortalEnabled = useSelector(getIsPaymentsPortalEnabled).active;

    if (userRemainingCreditLimit && userRemainingCreditLimit <= 0) {
        return (
            <div className="account-on-hold-banner">
                <Cin7ConfirmationBox
                    boxType={"warning-box"}
                    boxIcon={"warning-icon"}
                    boxMessage={isPaymentsPortalEnabled ? AccountOnHoldContent.default_accountOverCreditLimitPaymentsPortalMessage : AccountOnHoldContent.default_accountOverCreditLimitMessage}
                />
            </div>
        );
    }
    else if (userIsOnHold) {
        return (
            <div className="account-on-hold-banner">
                <Cin7ConfirmationBox
                    boxType={"warning-box"}
                    boxIcon={"warning-icon"}
                    boxMessage={isPaymentsPortalEnabled ? AccountOnHoldContent.default_accountOnHoldPaymentsPortalMessage : AccountOnHoldContent.default_accountOnHoldMessage}
                />
            </div>
        );
    }
    else if (userRemainingCreditLimit && userRemainingCreditLimit > 0 && cartItemsTotal > 0 && cartItemsTotal > userRemainingCreditLimit) {
        return (
            <div className="account-on-hold-banner">
                <Cin7ConfirmationBox
                    boxType={"warning-box"}
                    boxIcon={"warning-icon"}
                    boxMessage={isPaymentsPortalEnabled ? AccountOnHoldContent.default_orderTotalOverCreditLimitPaymentsPortalMessage : AccountOnHoldContent.default_orderTotalOverCreditLimitMessage}
                />
            </div>
        );
    }
    else {
        return null;
    }
}
