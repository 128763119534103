import React, { Component } from 'react';
import { connect } from 'react-redux';
import { B2BPageTitles } from '../../../Language/Default_Settings';
import ReturnPanel from '../../../Components/Global/ReturnPanel';
import HtmlBlock from '../../../Components/Global/HtmlBlock';

interface componentProps {
  identity: {
    moduleSettings: {
      customPage1Title: string;
      customPage1Content: string;
    }
  };
}

interface componentState {}

class CustomPage1 extends Component<componentProps, componentState> {
  componentDidMount() {
    this.setPageTitle();
  }

  componentDidUpdate() {
    this.setPageTitle();
  }

  render() {
    const { identity } = this.props;

    return (
      <>
        <ReturnPanel title={identity.moduleSettings.customPage1Title ? identity.moduleSettings.customPage1Title : B2BPageTitles.custom_page_1_title} />
        <div id="Seller-Content-Container">
          <div id="Seller-Content">
            <HtmlBlock content={identity.moduleSettings.customPage1Content} />
          </div>
        </div>
      </>
    );
  }

  setPageTitle() {
    document.title = `${B2BPageTitles.root_title} - ${this.props.identity.moduleSettings.customPage1Title ? this.props.identity.moduleSettings.customPage1Title : B2BPageTitles.custom_page_1_title}`;
  }
}

const MapStateToProps = (state: {identityReducer: any;}) => ({
  identity: state.identityReducer
});

export default connect(MapStateToProps,{})(CustomPage1);
