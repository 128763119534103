import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

//Redux
import { connect } from 'react-redux';
import { ForgotPasswordConfirmationContent } from '../../../Language/Default_Settings';

interface componentProps {
    forgotPassword: {
        emailAddress: string;
    }
}

class ForgotPasswordConfirmation extends Component<componentProps, {}> {
    render() { 
        const { forgotPassword } = this.props;

        if (forgotPassword.emailAddress) {
            return (
                <>
                    <div className="side-image-theme-text-content">
                        {ForgotPasswordConfirmationContent.default_message} <span className="emphasis email-container email-display">{forgotPassword.emailAddress}</span>
                    </div>
                    <Link to={'/login'} className="login-pages-link">
                        {ForgotPasswordConfirmationContent.default_buttonLabel}
                    </Link>
                </>
            );
        }
        else {
            return (
                <Redirect to={
                    {
                        pathname: '/login/forgot-password'
                    }
                } />
            );
        }
    }
}

const MapStateToProps = (state: {identityReducer: any}) => ({
    forgotPassword: state.identityReducer
});

export default connect(MapStateToProps)(ForgotPasswordConfirmation);