import * as headerTypes from "./../../Types/HeaderTypes";

export type AppSettingsStateType = {
    isMenuOpen: boolean;
};

export const initialState: AppSettingsStateType = {
    isMenuOpen: false,
};

export const AppSettings = (
    state = initialState,
    action: { type: string; payload: any | undefined }
): AppSettingsStateType => {
    switch (action.type) {
        case headerTypes.Side_Menu_Toggle_Status:
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen,
            };
        case headerTypes.Side_Menu_Reset_Status:
            return {
                ...state,
                isMenuOpen: initialState.isMenuOpen,
            };
        default:
            return state;
    }
};
