import { OrderItemNote } from "../Reducers/App_Reducers/OrderReducer";

export const getStockCount = (optionId: number, productId: number, sizeId: number) => (state:any) => {
    state.productDetailsReducer?.product.find((orderItemNote: OrderItemNote) => orderItemNote.optionId === optionId && orderItemNote.productId === productId);
}

export const getProductOptionsByOptionId = (options: any[], optionId: number) => () => {
    return options.filter((option: any) => option.optionId === optionId);
}

export const productOptionsHaveSpecial = (options: any[]) => () => {
    return options.some((option: any) => option.specialPrice > 0);
}

export const getOptionsMinPrice = (options: any[]) => () => {
    return options.reduce((minPrice: number, option: any) => { return option.price < minPrice ? option.price : minPrice; }, options[0].price );
}

export const getOptionsMaxPrice = (options: any[]) => () => {
    return options.reduce((maxPrice: number, option: any) => { return option.price > maxPrice ? option.price : maxPrice; }, options[0].price );
}

export const getOptionsMinSpecialPrice = (options: any[]) => () => {
    return options.reduce((minSpecialPrice: number, option: any) => { return option.specialPrice > 0 && option.specialPrice < minSpecialPrice ? option.specialPrice : minSpecialPrice; }, options[0].specialPrice );
}

export const getOptionsMaxSpecialPrice = (options: any[]) => () => {
    return options.reduce((maxSpecialPrice: number, option: any) => { return option.specialPrice > maxSpecialPrice ? option.specialPrice : maxSpecialPrice; }, options[0].specialPrice );
}

export const getIsFetchingCategories = (state: any) => state.productListReducer?.isFetchingCategories;
