import * as headerTypes from "./../Types/HeaderTypes";

//Toggle Actions
export const ToggleSideMenu = () => (dispatch: any) => {
    dispatch({
        type: headerTypes.Side_Menu_Toggle_Status,
    });
};

export const ResetSideMenu = () => (dispatch: any) => {
    dispatch({
        type: headerTypes.Side_Menu_Reset_Status,
    });
};
