import React, { Component } from 'react';
class ShowPasswordIcon extends Component {
    render() { 

        const cls1 = {
            fill: 'none',
            stroke: '#373839',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        };

        const cls2 = {
            fill: '#373839',
            stroke: '#373839',
            strokeMiterlimit:10
        };

        return ( 
            <svg aria-labelledby="ShowPasswordIcon" xmlns="https://www.w3.org/2000/svg" width="19.676" height="11.111" viewBox="0 0 19.676 11.111">
                <title id="ShowPasswordIcon">Show password</title>
                <g id="ShowPassword" data-name="Show Password" transform="translate(-534.875 -783.924)">
                    <g id="Group_290" data-name="Group 290" transform="translate(540.63 785.264)">
                        <path id="Path_4962" d="M284.136 352.272a4.082 4.082 0 1 1-7.5 2.23 4.069 4.069 0 0 1 .716-2.31" style={cls1} data-name="Path 4962" transform="translate(-276.636 -352.192)"/>
                    </g>
                    <path id="Path_4967" d="M284.8 355.569s-4.181 5.055-9.338 5.055-9.338-5.055-9.338-5.055 4.181-5.056 9.338-5.056 9.338 5.056 9.338 5.056z" style={cls1} data-name="Path 4967" transform="translate(269.247 433.911)"/>
                    <g id="Group_291" data-name="Group 291" transform="translate(544.046 786.993)">
                        <g id="Group_287" data-name="Group 287">
                            <path id="Path_4966" d="M284.746 356.291a.639.639 0 1 1-.639-.64.637.637 0 0 1 .639.64z" style={cls2} data-name="Path 4966" transform="translate(-283.467 -355.651)"/>
                        </g>
                    </g>
                </g>
            </svg>
         );
    }
}
 
export default ShowPasswordIcon;