import React from 'react';
import { DecoratedRadioButton } from '@cin7/ui';
import { PaymentMethodType } from '../../../Enums/PaymentMethods/PaymentMethodType';
import { PayOnlinePaymentIcon } from '../../../Images/Icons/Orders/PayOnlinePaymentIcon';

interface PayOnlinePaymentOptionButtonProps {
    payOnlineMethodCode: PaymentMethodType,
    payOnlineChecked: boolean,
    onClickEventHandler: () => void
}

export const PayOnlinePaymentOptionButton: React.FC<PayOnlinePaymentOptionButtonProps> = ({payOnlineMethodCode, payOnlineChecked, onClickEventHandler}) => {
    return (
        <DecoratedRadioButton
            icon={<PayOnlinePaymentIcon />}
            message="Pay with a credit card or debit card online"
            title="Pay Online"
            value={payOnlineMethodCode}
            checked={payOnlineChecked}
            onClick={() => {
                onClickEventHandler();
            }}
            css={null}
        />
    )
}