import React, { SyntheticEvent, useState, useEffect, useRef, useMemo, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { SearchIcon } from '../../Images/Icons/Global/SearchIcon';
import ClearInputIcon from '../../Images/Icons/ClearInputBoldIcon';
import { useHistory } from 'react-router';
import { debounce, escapeRegExp } from 'lodash-es'
import { Button, Typography } from '@cin7/ui';

import { ProductNameSearch } from '../../Domain/ProductNameSearch';
import { StripHTMLFromInputs } from '../../Utils/B2BHelpers';
import Loading from './Loading'
import { ShowProductDetails } from '../../Redux/Actions/ProductDetailsActions';

import './Styles/_SearchModal.scss';

interface componentProps {
    closeModal: (e?: SyntheticEvent) => void;
}

type AutoCompleteResultsType = {
    title: string,
    code: string,
    id: number,
};

export const SearchModal = (props: componentProps) => {
    const [query, setQuery] = useState("");
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState<string>();
    const dispatch = useDispatch();
    const showProductDetails = (productId: number) => dispatch(ShowProductDetails(productId));
    const [autoCompleteResults, setAutoCompleteResults] = useState<AutoCompleteResultsType[]>([]);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const closeModal = props.closeModal;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef && !wrapperRef.current?.contains(event.target as Node)) {
                closeModal()
            }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        // Remove the listener on unmount
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [closeModal])

    const fetchProductNames = async (query: string) => {
        try {
            setError(undefined);
            setFetching(true);
            const result = await ProductNameSearch(query);
            const mappedProductOptions = result.products.map(p => ({title: p.name, code: p.styleCode, id: p.id}));
            setAutoCompleteResults(mappedProductOptions)
        } catch {
            setAutoCompleteResults([]);
            setError("Unable to load products");
        } finally {
            setFetching(false);
        }
    }

    const debouncedFetchProductNames = useMemo(
        () => debounce(fetchProductNames, 350)
    , []);

    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
      return () => {
        debouncedFetchProductNames.cancel();
      }
    }, [debouncedFetchProductNames]);

    const _handleSearch = (query: string) => {
        setFetching(true);
        setQuery(query);
        debouncedFetchProductNames(query);
    }

    const _handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
            setQuery("");
            return;
        }
        
        _handleSearch(e.target.value);
    }

    const _handleOptionSelected = (_: React.MouseEvent<HTMLDivElement>, id: number) => {
        props.closeModal();
        showProductDetails(id);
    }

    const _navigate = (e: SyntheticEvent, title?: string) => {
        e.preventDefault();
        props.closeModal(e);
        history.push(`/products/?query=${encodeURIComponent(title ?? "")}`)
    }

    const _onClear = (e:React.MouseEvent<any, MouseEvent>) => {
        e.preventDefault();

        let targetFormGroup = e.currentTarget.parentNode;
        let targetInput = targetFormGroup.querySelector(".cin7-input");

        setQuery("");
 
        targetInput.focus();
    }

    const withBoldMatchedChars = (title: string) => {
        // Match case insensitive
        const regex = new RegExp("(" + escapeRegExp(query) + ")", "gi");
        return title.replace(regex, '<b>$1</b>');
    }

    const getProductCodeText = (code: string) => {
        return code !== "" ? " - " + code : code;
    }

    return (
        <div id="Search-Modal" ref={wrapperRef}>
            <div className="modal-overlay" onClick={props.closeModal}></div>
            <div className="search-background"></div>
            <div className="search-container">
                <div className="search-input-container">
                    <div className="search-button" onClick={(e) => _navigate(e, query)}><SearchIcon /></div>
                    <form className="search-label" onSubmit={(e) => _navigate(e, query)}>
                        <input className="cin7-input" value={query} onChange={_handleChange} autoFocus />
                    </form>
                    <div className="clear-icon" onClick={_onClear}><ClearInputIcon fillColor="#B2B2B2" /></div>
                    <div className="cancel-button" onClick={props.closeModal} >Cancel</div>
                </div>
            </div>
            <div className="search-results">
                {query.length > 0 && (
                    <>
                        <div className="results">
                            {fetching ? (
                                <Loading inLine />
                            )   :   (
                                !!error ? (
                                    <>
                                        <Typography variant={"h2"}>Sorry, we were unable to load products</Typography>
                                        <Typography variant={'body1'} gutterBottom>Please try again or contact us with a screenshot</Typography>
                                        <Button onClick={() => {setError(""); _handleSearch(query)}}>Try Again</Button>
                                    </>
                                ) : (
                                    <>
                                    <div className="products">
                                        {autoCompleteResults.length === 0 && (
                                            <Typography variant={"h4"}>No products found matching "{query}"</Typography>
                                        )}
                                        {autoCompleteResults.map((result: any) => {
                                            return <div className="result" key={result.id} onClick={(e) => _handleOptionSelected(e, result.id) } dangerouslySetInnerHTML={{ __html: withBoldMatchedChars(StripHTMLFromInputs(result.title)) + "<span class=\"result-sku\">" + withBoldMatchedChars(StripHTMLFromInputs(getProductCodeText(result.code))) + "</span>" }}></div>
                                        })}
                                        </div>
                                        
                                        {autoCompleteResults.length === 12 && (
                                            <Button fullWidth variant="secondary" className="view-more-button" onClick={(e) => _navigate(e, query)} >View More</Button>
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default SearchModal;