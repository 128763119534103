import React, { Component } from 'react';
import LoadingSpinnerIcon from '../../Images/Icons/Global/LoadingSpinnerIcon';
import './Styles/_LoadingSpinner.scss';

class LoadingSpinner extends Component<{size?: number}> {

    render() {
        return (
            <div className="loading-spinner-container">
                <LoadingSpinnerIcon fillColor={'#b1b1b2'} size={this.props.size} />
            </div>
        );
    }
}

export default LoadingSpinner;