import React, {Component, CSSProperties} from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentStates extends componentProps {}

class CaretDownIcon extends Component<componentProps,componentStates> {

    constructor(props:componentProps) {
        super(props);
        this.state = {
            strokeColor: "#000"
        }
    }

    componentDidMount() {
        if(this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }

    render() {

        const { strokeColor } = this.state;
        
        const cls1: CSSProperties = {
            stroke: strokeColor
        }

        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                <title>More</title>
                <path d="M8 10L12 14.5L16 10" style={cls1} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        )
    }
}

export default CaretDownIcon;