import React, { Component } from 'react';
import {RouteComponentProps} from 'react-router-dom'
import { withRouter } from 'react-router';

import BackArrowIcon from '../../Images/Icons/NavigationIcons/BackArrowIcon';

import './Styles/_GoBack.scss';

interface componentProps extends RouteComponentProps<any> {
    goBackAttributes?: {
        specificRoute: string;
        linkText: string;
    };
}

class GoBack extends Component<componentProps,{}> {

    render() {

        const { goBackAttributes } = this.props;

        const RenderButton = () => {
            if(goBackAttributes) {
                return(
                    <button className={`go-back-button`} onClick={() => this.props.history.push(goBackAttributes.specificRoute)}>
                        <div className="back-icon"><BackArrowIcon strokeColor="#393939" /></div>
                        {goBackAttributes.linkText && <div className="link-text">{goBackAttributes.linkText}</div>}
                    </button>
                );
            }
            
            return(
                <button className="go-back-button" onClick={() => this.props.history.goBack()}>
                    <div className="back-icon"><BackArrowIcon strokeColor="#393939" />Back</div>
                </button>
            );
        }

        return <RenderButton />
    }
    
}

export default withRouter(GoBack);