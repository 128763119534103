import React, { SyntheticEvent } from "react";
import { useSelector } from "react-redux";
import Cin7ConfirmationBox from "../../Components/PageElements/Cin7ConfirmationBox";
import SidePageBanner from "../../Components/PageElements/Login/SidePageBanner";
import { RootState } from "../..";
import "./Styles/_ContactUs.scss";
import { useHistory } from "react-router";
import StoreHeading from "./Views/StoreHeading";
import { GetTenantImageUrl } from "../../Utils/LoginHelper";

export const ContactUsConfirmation = () => {
  const brandingInfo = useSelector((state: RootState) => state.brandingReducer);
  const history = useHistory();

  const handleReturnToLoginClick = (e: SyntheticEvent) => {
    history.push("/login");
  };

  let logoImageUrl = GetTenantImageUrl(brandingInfo.loginPageImageUrl);
  return (
    <div id="Side-Image-Theme">
      <div className="side-image-theme-container customer-page">
        <SidePageBanner customerBanner={brandingInfo.loginBannerImageUrl} />

        <div className="side-image-theme-content">
          <div className="side-image-theme-header">
            <StoreHeading
              logoImageUrl={logoImageUrl}
              storeName={brandingInfo.storeName}
            />
          </div>
          <div className="side-image-theme-body">
            <Cin7ConfirmationBox
              boxIcon="success"
              boxMessage="Thank you! We have received your message."
            />
            <div className="return-to-login">
              <button
                className="default-button filled contact-us-button"
                onClick={(e) => handleReturnToLoginClick(e)}
              >
                Return to login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
