import React from 'react';
import { useSelector } from 'react-redux';
import { getUserCurrency } from '../../Redux/Selectors/UserSelectors';
import { FormatMoney } from '../../Utils/OrderHelper';
import LoadingSpinner from './LoadingSpinner';

interface PriceDisplayProps {
    value: number;
    isLoading?: boolean;
};

export const PriceDisplay = (props: PriceDisplayProps) => {

    const {
        value,
        isLoading,
    } = props;

    const userCurrencyCode = useSelector(getUserCurrency);

    if (isLoading) {
        return <LoadingSpinner size={26} />;
    }

    if (userCurrencyCode) {
        return <>{FormatMoney(value, userCurrencyCode)}</>;
    }
    else {
        return null;
    }
};

export default PriceDisplay;
