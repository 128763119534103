import React from 'react';
import Img from 'react-image';
import { EmptyCategoryImage } from '../../../Images/Icons/CustomHomepage/EmptyCategoryImage';
import LoadingSpinner from '../../Global/LoadingSpinner';

import './Styles/_CustomHomepageImage.scss';

type CustomHomepageImageProps = {
    imagePath: string;
    altText: string;
};

export const CustomHomepageImage = (props: CustomHomepageImageProps) => {

    return (
        <div className="homepage-image-container">
            <Img src={props.imagePath} alt={props.altText} loader={<LoadingSpinner />} unloader={<EmptyCategoryImage />} />
        </div>
    );
};