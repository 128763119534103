import { AxiosError } from "axios";
import * as types from "../../Redux/Types/AuthTypes";

export const unauthorizeIfNeeded = (error: AxiosError, dispatch: any) => {
    if (error.response) {
        let errorStatus = error.response.status;

        if (errorStatus === 401) {
            dispatch({
                type: types.Deauthorize_B2B_User,
                payload: {
                    message: "Failed to authenticate user. Please login again.",
                },
            });
        } else if (errorStatus === 403) {
            dispatch({
                type: types.Deauthorize_B2B_User,
                payload: {
                    message: "Failed to authenticate user. Please login again.",
                },
            });
        }
    }
};
