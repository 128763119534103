import React from 'react';

export const DeliveryAddressIcon: React.FC = () => {
    return (
        <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Delivery</title>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.34766 19.8261C8.34766 19.2498 8.81484 18.7826 9.39113 18.7826H38.6085C39.1848 18.7826 39.652 19.2498 39.652 19.8261V38.6087C39.652 39.185 39.1848 39.6522 38.6085 39.6522H9.39113C8.81484 39.6522 8.34766 39.185 8.34766 38.6087V19.8261ZM10.4346 20.8695V37.5652H37.565V20.8695H10.4346Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5645 24C13.5645 23.4237 14.0316 22.9565 14.6079 22.9565H33.3905C33.9668 22.9565 34.434 23.4237 34.434 24C34.434 24.5763 33.9668 25.0435 33.3905 25.0435H14.6079C14.0316 25.0435 13.5645 24.5763 13.5645 24Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5645 28.174C13.5645 27.5977 14.0316 27.1305 14.6079 27.1305H33.3905C33.9668 27.1305 34.434 27.5977 34.434 28.174C34.434 28.7503 33.9668 29.2174 33.3905 29.2174H14.6079C14.0316 29.2174 13.5645 28.7503 13.5645 28.174Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5645 32.3478C13.5645 31.7715 14.0316 31.3043 14.6079 31.3043H33.3905C33.9668 31.3043 34.434 31.7715 34.434 32.3478C34.434 32.9241 33.9668 33.3913 33.3905 33.3913H14.6079C14.0316 33.3913 13.5645 32.9241 13.5645 32.3478Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.04348 12.5217C1.61978 12.5217 2.08696 12.9889 2.08696 13.5652V36.5217C2.08696 36.7985 2.19689 37.0639 2.39258 37.2596C2.58827 37.4553 2.85368 37.5652 3.13043 37.5652H44.8696C45.1463 37.5652 45.4117 37.4553 45.6074 37.2596C45.8031 37.0639 45.913 36.7985 45.913 36.5217V13.5652C45.913 12.9889 46.3802 12.5217 46.9565 12.5217C47.5328 12.5217 48 12.9889 48 13.5652V36.5217C48 37.352 47.6702 38.1482 47.0831 38.7353C46.496 39.3224 45.6998 39.6522 44.8696 39.6522H3.13043C2.30019 39.6522 1.50395 39.3224 0.916883 38.7353C0.329812 38.1482 0 37.352 0 36.5217V13.5652C0 12.9889 0.467181 12.5217 1.04348 12.5217Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.2384 12.5217H45.7616C45.7201 12.3377 45.6672 12.1339 45.5999 11.9136C45.2796 10.8653 44.6322 9.44337 43.3177 8.00945C40.7082 5.16265 35.2929 2.08696 24 2.08696C12.7071 2.08696 7.29181 5.16265 4.68225 8.00945C3.36782 9.44337 2.72043 10.8653 2.40011 11.9136C2.3328 12.1339 2.27993 12.3377 2.2384 12.5217ZM46.9565 13.5652C48 13.5652 48 13.5642 48 13.5632L48 13.5609L48 13.5554L47.9998 13.541L47.9988 13.4985C47.9978 13.4642 47.9959 13.4181 47.9925 13.3609C47.9855 13.2467 47.9721 13.0882 47.9463 12.8917C47.8949 12.4989 47.794 11.9525 47.5958 11.3038C47.1987 10.0043 46.4113 8.29576 44.8562 6.59924C41.7266 3.18517 35.6636 0 24 0C12.3364 0 6.2734 3.18517 3.14384 6.59924C1.5887 8.29576 0.801311 10.0043 0.404242 11.3038C0.206032 11.9525 0.105107 12.4989 0.0536706 12.8917C0.0279357 13.0882 0.0145271 13.2467 0.00754763 13.3609C0.00405681 13.4181 0.0021704 13.4642 0.00115648 13.4985L0.000198033 13.541L3.81262e-05 13.5554L8.0855e-06 13.5609L1.86588e-06 13.5632C4.35373e-07 13.5642 0 13.5652 1.04348 13.5652H0C0 14.1415 0.467181 14.6087 1.04348 14.6087H46.9565C47.5328 14.6087 48 14.1415 48 13.5652H46.9565Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.8262 7.30434C19.8262 6.72805 20.2934 6.26086 20.8697 6.26086H27.1305C27.7068 6.26086 28.174 6.72805 28.174 7.30434C28.174 7.88064 27.7068 8.34782 27.1305 8.34782H20.8697C20.2934 8.34782 19.8262 7.88064 19.8262 7.30434Z" fill="black" />
        </svg>
    );
}
