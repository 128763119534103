import { Link } from '@cin7/ui';
import React from 'react';
import { GetProductImageUrl } from '../../../Utils/ProductsHelper';
import './Styles/_ProductAttachment.scss';

type Props = {
    attachment?: {
        description: string;
        path: string;
    }
};

export const ProductAttachment = (props: Props) => {
    if (!props.attachment) {
        return null;
    }

    const attachmentLink = GetProductImageUrl(props.attachment.path);

    return (
        <div className={"product-details-attachment-container"}>
            <div className={"product-attachment"}>
                <div className={"attachment-label"}><i className={"icon fas fa-list-alt"}></i></div>
                <Link id="attachment-description" href={attachmentLink} target="_blank" rel="noopener noreferrer">
                    {props.attachment.description}
                </Link>
            </div>
        </div>
    );
};
