import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FeaturedCategoryType } from "../../../Redux/Reducers/App_Reducers/IdentityReducer";
import { getIsFetchingCategories } from "../../../Redux/Selectors/ProductSelectors";
import { getCustomHomepageSettings } from "../../../Redux/Selectors/SettingsSelectors";
import { GetCurrentUrl } from "../../../Utils/B2BHelpers";
import { findCategoryFromId, useCategoryRouteObjects, useDefaultParent } from "../../../Utils/CategoriesHelper";
import { useHomepageImageUrl } from "../../../Utils/CustomHomepageHelper";
import Loading from "../../Global/Loading";
import { CustomHomepageImage } from "./CustomHomepageImage";
import "./Styles/_CustomHomepageContent.scss";

export const CustomHomepageContent = () => {
    const history = useHistory();
    const customHomepageSettings = useSelector(getCustomHomepageSettings);
    const isFetchingCategories = useSelector(getIsFetchingCategories);

    const Banner = () => {
        const currentHost = GetCurrentUrl();
        const banner = customHomepageSettings.banner;
        const homepageImagePath = useHomepageImageUrl();
        const bannerImagePath = GetHomepageImageUrl(homepageImagePath, banner?.imageName ?? null);

        if (banner && bannerImagePath) {
            const bannerImage = <CustomHomepageImage imagePath={bannerImagePath} altText={banner.imageAltText} />;

            if (banner.imageLink) {
                const linkIsInternal = banner.imageLink.startsWith(currentHost.origin) ||
                    banner.imageLink.startsWith(currentHost.host) ||
                    banner.imageLink.startsWith("/");

                return (
                    <div className="homepage-banner-container">
                        <a href={banner.imageLink}
                            rel="noopener noreferrer"
                            target={linkIsInternal ? '_self' : '_blank'}>
                            {bannerImage}
                        </a>
                    </div>
                );
            }
            else {
                return (
                    <div className="homepage-banner-container">
                        {bannerImage}
                    </div>
                );
            }
        }
        else {
            return null;
        }
    };

    const GetHomepageImageUrl = (path: string, imageName: string | null) => {
        if (imageName === null || imageName === "") return null;
        return `${path}${imageName}`;
    };

    const GetCategoryName = (defaultParent: any, categoryId: number) => {
        const category = findCategoryFromId(defaultParent, categoryId);
        return category?.name;
    };

    const GetCategoryLink = (categoryRouteObjects: any, categoryId: number) => {        
        const categoryRoute = categoryRouteObjects.find((cat: any) => cat.id === categoryId);
        return categoryRoute?.path;
    };

    const onClickCategoryTile = async (toPath: string | undefined) => {
        if (toPath === undefined) history.push("/products/");
        history.push(`/products/?t=${toPath}`);
    };

    const FeaturedCategories = () => {
        const heading = customHomepageSettings.featuredCategoriesHeading;
        const featuredCategories = customHomepageSettings.featuredCategories;
        const defaultParent = useDefaultParent();
        const categoryRouteObjects = useCategoryRouteObjects();
        const homepageImagePath = useHomepageImageUrl();

        if (featuredCategories?.some) {

            const activeCategories = featuredCategories.filter((cat) => findCategoryFromId(defaultParent, cat.categoryID) !== undefined);

            if (activeCategories.length > 0) {
                return (
                    <div className="homepage-categories-container">
                        {heading && <h2>{heading}</h2>}
                        <div className="categories-container">
                            {activeCategories.map((category: FeaturedCategoryType, index) => {
                                const id = category.categoryID;
                                const name = GetCategoryName(defaultParent, id);
                                const imageUrl = GetHomepageImageUrl(homepageImagePath, category.imageName ?? null);
                                const route = GetCategoryLink(categoryRouteObjects, id);

                                return (
                                    <div className="category-tile" key={index} onClick={() => onClickCategoryTile(route)}>
                                        <div className="category-image-overlay">
                                            {<CustomHomepageImage imagePath={imageUrl || ""} altText={category.imageAltText} />}
                                        </div>
                                        <div className="category-title">
                                            {name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }
        }

        return null;
    };

    return (
        <div className="custom-homepage-content">
            <div className="custom-homepage-content-inner">
                {isFetchingCategories && <Loading inLine />}
                {<Banner />}
                {<FeaturedCategories />}
            </div>
        </div>
    );
};
