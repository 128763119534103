export const Calculated_Promotions = "Calculated_Promotions";
export const Calculate_Promotions = "Calculate_Promotions";
export const Increment_Order_Quantity = "Increment_Order_Quantity";
export const Decrement_Order_Quantity = "Decrement_Order_Quantity";
export const Set_Order_Quantity = "Set_Order_Quantity";
export const Remove_Order_Product = "Remove_Order_Product";
export const Clear_Cart = "Clear_Cart";
export const Creating_Order = "Creating_Order";
export const Create_Order_Success = "Create_Order_Success";
export const Create_Order_Fail = "Create_Order_Fail";
export const Redirect_To_Confirmation = "Redirect_To_Confirmation";
export const Redirect_To_Payment = "Redirect_To_Payment";
export const Order_Completion_Successful= "Order_Completion_Successful";
export const Update_Purchase_Order_Number = "Update_Purchase_Order_Number";
export const Update_Delivery_Instructions = "Update_Delivery_Instructions";
export const Update_Delivery_Date = "Update_Delivery_Date";
export const Update_Delivery_Address = "Update_Delivery_Address";
export const Update_Order_Item_Notes = "Update_Order_Item_Notes";
export const Delete_Order_Item_Notes = "Delete_Order_Item_Notes";
export const Reset_Checkout_State = "Reset_Checkout_State";
export const Update_Order_Payment_Method = "Update_Order_Payment_Method";

export const Fetching_Reorder_Details = "Fetching_Reorder_Details";
export const Reorder_Details_Success = "Reorder_Details_Success";
export const Reorder_Details_Fail = "Reorder_Details_Fail";
export const Reorder_Details_Reset = "Reorder_Details_Reset";
export const Force_Stop_Display_Placing_Order_Message = "Force_Stop_Display_Placing_Order_Message";
export const Set_Payment_Failed = "Set_Payment_Failed";