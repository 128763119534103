import * as React from "react";
import StandardPromotionDisplay from "../../../Interfaces/PromoMatrix/StandardPromotionDisplay";
import ReactHtmlParser from 'html-react-parser'; 

import "./Styles/_ProductStandardPromotion.scss"

interface IProductStandardPromotionsProps {
    descriptions : StandardPromotionDisplay[];
    showHeaderTitle? : boolean;
}

export const ProductStandardPromotion : React.FC<IProductStandardPromotionsProps> = ({descriptions, showHeaderTitle=false}) => {
    
    return (
        <>
        { descriptions.length > 0 && 
            <div data-testid="promo-list-container" className="promotions-list-container">
                {showHeaderTitle && <div className="promotion-description header">Current Promotions</div>}
                {descriptions.map((pd:StandardPromotionDisplay, i:number) => {
                    return <div className="promotion-description" key={`promo-desc-${i}`}><span>- {ReactHtmlParser(pd.promotionDescription)}</span></div>
                })}
            </div>
        }
        </>
    )
}