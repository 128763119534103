import React, { Component, SyntheticEvent } from "react";

import ReturnPanel from "../../../Components/Global/ReturnPanel";
import * as PageContent from "../../../Language/Default_Settings";
import * as PageRoutes from "../../../Routes";

//Redux
import { connect } from "react-redux";
import { GetProductDetails } from "../../../Redux/Actions/ProductDetailsActions";
import { RemoveOrderProduct, CalculatePromotions } from "../../../Redux/Actions/OrderActions";

//Assets
import { ProductActionsContent } from "../../../Language/Default_Settings";

//Page Elements
import "./../Styles/_ProductDetail.scss";

//Assets
import Cin7ConfirmationBox from "../../../Components/PageElements/Cin7ConfirmationBox";
import Loading from "../../../Components/Global/Loading";
import ProductDetailsContent from "../../../Components/PageElements/Products/ProductDetailsContent";
import ProductNotFound from "../../../Components/PageElements/Products/ProductNotFound";

interface componentProps {
    match: {
        params: {
            id: number;
        };
    };
    productDetails: {
        isFetchingProductDetails: boolean;
        product: any;
        message: string;
        productNotFound: boolean;
    };
    auth: {
        subscribedToken: string;
    };

    GetProductDetails: (token: string, productId: number) => void;
    RemoveOrderProduct: (
        productId: number,
        optionId: number | null,
        sizeId: number | null,
        uomId: number | null
    ) => void;
    CalculatePromotions: () => void;
}

interface componentState {
    productId: number;
    isFetchingProductDetails: boolean;
    product: any;
    message: string;
    userToken: string;
}

const GoBackButton = {
    specificRoute: PageRoutes.CategoriesRoute,
    linkText: PageContent.ProductDetailsPageContent.default_backButtonLabel,
};

class ProductDetail extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            productId: 0,
            isFetchingProductDetails: props.productDetails.isFetchingProductDetails,
            product: props.productDetails.product,
            message: props.productDetails.message,
            userToken: props.auth.subscribedToken,
        };
    }

    componentDidMount() {
        let id = Number(this.props.match.params.id);

        this.setState({
            productId: id,
        });

        this.props.GetProductDetails(this.props.auth.subscribedToken, id);
    }

    static getDerivedStateFromProps(props: componentProps, state: componentState) {
        if (props.auth.subscribedToken !== state.userToken) {
            return {
                userToken: props.auth.subscribedToken,
            };
        }
        if (props.match.params.id !== state.productId) {
            return {
                productId: props.match.params.id,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.auth.subscribedToken !== this.props.auth.subscribedToken) {
            this.props.GetProductDetails(this.props.auth.subscribedToken, this.state.productId);
        }
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.GetProductDetails(this.props.auth.subscribedToken, this.props.match.params.id);
        }
        if (prevProps.productDetails.isFetchingProductDetails !== this.props.productDetails.isFetchingProductDetails) {
            this.props.CalculatePromotions();
        }
    }

    render() {
        const { productDetails } = this.props;

        const actions = [
            {
                name: ProductActionsContent.default_resetQuantityLabel,
                action: this._resetProductQuantity,
            },
        ];

        if (productDetails.message) {
            if (productDetails.productNotFound) {
                return (
                    <div className={"content-box"}>
                        <ReturnPanel goBackSettings={GoBackButton} actions={actions} />
                        <ProductNotFound message={productDetails.message} />
                    </div>
                );
            } else {
                return (
                    <div className={"content-box"}>
                        <ReturnPanel goBackSettings={GoBackButton} actions={actions} />
                        {productDetails.message && (
                            <div className="error-message-container">
                                <Cin7ConfirmationBox
                                    boxType={"error-box"}
                                    boxIcon={"error-icon"}
                                    boxMessage={productDetails.message}
                                />
                            </div>
                        )}
                    </div>
                );
            }
        }

        if (productDetails.isFetchingProductDetails) {
            return <Loading />;
        } else {
            return (
                <div className={"content-box"}>
                    <ReturnPanel goBackSettings={GoBackButton} actions={actions} />
                    <ProductDetailsContent productDetails={productDetails} goBackSettings={GoBackButton} />
                </div>
            );
        }
    }

    _resetProductQuantity = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.RemoveOrderProduct(this.state.productId, null, null, null);

        //Re renders the details page
        this.setState(this.state);
    };
}

const MapStateToProps = (state: { productDetailsReducer: any; orderReducer: any; authReducer: any }) => ({
    productDetails: state.productDetailsReducer,
    auth: state.authReducer,
});

export default connect(MapStateToProps, { GetProductDetails, RemoveOrderProduct, CalculatePromotions })(ProductDetail);
