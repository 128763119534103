import React, { Component } from 'react';
import { B2BPageTitles } from '../../../Language/Default_Settings';
import OrderHistoryList from '../../../Components/PageElements/Account/OrderHistoryList';

import '../Styles/_OrderHistory.scss';

class OrderHistory extends Component {

    componentDidMount() {
        document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.order_history_title}`;
    }

    render() {
        return (
            <div id="Order-History-Container">
                <div className="order-history-content">
                    <OrderHistoryList />
                </div>
            </div>
        );
    }
}

export default OrderHistory;
