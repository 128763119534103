import React, { Component } from 'react';
import { FormatDate, FormatAmount } from '../../../Utils/OrderHelper';
import { RoundStockNumber } from '../../../Utils/ProductsHelper';
import PriceDisplay from '../../Global/PriceDisplay';

import './Styles/_OrderTile.scss';

interface componentProps {
    order: any;
    OpenReorderDialog: (orderId: number, orderRef: string) => void;
}

class OrderTile extends Component<componentProps, {}> {
    
    render() {
        const { order } = this.props;

        return (
            <tr className="order-tile" key={order.id}>
                <td className="order-reference"><a href={order.documentLinkAbsolutePath} rel="noopener noreferrer" target="_blank">{order.reference}</a></td>
                <td className="order-created">{FormatDate(order.createdDate)}</td>
                <td className={"order-status " + (order.isDispatched ? "order-status-dispatched" : "order-status-open")} >{order.isDispatched ? "Dispatched" : "Open"}</td>
                <td className="order-customer-number">{order.customerOrderNumber}</td>
                <td className="order-total-quantity">{FormatAmount(RoundStockNumber(order.totalQuantity))}</td>
                <td className="order-total">{<PriceDisplay value={order.total} />}</td>
                <td className="order-outstanding-amt">{order.outstandingAmt !== 0 && <PriceDisplay value={order.outstandingAmt} />}</td>
                <td className="order-action" onClick={(e) => this._reorder(e, order.id, order.reference)}>Reorder</td>
            </tr>
        );
    }

    _reorder = (e: React.MouseEvent, orderId: number, orderRef: string) => {
        this.props.OpenReorderDialog(orderId, orderRef);
    }
}

export default OrderTile;