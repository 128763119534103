import React, { Component } from 'react';

class EmailIcon extends Component {
    
    render() { 

        const cls1 = {
            fill: 'none',
            stroke: '#373839',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px'
        };

        const cls2 = {
            fill: 'none',
            stroke: '#373839',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
            strokeLinecap: 'square'
        };

        const cls3 = {
            fill: 'none',
            stroke: '#373839',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
            strokeLinecap: 'round'
        };

        return ( 
            <svg xmlns="https://www.w3.org/2000/svg" width="18.5" height="13.846" viewBox="0 0 18.5 13.846">
                <title id="Email-Icon">Email Envelope</title>
                <g id="Group_9927" data-name="Group 9927" transform="translate(-1406.633 -410.645)">
                    <path id="Rectangle_2801" d="M0 0h17v12.346H0z" style={cls1} data-name="Rectangle 2801" transform="translate(1407.383 411.395)"/>
                    <g id="Group_9921" data-name="Group 9921" transform="translate(1408.831 418.329)">
                        <path id="Line_249" d="M3.58 0L0 4.284" style={cls2} data-name="Line 249"/>
                        <path id="Line_250" d="M0 0l3.58 4.284" style={cls2} data-name="Line 250" transform="translate(10.524)"/>
                    </g>
                    <path id="Path_5182" d="M1408.452 412.353l7.266 5.9 7.206-5.944" style={cls3} data-name="Path 5182" transform="translate(.186 .031)"/>
                </g>
            </svg>
         );
    }
}
 
export default EmailIcon;