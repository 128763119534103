import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { CategoryType } from "../../../Components/PageElements/Products/ProductCategoryFilter";
import ProductsListBrandFilter from "../../../Components/PageElements/Products/ProductsListBrandFilter";
import {
    findElders,
    useCategoryRouteObjects,
    useCurrentCategory,
    useDefaultParent,
    useMatchingCategory,
} from "../../../Utils/CategoriesHelper";
import ProductsList from "./ProductsList";
import {
    DisplayFilterMenu,
    ToggleProductListView,
    UpdateProductsFilter,
} from "../../../Redux/Actions/ProductListActions";
import { RootState } from "../../..";
import { SetGoBack } from "../../../Redux/Actions/CategoryActions";
import { GetUrlParameters } from "../../../Utils/B2BHelpers";
import ProductNotFound from "../../../Components/PageElements/Products/ProductNotFound";
import { Chip } from "../../../Components/Global/Chip";

import "../Styles/_Category.scss";
import "../../../Components/Global/Styles/_Buttons.scss";

const Category = (props: any): JSX.Element => {
    const currentCategory = useCurrentCategory();
    const matchingCategory = useMatchingCategory();
    const defaultParent = useDefaultParent();
    const cro = useCategoryRouteObjects();
    const history = useHistory();
    const dispatch = useDispatch();
    const viewType = useSelector((state: RootState) => state.productListReducer.productListViewStyle);
    const brands = useSelector((state: RootState) => state.productListReducer.brands);
    const categoriesFetched = useSelector((state: RootState) => state.productListReducer.categoriesFetched);
    const brandsFetched = useSelector((state: RootState) => state.productListReducer.brandsFetched);
    const filter = useSelector((state: RootState) => state.productListReducer.filter);
    const brandsAvailable = brandsFetched && brands.length > 0;

    const [searchParameter, setSearchParameter] = useState("");
    const parameters = GetUrlParameters(["query"]);

    useEffect(() => {
        if (parameters.query) {
            setSearchParameter(decodeURIComponent(parameters.query));
        } else {
            setSearchParameter("");
        }
    }, [setSearchParameter, parameters]);

    const elders = findElders(defaultParent, currentCategory.id);
    const breadcrumbs = [];
    if (elders.length > 1) {
        // Exclude defaultParent from the breadcrumbs with slice
        breadcrumbs.push(...elders.slice(1), currentCategory);
    }

    const onClickBreadcrumb = async (categorySelected: CategoryType) => {
        const newPath = cro.find((routeObject) => routeObject.id === categorySelected.id)?.path;
        history.push(`/products/?t=${newPath}`);
        // Someone tell me why this works
        await dispatch(SetGoBack(true));
    };

    const crumbList: React.ReactElement[] = [];
    breadcrumbs.forEach((crumbCategory, i) => {
        crumbList.push(
            <div
                key={crumbCategory.id}
                onClick={() => onClickBreadcrumb(crumbCategory)}
                className={`breadcrumbs${i === breadcrumbs.length - 1 ? " last" : ""}`}
            >
                {i === 0 ? null : <li>{"/"}</li>}
                <li>{`${crumbCategory.name}`}</li>
            </div>
        );
    });

    const removeBrandFilter = (brandId: number) => {
        dispatch(
            UpdateProductsFilter({
                brandIds: filter.brandIds.filter((id) => id !== brandId),
                categoryIds: filter.categoryIds,
            })
        );
    };

    const clearBrandFilter = () => {
        dispatch(
            UpdateProductsFilter({
                brandIds: [],
                categoryIds: [],
            })
        );
    };

    if (categoriesFetched && !matchingCategory) {
        return (
            <div id={"contain"}>
                <ProductNotFound message={"Category not found"} />
            </div>
        );
    }

    return (
        <>
            {brandsAvailable && <ProductsListBrandFilter />}
            <div id={"contain"}>
                <div className={"category-header"}>
                    <div className={"title-container"}>
                        <h1 className={"category-title"}>
                            {(searchParameter && `Search results for "${searchParameter}"`) ||
                                currentCategory.name ||
                                "All Products"}
                        </h1>
                        <ol className={"crumb-list"}>{crumbList}</ol>
                    </div>
                    <div className={"category-buttons"}>
                        {searchParameter && (
                            <button className={"default-button filled"} onClick={() => history.push("/products")}>
                                Clear Search
                            </button>
                        )}
                        <div className={"filter-change-view-container"}>
                            {brandsAvailable && (
                                <button
                                    className={"default-button outline"}
                                    onClick={() => dispatch(DisplayFilterMenu(true))}
                                >
                                    <div className={"button-icon-container"}>
                                        <i className="icon fas fa-sliders"></i>Filter
                                    </div>
                                </button>
                            )}
                            <button
                                className={"default-button outline"}
                                onClick={() =>
                                    dispatch(
                                        ToggleProductListView(viewType === "list-view" ? "gallery-view" : "list-view")
                                    )
                                }
                            >
                                <div className={"button-icon-container switching"}>
                                    <i className={`icon fas fa-${viewType === "list-view" ? "picture-o" : "bars"}`}></i>
                                    Change View
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                {filter.brandIds.length >= 1 && (
                    <div className="chips">
                        <div className={"chip-box"}>
                            <label className={"label"}>
                                <i className="icon fas fa-filter"></i>Filtered By:
                            </label>
                            <div className={"chips-inner"}>
                                {filter.brandIds.map((brandId) => {
                                    return (
                                        <Chip
                                            key={brandId}
                                            label={brands.find((brand) => brand.id === brandId)?.name || ""}
                                            onDelete={() => removeBrandFilter(brandId)}
                                            deletable
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <hr />
                        <div className={"clear-box"}>
                            <button className={"default-button outline clear-button"} onClick={clearBrandFilter}>
                                Clear All
                            </button>
                        </div>
                    </div>
                )}
                {/* Don't load the Products until there is a category confirmed */}
                {currentCategory.name && <ProductsList {...props} />}
            </div>
        </>
    );
};

export default Category;
