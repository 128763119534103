import React, { useState } from 'react';
import { useHistory } from "react-router";
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BemHelper, Button } from '@cin7/ui';
import { CreateOrder } from '../../../Redux/Actions/OrderActions';
import { getIsPaymentsPortalEnabled, getUserInfo, getUserIsOnHold, getUserRemainingCreditLimit } from '../../../Redux/Selectors/UserSelectors';
import { getCartItemsTotal, getFullOrder, getIsFetchingPromotions, getOrderItemCount, getPaymentMethod } from '../../../Redux/Selectors/OrderSelectors';
import Loading from '../../Global/Loading';
import { useCurrentFreightDescription, useCurrentFreightPrice, useHasFilteredZones, useHasFreightOptions } from '../../../Utils/FreightHelper';
import './Styles/_CheckoutFooter.scss';
import { PaymentMethodType } from '../../../Enums/PaymentMethods/PaymentMethodType';
import { getIsPayAccountOnHold } from '../../../Utils/OrderHelper';

const bem = new BemHelper('checkout-footer');

export interface CheckoutFooterProps {
    displayCompleteButton?: boolean;
    ShouldContinue?: () => Promise<boolean>;
}

export const CheckoutFooter: React.FC<CheckoutFooterProps> = (props) => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const userIsOnHold = useSelector(getUserIsOnHold);
    const remainingCreditLimit = useSelector(getUserRemainingCreditLimit);
    const orderData = useSelector(getFullOrder);
    const userInfo = useSelector(getUserInfo);
    const cartItemsTotal = useSelector(getCartItemsTotal);
    const totalItems = useSelector(getOrderItemCount);
    const isPaymentsPortalEnabled = useSelector(getIsPaymentsPortalEnabled);
    const paymentMethod = useSelector(getPaymentMethod);
    const freightPrice = useCurrentFreightPrice();
    const freightDescription = useCurrentFreightDescription();
    const hasFreightOptions = useHasFreightOptions();
    const calculatingPromotions = useSelector(getIsFetchingPromotions);
    const hasFilteredZones = useHasFilteredZones();

    const dispatch = useDispatch();

    const placeOrder = () => {
        dispatch(CreateOrder(orderData, userInfo, freightPrice, freightDescription));
    };

    const handleContinueClick = () => {
        if (typeof props.ShouldContinue === "undefined") {
            history.push('/checkout');
            return;
        }
        setLoading(true);
        props.ShouldContinue().then((shouldContinue: boolean) => {
            if (shouldContinue) history.push('/checkout');
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleBackToStoreClick = () => {
        history.push('/products/');
    }

    const handleBackToCartClick = () => {
        history.push('/cart');
    }

    const shouldDisablePlaceOrder = () => {
        if (totalItems <= 0) return true;
        if (hasFreightOptions && hasFilteredZones && !freightDescription) return true;
        if (calculatingPromotions) return true;
        if (isPaymentsPortalEnabled.active) return false;
        return getIsPayAccountOnHold(userIsOnHold, remainingCreditLimit, cartItemsTotal);
    }

    return (
        <div className="checkout-footer">
            {loading && createPortal(<Loading takeOver text="Validating stock"/>, document.body)}
            {!props.displayCompleteButton && <Button disabled={calculatingPromotions} className={bem.element('next-step-button')} fullWidth onClick={handleContinueClick} >Checkout</Button>}
            {!props.displayCompleteButton && <Button className={bem.element('back-to-store-button')} fullWidth variant={"secondary"} onClick={handleBackToStoreClick} >Keep Shopping</Button>}
            {props.displayCompleteButton && <Button className={bem.element('place-order-button')} fullWidth onClick={placeOrder} 
                disabled={shouldDisablePlaceOrder()} >{paymentMethod === PaymentMethodType.PayOnline ? "Pay Online" : "Place Order"}</Button>}
            {props.displayCompleteButton && <Button className={bem.element('back-to-cart-button')} fullWidth variant={"secondary"} onClick={handleBackToCartClick} >Back to Cart</Button>}
        </div>
    );
}
