import React, {Component} from 'react';
import StockIndicator from './StockIndicator';
import './Styles/_ProductInfoCard.scss';

interface componentProps {
    product: any;
}
 
interface componentState {}
 
class ProductInfoCard extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {}
    }

    render() {
        const { product } = this.props;

        if(!product) {
            return <></>
        }

        return (
            <div id="Product-Info-Card">
                {product?.brand && product.brand !== "0" &&
                <div className="product-info-row">
                    <div className="product-info-brand">{product.brand}</div>
                </div>}
                <div className="product-info-row">
                    <div className="product-info-name">{product.name}</div>
                </div>
                <div className="product-info-row">
                    {product.styleCode && <div className="product-info-sku">{product.styleCode} -&nbsp;</div>}
                    <StockIndicator product={product} />
                </div>
            </div>
        );
    }
}
 
export default ProductInfoCard;