import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

import './Styles/_SidePageBannerFooter.scss';

class SidePageBannerFooter extends Component {
    
    render() {

        return ( 
            <div className="side-image-theme-footer text-logo">
                <div className="side-image-theme-footer-logo">
                    <span className="cin7-text-logo"><NavLink href="https://www.cin7.com/legal/terms-of-use/" target="_blank" rel="noopener noreferrer">Powered by Cin7</NavLink></span>
                </div>
            </div>
         );
    }
}
 
export default SidePageBannerFooter;