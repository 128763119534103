import {
    Button,
    Typography,
    FileUploader,
    UploadIcon,
    BemHelper,
    makeStyles,
    Divider,
    Dialog,
    DialogContent,
} from "@cin7/ui";
import React from "react";
import { UploadBulkOrderModalContent as UploadBulkOrderModalText } from "../../../Language/Default_Settings";
import Cin7ConfirmationBox from "../Cin7ConfirmationBox";

import "./Styles/_UploadBulkOrderModal.scss";

export interface UploadBulkOrderModalProps {
    onDownloadTemplateClick: VoidFunction;
    onUploadCancel: VoidFunction;
    onCloseClick: VoidFunction;
    onUpload: (files: File[]) => void;
    uploading: boolean;
    errorMessage?: string;
}

const bem = new BemHelper("upload-bulk-order-modal");

const useStyles = makeStyles({
    root: {
        backgroundColor: "#00326B",
        borderRadius: 4,
        color: "white",
        height: 32,
        padding: ".5rem 1rem",
        fontFamily: "Calibre",
        fontSize: "1rem",
        fontWeight: 600,
        minWidth: 200,
        "&:hover": {
            backgroundColor: "#002EFF",
        },
    },
});

export const UploadBulkOrderModal: React.FC<UploadBulkOrderModalProps> = (props) => {
    const classes = useStyles();
    return (
        <Dialog open fullWidth maxWidth={"sm"} onClose={(e, reason) => props.onCloseClick()} className={bem.block()}>
            <DialogContent>
                <div className={bem.element("content")}>
                    <div className={bem.element("header")}>
                        <Typography variant={"h3"} className={bem.element("header-text")}>
                            {UploadBulkOrderModalText.default_header}
                        </Typography>
                        <div className={bem.element("header-actions")}>
                            {/* <Button type="secondary">{UploadBulkOrderModalText.default_getHelp}</Button> */}
                            <Button
                                variant="secondary"
                                onClick={props.onCloseClick}
                                className={bem.element("cancel-button")}
                            >
                                {UploadBulkOrderModalText.default_cancel}
                            </Button>
                        </div>
                    </div>
                    <Divider />
                    {props.errorMessage && (
                        <Cin7ConfirmationBox
                            boxType={"error-box"}
                            boxIcon={"error-icon"}
                            boxMessage={props.errorMessage}
                        />
                    )}
                    <div className={bem.element("prepare-order")}>
                        <div className={bem.element("prepare-order-instructions")}>
                            <Typography variant={"h4"}>
                                {UploadBulkOrderModalText.default_prepareYourOrderTitle}
                            </Typography>
                            <Typography variant={"body1"}>
                                {UploadBulkOrderModalText.default_prepareYourOrderMessage}
                            </Typography>
                        </div>
                        <Button className={classes.root} variant="primary" onClick={props.onDownloadTemplateClick}>
                            {UploadBulkOrderModalText.default_downloadTemplateButtonLabel}
                        </Button>
                    </div>
                    <Divider light />
                    <div className={bem.element("upload-order")}>
                        <div className={bem.element("upload-order-instructions")}>
                            <Typography variant={"h4"}>
                                {UploadBulkOrderModalText.default_uploadYourOrderTitle}
                            </Typography>
                            <Typography className={bem.element("upload-drag-and-drop-message")} variant={"body1"}>
                                {UploadBulkOrderModalText.default_uploadYourOrderMessageDragAndDrop}
                            </Typography>
                            <Typography className={bem.element("upload-message")} variant={"body1"}>
                                {UploadBulkOrderModalText.default_uploadYourOrderMessageUpload}
                            </Typography>
                        </div>
                        <FileUploader
                            uploadButtonText={UploadBulkOrderModalText.default_uploadButtonLabel}
                            icon={<UploadIcon />}
                            dragAndDropPromptText={UploadBulkOrderModalText.default_uploadPrompt}
                            dragAndDropPromptSubText={UploadBulkOrderModalText.default_uploadPromptSubText}
                            onDrop={props.onUpload}
                            onUploadCancel={props.onUploadCancel}
                            uploading={props.uploading}
                            uploadingButtonText={UploadBulkOrderModalText.default_uploadingButtonLabel}
                            uploadingText={UploadBulkOrderModalText.default_uploadingTitle}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
