import React, { Component } from 'react';
import Img from 'react-image';
import LoadingSpinner from './LoadingSpinner';
import OpenBox from '../../Images/Icons/BoxIcons/OpenBox';
import Cin7Logo from '../../Images/Cin7Logo';

import './Styles/_Image.scss';

interface componentProps {
    imagePath: string;
    altText: string;
    logo?: boolean;
}

class Image extends Component<componentProps,{}> {

    render() {

        const { imagePath, altText, logo } = this.props;

        const Placeholder: any = () => {
            if (logo) {
                return (
                    <div className="logo-image">
                        <Cin7Logo />
                    </div>
                );
            }
            else {
                return (
                    <OpenBox strokeColor={'#D6D7D8'} />
                );
            }
        };

        return(
            <div className="image-container">
                <Img src={imagePath} alt={altText} loader={<LoadingSpinner />} unloader={<Placeholder />} />
            </div>
        );
    }    
}

export default Image;