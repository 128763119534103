import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "..";
import { CategoryType } from "../Components/PageElements/Products/ProductCategoryFilter";

/**
 * Recursively flattens the pathnames of a category tree.
 * The first call of this function should ensure parent category path is empty or starts with a slash
 * @param parentCategory Path and ID of parent. Path should be "" if this is the first call of this function.
 * @param categoryArray An array of categories, each of which may contain subCategories of the same CategoryType
 * @returns Returns an array of route strings or [ ]
 */
export const getCategoryPaths = (parentCategory: { path: string; id: number }, categoryArray: CategoryType[]) => {
    if (categoryArray.length === 0) {
        return [];
    }
    const totalList: { path: string; id: number }[] = [];
    categoryArray.forEach((c) => {
        // Encode each category for use in the URL. Browsers needs help replacing ' with %27.
        const encodedName = encodeURIComponent(c.name).replace(/'/g, "%27");
        totalList.push({ id: c.id, path: `${parentCategory.path}/${encodedName}/` });
        if (c.subCategories.length !== 0) {
            totalList.push(
                ...getCategoryPaths({ path: `${parentCategory.path}/${encodedName}`, id: c.id }, c.subCategories)
            );
        }
    });
    return totalList;
};

// Searches recursively for the category belonging to the provided id
export const findCategoryFromId = (cat: CategoryType, id: number): CategoryType | undefined => {
    // Base case
    if (cat.id === id) {
        return cat;
    }
    if (cat.subCategories.length !== 0) {
        for (let c of cat.subCategories) {
            const result = findCategoryFromId(c, id);
            if (result) return result;
        }
    }
};

// Searches recursively for the category which has the provided Id as a child
export const findParent = (categoryToSearch: CategoryType, childId: number): CategoryType | undefined => {
    // If categoryToSearch and child match then there is no parent
    if (categoryToSearch.id === childId) {
        return undefined;
    }

    // These for loops could be combined but are seperate to improve speed (preventing prematurely searching deeper)
    if (categoryToSearch.subCategories.length !== 0) {
        for (let c of categoryToSearch.subCategories) {
            // Base case
            if (c.id === childId) {
                return categoryToSearch;
            }
        }
        for (let cat of categoryToSearch.subCategories) {
            const result = findParent(cat, childId);
            if (result) return result;
        }
    }
};

// Returns an array of all the parents, grandparents, ancestors of the given category(id)
export const findElders = (defaultParent: CategoryType, id: number): CategoryType[] => {
    if (id < 0) return [];
    const familyArray: CategoryType[] = [];
    const parent = findParent(defaultParent, id);
    if (parent) {
        if (parent.id < 0) return [defaultParent];
        familyArray.unshift(parent);
        familyArray.unshift(...findElders(defaultParent, parent.id));
    }
    return familyArray;
};

// Retrieves the given variable from the url query params
export function getQueryVariable(variable: string) {
    const query = window.location.search.substring(1);
    const variables = query.split("&");
    for (let i = 0; i < variables.length; i++) {
        const pair = variables[i].split("=");
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return undefined;
}

// Find if an array of category paths (with ID) contains a provided path.
// categoryRoutes must start and end with a forward slash (/).
export const checkEquivalenceOfPath = (
    categoryRoutes: { id: number; path: string }[],
    pathToMatch: string
): { id: number; path: string } | undefined => {
    for (const route of categoryRoutes) {
        // Ignore the start and end slahses, check for equivalence of the string
        if (route.path.substring(1, route.path.length - 1).localeCompare(pathToMatch) === 0) {
            return route;
        }
    }
};

// Check current page in query params exists
export const useMatchingCategory = () => {
    const defaultParent = useDefaultParent();
    const categoryRouteObjects = useCategoryRouteObjects();
    const pagePath = getQueryVariable("t");
    const segments = [];
    if (!pagePath) {
        return { id: defaultParent.id, path: "" };
    }
    // Remove any slahses on the ends of the string
    segments.push(...pagePath.split("/").filter(Boolean));
    const pathToMatch = segments.join("/");
    return checkEquivalenceOfPath(categoryRouteObjects, pathToMatch);
};

// Returns the id's of all the descendents of a given category (must provide the whole tree)
export const getAllDescendents = (category: CategoryType) => {
    const children: number[] = [];
    category.subCategories.forEach((sub) => {
        children.push(sub.id);
        children.push(...getAllDescendents(sub));
    });
    return children;
};

export const useCategoryRouteObjects = () => {
    return useSelector((state: RootState) => state.productListReducer.categoryRouteObjects);
};

export const useCurrentCategory = () => {
    return useSelector((state: RootState) => state.categoryReducer.category);
};

export const useCategories = () => {
    return useSelector((state: RootState) => state.productListReducer.categories);
};

export const useDefaultParent = () => {
    const categories = useCategories();
    return useMemo(() => {
        return {
            id: -1,
            name: "All Products",
            subCategories: categories,
        };
    }, [categories]);
};
