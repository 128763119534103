import * as types from './../Types/UserDetailsTypes';
import { RefreshUserToken } from './../Actions/AuthActions';
import APIHost from './../../APIHost';
import axios from 'axios';
import ApiUtil from '../../Utils/ApiUtil';

const host = new APIHost();
const apiUtil = new ApiUtil();

export const GetOrderHistoryList = (token: string, page: number, keyword: string) => (dispatch: any) => {

    var headers = apiUtil.DefaultHeaders(token);

    if (headers === null) {
        return;
    }

    dispatch({
        type: types.Getting_Order_History
    });

    const postBody = {
        moduleId: apiUtil.GetModuleId(),
        page: page,
        keyword: keyword
    };

    axios.post(host.CheckoutAPI("GetOrderHistoryList"), postBody, headers).then(res => {
        dispatch({
            type: types.Order_History_Success,
            payload: {
                orders: res.data.orders,
                totalPages: res.data.numberOfPagesAvailable
            }
        });
    }).catch((error) => {
        if (error.response) {
            let errorStatus = error.response.status;

            switch (errorStatus) {
                case 401:
                    dispatch({
                        type: types.Order_History_Fail,
                        payload: {
                            message: ''
                        }
                    });
                    dispatch(RefreshUserToken());
                    break;

                default:
                    dispatch({
                        type: types.Order_History_Fail,
                        payload: {
                            message: error.message
                        }
                    });
                    break;
            }
        } else {
            dispatch({
                type: types.Order_History_Fail,
                payload: {
                    message: error.message
                }
            });
        }
    });
};