import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../Global/Loading';
import Cin7ConfirmationBox from '../Cin7ConfirmationBox';
import { ReorderModal } from './ReorderModal';
import OrderTile from './OrderTile';
import { OrderHistoryListPageContent } from '../../../Language/Default_Settings';
import { GetOrderHistoryList } from '../../../Redux/Actions/UserDetailsActions';
import { Table } from 'reactstrap';
import { CloseCircleIcon, Input, Pagination, SearchIcon } from '@cin7/ui';

import './Styles/_OrderHistoryList.scss';

interface componentProps {
    userDetails: {
        orderHistory: {
            isLoading: boolean;
            message: string;
            orders: [];
            totalPages: number;
        }
    };
    auth: {
        subscribedToken: string;
    };
    GetOrderHistoryList: (token: string, page: number, keyword: string) => void;
}

interface componentState {
    searchTerm: string;
    reorderModalOpen: boolean;
    reorderId: number;
    reorderRef: string;
    showShortenedPagination: boolean;
}

class OrderHistoryList extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            searchTerm: "",
            reorderModalOpen: false,
            reorderId: 0,
            reorderRef: '',
            showShortenedPagination: false
        };
    }

    componentDidMount() {
        this.props.GetOrderHistoryList(this.props.auth.subscribedToken, 1, "");

        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
      }
    
      handleResize = () => {    
        if (window.innerWidth < 500) {
          this.setState({showShortenedPagination: true});
        } else {
          this.setState({showShortenedPagination: false});
        }
      }
    

    render() {
        const { userDetails } = this.props;
        const { searchTerm, reorderModalOpen } = this.state;

        let orderHistory = userDetails.orderHistory;
        let totalPages = orderHistory.totalPages;

        if (orderHistory.message) {
            return (
                <Cin7ConfirmationBox
                    boxType={"error-box"}
                    boxIcon={"error-icon"}
                    boxMessage={orderHistory.message}
                />
            );
        }

        const OrderHistoryTable = () => {
            if (orderHistory.isLoading) {
                return (
                    <Loading inLine={true} />
                );
            }
            else if (!orderHistory.orders) {
                return (
                    <div className="no-results-panel">{OrderHistoryListPageContent.default_noResultsLabel}</div>
                );
            }
            else {
                return (
                    <div className="order-history-list-results">
                        <Table striped borderless>
                            <thead>
                                <tr className="order-headers">
                                    <th className="order-reference">Order Ref</th>
                                    <th className="order-created">Created Date</th>
                                    <th className="order-status">Order Status</th>
                                    <th className="order-customer-number">PO Number</th>
                                    <th className="order-total-quantity">Order Qty</th>
                                    <th className="order-total">Order Total</th>
                                    <th className="order-outstanding-amt">Outstanding Amt</th>
                                    <th className="order-action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderHistory.orders.map((order: any) =>
                                    <OrderTile key={order.id} order={order} OpenReorderDialog={this._openReorderDialog} />
                                )}
                            </tbody>
                        </Table>
                    </div>
                );
            }
        }
        return (
            <div className="order-history-list">
                <div className="order-history-search" onKeyDown={(e) => { e.key === 'Enter' && this._onSearch(this.state.searchTerm)}}>
                    <div className="order-search-label">Search</div>
                    <Input
                        className="order-search-box"
                        onChange={(e) => this.setState({searchTerm: e.target.value})}
                        InputProps={{
                            startAdornment: <div className="input-icon search-input-icon"><SearchIcon /></div>,
                            endAdornment: this.state.searchTerm && <div className="input-icon" onClick={() => this._onClear()}><CloseCircleIcon /></div>
                        }}
                        value={this.state.searchTerm}
                        placeholder={"Order Ref or PO Number"}
                    />
                </div>
                {reorderModalOpen && <div className="reorder-modal-container"><ReorderModal orderId={this.state.reorderId} orderRef={this.state.reorderRef} CloseAction={this._closeReorderDialog} /></div>}
                {<OrderHistoryTable />}
                {<Pagination key={searchTerm} shape="rounded" variant="outlined" count={totalPages} onChange={this._changePage} siblingCount={this.state.showShortenedPagination ? 0 : 1} />}
                <div className="order-history-help-text">Recent orders may not display immediately.</div>
            </div>
        );
    }

    _changePage = (_: React.ChangeEvent<unknown>, nextActivePage: number) => {
        let activePage = nextActivePage;

        this.props.GetOrderHistoryList(this.props.auth.subscribedToken, activePage, this.state.searchTerm);
    };

    _onClear = () => {
        if (this.state.searchTerm !== "") {
            this._onSearch("");
        }
    };

    _onSearch = (value: string | number) => {
        let term = String(value);
        this.setState({
            searchTerm: term,
        });
        this.props.GetOrderHistoryList(this.props.auth.subscribedToken, 1, term);
    };

    _openReorderDialog = (orderId: number, orderRef: string) => {
        this.setState({
            reorderModalOpen: true,
            reorderId: orderId,
            reorderRef: orderRef
        });
    };

    _closeReorderDialog = () => {
        this.setState({
            reorderModalOpen: false
        });
    };
}

const MapStateToProps = (state: { userDetailsReducer: any; authReducer: any; }) => ({
    userDetails: state.userDetailsReducer,
    auth: state.authReducer
});

export default connect(MapStateToProps, {GetOrderHistoryList})(OrderHistoryList);