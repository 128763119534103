import * as React from 'react';
import { Popover } from '@cin7/ui';

import "./Styles/_ProductsPromotionsTooltip.scss";

interface IProductsPromotionsTooltip{
    content: JSX.Element;
}

export const ProductsPromotionsTooltip : React.FC<IProductsPromotionsTooltip> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {props.children}
            </div>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handlePopoverClose}
            >
                {props.content}
            </Popover>
        </>
    )
}