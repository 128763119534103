import React, { Component } from 'react';
import GoBack from '../PageElements/GoBack';
import './Styles/_ReturnPanel.scss';
import MoreActionsDropdown from './MoreActionsDropdown';

interface componentProps {
    goBackSettings?: {
        specificRoute: string;
        linkText: string;
    };
    title?: string;
    actions?: any;
}

class ReturnPanel extends Component<componentProps> {

    render(){

        const { goBackSettings, title, actions } = this.props;

        return (
            <>
                <div id="Global-Return-Panel">
                    <GoBack goBackAttributes={goBackSettings} />
                    {title && 
                    <div className="title-content">{title}</div>}
                    <MoreActionsDropdown actions={actions} />
                </div>
            </>
        );
    }
}

export default (ReturnPanel);