import React, { Component } from 'react';
class HidePasswordIcon extends Component {
    render() { 

        const cls1 = {
            fill: 'none',
            stroke: '#373839',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        };

        const cls2 = {
            fill: '#373839',
            stroke: '#373839',
            strokeMiterlimit: 10
        };

        const cls3 = {
            fill: 'none',
            stroke: '#373839'
        }

        return ( 
            <svg aria-labelledby="HidePasswordIcon" xmlns="https://www.w3.org/2000/svg" width="20" height="11.923" viewBox="0 0 20 11.923">
                <title id="HidePasswordIcon">Hide password</title>
                <g id="Hide Password" data-name="Hide Password" transform="translate(-533.875 -792.077)">
                    <g id="Group_290" data-name="Group 290" transform="translate(539.792 794.25)">
                        <path id="Path_4962" d="M284.136 352.272a4.082 4.082 0 1 1-7.5 2.23 4.069 4.069 0 0 1 .716-2.31" style={cls1} data-name="Path 4962" transform="translate(-276.636 -352.192)"/>
                    </g>
                    <path id="Path_4967" d="M285.128 355.552s-4.254 5.037-9.5 5.037-9.5-5.037-9.5-5.037 4.254-5.039 9.5-5.039 9.5 5.039 9.5 5.039z" style={cls1} data-name="Path 4967" transform="translate(268.247 442.911)"/>
                    <g id="Group_291" data-name="Group 291" transform="translate(543.207 795.98)">
                        <g id="Group_287" data-name="Group 287">
                            <path id="Path_4966" d="M284.746 356.291a.639.639 0 1 1-.639-.64.637.637 0 0 1 .639.64z" style={cls2} data-name="Path 4966" transform="translate(-283.467 -355.651)"/>
                        </g>
                    </g>
                    <path id="Line_191" d="M0 10.965L17.324 0" style={cls3} data-name="Line 191" transform="translate(535.5 792.5)"/>
                </g>
            </svg>
         );
    }
}
 
export default HidePasswordIcon;

