import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormatIncomingDate, RoundStockNumber } from './../../../Utils/ProductsHelper';
import { FormatAmount } from '../../../Utils/OrderHelper';

import './Styles/_StockIndicator.scss';

interface componentProps {
    product?: any;
    productOption?: any;
    sizes?: boolean;
    remainingOptionStock?: number;
    identity: {
        moduleSettings: {
            stockDisplay: number;
            oversellStockIndicator: string | null;
        }
    };
}

class StockIndicator extends Component<componentProps,{}> {

    render() {
        const { sizes, productOption, product, identity, remainingOptionStock } = this.props;

        let optionGroupStock = productOption != null ? productOption.stock : product.stock;
        let incomingStockDate = productOption != null ? productOption.incomingDate : product.incomingDate;

        let indicators = false;

        switch (identity.moduleSettings.stockDisplay) {
            case 2: // Do not display stock.
                return null;
            case 1: // Display stock indicators.
                indicators = true;
                break;
            case 0: // Display stock numbers.
            default:
                break;
        }

        if (product?.isNonStock) {
            return (
                <div className="stock-indicator">Available</div>
            );
        }

        if (productOption) {            
            if (sizes) {
                optionGroupStock = productOption.reduce((totalStock: number, item: { uomId: number, stock: number; uomSize: number; }) => {
                    return totalStock + (item.stock > 0 ? (item.uomId ? Math.floor(item.stock / item.uomSize) : item.stock) : 0);
                }, 0);

                if (productOption.some((option: { uomId: number; }) => option.uomId)) {
                    // Only show an indicator for UOM group.
                    indicators = true;
                }

                if (productOption.some((option: { incomingDate: string; }) => option.incomingDate)) {
                    const optionsSortedByDate = productOption.slice().sort((a: any, b: any) => Number(new Date(a.incomingDate)) - Number(new Date(b.incomingDate)));
                    incomingStockDate = optionsSortedByDate.find((option: { incomingDate: string; }) => option.incomingDate).incomingDate;
                }
            }
            else if (productOption.uomId) {
                // Calculate UOM stock.
                optionGroupStock = Math.floor(productOption.stock / productOption.uomSize);
            }
        }

        if (optionGroupStock <= 0) {
            if (product?.allowOverselling) {
                return (
                    <div className="stock-indicator-container">
                        <div className="stock-indicator">{identity.moduleSettings?.oversellStockIndicator || 'Available'}</div>
                        {incomingStockDate && <div className="stock-indicator incoming-stock">Incoming {FormatIncomingDate(incomingStockDate)}</div>}
                    </div>
                );
            }
            else {
                if (incomingStockDate) {
                    return (
                        <div className="stock-indicator incoming-stock">Incoming {FormatIncomingDate(incomingStockDate)}</div>
                    );
                }
                else {
                    return (
                        <div className="stock-indicator out-of-stock">Out of stock</div>
                    );
                }
            }
        }

        const remainingStock = remainingOptionStock !== undefined && remainingOptionStock !== optionGroupStock ? FormatAmount(RoundStockNumber(remainingOptionStock)) : null;

        if (indicators) {
            if (!product?.allowOverselling) {
                if (optionGroupStock > 0 && optionGroupStock < 4) {
                    return (
                        <div className="stock-indicator stock-warning">{remainingStock !== null && (remainingStock + " of ")}{FormatAmount(RoundStockNumber(optionGroupStock))} left in stock</div>
                    );
                } else if (optionGroupStock < 11) {
                    return (
                        <div className="stock-indicator stock-warning">Low stock</div>
                    );
                }
            }

            return (
                <div className="stock-indicator">{optionGroupStock <= 0 && identity.moduleSettings?.oversellStockIndicator ? identity.moduleSettings.oversellStockIndicator : 'Available'}</div>
            );
        }
        else {
            return (
                <div className="stock-indicator">{remainingStock !== null && (remainingStock + " of ")}{FormatAmount(RoundStockNumber(optionGroupStock))} Available</div>
            );
        }
    }    
}

const MapStateToProps = (state: { identityReducer: any; }) => ({
    identity: state.identityReducer
});

export default connect(MapStateToProps, {})(StockIndicator);