import * as types from "./../../Types/AuthTypes";

export type AuthStateType = {
    isAuthorize: boolean;
    subscribedToken: string;
    refreshToken: string;
    errorMessage: string;
};

export const initialState: AuthStateType = {
    isAuthorize: false,
    subscribedToken: "",
    refreshToken: "",
    errorMessage: "",
};

export const AuthReducer = (
    state = initialState,
    action: { type: string; payload: any | undefined }
): AuthStateType => {
    switch (action.type) {
        case types.Authorize_B2B_User:
        case types.Refresh_User_Token:
            return {
                ...state,
                isAuthorize: action.payload.isAuthorize,
                subscribedToken: action.payload.subscribedToken,
                refreshToken: action.payload.refreshToken,
                errorMessage: initialState.errorMessage,
            };
        case types.Deauthorize_B2B_User:
            return {
                ...state,
                isAuthorize: false,
                subscribedToken: "",
                refreshToken: "",
                errorMessage: action.payload.errorMessage,
            };
        case types.Logout_Clear_State:
            return {
                ...state,
                isAuthorize: initialState.isAuthorize,
                subscribedToken: initialState.subscribedToken,
                refreshToken: initialState.refreshToken,
                errorMessage: initialState.errorMessage,
            };
        default:
            return state;
    }
};
