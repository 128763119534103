import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

//Redux
import {connect} from 'react-redux';

interface componentProps {
    resetPassword: {
        emailAddress: string;
    }
}

interface componentStates {}

class ResetPasswordConfirmation extends Component<componentProps, componentStates> {
    render() { 
        const { resetPassword } = this.props;

        return ( 
            <>
            <div className="side-image-theme-text-content">
                The password for <span className="emphasis">{resetPassword.emailAddress}</span> has been updated.
            </div>
            <NavLink href={`/login`}
                className="login-pages-link">
                Return to login
            </NavLink>
            </>
         );
    }
}

const MapStateToProps = (state: {identityReducer: any}) => ({
    resetPassword: state.identityReducer
});

export default connect(MapStateToProps)(ResetPasswordConfirmation);