import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrderDetailProductTile from './OrderDetailProductTile';

import './Styles/_OrderReviewDetailsList.scss';

interface componentProps {
    cartItems: {
        orderItems: any;
    };
}

interface componentStates { }

class OrderReviewDetailsList extends Component<componentProps, componentStates> {
    render() {
        const { cartItems } = this.props;

        if (cartItems.orderItems.length > 0) {
            return (
                <div id="Order-Review-Details-List">
                    {cartItems.orderItems.map((item: any) =>
                        <OrderDetailProductTile key={item.productId} item={item} />
                    )}
                </div>
            );
        }
        return null;
    }
}

const MapStateToProps = (state: { orderReducer: any }) => ({
    cartItems: state.orderReducer
});

export default connect(MapStateToProps)(OrderReviewDetailsList);