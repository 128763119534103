import React from 'react';
import { BemHelper, Typography } from '@cin7/ui';
import { AddressPanel } from '../../../Components/PageElements/Orders/AddressPanel';
import * as PageContent from '../../../Language/Default_Settings';
import { useSelector } from 'react-redux';
import { getBillingAddress } from '../../../Redux/Selectors/OrderSelectors';
import { PaymentPanel } from './PaymentPanel';
import InvoiceParticularPanel from './InvoiceParticularsPanel';

import './Styles/_Billing.scss';

const bem = new BemHelper('billing');

export const Billing: React.FC = () => {

    const billingAddress = useSelector(getBillingAddress);

    return (
        <div className={bem.block()}>
            <div className={bem.element('billing-container')}>
                <Typography variant={"h2"} className={bem.element('billing-title')} >
                    Billing
                </Typography>
                <div className={bem.element('billing-inner-content')}>
                    <AddressPanel title={PageContent.OrderCheckoutPageContent.default_billingAddressLabel} variant={"billing"} address={billingAddress} />
                    <InvoiceParticularPanel editMode={true} />
                    <PaymentPanel />
                </div>
            </div>
        </div>
    );
}
