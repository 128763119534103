import React, { Component } from 'react';
import { Redirect, Switch, Route, RouteComponentProps } from 'react-router-dom';
import SideMenu from '../../Components/Global/SideMenu';
import { Header } from '../../Components/Global/Header';
import ReturnPanel from '../../Components/Global/ReturnPanel';
import { B2BPageTitles } from '../../Language/Default_Settings';
import AccountDetails from './Views/AccountDetails';
import OrderHistory from './Views/OrderHistory';

import './Styles/_Account.scss';

interface componentProps extends RouteComponentProps<any> {
    pagePath?: string;
}

class Account extends Component<componentProps, {}> {
    render() {

        const pageHeading = this._getPageHeading();

        return (
            <>
                <SideMenu />
                <div id="Body-Content">
                    <Header />
                    <ReturnPanel title={pageHeading} />
                    <div id="Account-Container">
                        <Switch>
                            <Route exact path={'/account/details'} component={AccountDetails} />
                            <Route exact path={'/account/orders'} component={OrderHistory} />
                            <Redirect to={'/page-not-found'} />
                        </Switch>
                    </div>
                </div>
            </>
        );
    }

    _getPageHeading() {
        switch (this.props.location.pathname) {
            case "/account/orders":
                return B2BPageTitles.order_history_title;
            default:
                return B2BPageTitles.my_account_title;
        }
    }
}

export default Account;
