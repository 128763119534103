import * as types from "../../Types/FreightTypes";

export type FreightZoneType = {
    id: number;
    name: string;
    tiers?: FreightTierType[];
};

export enum FreightTierPriceType {
    Undefined = 0,
    ToBeConfirmed = 1,
    FlatRate = 2,
    Weight = 3,
    Value = 4,
};

export type FreightTierType = {
    id: number;
    name: string;
    type: FreightTierPriceType;
    price: number;
    conditions?: FreightTierAttributeType[];
};

export type FreightTierAttributeType = {
    id: number;
    minimum: number;
    maximum: number;
    price: number;
};

export type FreightStateType = {
    freightOptionsFetched: boolean;
    freightOptions: FreightZoneType[];
    freightZone: FreightZoneType;
    freightTier: FreightTierType;
    freightTierAttribute: FreightTierAttributeType;
    freightZonesFiltered: boolean;
};

export const initialState: FreightStateType = {
    freightOptionsFetched: false,
    freightOptions: [],
    freightZone: { id: -1, name: "" },
    freightTier: { id: -1, name: "", type: FreightTierPriceType.Undefined, price: 0 },
    freightTierAttribute: { id: -1, minimum: -1, maximum: -1, price: 0 },
    freightZonesFiltered: true,
};

export const FreightReducer = (state = initialState, action: { type: string; payload: any }): FreightStateType => {
    switch (action.type) {
        case types.Get_Freight_Options:
            return {
                ...state,
                freightOptionsFetched: action.payload.freightOptionsFetched,
                freightOptions: action.payload.freightOptions,
            };
        case types.Set_Selected_Freight_Zone:
            return {
                ...state,
                freightZone: action.payload.freightZone,
            };
        case types.Reset_Selected_Freight_Zone:
            return {
                ...state,
                freightZone: initialState.freightZone,
                freightTier: initialState.freightTier,
                freightTierAttribute: initialState.freightTierAttribute,
            };
        case types.Set_Selected_Freight_Tier:
            return {
                ...state,
                freightTier: action.payload.freightTier,
            };
        case types.Reset_Selected_Freight_Tier:
            return {
                ...state,
                freightTier: initialState.freightTier,
                freightTierAttribute: initialState.freightTierAttribute,
            };
        case types.Set_Selected_Freight_Tier_Attribute:
            return {
                ...state,
                freightTierAttribute: action.payload.freightTierAttribute,
            };
        case types.Reset_Selected_Freight_Tier_Attribute:
            return {
                ...state,
                freightTierAttribute: initialState.freightTierAttribute,
            };
        case types.Reset_Freight_Options:
            return {
                ...state,
                freightOptionsFetched: initialState.freightOptionsFetched,
                freightOptions: initialState.freightOptions,
                freightZone: initialState.freightZone,
                freightTier: initialState.freightTier,
                freightTierAttribute: initialState.freightTierAttribute,
            };
        case types.Set_Filtered_Freight_Zones:
            return {
                ...state,
                freightZonesFiltered: action.payload.freightZonesFiltered
            };
        case types.Reset_Filtered_Freight_Zones:
            return {
                ...state,
                freightZonesFiltered: initialState.freightZonesFiltered
            };
        default:
            return state;
    }
};
