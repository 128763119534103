import React, { Component } from 'react';
import {Button, FormGroup} from 'reactstrap';

interface componentProps {
    buttonClass?: string;
    buttonLabel?: string;
    buttonType?: "button" | "submit" | "reset";
    disableButton?: boolean;
}

class ButtonField extends Component<componentProps, {}> {

    render() {
        const {disableButton, buttonLabel, buttonClass, buttonType} = this.props;

        return ( 
            <FormGroup className="cin7-form-group">
                    <Button type={buttonType ? buttonType : "button"} className={`cin7-button ${buttonClass ? buttonClass : "default"}`} disabled={disableButton ? disableButton : false}>{buttonLabel}</Button>
            </FormGroup>
         );
    }
}
 
export default ButtonField;