import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { IndexReducers } from './Reducers/IndexReducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

//Redux Persist
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'authReducer',
    'brandingReducer',
    'productListReducer',
    'orderReducer',
    'identityReducer',
    'userDetailsReducer',
    'freightReducer'
  ]
};

export default function configureStore (history: any, initialState: object) {
  
  const rootReducer = combineReducers({
    ...IndexReducers,
    router: connectRouter(history)
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  //Redux Persistor
  const persistor = persistStore(store);
  
  return { store, persistor };
}
