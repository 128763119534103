import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';

//Redux
import { connect } from 'react-redux';
import { SendResetPasswordLink } from './../../Redux/Actions/IdentityActions';

//Custom Assets
import InputField from './../FormElements/InputField';
import ButtonField from './../FormElements/ButtonField';
import Cin7ConfirmationBox from '../PageElements/Cin7ConfirmationBox';
import { GenerateTextInputValidationParameters, ClearFailValidation } from '../../Utils/B2BHelpers';
import { ForgotPasswordContent } from '../../Language/Default_Settings';


interface componentProps {
    forgotPassword: {
        forgotPasswordButtonLabel: string,
        isForgotPasswordValidationActive: boolean,
        isSendingPasswordLink: boolean,
        forgotPasswordErrorMessage: string
    }
    SendResetPasswordLink: (params:any) => void;
}

interface componentStates {
    emailAddress: string;
}

class ForgotPasswordForm extends Component<componentProps, componentStates> {

    constructor(props: componentProps) {
        super(props);
        this.state = {
            emailAddress: ''
        }
    }

    render(){
        
        const { forgotPassword } = this.props;

        return(
            <>
            {forgotPassword.forgotPasswordErrorMessage && 
                <Cin7ConfirmationBox 
                    boxType={"error-box"}
                    boxIcon={"error-icon"}
                    boxMessage={forgotPassword.forgotPasswordErrorMessage}
                />
            }
            <Form className="cin7-form" onSubmit={this._onSubmit}>
                <InputField 
                    fieldType="email"
                    fieldLabel="Email address" 
                    fieldId="EmailAddress" 
                    fieldName="emailAddress"
                    fieldErrorId="emailValidationError" 
                    onChange={this._onChange}
                    onClear={this._onClear}
                />
                <ButtonField buttonLabel={forgotPassword.forgotPasswordButtonLabel} buttonType="submit" disableButton={forgotPassword.isSendingPasswordLink} />
            </Form>
            <Link to={'/login'} className="login-pages-link">
                {ForgotPasswordContent.return_linkLabel}
            </Link>
            </>
        );
    }

    //Functions
    _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let validationParameters = GenerateTextInputValidationParameters("EmailAddress","emailValidationError");
        if(this.props.forgotPassword.isForgotPasswordValidationActive) {
            ClearFailValidation(validationParameters.inputField, validationParameters.validationField, null);
        }

        this.setState({
            [e.target.name]: e.target.value
        } as unknown as Pick<componentStates, keyof componentStates>);
    }

    _onClear = () => {
        this.setState({
            emailAddress: ''
        });
    }
    
    _onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        let credentials = {
            emailAddress: this.state.emailAddress
        }
        this.props.SendResetPasswordLink(credentials);
    }
    
}


const MapStateToProps = (state: {identityReducer: any} ) => ({
    forgotPassword: state.identityReducer
})

export default connect(MapStateToProps, {SendResetPasswordLink})(ForgotPasswordForm);