import React from 'react';

export const SearchIcon: React.FC = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Search</title>
            <path d="M13.3333 25.6667C20.1448 25.6667 25.6667 20.1448 25.6667 13.3333C25.6667 6.52182 20.1448 1 13.3333 1C6.52182 1 1 6.52182 1 13.3333C1 20.1448 6.52182 25.6667 13.3333 25.6667Z" stroke="#00326B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.6673 31.6667L22.334 22.3334" stroke="#00326B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
