import { useSelector } from "react-redux";
import { RootState } from "..";
import APIHost from "../APIHost";

export const useModuleId = () => {
    return useSelector((state: RootState) => state.brandingReducer?.moduleId);
};

export const useHomepageImageUrl = () => {

    const apiHost = new APIHost();
    const appSettings = apiHost.GetEnvironmentConfig();
    const moduleId = useModuleId();

    return `${appSettings.AssetsHost}/module/${moduleId}/homepage/`;
};