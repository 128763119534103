import React, { Component } from 'react';

class PageNotFound extends Component {

    render() { 
        return ( 
            <h2>404 - Oops page not found</h2>
         );
    }
}
 
export default PageNotFound;