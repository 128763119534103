import { BemHelper, Button, Divider, Dialog, DialogContent, Typography } from '@cin7/ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircleTickIcon from '../../../Images/Icons/Global/CircleTickIcon';
import AlertCircleIcon from '../../../Images/Icons/Global/AlertCircleIcon';
import { Reorder as ReorderAction, ResetReorderState } from '../../../Redux/Actions/OrderActions';
import { getOrderItemCount, getReorderItemsCount, getReorderUnavailableItemsCount, getIsFetchingReorderDetails, getIsReorderComplete, getReorderErrorMessage } from '../../../Redux/Selectors/OrderSelectors';
import Loading from '../../Global/Loading';
import Cin7ConfirmationBox from '../Cin7ConfirmationBox';
import { ReorderModalContent } from '../../../Language/Default_Settings'
import './Styles/_ReorderModal.scss';

interface ReorderModalProps {
    orderId: number,
    orderRef: string,
    CloseAction: VoidFunction;
}

const bem = new BemHelper('reorder-modal')

export const ReorderModal: React.FC<ReorderModalProps> = (props) => {
    const {orderRef, orderId, CloseAction} = props;
    const errorMessage = useSelector(getReorderErrorMessage);
    const orderItemsCount = useSelector(getOrderItemCount);
    const reorderItemsCount = useSelector(getReorderItemsCount);
    const reorderUnavailableItemsCount = useSelector(getReorderUnavailableItemsCount);
    const isFetchingReorderDetails = useSelector(getIsFetchingReorderDetails);
    const isReorderComplete = useSelector(getIsReorderComplete);
    const dispatch = useDispatch();
    const Reorder = () => dispatch(ReorderAction(orderId))
    
    useEffect(() => {
        dispatch(ResetReorderState())
    }, [dispatch])
    
    return (
        <Dialog open onClose={(e, reason) => CloseAction()} className={bem.block()}>
            <DialogContent>
            <div className={bem.element('content')}>
                <div className={bem.element('header')}>
                    <Typography variant={"h1"} className={bem.element('header-text')}>
                        Reorder
                    </Typography>
                </div>
                <Divider />
                {isFetchingReorderDetails && <Loading inLine={true} />}
                {
                    errorMessage && (
                        <>
                            <Cin7ConfirmationBox
                                boxType={"error-box"}
                                boxIcon={"error-icon"}
                                boxMessage={errorMessage}
                            />
                            <div className={bem.element('action-buttons')}>
                                <Button className={bem.element('retry-button')} onClick={Reorder}>{ReorderModalContent.default_retryButtonLabel}</Button>
                                <Button className={bem.element('cancel-button')} onClick={CloseAction} variant={"secondary"}>{ReorderModalContent.default_cancelButtonLabel}</Button>
                            </div>
                        </>
                    )
                }
                {isReorderComplete && (
                    <div className={bem.element('complete-container')}>
                        {reorderItemsCount > 0 && (<>
                            <CircleTickIcon strokeColor="#61A25E" />
                            <Typography className={bem.element('reorder-text')} align={"center"}>
                                Successfully reordered <strong>{orderRef}</strong>.<br />{reorderItemsCount} {reorderItemsCount === 1 ? 'item' : 'items'} added to your cart.
                            </Typography>
                            {reorderUnavailableItemsCount > 0 && (
                                <Typography className={bem.element('reorder-text')} align={"center"}>
                                    {reorderUnavailableItemsCount} {reorderUnavailableItemsCount === 1 ? 'item wasn\'t' : 'items weren\'t'} available for reordering.
                                </Typography>
                            )}
                        </>)}
                        {reorderItemsCount <= 0 && (<>
                            <div className="info-icon"><AlertCircleIcon strokeColor="#00326b" /></div>
                            <Typography className={bem.element('reorder-text')} align={"center"}>
                                None of the items in order <strong>{orderRef}</strong> are available for reordering.
                            </Typography>
                        </>)}
                        <div className={bem.element('action-buttons')}>
                            <Button className={bem.element('done-button')} onClick={CloseAction}>{ReorderModalContent.default_doneButtonLabel}</Button>
                        </div>
                    </div>
                )}
                {!isReorderComplete && !isFetchingReorderDetails && !errorMessage && (
                    <>
                        {orderItemsCount > 0 && <Cin7ConfirmationBox
                            boxType={"info-box"}
                            boxIcon={"info-icon"}
                            boxMessage={ReorderModalContent.default_addToCartNote}
                        />}
                        <Typography className={bem.element('reorder-text')} align={"center"}>
                            Would you like to reorder <strong>{orderRef}</strong>?
                        </Typography>

                        <div className={bem.element('action-buttons')}>
                            <Button className={bem.element('confirm-button')} onClick={Reorder}>{ReorderModalContent.default_confirmButtonLabel}</Button>
                            <Button className={bem.element('cancel-button')} onClick={CloseAction} variant={"secondary"}>{ReorderModalContent.default_cancelButtonLabel}</Button>
                        </div>
                    </>
                )}
            </div>
            </DialogContent>
        </Dialog>
    )
}
