let environment = "";

const hostname = window.location.hostname;

// Hostnames can be custom domains (i.e. mobelgroup.co.nz) or cin7 domains (i.e. ghettomart.staging.cin7.com).
// Custom domains can be configured to be run on an environment by including the corresponding environment name in the domain.
// e.g. b2b.ci.mobelgroup.co.nz

if (hostname.includes("test01.b2b.")) {
    environment = "test01";
} else if (hostname.includes("test02.b2b.")) {
    environment = "test02";
} else if (hostname.includes("test03.b2b.")) {
    environment = "test03";
} else if (hostname.includes("b2b.staging.")) {
    environment = "staging";
} else if (hostname.includes("b2b.cin7.com")) {
    environment = "production";
} else if (hostname.includes("b2b.ci.")) {
    environment = "ci";
} else if (hostname === "localhost" || hostname.endsWith(".localhost")) {
    environment = "development";
} else {
    environment = "production";
}

class APIHost {
    hostname: string;
    appSettings: any;

    constructor() {
        this.hostname = window.location.hostname;
        this.appSettings = this.GetEnvironmentConfig();
    }

    static GetEnvironment() {
        return environment;
    }

    GetEnvironmentConfig() {
        switch (environment) {
            case "ci":
                return require("./Config/appsettings.ci.json");
            case "staging":
                return require("./Config/appsettings.staging.json");
            case "production":
                return require("./Config/appsettings.production.json");
            case "test01":
                return require("./Config/appsettings.test01.json");
            case "test02":
                return require("./Config/appsettings.test02.json");
            case "test03":
                return require("./Config/appsettings.test03.json");
            case "development":
            default:
                return require("./Config/appsettings.development.json");
        }
    }

    RedirectHostRoutes(requestType: string, domainName: any) {
        const clientHostSettings = this.appSettings.ClientHostUrls.PageRoutes;
        if (requestType === "Login") {
            return this.RedirectClientSideSwitchCase(this.hostname, clientHostSettings.login, domainName);
        }

        return this.RedirectClientSideSwitchCase(this.hostname, clientHostSettings.default, "b2b.dev.cin7.com");
    }

    AuthAPI(requestType: string) {
        const authSettings = this.appSettings.ApiHostUrls.AuthApi;
        switch (requestType) {
            case "StoreInformation":
            case "AuthorizeUser":
                return this.GenerateSwitchCase(authSettings.AuthorizeUser);
            case "RefreshUserToken":
                return this.GenerateSwitchCase(authSettings.RefreshUserToken);
            case "Logout":
                return this.GenerateSwitchCase(authSettings.LogoutUser);
            default:
                return null;
        }
    }

    IdentityAPI(requestType: string) {
        const identitySettings = this.appSettings.ApiHostUrls.IdentityApi;
        switch (requestType) {
            case "ResetPassword":
                return this.GenerateSwitchCase(identitySettings.ResetPassword);
            case "CreatePassword":
                return this.GenerateSwitchCase(identitySettings.CreatePassword);
            case "ForgotPassword":
                return this.GenerateSwitchCase(identitySettings.ForgotPassword);
            case "ConfirmEmail":
                return this.GenerateSwitchCase(identitySettings.ConfirmEmail);
            case "GetRegisterDetails":
                return this.GenerateSwitchCase(identitySettings.GetRegisterDetails);
            case "GetUserDetails":
                return this.GenerateSwitchCase(identitySettings.GetUserDetails);
            case "GetUserStatus":
                return this.GenerateSwitchCase(identitySettings.GetUserStatus);
            default:
                return null;
        }
    }

    ProductsAPI(requestType: string) {
        const productSettings = this.appSettings.ApiHostUrls.ProductApi;
        switch (requestType) {
            case "GetProductNames":
                return this.GenerateSwitchCase(productSettings.GetProductNames);
            case "GetProductsList":
                return this.GenerateSwitchCase(productSettings.GetProductsList);
            case "GetProductDetails":
                return this.GenerateSwitchCase(productSettings.GetProductDetails);
            case "GetBrandsList":
                return this.GenerateSwitchCase(productSettings.GetBrandsList);
            case "GetCategoriesList":
                return this.GenerateSwitchCase(productSettings.GetCategoriesList);
            case "GetCheckoutValidationDetail":
                return this.GenerateSwitchCase(productSettings.GetCheckoutValidationDetail);
            default:
                return null;
        }
    }

    CheckoutAPI(requestType: string) {
        const checkoutSettings = this.appSettings.ApiHostUrls.CheckoutApi;
        switch (requestType) {
            case "CreateOrder":
                return this.GenerateSwitchCase(checkoutSettings.CreateOrder);
            case "Reorder":
                return this.GenerateSwitchCase(checkoutSettings.Reorder);
            case "GetOrderHistoryList":
                return this.GenerateSwitchCase(checkoutSettings.GetOrderHistoryList);
            case "BulkImportCSV":
                return this.GenerateSwitchCase(checkoutSettings.BulkImportCSV);
            case "ApproveOrder":
                return this.GenerateSwitchCase(checkoutSettings.ApproveOrder);
            case "Freight":
                return this.GenerateSwitchCase(checkoutSettings.Freight);
            default:
                return null;
        }
    }

    PromoMatrixAPI(requestType: string) {
        const promoMatrixSettings = this.appSettings.ApiHostUrls.PromoMatrixApi;
        switch (requestType) {
            case "CalculatePromotions":
                return this.GenerateSwitchCase(promoMatrixSettings.CalculatePromotions);
            default:
                return null;
        }
    }

    ContactUsFormAPI(requestType: string) {
        const contactUsFormSettings = this.appSettings.ApiHostUrls.ContactUsFormApi;
        switch (requestType) {
            case "SendRequestEmail":
                return this.GenerateSwitchCase(contactUsFormSettings.SendRequestEmail);
            case "ValidateReCaptchaToken":
                return this.GenerateSwitchCase(contactUsFormSettings.ValidateReCaptchaToken);
            default:
                return null;
        }
    }

    GenerateSwitchCase(apiUrl: any) {
        return this.appSettings.MobileClientUrlEndpoint + apiUrl;
    }

    RedirectClientSideSwitchCase(hostValue: string, pageRoute: any, domainName: string) {
        if (hostValue === "localhost") {
            return "http://" + domainName + ":3000" + pageRoute;
        }

        return "https://" + domainName + pageRoute;
    }
}

export default APIHost;
