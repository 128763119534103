import React, {Component} from 'react';

import './Styles/_HtmlBlock.scss';

interface componentProps {
    content: any;
    htmlBlock: any;
}
 
interface componentState {}
 
class HtmlBlock extends Component<componentProps, componentState> {

    rawDescription(){
        var rawMarkup = this.props.content;
        return { __html: rawMarkup };
    }

    render() {
        const { content, htmlBlock } = this.props;

        if (content) {
            return (
                <div className={htmlBlock}>
                    {content.indexOf('</') !== -1
                        ? (
                            <div dangerouslySetInnerHTML={this.rawDescription()} >
                            </div>
                        )
                        : content
                    }
                </div>
            );
        } else {
            return null;
        }
    }
}
 
export default HtmlBlock;