import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LogoutB2BUser } from '../../../Redux/Actions/AuthActions';
import { AccountDetailsPageContent, B2BPageTitles } from '../../../Language/Default_Settings';
import { AccountOnHoldBanner } from '../../../Components/PageElements/Account/AccountOnHoldBanner';

import '../Styles/_AccountDetails.scss';

interface componentProps {
    identity: {
        userInfo: {
            company: string;
            firstName: string;
            lastName: string;
            email: string;
        }
    };
    LogoutB2BUser: () => void;
}

interface componentState {}

class AccountDetails extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.my_account_title}`;
    }

    render() {
        const { userInfo } = this.props.identity;

        return (
            <div id="Account-Details-Container">
                <div className="account-details-content">
                    <div className="account-on-hold-container"><AccountOnHoldBanner /></div>
                    <div className="section-heading">{AccountDetailsPageContent.default_detailsSectionHeading}</div>
                    <div className="section-note">{AccountDetailsPageContent.default_detailsSectionNote}</div>
                    <div className="user-details-container">
                        <div className="user-details">
                            <div className="user-detail user-name">{userInfo.firstName} {userInfo.lastName}</div>
                            <div className="user-detail user-company">{userInfo.company}</div>
                            <div className="user-detail user-email email-display">{userInfo.email}</div>
                        </div>
                        <div className="user-details-action"><div className="log-out-link" onClick={this._logoutUser}>Log out</div></div>
                    </div>
                    <div className="order-history-link"><Link to={"/account/orders"}>View your order history</Link></div>
                </div>
            </div>
        );
    }

    _logoutUser = (e: React.SyntheticEvent) => {
        e.preventDefault();
        this.props.LogoutB2BUser();
    }
}

const MapStateToProps = (state: {identityReducer: any;}) => ({
    identity: state.identityReducer,
});

export default connect(MapStateToProps,{LogoutB2BUser})(AccountDetails);
