import { Button, Dialog, Typography, BemHelper, AddToCartIcon, ReplaceCartIcon, ActionCard, UploadIcon, Divider, DialogContent } from '@cin7/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { FailedImportRecord, FailedReadRecord } from '../../../Domain/BulkImportCSV';
import { ConfirmBulkOrderModalContent } from '../../../Language/Default_Settings';
import { getOrderItemCount } from '../../../Redux/Selectors/OrderSelectors';
import { IssuesCard, IssuesCardVariant } from './IssuesCard';

import './Styles/_ConfirmBulkOrderModal.scss';

export interface ConfirmBulkOrderModalProps {
    onCloseClick: VoidFunction;
    onAddToCart: VoidFunction;
    onReplaceCart: VoidFunction;
    onReuploadClick: VoidFunction;
    csvReadErrors: FailedReadRecord[];
    importErrors: FailedImportRecord[];
    stockErrors: string[];
    stockWarnings: string[];
    hasSuccessfullRows: boolean;
}

export interface Error {
    product: string,
    sku: string,
    error: string
}

const bem = new BemHelper('confirm-bulk-order-modal')

export const ConfirmBulkOrderModal: React.FC<ConfirmBulkOrderModalProps> = (props) => {
    const orderItemCount = useSelector(getOrderItemCount);
    const hasErrors = props.csvReadErrors.length > 0 || props.importErrors.length > 0 || props.stockErrors.length > 0;
    const errorSections = [];
    
    if (props.csvReadErrors.length > 0) {
        errorSections.push({
            title: ConfirmBulkOrderModalContent.default_csvReadErrorMessage,
            issues: 
                props.csvReadErrors.map((error, i) => {
                    return <li key={i}><Typography variant={"body1"} gutterBottom>Row <strong>{error.rowIndex}</strong></Typography></li>
                })
        })
    }

    if (props.importErrors.length > 0) {
        errorSections.push({
            title: ConfirmBulkOrderModalContent.default_importErrorMessage,
            issues: 
                props.importErrors.map((error, i) => {
                    return (
                        <li key={i}>
                            <Typography variant={"body1"} gutterBottom>
                                SKU: <strong>"{error.sku}"</strong> - Barcode: <strong>"{error.barcode}"</strong> - Size: <strong>"{error.size}"</strong> - Quantity: <strong>{error.quantity}</strong>
                            </Typography>
                        </li>
                    )
                })
        })
    }

    if (props.stockErrors.length > 0) {
        errorSections.push({
            title: ConfirmBulkOrderModalContent.default_stockErrorMessage,
            issues: 
                props.stockErrors.map((error, i) => {
                    return <li key={i}><Typography variant={"body1"} gutterBottom>{error}</Typography></li>
                })
        })
    }

    return (
        <Dialog open onClose={(e, reason) => props.onCloseClick()} className={bem.block()}>
            <DialogContent>
            <div className={bem.element('content')}>
                <div className={bem.element('header')}>
                    <Typography variant={"h1"} className={bem.element('header-text')}>
                        {ConfirmBulkOrderModalContent.default_header}
                    </Typography>
                    <Button variant="secondary" onClick={props.onCloseClick} className={bem.element('cancel-button')}>{ConfirmBulkOrderModalContent.default_cancelButtonLabel}</Button>
                </div>
                <Divider />
                {hasErrors && (
                    <IssuesCard 
                        title={ConfirmBulkOrderModalContent.default_issuesTitle}
                        variant={IssuesCardVariant.Error}
                        sections={errorSections}
                        action={<Button className={bem.element('reupload-button')} onClick={props.onReuploadClick}>{ConfirmBulkOrderModalContent.default_reuploadFileButtonLabel}</Button>} 
                    />
                )}
                 {props.stockWarnings.length > 0 && (
                    <IssuesCard 
                        title={ConfirmBulkOrderModalContent.default_warningsTitle}
                        variant={IssuesCardVariant.Warning}
                        sections={[
                            {
                                title: ConfirmBulkOrderModalContent.default_stockWarningMessage,
                                issues: 
                                    props.stockWarnings.map((warning, i) => {
                                        return <li key={i}><Typography variant={"body1"} gutterBottom>{warning}</Typography></li>
                                    })
                            }
                        ]}
                    />
                )}
                <Typography variant={"body1"} gutterBottom className={bem.element('prompt')}>{ConfirmBulkOrderModalContent.default_subtitle}</Typography>
                <div className={bem.element('actions')}>
                    {props.hasSuccessfullRows ? (
                        <ActionCard 
                            actionButtonLabel={ConfirmBulkOrderModalContent.default_addButtonLabel} 
                            icon={<AddToCartIcon />} 
                            message={ConfirmBulkOrderModalContent.default_addMessage}
                            onActionClick={props.onAddToCart} 
                            title={ConfirmBulkOrderModalContent.default_addTitle}
                        />
                        ) : (
                        <ActionCard 
                            actionButtonLabel={ConfirmBulkOrderModalContent.default_reuploadButtonLabel} 
                            icon={<UploadIcon />} 
                            message={ConfirmBulkOrderModalContent.default_reuploadMessage}
                            onActionClick={props.onReuploadClick} 
                            title={ConfirmBulkOrderModalContent.default_reuploadTitle}
                        />
                    )}
                    {props.hasSuccessfullRows && orderItemCount > 0 && <ActionCard
                        actionButtonLabel={ConfirmBulkOrderModalContent.default_replaceButtonLabel} 
                        icon={<ReplaceCartIcon />} 
                        message={`This will remove the ${orderItemCount} item${orderItemCount > 1 ? 's' : ''} in your Cart and create a new Cart for your order`}
                        onActionClick={props.onReplaceCart} 
                        title={ConfirmBulkOrderModalContent.default_replaceTitle}
                    />}
                </div>
            </div>
            </DialogContent>
        </Dialog>
    )
}