import * as React from 'react';
import { connect } from "react-redux";

import './Styles/_ProductPromotionsBanner.scss';

interface IProductPromotionsBannerProps{
    productListViewStyle : string;
    OpenAction: (e: any) => void;
}

const ProductPromotionsBanner : React.FC<IProductPromotionsBannerProps> = ({productListViewStyle, OpenAction}) => {
    return (
        <span data-testid="promotion-banner" className={["promotion-tile", productListViewStyle && productListViewStyle === "list-view" ? " list-view" : ""].join('')} 
            onClick={(e) => {OpenAction(e)}}>
            Promo
        </span>
    )
}

const MapStateToProps = (state: {
    productListReducer: any;
  }) => ({
    productListViewStyle: state.productListReducer?.productListViewStyle
});

export default connect(MapStateToProps, {
})(ProductPromotionsBanner);