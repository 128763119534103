import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import SideMenu from '../../Components/Global/SideMenu';
import { Header } from '../../Components/Global/Header';
import { Cart } from './Views/Cart';
import { Checkout } from './Views/Checkout';
import OrderCompletion from './Views/OrderCompletion';
import Loading from '../../Components/Global/Loading';

import './Styles/_Orders.scss';

interface componentProps {
    cartItems: {
        isLoading: boolean;
        shouldRedirectToPayment: boolean;
        shouldContinueDisplayingPlacingOrder: boolean;
    };
}
 
interface componentStates {}

class Orders extends Component<componentProps, componentStates> {
    render() {
        const { cartItems } = this.props;
        return (
            <>
                {(cartItems.isLoading || cartItems.shouldRedirectToPayment || cartItems.shouldContinueDisplayingPlacingOrder)
                    && createPortal(<Loading text="Placing Order..." takeOver/>, document.body)}
                <SideMenu />
                <div id="Body-Content">
                    <Header />
                    <Switch>
                        <Route exact path={'/cart'} component={Cart} />
                        <Route exact path={'/checkout'} component={Checkout} />
                        <Route exact path={'/orders/complete'} component={OrderCompletion} />
                        <Redirect to={'/page-not-found'} />
                    </Switch>
                </div>
            </>
        );
        
    }
}

const MapStateToProps = (state: {orderReducer: any}) => ({
    cartItems: state.orderReducer
});

export default connect(MapStateToProps)(Orders);