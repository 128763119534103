import React, { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { GetProductDetails } from "../../../Redux/Actions/ProductDetailsActions";
import { RemoveOrderProduct, CalculatePromotions } from "../../../Redux/Actions/OrderActions";
import { ProductActionsContent } from "../../../Language/Default_Settings";
import MoreActionsPanel from "../../Global/MoreActionsPanel";
import Loading from "../../Global/Loading";
import ProductDetailsContent from "./ProductDetailsContent";
import Cin7ConfirmationBox from "../Cin7ConfirmationBox";
import ProductNotFound from "./ProductNotFound";

import "./Styles/_ProductDetailsModal.scss";

interface componentProps {
    productId?: number;
    productDetails: {
        isFetchingProductDetails: boolean;
        product: any;
        message: string;
        productNotFound: boolean;
    };
    auth: {
        subscribedToken: string;
    };
    CloseAction: (e: SyntheticEvent, productId: number | null) => void;
    CalculatePromotions: (addProductDetailsReducerProduct: boolean) => void;
    GetProductDetails: (token: string, productId: number) => void;
    RemoveOrderProduct: (
        productId: number,
        optionId: number | null,
        sizeId: number | null,
        uomId: number | null
    ) => void;
}

interface componentState {
    productId: number;
    isFetchingProductDetails: boolean;
    product: any;
    message: string;
    userToken: string;
}

class ProductDetailsModal extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            productId: 0,
            isFetchingProductDetails: props.productDetails.isFetchingProductDetails,
            product: props.productDetails.product,
            message: props.productDetails.message,
            userToken: props.auth.subscribedToken,
        };
    }

    componentDidMount() {
        let id = Number(this.props.productId);

        this.setState({
            productId: id,
        });

        this.props.GetProductDetails(this.props.auth.subscribedToken, id);
    }

    static getDerivedStateFromProps(props: componentProps, state: componentState) {
        if (props.auth.subscribedToken !== state.userToken) {
            return {
                userToken: props.auth.subscribedToken,
            };
        }
        if (props.productId !== state.productId) {
            return {
                productId: props.productId,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.auth.subscribedToken !== this.props.auth.subscribedToken) {
            this.props.GetProductDetails(this.props.auth.subscribedToken, this.state.productId);
        }
        if (this.props.productId && this.props.productId > 0 && prevProps.productId !== this.props.productId) {
            this.props.GetProductDetails(this.props.auth.subscribedToken, this.props.productId);
        }

        // fetch promotions after loading product
        if (prevProps.productDetails.isFetchingProductDetails && 
                !this.props.productDetails.isFetchingProductDetails) {
            this.props.CalculatePromotions(true);
        }
    }

    render() {
        const { productDetails, CloseAction } = this.props;

        const actions = [
            {
                name: ProductActionsContent.default_resetQuantityLabel,
                action: this._resetProductQuantity,
            },
            {
                name: ProductActionsContent.default_openNewTabLabel,
                action: this._openNewTab,
            },
        ];

        if (productDetails.message) {
            if (productDetails.productNotFound) {
                return (
                    <>
                        <MoreActionsPanel actions={actions} />
                        <ProductNotFound message={productDetails.message} />
                    </>
                );
            } else {
                return (
                    <>
                        <MoreActionsPanel actions={actions} />
                        {productDetails.message && (
                            <div className="error-message-container">
                                <Cin7ConfirmationBox
                                    boxType={"error-box"}
                                    boxIcon={"error-icon"}
                                    boxMessage={productDetails.message}
                                />
                            </div>
                        )}
                    </>
                );
            }
        }

        if (productDetails.isFetchingProductDetails) {
            return <Loading inLine />;
        } else {
            return (
                <>
                    <MoreActionsPanel actions={actions} />
                    <ProductDetailsContent productDetails={productDetails} CloseAction={CloseAction} />
                </>
            );
        }
    }

    _resetProductQuantity = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.RemoveOrderProduct(this.state.productId, null, null, null);
        this.props.CalculatePromotions(true);

        //Re renders the details page
        this.setState(this.state);
    };

    _openNewTab = (e: SyntheticEvent) => {
        e.preventDefault();

        window.open(`/products/details/${this.props.productId}`, "_blank");
    };
}

const MapStateToProps = (state: { productDetailsReducer: any; orderReducer: any; authReducer: any }) => ({
    productDetails: state.productDetailsReducer,
    auth: state.authReducer,
});

export default connect(MapStateToProps, {
    GetProductDetails,
    RemoveOrderProduct,
    CalculatePromotions,
})(ProductDetailsModal);
