import React, { Component } from 'react';

class ClearInputIcon extends Component {
    render() { 
        const cls1 = {
            fill: 'none',
            stroke: '#6d6e74',
            strokeWidth: '2px'
        };
        return ( 
            <svg xmlns="https://www.w3.org/2000/svg" width="15.414" height="15.414" viewBox="0 0 15.414 15.414">
                <title>Clear</title>
                <g id="Group_505" data-name="Group 505" transform="translate(-538.793 -427.793)">
                    <path id="Line_223" d="M0 0l14 14" style={cls1} data-name="Line 223" transform="translate(539.5 428.5)"/>
                    <path id="Line_224" d="M14 0L0 14" style={cls1} data-name="Line 224" transform="translate(539.5 428.5)"/>
                </g>
            </svg>

         );
    }
}
 
export default ClearInputIcon;