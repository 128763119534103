import React from 'react';
import { useSelector } from 'react-redux';
import {
    getOptionsMaxPrice,
    getOptionsMaxSpecialPrice,
    getOptionsMinPrice,
    getOptionsMinSpecialPrice,
    getProductOptionsByOptionId,
    productOptionsHaveSpecial
} from '../../../Redux/Selectors/ProductSelectors';
import PriceDisplay from '../../Global/PriceDisplay';

import './Styles/_StockIndicator.scss';

interface ProductOptionPriceRangeProps {
    product: any;
    optionId: number;
}

const ListPrice = (minPrice: number, maxPrice: number) => {

    if (minPrice === maxPrice) {
        return <PriceDisplay value={minPrice} />;
    }
    else {
        return (
            <>
                <PriceDisplay value={minPrice} /> - <PriceDisplay value={maxPrice} />
            </>
        );
    }
};

const ListSpecialPrice = (minSpecialPrice: number, maxSpecialPrice: number) => {
    if (minSpecialPrice === maxSpecialPrice) {
        return <PriceDisplay value={minSpecialPrice} />;
    }
    else {
        return (
            <>
                <PriceDisplay value={minSpecialPrice} /> - <PriceDisplay value={maxSpecialPrice} />
            </>
        );
    }
};

export const ProductOptionPriceRange: React.FC<ProductOptionPriceRangeProps> = (
    props
) => {
    const { product, optionId } = props;

    const options = useSelector(getProductOptionsByOptionId(product.options, optionId));
    const hasSpecialPrice = useSelector(productOptionsHaveSpecial(options));
    const minPrice = useSelector(getOptionsMinPrice(options));
    const maxPrice = useSelector(getOptionsMaxPrice(options));
    const minSpecialPrice = useSelector(getOptionsMinSpecialPrice(options));
    const maxSpecialPrice = useSelector(getOptionsMaxSpecialPrice(options));

    if (hasSpecialPrice) {
        return (
            <div className="product-details-price">
                <span className="special-price">{ListSpecialPrice(minSpecialPrice, maxSpecialPrice)} <span className="original-price">{ListPrice(minPrice, maxPrice)}</span></span>
            </div>
        );
    } else {
        return (
            <div className="product-details-price">
                {ListPrice(minPrice, maxPrice)}
            </div>
        );
    }
}

export default ProductOptionPriceRange;
