import React, { Component } from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentState {
    strokeColor: string;
}

class CircleTickIcon extends Component<componentProps, componentState> {
    constructor(props: Readonly<componentProps>) {
        super(props);
        this.state = {
            strokeColor: '#000'
        }
    }

    componentDidMount() {
        if(this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }
    
    render() {
        const { strokeColor } = this.state;
        
        const cls1 = {
            stroke: strokeColor
        }
        
        return (
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="https://www.w3.org/2000/svg">
                <title>Success</title>
                <path d="M21 39L30 48L51 27" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" style={cls1}/>
                <circle cx="36" cy="36" r="31.5" strokeWidth="3" style={cls1}/>
            </svg>
        );
    }
}
 
export default CircleTickIcon;