import React from 'react';
import { useSelector } from 'react-redux';
import { getOrderItemOptionNote } from '../../../Redux/Selectors/OrderSelectors';

import './Styles/_OrderOptionNotes.scss';

export interface OrderOptionNotesProps {
    itemOptionId: number;
    productId: number;
    uomId: number | null;
}

export const OrderOptionNotes: React.FC<OrderOptionNotesProps> = (props) => {
    const itemOptionNote = useSelector(getOrderItemOptionNote(props.itemOptionId, props.productId, props.uomId));
    const hasNote = !!itemOptionNote?.notes;
    return (
        <div className={["order-option-notes", hasNote ? ' has-note' : ''].join('')}>
            {hasNote && <>
                <div className="order-option-notes__note"><span className="order-option-notes__note-header">Note: </span>{itemOptionNote?.notes}</div>
            </>}
        </div>
    )
}