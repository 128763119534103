//Review Page
export const Review_Order_Reset = "Review_Order_Reset";
export const Review_Order_Active = "Review_Order_Active";
export const Review_Order_Completed = "Review_Order_Completed";

//Delivery Page
export const Delivery_Order_Reset = "Delivery_Order_Reset";
export const Delivery_Order_Active = "Delivery_Order_Active";
export const Delivery_Order_Completed = "Delivery_Order_Completed";

//Billing Page
export const Billing_Order_Reset = "Billing_Order_Reset";
export const Billing_Order_Active = "Billing_Order_Active";
export const Billing_Order_Completed = "Billing_Order_Completed";

//Confirm Page
export const Confirm_Order_Reset = "Confirm_Order_Reset";
export const Confirm_Order_Active = "Confirm_Order_Active";
//export const Confirm_Order_Completed = "Confirm_Order_Completed";