import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LockIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fillColor: '#33b3cb'
        }
    }

    componentDidMount() {
        if(this.props.FillColor) {
            this.setState({
                fillColor: this.props.FillColor
            });
        }
    }

    render() { 
        const { fillColor } = this.state;
        const cls1 = {
            fill: fillColor
        }

        return ( 
            <svg xmlns="https://www.w3.org/2000/svg" width="18.214" height="27.857" viewBox="0 0 18.214 27.857">
                <title>Lock Icon</title>
                <path id="Path_5287" d="M433.371 292.663h-.757v-4.654a7.008 7.008 0 0 0-14.016-.016v4.67h-.755a1.343 1.343 0 0 0-1.343 1.343v13.5a1.343 1.343 0 0 0 1.342 1.344h15.528a1.343 1.343 0 0 0 1.344-1.342v-13.5a1.343 1.343 0 0 0-1.343-1.345zm-7.768-9.788a5.135 5.135 0 0 1 5.134 5.134v4.654h-10.265v-4.654a5.135 5.135 0 0 1 5.128-5.134zm7.233 24.1h-14.462v-12.439h14.465zm-7.232-3.254a.936.936 0 0 0 .937-.935v-4.065a.937.937 0 1 0-1.874-.005v4.068a.936.936 0 0 0 .933.941z" style={cls1} data-name="Path 5287" transform="translate(-416.5 -280.993)"/>
            </svg>
         );
    }
}

LockIcon.propTypes = {
    FillColor: PropTypes.string
}
 
export default LockIcon;