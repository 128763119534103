import React, { Component } from 'react';

interface componentProps {
    fillColor?: string;
}

interface componentState {
    fillColor: string;
}

class RemoveIcon extends Component<componentProps, componentState> {
    constructor(props: Readonly<componentProps>) {
        super(props);
        this.state = {
            fillColor: '#000'
        }
    }

    componentDidMount() {
        if(this.props.fillColor) {
            this.setState({
                fillColor: this.props.fillColor
            });
        }
    }
    
    render() { 
        const {fillColor} = this.state;
          
        return ( 
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                <title>Remove</title>
                <path d="M18 8L8 18" stroke={fillColor} strokeLinecap="round" />
                <path d="M18 18L8 8" stroke={fillColor} strokeLinecap="round" />
            </svg>
         );
    }
}
 
export default RemoveIcon;