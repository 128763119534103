import React, { Component } from 'react';
import { connect } from 'react-redux';
import { B2BPageTitles } from '../../../Language/Default_Settings';
import ReturnPanel from '../../../Components/Global/ReturnPanel';
import HtmlBlock from '../../../Components/Global/HtmlBlock';

interface componentProps {
  identity: {
    moduleSettings: {
      customPage2Title: string;
      customPage2Content: string;
    }
  };
}

interface componentState {}

class CustomPage2 extends Component<componentProps, componentState> {
    componentDidMount() {
        this.setPageTitle();
    }

    componentDidUpdate() {
        this.setPageTitle();
    }

    render() {
        const { identity } = this.props;

        return (
            <>
                <ReturnPanel title={identity.moduleSettings.customPage2Title ? identity.moduleSettings.customPage2Title : B2BPageTitles.custom_page_2_title} />
                <div id="Seller-Content-Container">
                    <div id="Seller-Content">
                        <HtmlBlock content={identity.moduleSettings.customPage2Content} />
                    </div>
                </div>
            </>
        );
    }

    setPageTitle() {
        document.title = `${B2BPageTitles.root_title} - ${this.props.identity.moduleSettings.customPage2Title ? this.props.identity.moduleSettings.customPage2Title : B2BPageTitles.custom_page_2_title}`;
    }
}

const MapStateToProps = (state: {identityReducer: any;}) => ({
  identity: state.identityReducer
});

export default connect(MapStateToProps,{})(CustomPage2);
