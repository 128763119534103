import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import Category from "./Views/Category";
import SideMenu from "../../Components/Global/SideMenu";
import { Header } from "../../Components/Global/Header";
import { GetCategoriesList } from "../../Redux/Actions/ProductListActions";
import NavigationMenu from "../../Components/Global/NavigationMenu";
import { useCategoryRouteObjects } from "../../Utils/CategoriesHelper";
import { useIsWindowSmall } from "../../Utils/Global/UseWindowSizeHook";
import ProductDetail from "./Views/ProductDetail";
import ProductFilterBackground from "../../Components/PageElements/Products/ProductFilterBackground";

import "./Styles/_ProductDetails.scss";

type Props = {
    auth: {
        subscribedToken: string;
    };
    GetCategoriesList: (token: string) => void;
};

const Products = (props: Props) => {
    const categoryRouteObjects = useCategoryRouteObjects();
    const smallScreen = useIsWindowSmall();

    useEffect(() => {
        GetCategoriesList(props.auth.subscribedToken);
    }, [props.auth.subscribedToken]);

    return (
        <>
            <SideMenu />
            <div id="Body-Content">
                <Header />
                <div className={"content-container"}>
                    <ProductFilterBackground />
                    {!smallScreen && <NavigationMenu />}
                    {categoryRouteObjects && (
                        <Switch>
                            <Route exact path={"/products/"} component={Category} />;
                            <Route exact path={"/products/details/:id"} component={ProductDetail} />
                            <Redirect exact path={"/products/list"} to={"/products/"} />;
                            <Redirect to={"/page-not-found"} />
                        </Switch>
                    )}
                </div>
            </div>
        </>
    );
};

const MapStateToProps = (state: { authReducer: any }) => ({
    auth: state.authReducer,
});

export default connect(MapStateToProps, { GetCategoriesList })(Products);
