import APIHost from '../APIHost';

const apiHost = new APIHost();

export const GetTenantImageUrl = (imagePath:string) => {
    let appSettings = apiHost.GetEnvironmentConfig();
    
    if (appSettings && imagePath && imagePath.length > 0) {

        if (imagePath.includes('https://')) {
            return imagePath;
        }

        return appSettings.AssetsHost + imagePath;
    }

    return imagePath;
};