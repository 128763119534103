import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RemoveOrderProduct, CalculatePromotions } from '../../../Redux/Actions/OrderActions';
import ProductOptionPrice from '../../../Components/PageElements/Products/ProductOptionPrice';
import { ProductOptionUOMUnitPrice } from '../../../Components/PageElements/Products/ProductOptionUOMUnitPrice';

import ProductOptionQuantity from '../../../Components/PageElements/Products/ProductOptionQuantity';
import { FormatAmount } from '../../../Utils/OrderHelper';
import PriceDisplay from '../../Global/PriceDisplay';
import RemoveIcon from '../../../Images/Icons/Orders/RemoveIcon';
import { OrderOptionNotes } from './OrderOptionNotes';
import { OrderItemNote } from '../../../Redux/Reducers/App_Reducers/OrderReducer';
import { GetTotalProductOptionPrice, GetLineItemDiscount } from '../../../Utils/ProductsHelper';
import { AddNoteButton } from './AddNoteButton';

import './Styles/_OrderDetailProductOption.scss';

interface componentProps {
    product: any;
    order: any;
    optionGroup: any;
    hasMultipleOptions: boolean;
    hasMultipleOptionGroups: boolean;
    RemoveOrderProduct: (productId: number, optionId: number, sizeId: number | null, uomId: number | null) => void;
    isSizeOption: boolean;
    CalculatePromotions: () => void;
}

interface componentState { }

class OrderDetailProductOption extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {}
    }

    hasNotes(optionId: number, productId: number, uomId: number | null) {
        const itemNote = this.props.order.orderItemNotes.find((note: OrderItemNote) => note.optionId === optionId && note.productId === productId && note.uomId === uomId);
        return !!itemNote?.notes;
    }

    render() {
        const { product, optionGroup, hasMultipleOptions, hasMultipleOptionGroups, isSizeOption } = this.props;

        let option = optionGroup[0];

        let totalOptionsPrice = 0;
        let totalOptionsDiscount = 0;
        let totalOptionsQuantity= 0;
        
        optionGroup.forEach((options: { productQuantity: number; }) => {
            totalOptionsPrice += GetTotalProductOptionPrice(options);
            totalOptionsDiscount += GetLineItemDiscount(options);
            totalOptionsQuantity += options.productQuantity;
        });
        let optionHasMultipleOptions = Array.from(optionGroup).length > 1;
        const singleOption = optionGroup.length === 1;
        return (
            <>
            <div className={["option-header", this.hasNotes(option.optionId, product.id, option.uomId) ? " has-notes" : ""].join('')}>
                {(option.option1 || option.uomSize) && <div className="option-title">{option.option1}{option.uomSize && ` (${option.uomSize})`}</div>}
                {isSizeOption && (
                    <div className="option-note">
                        <div className="note-container"><OrderOptionNotes itemOptionId={option.optionId} productId={product.id} uomId={option.uomId} /></div>
                        <div className="add-note-button-container"><AddNoteButton itemOptionId={option.optionId} productId={product.id} uomId={option.uomId} /></div>
                    </div>)
                }
                </div>
                <div className="option-header-titles">
                    <div className="title-quantity">Quantity</div>
                    <div className="title-price">
                        <div>Price</div>
                        <div>{totalOptionsDiscount > 0 && "Discount"}</div>
                        <div>Total</div>
                    </div>
                </div>
            {optionGroup.map((productOption: any) =>  {
                const labelHasContent = !!productOption.option2 || !!productOption.option3 || !!productOption.size;
                const lineItemDiscount = GetLineItemDiscount(productOption);
                
                return (
                    <div className="product-options-inner-container" key={`${productOption.optionId}${productOption.sizeId}`}>
                        <div className={["option-label", labelHasContent ? " has-label" : ""].join('')}>
                            {productOption.option2} {productOption.option3} {productOption.size} {!labelHasContent && !singleOption && productOption.code}
                        </div>
                        <div className="option-action-container">
                            {hasMultipleOptions && <span className="product-cart-delete" onClick={(e) => this._removeCartProduct(e, productOption.optionId, productOption.sizeId, productOption.uomId)}>
                                <RemoveIcon fillColor='#808081' />
                            </span>}
                        </div>
                        {!isSizeOption && <div className="add-note-button-desktop">
                            <AddNoteButton itemOptionId={productOption.optionId} productId={product.id} uomId={productOption.uomId} />
                        </div>}
                        {!isSizeOption && <div
                            className={
                                [
                                    "option-note",
                                    this.hasNotes(productOption.optionId, product.id, productOption.uomId) ? " has-notes" : "",
                                    (!labelHasContent && singleOption) ? " no-label" : ""
                                ].join('')
                            }
                        >
                            <OrderOptionNotes itemOptionId={productOption.optionId} productId={product.id} uomId={productOption.uomId} />
                            <div className="add-note-button-mobile"><AddNoteButton itemOptionId={productOption.optionId} productId={product.id} uomId={productOption.uomId} /></div>
                        </div>}
                        <div className="option-quantity-container">
                            <div className="quantity-label">Quantity</div>
                            <ProductOptionQuantity product={product} option={productOption} />
                        </div>
                        <div className="option-price-container">
                            <div className="option-price">
                                <div className="price-value-label">Price</div>
                                <ProductOptionPrice forceHideSpecialPrice option={productOption} />
                                {(productOption.uomId && productOption.uomSize > 0) && (
                                    <div className="option-UOM-unit-price">
                                        (<ProductOptionUOMUnitPrice option={productOption} />&nbsp;per unit)
                                    </div>
                                )}
                            </div>
                            <div className={`option-discount ${lineItemDiscount > 0 ? "discount-applied" : ""}`}>
                                <div className="price-value-label">{lineItemDiscount > 0 && "Discount"}</div>
                                {lineItemDiscount > 0 ? <PriceDisplay value={lineItemDiscount} /> : <div>&nbsp;</div>}
                            </div>
                            <div className="option-total">
                                <div className="price-value-label">Total</div>
                                {<PriceDisplay value={GetTotalProductOptionPrice(productOption)} />}
                            </div>
                        </div>
                    </div>
                )
            })}
            {(hasMultipleOptionGroups && optionHasMultipleOptions) && <div className="product-options-total-container">
                <div className="product-total-items">{FormatAmount(totalOptionsQuantity)} Item{totalOptionsQuantity === 1 ? '' : 's'}</div>
                <div className="product-total-price">{<PriceDisplay value={totalOptionsPrice} />}</div>
            </div>}
            </>
        );
    }

    _removeCartProduct = (e: SyntheticEvent, optionId: number, sizeId: number, uomId: number) => {
        e.preventDefault();
        this.props.RemoveOrderProduct(this.props.product.id, optionId, sizeId, uomId);
        this.props.CalculatePromotions();
    }
}

const MapStateToProps = (state: { orderReducer: any }) => ({
    order: state.orderReducer
});

export default connect(MapStateToProps, { RemoveOrderProduct, CalculatePromotions })(OrderDetailProductOption);