import * as types from "../Types/BrandingTypes";
import APIHost from "../../APIHost";
import axios from "axios";

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const host = new APIHost();

export interface contactRequestEmailTypes{
    name: string;
    email: string;
    companyName: string;
    phone: string;
    message: string;
    moduleId: number;
}

export interface ReCaptchaValidationRequestTypes{
    token: string;
    moduleId: number;
}

//Get Store Details
export const GetStoreInformation = (brandFound: boolean) => (dispatch: any) => {
    if (!brandFound) {
        dispatch({ type: types.Getting_Brand_Info });

        axios
            .get(host.AuthAPI("StoreInformation"), config)
            .then((res) => {
                let brandInfo = res.data;
                dispatch({
                    type: types.Display_Store_Brand_Info,
                    payload: {
                        storeName: brandInfo.storeName,
                        moduleId: brandInfo.moduleId,
                        loginPageImageUrl: brandInfo.loginPageImageUrl,
                        loginBannerImageUrl: brandInfo.loginBannerImageUrl,
                        contactFormEnabled: brandInfo.contactFormEnabled
                    },
                });
            })
            .catch(() => {
                dispatch({
                    type: types.Display_Store_Brand_Not_Found,
                    payload: {
                        loginPageImageUrl: "",
                        loginBannerImageUrl: "",
                    },
                });
            });
    }
};

export const ValidateGoogleReCaptcha = (reCaptchaValidationData: ReCaptchaValidationRequestTypes) =>(dispatch: any) => {   
    if(reCaptchaValidationData.token && reCaptchaValidationData.moduleId){

        axios.post(host.ContactUsFormAPI('ValidateReCaptchaToken'),reCaptchaValidationData,config).then((res) => {
            dispatch({
                type: types.Update_ReCaptcha_Validation_Result,
                payload: res.data
            }) 
        }).catch((error) => {
            dispatch({
                type: types.Update_ReCaptcha_Validation_Result,
                payload: {
                    success: false
                }
            }) 
        })
    }
};

export const SendContactUsRequestEmail = (contactFormData: contactRequestEmailTypes) => (dispatch: any) => {

    dispatch({
        type: types.Contact_Form_Email_Requested
    })

    if(contactFormData.moduleId){
        axios.post(host.ContactUsFormAPI('SendRequestEmail'),contactFormData, config).then(res => {
            let data = res.data;
            dispatch({
                type: types.Update_Contact_Form_Email_Status,
                payload: data
            });
            dispatch({
                type: types.Contact_Form_Email_Request_Has_Response
            });
        }).catch((error) => {
            
            dispatch({
                type: types.Update_Contact_Form_Email_Status,
                payload: {
                    success: false
                }
            });
            dispatch({
                type: types.Contact_Form_Email_Request_Has_Response
            });
        });
    }else{
        dispatch({
            type: types.Update_Contact_Form_Email_Status,
            payload: {
                success: false
            }
        });
        dispatch({
            type: types.Contact_Form_Email_Request_Has_Response
        });
    }
}

export const ResetContactUsForm = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Contact_Form_Email_Status
    });
}
