import React from 'react';

export const CartIcon: React.FC = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Cart</title>
            <path d="M5.24707 7.95654H31.1782C31.3921 7.9565 31.6032 8.00579 31.795 8.1006C31.9868 8.1954 32.1541 8.33315 32.284 8.50315C32.4139 8.67315 32.5028 8.87082 32.5439 9.08078C32.585 9.29074 32.577 9.50735 32.5208 9.71376L29.6644 20.1847C29.4236 21.0681 28.899 21.8478 28.1714 22.4037C27.4438 22.9596 26.5536 23.2608 25.638 23.2609H8.46933" stroke="#00326B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 1H1.52591C2.16434 1.00003 2.78334 1.2196 3.27909 1.62187C3.77483 2.02415 4.11718 2.58466 4.24869 3.20939L8.88312 25.2254C9.01464 25.8501 9.35698 26.4106 9.85273 26.8129C10.3485 27.2152 10.9675 27.4347 11.6059 27.4348H27.4348" stroke="#00326B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.7393 33.0001C11.8919 33.0001 12.8263 32.0657 12.8263 30.9131C12.8263 29.7605 11.8919 28.8262 10.7393 28.8262C9.5867 28.8262 8.65234 29.7605 8.65234 30.9131C8.65234 32.0657 9.5867 33.0001 10.7393 33.0001Z" stroke="#00326B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.6524 33.0001C25.805 33.0001 26.7393 32.0657 26.7393 30.9131C26.7393 29.7605 25.805 28.8262 24.6524 28.8262C23.4998 28.8262 22.5654 29.7605 22.5654 30.9131C22.5654 32.0657 23.4998 33.0001 24.6524 33.0001Z" stroke="#00326B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
