import { Button, Link } from '@cin7/ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderNotesButtonContent } from '../../../Language/Default_Settings';
import { DeleteOrderItemNotes, UpdateOrderItemNotes } from '../../../Redux/Actions/OrderActions';
import { getOrderItemOptionNote } from '../../../Redux/Selectors/OrderSelectors';
import { AddNoteModal } from './AddNoteModal';

import './Styles/_AddNoteButton.scss';

export interface AddNoteButtonProps {
    itemOptionId: number;
    productId: number;
    uomId: number | null;
}

export const AddNoteButton: React.FC<AddNoteButtonProps> = (props) => {
    const [displayModal, setDisplayModal] = useState(false);
    const dispatch = useDispatch();
    const itemOptionNote = useSelector(getOrderItemOptionNote(props.itemOptionId, props.productId, props.uomId));
    const updateNote = (note: string) => dispatch(UpdateOrderItemNotes(props.itemOptionId, props.productId, props.uomId, note))
    const deleteNote = () => dispatch(DeleteOrderItemNotes(props.itemOptionId, props.productId, props.uomId))
    const hasNote = !!itemOptionNote?.notes;
    return (
        <>
            {hasNote && <Button className={"edit-note-button"} variant={"secondary"} onClick={() => setDisplayModal(true)} >{OrderNotesButtonContent.default_editNote}</Button>}
            {!hasNote && <Link className={"add-note-button"} onClick={() => setDisplayModal(true)} >{OrderNotesButtonContent.default_addNote}</Link>}
            {displayModal && <AddNoteModal onCloseClick={() => setDisplayModal(false)} note={itemOptionNote?.notes || ""} updateNote={updateNote} deleteNote={deleteNote} />}
        </>
    );
}