import React, { Component } from "react";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import Cin7FormHeading from "../../Components/PageElements/Cin7FormHeading";
import SidePageBanner from "../../Components/PageElements/Login/SidePageBanner";
import Cin7ConfirmationBox from "../../Components/PageElements/Cin7ConfirmationBox";
import { GetTenantImageUrl } from "../../Utils/LoginHelper";
import { GetStoreInformation } from "../../Redux/Actions/BrandingActions";
import Loading from "../../Components/Global/Loading";
import { B2BPageTitles } from "../../Language/Default_Settings";
import StoreHeading from "./Views/StoreHeading";

//Pages
import SignIn from "./Views/SignIn";
import ForgotPassword from "./Views/ForgotPassword";
import ResetPassword from "./Views/ResetPassword";
import ResetPasswordConfirmation from "../Notifications/Success/ResetPasswordConfirmation";
import ForgotPasswordConfirmation from "../Notifications/Success/ForgotPasswordConfirmation";
import FailedView from "../Notifications/Errors/FailedView";
import SidePageBannerFooter from "../../Components/PageElements/Login/SidePageBannerFooter";
import { ContactUsFooter } from "../../Components/PageElements/Login/ContactUsFooter";

interface componentProps extends RouteComponentProps<any> {
    login: {
        brandFound: boolean;
        storeName: string;
        loginPageImageUrl: string;
        loginBannerImageUrl: string;
        isGettingBrandInfo: boolean;
        contactFormEnabled: boolean;
    };
    pagePath?: string;
    pageView?: string;
    GetStoreInformation: (brandFound: boolean) => void;
}

interface componentStates {
    pagePath: string;
    parentPath: string;
}

class Login extends Component<componentProps, componentStates> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            pagePath: props.location.pathname,
            parentPath: props.match.path,
        };
    }

    componentDidMount() {
        let pathName = this.props.location.pathname;
        this._renderPageTitles(pathName);

        //Get Store Logo, Banner, Module ID, and Store Name from store info API
        this.props.GetStoreInformation(this.props.login.brandFound);
    }

    static getDerivedStateFromProps(props: componentProps, state: componentStates) {
        if (props.pagePath !== state.pagePath) {
            return {
                pagePath: props.location.pathname,
            };
        }

        return null;
    }

    componentDidUpdate() {
        this._renderPageTitles(this.state.pagePath);
    }

    _renderPageTitles = (pathName: string) => {
        let parentPath = this.state.parentPath;

        let storeName = this.props.login.storeName ? this.props.login.storeName : B2BPageTitles.root_title;

        switch (pathName) {
            case `${parentPath}/reset-password`:
            case `${parentPath}/reset-password/`:
                document.title = `${storeName} - Reset Password`;
                break;
            case `${parentPath}/forgot-password`:
                document.title = `${storeName} - Forgot Password`;
                break;
            case `${parentPath}/reset-confirmation`:
                document.title = `${storeName} - Confirmation`;
                break;
            default:
                document.title = `${storeName} - Login`;
                break;
        }
    };

    _resetBranding = () => {
        this.props.GetStoreInformation(this.props.login.brandFound);
    };

    render() {
        const { pagePath, parentPath } = this.state;
        const { login } = this.props;

        if (login.isGettingBrandInfo) {
            return <Loading />;
        }

        if (!login.brandFound) {
            return <FailedView message={"Store not found."} tryAgainAction={this._resetBranding} />;
        }

        let logoImageUrl = GetTenantImageUrl(login.loginPageImageUrl);

        const Cin7B2BHeading: any = (props: componentProps) => {
            switch (props.pageView) {
                case `${parentPath}`:
                case `${parentPath}/`:
                    return null;
                case `${parentPath}/forgot-password`:
                    return (
                        <Cin7FormHeading
                            pageTitle={"Forgot your password"}
                            pageSubtitle={"Enter your email address and we’ll send you a link to reset your password."}
                            pageSubtitleClass={"light"}
                        />
                    );
                case `${parentPath}/forgot-password/confirmation`:
                    return (
                        <Cin7ConfirmationBox
                            boxIcon={"email-icon"}
                            boxMessage={"We’ve sent an email with password reset instructions."}
                        />
                    );
                case `${parentPath}/reset-password`:
                case `${parentPath}/reset-password/`:
                    return <Cin7FormHeading pageTitle={"Reset Password"} enableIcon={true} iconType={"LockIcon"} />;
                case `${parentPath}/reset-password/confirmation`:
                    return (
                        <Cin7ConfirmationBox
                            boxIcon={"tick-icon"}
                            boxType={"success-box"}
                            boxMessage={"Success!"}
                            boxSubMessage={"Your password has been updated."}
                        />
                    );
                default: {
                    return (
                        <Cin7FormHeading
                            pageTitle={"Cin7 B2B"}
                            pageSubtitle={"Unable to find matching page."}
                            pageSubtitleClass={"light"}
                        />
                    );
                }
            }
        };

        return (
            <div id="Side-Image-Theme">
                <div className="side-image-theme-container customer-page">
                    <SidePageBanner customerBanner={login.loginBannerImageUrl} />
                    <div className="side-image-theme-content">
                        <div className="side-image-theme-header">
                            <StoreHeading logoImageUrl={logoImageUrl} storeName={login.storeName} />
                            <Cin7B2BHeading pageView={pagePath} />
                        </div>
                        <div className="side-image-theme-body">
                            <Switch>
                                <Route exact path={"/login"} component={SignIn} />
                                <Route exact path="/login/forgot-password" component={ForgotPassword} />
                                <Route
                                    exact
                                    path="/login/forgot-password/confirmation"
                                    component={ForgotPasswordConfirmation}
                                />
                                <Route exact path="/login/reset-password" component={ResetPassword} />
                                <Route
                                    exact
                                    path={"/login/reset-password/confirmation"}
                                    component={ResetPasswordConfirmation}
                                />                            
                                <Redirect to={"/page-not-found"} />
                            </Switch>
                            {login.contactFormEnabled && <ContactUsFooter />}
                        </div>                        
                        <SidePageBannerFooter />
                    </div>
                </div>
            </div>
        );
    }
}

const MapStateToProps = (state: { brandingReducer: any }) => ({
    login: state.brandingReducer,
});

export default connect(MapStateToProps, { GetStoreInformation })(Login);
