import React from 'react';
import { BemHelper, Typography } from '@cin7/ui';
import { AddressPanel } from '../../../Components/PageElements/Orders/AddressPanel';
import FreightPanel from '../../../Components/PageElements/Orders/FreightPanel';
import * as PageContent from '../../../Language/Default_Settings';
import { useDispatch, useSelector } from 'react-redux';
import DeliveryInstructionsPanel from '../../../Components/PageElements/Orders/DeliveryInstructionsPanel';
import { UpdateDeliveryAddress } from './../../../Redux/Actions/OrderActions';
import { getOrderAddressOrUserAddress, getOrderFirstNameOrUserFirstName, getOrderLastNameOrUserLastName } from '../../../Redux/Selectors/OrderSelectors';

import './Styles/_Delivery.scss';

const bem = new BemHelper('delivery');

export const Delivery: React.FC = () => {

    const deliveryAddress = useSelector(getOrderAddressOrUserAddress);
    const firstName = useSelector(getOrderFirstNameOrUserFirstName);
    const lastName = useSelector(getOrderLastNameOrUserLastName);
    const dispatch = useDispatch();
    const updateDeliveryAddress = (deliveryAddress: any) => dispatch(UpdateDeliveryAddress(deliveryAddress));

    return (
        <div className={bem.block()}>
            <div className={bem.element('delivery-container')}>
                <Typography variant={"h2"} className={bem.element('delivery-title')} >
                    Delivery
                </Typography>
                <div className={bem.element('delivery-inner-content')}>
                    <AddressPanel title={PageContent.OrderCheckoutPageContent.default_deliveryAddressLabel}
                        variant={"delivery"}
                        address={deliveryAddress}
                        firstName={firstName}
                        lastName={lastName}
                        UpdateAction={updateDeliveryAddress} />
                    <FreightPanel />
                    <DeliveryInstructionsPanel editMode={true} />
                </div>
            </div>
        </div>
    );
}
