import React, { Component } from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentState {
    strokeColor: string;
}

class CloseIcon extends Component<componentProps, componentState> {
    constructor(props: Readonly<componentProps>) {
        super(props);
        this.state = {
            strokeColor: '#000'
        }
    }

    componentDidMount() {
        if (this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }
    
    render() {
        const { strokeColor } = this.state;
        
        const cls1 = {
            stroke: strokeColor
        }
        
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M16 8L8 16" style={cls1} strokeLinecap="round"/>
                <path d="M16 16L8 8" style={cls1} strokeLinecap="round"/>
            </svg>
        );
    }
}
 
export default CloseIcon;