import React, { Component } from "react";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import Cin7FormHeading from "../../Components/PageElements/Cin7FormHeading";
import SidePageBanner from "../../Components/PageElements/Login/SidePageBanner";
import Cin7ConfirmationBox from "../../Components/PageElements/Cin7ConfirmationBox";
import { GetStoreInformation } from "../../Redux/Actions/BrandingActions";
import { connect } from "react-redux";
import Loading from "../../Components/Global/Loading";
import FailedView from "../Notifications/Errors/FailedView";
import { GetTenantImageUrl } from "../../Utils/LoginHelper";
import StoreHeading from "./Views/StoreHeading";

//Pages
import CreatePasswordConfirmation from "../Notifications/Success/CreatePasswordConfirmation";
import Registration from "./Views/Registration";
import SidePageBannerFooter from "../../Components/PageElements/Login/SidePageBannerFooter";

interface componentProps extends RouteComponentProps<any> {
    login: {
        brandFound: boolean;
        storeName: string;
        loginPageImageUrl: string;
        loginBannerImageUrl: string;
        isGettingBrandInfo: boolean;
    };
    pagePath?: string;
    pageView?: string;
    GetStoreInformation: (brandFound: boolean) => void;
}

interface componentStates {
    pagePath: string;
    parentPath: string;
}

class Register extends Component<componentProps, componentStates> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            pagePath: props.location.pathname,
            parentPath: props.match.path,
        };
    }

    componentDidMount() {
        let pathName = this.props.location.pathname;
        this._renderPageTitles(pathName);

        //Get Store Logo, Banner, Module ID, and Store Name from store info API
        this.props.GetStoreInformation(this.props.login.brandFound);
    }

    static getDerivedStateFromProps(props: componentProps, state: componentStates) {
        if (props.pagePath !== state.pagePath) {
            return {
                pagePath: props.location.pathname,
            };
        }

        return null;
    }

    componentDidUpdate() {
        this._renderPageTitles(this.state.pagePath);
    }

    _renderPageTitles = (pathName: string) => {
        let parentPath = this.state.parentPath;

        switch (pathName) {
            case `${parentPath}/confirmation`:
                document.title = "Cin7 B2B - Confirmation";
                break;
            default:
                document.title = "Cin7 B2B - Registration";
                break;
        }
    };

    _resetBranding = () => {
        this.props.GetStoreInformation(this.props.login.brandFound);
    };

    render() {
        const { pagePath, parentPath } = this.state;
        const { login } = this.props;

        if (login.isGettingBrandInfo) {
            return <Loading />;
        }

        if (!login.brandFound) {
            return <FailedView message={"Store not found."} tryAgainAction={this._resetBranding} />;
        }

        let logoImageUrl = GetTenantImageUrl(login.loginPageImageUrl);

        const Cin7B2BHeading: any = (props: componentProps) => {
            switch (props.pageView) {
                case `${parentPath}/`:
                case `${parentPath}`:
                    return "";
                case `${parentPath}/confirmation`:
                    return (
                        <Cin7ConfirmationBox
                            boxIcon={"tick-icon"}
                            boxType={"success-box"}
                            boxMessage={"Your password has been set."}
                        />
                    );
                default: {
                    return (
                        <Cin7FormHeading
                            pageTitle={"Cin7 B2B"}
                            pageSubtitle={"Unable to find matching page."}
                            pageSubtitleClass={"light"}
                        />
                    );
                }
            }
        };

        return (
            <div id="Side-Image-Theme">
                <div className="side-image-theme-container">
                    <SidePageBanner customerBanner={login.loginBannerImageUrl} />
                    <div className="side-image-theme-content">
                        <div className="side-image-theme-header">
                            <StoreHeading logoImageUrl={logoImageUrl} storeName={login.storeName} />
                            <Cin7B2BHeading pageView={pagePath} />
                        </div>
                        <div className="side-image-theme-body">
                            <Switch>
                                <Route path="/registration/confirmation" component={CreatePasswordConfirmation} />
                                <Route path="/registration" component={Registration} />
                                <Redirect to={"/page-not-found"} />
                            </Switch>
                        </div>
                        <SidePageBannerFooter />
                    </div>
                </div>
            </div>
        );
    }
}

const MapStateToProps = (state: { identityReducer: any; brandingReducer: any }) => ({
    registration: state.identityReducer,
    login: state.brandingReducer,
});

export default connect(MapStateToProps, { GetStoreInformation })(Register);
