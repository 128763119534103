import React, { Component } from 'react';
import { GetUrlParameters } from '../../../Utils/B2BHelpers';
import { Redirect } from 'react-router';

//Components
import CreateAccountForm from '../../../Components/AppForms/CreateAccountForm';
import FailedView from '../../Notifications/Errors/FailedView';
import Loading from '../../../Components/Global/Loading';

//Redux
import { connect } from 'react-redux';
import { GetRegisteredUserDetails, ConfirmUserEmail, GetUserStatus } from '../../../Redux/Actions/IdentityActions';
//Assets
import { RouteComponentProps } from 'react-router';
import { B2BPageTitles } from '../../../Language/Default_Settings';

interface componentProps extends Partial<RouteComponentProps> {
    registration: {
        isCreatePasswordCompleted: boolean;
        emailAddress: string;
        storeName: string;
        errorMessage?: string;
        shouldRedirect: boolean;
        displayErrorView: boolean;
        isGettingUserDetailsAndStatus: boolean;
        displayReturnToLogin: boolean;
        userStatus: number;
    };
    GetRegisteredUserDetails: (credentials:any) => void;
    ConfirmUserEmail: (credentials:any) => void;
    GetUserStatus: (credentials:any) => void;
}

interface componentStates {
    storeName: string;
    emailAddress: string;
    errorMessage?: any;
    displayErrorView: boolean;
}

class Registration extends Component<componentProps, componentStates> {
    constructor(props: any) {
        super(props);
        this.state = { 
            storeName: props.registration.storeName,
            emailAddress: props.registration.emailAddress,
            errorMessage: props.registration.errorMessage,
            displayErrorView: props.registration.displayErrorView,
         }
    }

    componentDidMount() {
        //Set Page Title
        document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.registration_title}`;

        //Get User Credentials on Load
        this._getUserDetails();
    }

    static getDerivedStateFromProps(props: componentProps, state: componentStates) {
        if (props.registration.emailAddress !== state.emailAddress || props.registration.storeName !== state.storeName) {
            return {
                emailAddress: props.registration.emailAddress,
                storeName: props.registration.storeName
            }
        }
        
        return null;
    }

    _getUserDetails = () => {
        let userProfileDetails = GetUrlParameters(["userId", "emailToken", "storeModuleId"]);

        //Confirm Email
        this.props.ConfirmUserEmail(userProfileDetails);

        this.props.GetRegisteredUserDetails(userProfileDetails);

        //Get Status
        this.props.GetUserStatus(userProfileDetails);
    }

    render() { 
        const { location, registration } = this.props;
        const { storeName, emailAddress } = this.state;

        if (registration.isCreatePasswordCompleted) {
            return(
                <Redirect to={
                    {
                        pathname: '/registration/confirmation',
                        state: {from: location}
                    }
                } />
            )
        }

        if (registration.isGettingUserDetailsAndStatus) {
            return <Loading />;
        }

        const InvitationText: any = () => {

            if (storeName) {
                return (
                    <h2>
                        <strong>{storeName}</strong> has invited you to create a <strong>Cin7 B2B</strong> account.
                    </h2>
                );
            }
            else {
                return (
                    <h2>
                        You have been invited to create a <strong>Cin7 B2B</strong> account.
                    </h2>
                );
            }
        }

        if (!emailAddress || registration.userStatus === -1) {
            return (
                <FailedView message={"Invalid link."} hideBanner={true} tryAgainAction={this._getUserDetails} />
            );
        }
        else if (registration.userStatus === 60) {
            return (
                <FailedView message={"Your invitation has been canceled. Please ask the seller to resend an invitation."} hideBanner={true} />
            );
        }
        else if (registration.userStatus !== 20 && registration.userStatus !== 21) {
            return (
                <FailedView message={"Invalid status. Please ask the seller to resend an invitation."} hideBanner={true} />
            );
        }
        else {
            return (
                <>
                    {!registration.displayReturnToLogin &&
                        <div className="side-image-theme-header">
                            <InvitationText />
                            <h3>
                                <span className="legend">Your email login will be: </span>
                                <span className="email-text email-display"><strong>{emailAddress}</strong></span>
                            </h3>
                        </div>
                    }
                    <div className="side-image-theme-body">
                        <CreateAccountForm displayReturnToLogin={registration.displayReturnToLogin} />
                    </div>
                </>
            );
        }
    }
}

//Redux Map Props
const MapStateToProps = (state: {identityReducer: any;}) => ({
    registration: state.identityReducer
});
 
export default connect(MapStateToProps, { GetRegisteredUserDetails, ConfirmUserEmail, GetUserStatus })(Registration);