import React from "react";

import CoverClientB2B from "../../../Images/Cover_Client_B2B.jpg";
import { GetTenantImageUrl } from "../../../Utils/LoginHelper";

import "./Styles/_SidePageBanner.scss";

type Props = {
    customerBanner?: string;
};

const SidePageBanner = (props: Props) => {
    const { customerBanner } = props;
    let backgroundImg = CoverClientB2B;

    if (customerBanner && customerBanner.length !== 0) {
        backgroundImg = GetTenantImageUrl(customerBanner);
    }

    const b2bCoverImageStyle = {
        backgroundImage: `url("${backgroundImg}")`,
    };

    return (
        <div className="side-banner-container" style={b2bCoverImageStyle}>
            {!CoverClientB2B && (
                <>
                    <div className="side-banner-overlay"></div>
                    <div className="side-banner-text">{`Order With A Click`}</div>
                </>
            )}
        </div>
    );
};

export default SidePageBanner;
