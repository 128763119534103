import React, { Component } from 'react';
import {FormGroup, Input, Label, Button} from 'reactstrap';
import PasswordMeter from './PasswordMeter';

import ShowPasswordIcon from '../../Images/Icons/ShowPasswordIcon.js';
import HidePasswordIcon from '../../Images/Icons/HidePasswordIcon.js';

interface componentProps {
    fieldClass?: string;
    fieldErrorId?: string;
    fieldId: string;
    fieldIndex?: number;
    fieldLabel?: string;
    fieldName: string;
    formGroupClass?: string;
    passwordStrength?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface componentStates {
    revealPassword: boolean;
    passwordEntry: string;
    passwordScoreReset: number;
}

class PasswordField extends Component<componentProps,componentStates> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            revealPassword: false,
            passwordEntry: '',
            passwordScoreReset: 0
         }
    }

    render() { 

        const { revealPassword, passwordEntry, passwordScoreReset } = this.state;
        const {fieldClass, fieldErrorId, fieldId, fieldIndex, fieldLabel, fieldName, formGroupClass, passwordStrength} = this.props;

        return ( 
            <>
                <FormGroup className={`cin7-form-group ${formGroupClass ? formGroupClass : "default-group"}`}>
                    <Input type={revealPassword ? "text" : "password" } 
                        onFocus={this._inputOnFocus}
                        onBlur={this._inputOnBlur}
                        onChange={this._onChange}
                        id={fieldId} name={fieldName} 
                        className={`${fieldClass ? fieldClass : "default-input-password"} cin7-input password-field`}
                        placeholder={" "}
                        tabIndex={fieldIndex} 
                    />
                    <Label className="cin7-float-label" for={fieldId}>{fieldLabel ? fieldLabel : "Default Label"}</Label>
                    <Button className="cin7-custom-input-icon eye-icon" tabIndex={-1} onClick={this._revealPassword}>
                        {revealPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                    </Button>
                </FormGroup>
                <span id={fieldErrorId} className="validation-message hide"></span>
                {passwordStrength && <PasswordMeter passwordEntry={passwordEntry} passwordIncrement={passwordScoreReset} />}
            </>
         );
    }

    _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(e);
        
        this.setState({
            passwordEntry: e.target.value,
            passwordScoreReset: 1
        });

        if(e.target.value === "") {
            this.setState({
                passwordScoreReset: 0
            });
        }
    }

    _inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        let targetFormGroup: any | undefined = e.currentTarget.parentNode;
        targetFormGroup.classList.add("active");
    }

    _inputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        let targetFormGroup: any | undefined = e.currentTarget.parentNode;
        if(!e.currentTarget.value) {
            targetFormGroup.classList.remove("active");
        }
    }

    _revealPassword = (e: React.MouseEvent<any, MouseEvent>) => {
        e.preventDefault();
        
        this.setState(prevState => ({
            revealPassword: !prevState.revealPassword
        }));
    }
}
 
export default PasswordField;