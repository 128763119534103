import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductDetailsDescription } from '../../../Redux/Selectors/ProductDetailsSelectors';
import HtmlBlock from '../../Global/HtmlBlock';
import './Styles/_ProductTeaser.scss';

export const ProductTeaser = () => {
	const [showMore, setShowMore] = useState(false);
    const [htmlBlock, setHtmlBlock] = useState("html-block--less");
	const description = useSelector(getProductDetailsDescription);

    const toggleDescription = () => {
        setHtmlBlock(showMore? "html-block--less" : "html-block");
        setShowMore(!showMore);
    }


    if (description) {
        return (
            <div className="product-details-teaser">
                <h6>Description</h6>
                <HtmlBlock content={description} htmlBlock={htmlBlock}/>
                <div className="showMore" onClick={toggleDescription}>
                    {showMore ? "Show less" : "Show more"}
                </div>
            </div>
        );
    } else {
        return (
            <div className="product-details-teaser">No description available.</div>
        );
    }
}