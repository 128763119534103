import { CategoryType } from "../../../Components/PageElements/Products/ProductCategoryFilter";
import * as types from "../../Types/CategoryTypes";

export type CategoryStateType = {
    category: CategoryType;
    goBack: boolean;
};

export const initialState: CategoryStateType = {
    category: { id: -1, name: "", subCategories: [] },
    goBack: false,
};

export const CategoryReducer = (state = initialState, action: { type: string; payload: any }): CategoryStateType => {
    switch (action.type) {
        case types.Change_Current_Category:
            return {
                ...state,
                category: action.payload.category,
            };
        case types.Reset_Current_Category:
            return {
                ...state,
                category: initialState.category,
            };
        case types.Go_Back:
            return {
                ...state,
                goBack: action.payload.goBack,
            };
        default:
            return state;
    }
};
