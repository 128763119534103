import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';

interface componentProps {
    passwordEntry: string;
    passwordIncrement: number;
}

interface componentStates extends componentProps {}

class PasswordMeter extends Component<componentProps, componentStates> {

    _renderPasswordLabel = (testedResult: { score: any; }) => {
        switch(testedResult.score) {
            case 0:
            case 1:
                return "Weak";
            case 2:
                return "Fair";
            case 3:
            case 4:
                return "Great!";
            default:
                return "Weak";
        }
    }

    render() { 
        const { passwordEntry, passwordIncrement } = this.props;
        const testedPassword: {score: any;} = zxcvbn(passwordEntry);
        
        //Overwrite Default State to display Indication of Weak password
        if(testedPassword.score === 0){
            testedPassword.score = passwordIncrement;
        }

        return ( 
            <div className={`cin7-password-meter`}>
                <progress id="Pass-Meter" className={`cin7-password-meter-progress strength-${this._renderPasswordLabel(testedPassword).replace("!","")}`} value={testedPassword.score} max="3" />
                <div className={`cin7-password-meter-label strength-${this._renderPasswordLabel(testedPassword).replace("!","")}`}>
                    {passwordEntry && (
                        <strong>{this._renderPasswordLabel(testedPassword)}</strong>
                    )}
                </div>
            </div>
         );
    }
}
 
export default PasswordMeter;