import * as types from "../../Types/ProcessCheckoutTypes";

type statuses = "step-completed" | "current-step" | "pending-step";

export type ProcessCheckoutStateType = {
    reviewPageStatus: statuses;
    deliveryPageStatus: statuses;
    billingPageStatus: statuses;
    confirmPageStatus: statuses;
};

export const initialState: ProcessCheckoutStateType = {
    reviewPageStatus: "pending-step",
    deliveryPageStatus: "pending-step",
    billingPageStatus: "pending-step",
    confirmPageStatus: "pending-step",
};

export const ProcessCheckoutReducer = (
    state = initialState,
    action: { type: string; payload: any | undefined }
): ProcessCheckoutStateType => {
    switch (action.type) {
        case types.Review_Order_Reset:
        case types.Review_Order_Active:
        case types.Review_Order_Completed:
            return {
                ...state,
                reviewPageStatus: action.payload.pageStatus,
            };
        case types.Delivery_Order_Reset:
        case types.Delivery_Order_Active:
        case types.Delivery_Order_Completed:
            return {
                ...state,
                deliveryPageStatus: action.payload.pageStatus,
            };
        case types.Billing_Order_Reset:
        case types.Billing_Order_Active:
        case types.Billing_Order_Completed:
            return {
                ...state,
                billingPageStatus: action.payload.pageStatus,
            };
        case types.Confirm_Order_Reset:
        case types.Confirm_Order_Active:
            return {
                ...state,
                confirmPageStatus: action.payload.pageStatus,
            };
        default:
            return state;
    }
};
