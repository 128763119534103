import React, { Component, SyntheticEvent } from "react";
import { ContainsUoms, GetProductImageUrl } from "../../../Utils/ProductsHelper";
import ProductOptionQuantity from "../../../Components/PageElements/Products/ProductOptionQuantity";
import ProductOptionPriceRange from "./ProductOptionPriceRange";
import ProductOptionPrice from "../../../Components/PageElements/Products/ProductOptionPrice";
import StockIndicator from "./StockIndicator";
import UOMStockIndicator from "./UOMStockIndicator";
import ProductOptionSectionInfo from "./ProductOptionSectionInfo";
import ExpandArrowIcon from "../../../Images/Icons/Products/ExpandArrowIcon";
import Image from "../../Global/Image";
import { ProductStandardPromotion } from "./ProductStandardPromotion";
import { ProductContractPricePromotion } from "./ProductContractPricePromotion";
import ContractPricePromotionDisplay from "../../../Interfaces/PromoMatrix/ContractPricePromotionDisplay";
import StandardPromotionDisplay from "../../../Interfaces/PromoMatrix/StandardPromotionDisplay";
import { ProductsPromotionsTooltip } from "./ProductPromotionsTooltip";
import PromotionsHelper from "../../../Utils/PromotionsHelper";
import { IProductPromotionsSectionProps } from "./ProductPromotionsSection";
import ProductGalleryModal from "./ProductGalleryModal";

import "./Styles/_ProductOptionSection.scss";
import { ProductOptionUOMUnitPrice } from "./ProductOptionUOMUnitPrice";
import RetailPriceDisplay from "../../Global/RetailPriceDisplay";
import { ProductDetailsOption } from "../../../Redux/Reducers/App_Reducers/ProductDetailsReducer";

interface componentProps {
    option: ProductDetailsOption[];
    sizes: boolean;
    product: any;
    tabIndex?: number;
}

interface componentState {
    active: boolean;
    imageModalOpen: boolean;
}

class ProductOptionSection extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            active: false,
            imageModalOpen: false,
        };
    }

    checkForRetailPrice(productOption: any){
        if (productOption.retailPrice > productOption.price)
        {
            return (
                <div className='product-option-code'>
                    <RetailPriceDisplay value={productOption.retailPrice} />
                </div>
            );
        }
    }

    render() {
        const activeClass = this.state.active ? "active" : "inactive";
        const { option, sizes, product, tabIndex } = this.props;
        let standardPromotions: StandardPromotionDisplay[] = option?.standardPromotions;
        let contractPricePromotions: ContractPricePromotionDisplay[] = option?.contractPricePromotions;

        const productOption = option;

        const ProductOptionAction: any = () => {
            if (sizes) {
                return (
                    <span className="product-option-arrow-right">
                        <ExpandArrowIcon strokeColor="#808081" />
                    </span>
                );
            } else {
                return <ProductOptionQuantity product={product} option={productOption} tabIndex={tabIndex} />;
            }
        };

        const ProductOptionInner: any = () => {
            const productOptions: any = option;

            if (sizes) {
                return (
                    <div key={productOption.optionId} className="option-details-inner">
                        <div className="option-details-inner-child">
                            {productOptions.map((productOption3: any) => {
                                const standardPromotions: StandardPromotionDisplay[] =
                                    productOption3?.standardPromotions;
                                const contractPricePromotions: ContractPricePromotionDisplay[] =
                                    productOption3?.contractPricePromotions;
                                return (
                                    <div
                                        key={`${productOption3.sizeId},${productOption3.uomId}`}
                                        className="option-details-inner-child-container"
                                    >
                                        <div className="option-details-inner-child-details">
                                            <div className="inner-child-option-title">
                                                {productOption3.size ?? productOption3.option1}
                                                {!!productOption3.uomSize && ` (${productOption3.uomSize})`}{" "}
                                                {productOption3.option2} {productOption3.option3}
                                            </div>
                                            {(productOption3.uomId || !productOption3.sizeId) && (
                                                <ProductOptionPrice option={productOption3} />
                                            )}
                                            {(productOption3.uomId && productOption3.uomSize > 0) && (
                                                <div className="product-details-option-UOM-unit-price">
                                                    (<ProductOptionUOMUnitPrice option={productOption3} />&nbsp;per unit)
                                                </div>
                                            )}
                                            {(productOption3.uomId || !productOption3.sizeId) && (
                                                <div className="option-sku">{productOption3.code}</div>
                                            )}
                                            {productOption3.sizeId ? (
                                                <StockIndicator product={product} productOption={productOption3} />
                                            ) : (
                                                <UOMStockIndicator product={product} productOption={productOption3} />
                                            )}
                                            {PromotionsHelper.ShouldShowContractPriceDescriptions(
                                                contractPricePromotions
                                            ) ? (
                                                <ProductsPromotionsTooltip
                                                    content={
                                                        <ProductContractPricePromotion
                                                            descriptions={contractPricePromotions}
                                                            showHeaderTitle
                                                        />
                                                    }
                                                >
                                                    <span className="hover-text">See Volume Pricing</span>
                                                </ProductsPromotionsTooltip>
                                            ) : PromotionsHelper.ShouldShowPromotionDescriptions(standardPromotions) ? (
                                                <ProductsPromotionsTooltip
                                                    content={
                                                        <ProductStandardPromotion
                                                            descriptions={standardPromotions}
                                                            showHeaderTitle
                                                        />
                                                    }
                                                >
                                                    <span className="hover-text">See Promotions</span>
                                                </ProductsPromotionsTooltip>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <ProductOptionQuantity
                                            product={product}
                                            option={productOption3}
                                            tabIndex={tabIndex}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        };

        let productImageUrl = "";
        let optionImageItem;

        if (product.images && product.images[0]) {
            productImageUrl = product.images[0].thumbnail;
            optionImageItem = product.images[0];
        }

        let optionImageUrl = GetProductImageUrl(productImageUrl);

        let parentItem = null;

        if (sizes) {
            parentItem = option[0];

            if (parentItem.image) {
                optionImageItem = parentItem.image;
                optionImageUrl = GetProductImageUrl(parentItem.image.thumbnail);
            }

            standardPromotions = (parentItem.commonPromotions as IProductPromotionsSectionProps)
                ?.standardPromotionDescriptions;
            contractPricePromotions = (parentItem.commonPromotions as IProductPromotionsSectionProps)
                ?.contractPricePromoDescriptions;
        } else {
            if (productOption.image) {
                optionImageItem = productOption.image;
                optionImageUrl = GetProductImageUrl(productOption.image.thumbnail);
            }
        }

        const galleryModalProps = { 
            closeModal: () => this._toggleImageModal(),
            prevImageClick: () => null,
            prevImage: () => null,
            nextImageClick: () => null,
            nextImage: () => null,
            galleryImages: [optionImageItem],
            currentImage: 0,
        }

        const hasUoms = ContainsUoms(Array.isArray(product?.options) ? Array.from(product.options) : []);

        const productOptionId: number = parentItem != null ? parentItem.optionId : productOption.optionId;
        return (
            <div className="product-option-details">
                <div className={`product-option-details-container ${activeClass}`}>
                    <div className={`product-option-details-tile`}>
                        <div
                            className={`product-option-image-container${optionImageItem ? " clickable" : ""}`}
                            onClick={(e) => this._toggleImageModalClick(e)}
                        >
                            <span className="product-option-image">
                                <Image
                                    imagePath={optionImageUrl}
                                    altText={parentItem != null ? parentItem.code : productOption.code}
                                />
                            </span>
                        </div>
                        <div
                            className={`product-option-detail-container${sizes ? " sizes" : ""}`}
                            onClick={() => sizes && this._toggleSizes()}
                        >
                            <div className="product-option-detail">
                                <div className="product-option-title">
                                    {parentItem != null
                                        ? parentItem.uomId == null
                                            ? parentItem.option1
                                            : null
                                        : productOption.option1}{" "}
                                    {parentItem != null
                                        ? parentItem.uomId == null
                                            ? parentItem.option2
                                            : null
                                        : productOption.option2}{" "}
                                    {parentItem != null
                                        ? parentItem.uomId == null
                                            ? parentItem.option3
                                            : null
                                        : productOption.option3}
                                    {productOption.uomSize && ` (${productOption.uomSize})`}
                                </div>
                                <div className="option-info-container">
                                    <div className="option-info-details">
                                        <div className="product-option-price">
                                            {parentItem != null ? (
                                                hasUoms && !product?.maxRangeContractPrice ? (
                                                    <ProductOptionPriceRange
                                                        product={product}
                                                        optionId={productOptionId}
                                                    />
                                                ) : (
                                                    <ProductOptionPrice option={parentItem} />
                                                )
                                            ) : (
                                                <>
                                                <ProductOptionPrice option={productOption} />
                                                {(productOption.uomId && productOption.uomSize > 0) && (
                                                    <div className="product-details-option-UOM-unit-price">
                                                        (<ProductOptionUOMUnitPrice option={productOption} /> &nbsp;per unit)
                                                    </div>
                                                )}
                                                </>
                                            )}
                                        </div>
                                        {sizes && (
                                            <>
                                                {this.checkForRetailPrice(productOption[0])}
                                                <div className="product-option-code">{productOption[0].optionCode}</div>
                                            </>
                                        )}
                                        {parentItem == null && (
                                            <>
                                                {this.checkForRetailPrice(productOption)}
                                                <div className="product-option-code">{productOption.code} </div>
                                            </>
                                        )}
                                        <div className="product-option-stock">
                                            <StockIndicator
                                                product={product}
                                                productOption={productOption}
                                                sizes={sizes}
                                            />
                                        </div>
                                        {PromotionsHelper.ShouldShowContractPriceDescriptions(
                                            contractPricePromotions
                                        ) ? (
                                            <ProductsPromotionsTooltip
                                                content={
                                                    <ProductContractPricePromotion
                                                        descriptions={contractPricePromotions}
                                                        showHeaderTitle
                                                    />
                                                }
                                            >
                                                <span className="hover-text">See Volume Pricing</span>
                                            </ProductsPromotionsTooltip>
                                        ) : PromotionsHelper.ShouldShowPromotionDescriptions(standardPromotions) ? (
                                            <ProductsPromotionsTooltip
                                                content={
                                                    <ProductStandardPromotion
                                                        descriptions={standardPromotions}
                                                        showHeaderTitle
                                                    />
                                                }
                                            >
                                                <span className="hover-text">See Promotions</span>
                                            </ProductsPromotionsTooltip>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    {parentItem != null && (
                                        <ProductOptionSectionInfo product={product} productOptionId={productOptionId} />
                                    )}
                                </div>
                            </div>
                            <div className="product-option-action-container">
                                <ProductOptionAction />
                            </div>
                        </div>
                    </div>
                    <div className="product-option-details-content">
                        <ProductOptionInner />
                    </div>
                </div>
                {this.state.imageModalOpen && optionImageItem && (
                    <ProductGalleryModal _props={galleryModalProps} />
                )}
            </div>
        );
    }

    _toggleSizes = () => {
        this.setState((prevState) => ({
            active: !prevState.active,
        }));
    };

    _toggleImageModalClick = (e: SyntheticEvent) => {
        e.preventDefault();
        this._toggleImageModal();
    };

    _toggleImageModal = () => {
        this.setState((prevState) => ({
            imageModalOpen: !prevState.imageModalOpen,
        }));
    };
}

export default ProductOptionSection;
