export const Fetching_Products_List = "Fetching_Products_List";
export const Fetch_Products_List = "Fetch_Products_List";
export const Fetch_Products_Error = "Fetch_Products_Error";
export const Reset_Products_List = "Reset_Products_List";

export const Product_List_View_List = "Product_List_View_List";
export const Product_List_View_Gallery = "Product_List_View_Gallery";

export const Fetch_Brands_List = "Fetch_Brands_List";
export const Reset_Brands_List = "Reset_Brands_List";

export const Fetch_Categories_List = "Fetch_Categories_List";
export const Reset_Categories_List = "Reset_Categories_List";
export const Fetch_Categories_Error = "Fetch_Categories_Error";
export const Create_Category_Route_Objects = "Create_Category_Route_Objects";
export const Reset_Category_Route_Objects = "Reset_Category_Route_Objects";

export const Filter_Menu_Toggle_Status = "Filter_Menu_Toggle_Status";
export const Reset_Products_Filter = "Reset_Products_Filter";
export const Update_Products_Filter = "Update_Products_Filter";
