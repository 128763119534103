import React, { Component } from 'react';

class Layout extends Component {
  render() {
    return(
      <div className="main-wrapper" role="main">
        {this.props.children}
      </div>
    );
  }
}

export default Layout;