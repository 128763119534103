import React from 'react';

export const OnAccountPaymentIcon: React.FC = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>On Account</title>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0078 26C12.0078 24.8954 12.9032 24 14.0078 24H34.0078C35.1124 24 36.0078 24.8954 36.0078 26V40C36.0078 41.1046 35.1124 42 34.0078 42H14.0078C12.9032 42 12.0078 41.1046 12.0078 40V26ZM34.0078 26H14.0078V40H34.0078V26Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0078 29C12.0078 28.4477 12.4555 28 13.0078 28H35.0078C35.5601 28 36.0078 28.4477 36.0078 29C36.0078 29.5523 35.5601 30 35.0078 30H13.0078C12.4555 30 12.0078 29.5523 12.0078 29Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.0078 24C19.5601 24 20.0078 24.4477 20.0078 25V41C20.0078 41.5523 19.5601 42 19.0078 42C18.4555 42 18.0078 41.5523 18.0078 41V25C18.0078 24.4477 18.4555 24 19.0078 24Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.0078 24C25.5601 24 26.0078 24.4477 26.0078 25V41C26.0078 41.5523 25.5601 42 25.0078 42C24.4555 42 24.0078 41.5523 24.0078 41V25C24.0078 24.4477 24.4555 24 25.0078 24Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9 2C8.73478 2 8.48043 2.10536 8.29289 2.29289C8.10536 2.48043 8 2.73478 8 3V45C8 45.2652 8.10536 45.5196 8.29289 45.7071C8.48043 45.8946 8.73478 46 9 46H39C39.2652 46 39.5196 45.8946 39.7071 45.7071C39.8946 45.5196 40 45.2652 40 45V15.4142L26.5858 2H9ZM6.87868 0.87868C7.44129 0.316071 8.20435 0 9 0H27C27.2652 0 27.5196 0.105357 27.7071 0.292893L41.7071 14.2929C41.8946 14.4804 42 14.7348 42 15V45C42 45.7956 41.6839 46.5587 41.1213 47.1213C40.5587 47.6839 39.7956 48 39 48H9C8.20435 48 7.44129 47.6839 6.87868 47.1213C6.31607 46.5587 6 45.7956 6 45V3C6 2.20435 6.31607 1.44129 6.87868 0.87868Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27 0C27.5523 0 28 0.447715 28 1V13C28 13.2652 28.1054 13.5196 28.2929 13.7071C28.4804 13.8946 28.7348 14 29 14H41C41.5523 14 42 14.4477 42 15C42 15.5523 41.5523 16 41 16H29C28.2043 16 27.4413 15.6839 26.8787 15.1213C26.3161 14.5587 26 13.7957 26 13V1C26 0.447715 26.4477 0 27 0Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.0078 16C17.5601 16 18.0078 16.4477 18.0078 17V19C18.0078 19.5523 17.5601 20 17.0078 20C16.4555 20 16.0078 19.5523 16.0078 19V17C16.0078 16.4477 16.4555 16 17.0078 16Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.0078 6C17.5601 6 18.0078 6.44772 18.0078 7V9C18.0078 9.55228 17.5601 10 17.0078 10C16.4555 10 16.0078 9.55228 16.0078 9V7C16.0078 6.44772 16.4555 6 17.0078 6Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15 10C14.7348 10 14.4804 10.1054 14.2929 10.2929C14.1054 10.4804 14 10.7348 14 11C14 11.2652 14.1054 11.5196 14.2929 11.7071C14.4804 11.8946 14.7348 12 15 12H19C19.7957 12 20.5587 12.3161 21.1213 12.8787C21.6839 13.4413 22 14.2043 22 15C22 15.7957 21.6839 16.5587 21.1213 17.1213C20.5587 17.6839 19.7957 18 19 18H13C12.4477 18 12 17.5523 12 17C12 16.4477 12.4477 16 13 16H19C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15C20 14.7348 19.8946 14.4804 19.7071 14.2929C19.5196 14.1054 19.2652 14 19 14H15C14.2043 14 13.4413 13.6839 12.8787 13.1213C12.3161 12.5587 12 11.7957 12 11C12 10.2043 12.3161 9.44129 12.8787 8.87868C13.4413 8.31607 14.2043 8 15 8H21C21.5523 8 22 8.44772 22 9C22 9.55228 21.5523 10 21 10H15Z" fill="#00326B" />
        </svg>
    );
}
