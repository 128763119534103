import React, { Component } from 'react';
class ErrorCrossIcon extends Component {

    render() { 
        const cls1 = {
            fill: 'none',
            stroke: '#dd3b3b',
            strokeWidth: '2.5px',
            strokeMiterlimit: 10
        }
        const cls2 = {
            fill: 'none',
            stroke: '#dd3b3b',
            strokeWidth: '2.5px',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
        }
        return ( 
            <svg xmlns="https://www.w3.org/2000/svg" width="92.5" height="92.5" viewBox="0 0 92.5 92.5">
                <title id="ErrorCrossIcon">Error Red Cross</title>
                <g id="Group_737" data-name="Group 737" transform="translate(-502.311 -407.038)">
                    <path id="Path_5156" d="M593.561 453.291a45 45 0 1 1-45.007-45 45 45 0 0 1 45.007 45z" style={cls1} data-name="Path 5156"/>
                    <g id="Group_736" data-name="Group 736" transform="translate(530.979 435.705)">
                        <path id="Line_247" d="M35.166 0L0 35.166" style={cls2} data-name="Line 247"/>
                        <path id="Line_248" d="M35.166 35.166L0 0" style={cls2} data-name="Line 248"/>
                    </g>
                </g>
            </svg>
         );
    }
}
 
export default ErrorCrossIcon;