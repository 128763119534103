export const GetUrlParameters = (parameterSearch:string[]) => {

    const urlParameters:any = {};
    
    const url = GetCurrentUrl();

    parameterSearch.forEach(parameter => {
        const urlValues:string | null = url.searchParams.get(parameter);

        if (urlValues){
            if(parameter === "userToken" || parameter === "emailToken"){
                urlParameters[parameter] = urlValues.replace(/ /g,"+");
            }
            else {
                urlParameters[parameter] = urlValues;
            }
        }
    });

    return urlParameters;
};

export const GetCurrentUrl = () => {
    const urlString:string = window.location.href;
    const url:URL = new URL(urlString);

    return url;
}

export const GetPasswordValidation = (password: string) => {

    let lowerCaseLetters = /[a-z]/g;
    let lowerCasePass = false;
    
    if(password.match(lowerCaseLetters)) { 
        lowerCasePass = true;
    } else {
        lowerCasePass = false;
    }

    let upperCaseLetters = /[A-Z]/g;
    let upperCasePass = false;

    if(password.match(upperCaseLetters)) { 
        upperCasePass = true;
    } else {
        upperCasePass = false;
    }

    let hasNumbers = /[0-9]/g;
    let hasNumbersPass = false;

    if(password.match(hasNumbers)) { 
        hasNumbersPass = true;
    } else {
        hasNumbersPass = false;
    }

    let passwordLength = 8;
    let passwordLengthPass = false;

    if(password.length >= passwordLength) {
        passwordLengthPass = true;
    }

    else {
        passwordLengthPass = false;
    }

    if(lowerCasePass && upperCasePass && hasNumbersPass && passwordLengthPass) {
        return true;
    }

    return false;

}

export const ValidateEmailAddress = (emailAddress: string) => {
    let emailRegex = /^(([^<>().,;:\s@"]+(.[^<>().,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
    if(emailAddress.match(emailRegex)){
        return true;
    }
    return false;
}


export const ValidateStrongPassword = (password: string, passwordScore: number) => {

    if(password) {
        if(passwordScore >= 2){
            return true;
        }

        return false;
    }
    
    return false;
}

export const GenerateTextInputValidationParameters = (inputFieldId: string, validationFieldId: string) => {
    let textInputValidationParameters:any = {};

    let textInputField = document.getElementById(inputFieldId);
    let textInputValidationField = document.getElementById(validationFieldId);

    if(textInputField) {
        textInputValidationParameters["inputField"] = textInputField;
    } else {
        textInputValidationParameters["inputField"] = null;
    }

    if(textInputValidationField) {
        textInputValidationParameters["validationField"] = textInputValidationField;
    } else {
        textInputValidationParameters["validationField"] = null;
    }

    return textInputValidationParameters;
}

export const GeneratePasswordValidationParameters = (inputFieldId: string, validationFieldId: string, passwordMeterContainerClass: string) => {
    let passwordValidationParameters:any = {};
    
    let passwordInputField = document.getElementById(inputFieldId);
    let passwordValidationField = document.getElementById(validationFieldId);
    let passwordMeterContainer = document.getElementsByClassName(passwordMeterContainerClass)[0];

    if(passwordInputField)  {
        passwordValidationParameters["inputField"] = passwordInputField;
    } else {
        passwordValidationParameters["inputField"] = null;
    }

    if(passwordValidationField) {
        passwordValidationParameters["validationField"] = passwordValidationField;
    } else {
        passwordValidationParameters["validationField"] = null;
    }

    if(passwordMeterContainer) {
        passwordValidationParameters["passwordMeterContainer"] = passwordMeterContainer;
    } else {
        passwordValidationParameters["passwordMeterContainer"] = null;
    }

    return passwordValidationParameters;
}

export const DisplayFailValidation = (inputTarget:HTMLElement | null, validationField: HTMLElement | null, passwordMeterContainer:Element | null, errorMessage:string) => {

    if(inputTarget) {
        if(inputTarget.parentNode) {
            let formGroup = inputTarget.parentNode as HTMLElement;
            formGroup.classList.add("validate-field-group","failed", "active");
        }

        if(validationField) {
            validationField.classList.remove("hide");
            validationField.classList.add("show");
            validationField.innerText = errorMessage;
        }
    }

    if(passwordMeterContainer) {
        passwordMeterContainer.classList.remove("show");
        passwordMeterContainer.classList.add("hide");
    }
}

export const ClearFailValidation = (inputTarget:HTMLElement | null, validationField: HTMLElement | null, passwordMeterContainer:Element | null) => {
    if(inputTarget) {
        if(inputTarget.parentNode) {
            let formGroup = inputTarget.parentNode as HTMLElement;
            formGroup.classList.remove("validate-field-group","failed"); 
        }
        if(validationField) {
            validationField.classList.remove("show");
            validationField.classList.add("hide");
            validationField.innerText = "";
        }
    }
    if(passwordMeterContainer) {
        passwordMeterContainer.classList.remove("hide");
        passwordMeterContainer.classList.add("show");
    }
}

export const StripHTMLFromInputs = (htmlValue: string) => {
    var validation = /(<([^>]+)>)/ig;

    return htmlValue.replace(validation, "");
}
