import { PaymentOptionsConfig } from '../../Types/PaymentOptionsConfig';

const defaultPaymentPortalEnabled : PaymentOptionsConfig = {
    active: false,
    sort: -1
}

const defaultOnAccountEnabled : PaymentOptionsConfig = {
    active: false,
    sort: 0
}

export const getUserIsOnHold = (state: any) => state.identityReducer?.userInfo?.accountOnHold;

export const getUserRemainingCreditLimit = (state: any) => state.identityReducer?.userInfo?.remainingCreditLimit;

export const getUserInfo = (state: any) => state.identityReducer?.userInfo;

export const getBranchInfo = (state: any) => state.identityReducer?.branchInfo;

export const getIsPaymentsPortalEnabled = (state: any) : PaymentOptionsConfig => state.identityReducer?.moduleSettings?.paymentOptions?.paymentPortalEnabled ?? defaultPaymentPortalEnabled;

export const getUserDepositPercentage = (state: any) =>  state.identityReducer?.userInfo?.depositPercentage;

export const getIsOnAccountOptionEnabled = (state: any) : PaymentOptionsConfig => {
    if (getIsPaymentsPortalEnabled(state)?.active) {
        return state.identityReducer?.moduleSettings?.paymentOptions?.onAccountEnabled ?? defaultOnAccountEnabled;
    }
    else {
        defaultOnAccountEnabled.active = true;
        return defaultOnAccountEnabled;
    }
};

export const getUserCurrency = (state: any) => state.identityReducer?.userInfo?.priceTier?.currencyCode;

export const getUserPriceTier = (state: any) => state.identityReducer?.userInfo?.priceTier;

export const getUserInfoFetched = (state: any) => state.identityReducer?.userDetailsFetched;

export const getRetailPriceTierCurrency = (state: any) : string => state.identityReducer?.moduleSettings?.productRetailPriceTier?.currencyCode;
