import React, { Component } from 'react';
import ResetPasswordForm from '../../../Components/AppForms/ResetPasswordForm';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { ResetPasswordClearState } from '../../../Redux/Actions/IdentityActions';

interface componentProps extends RouteComponentProps<any> {
    resetPassword: {
        isResetPasswordCompleted: boolean;
        displayReturnToLogin: boolean;
    }
    ResetPasswordClearState: () => void;
}

class ResetPassword extends Component<componentProps, {}> {

    componentWillMount() {
        this.props.ResetPasswordClearState();
    }

    render(){

        const {resetPassword, location} = this.props;

        if (resetPassword.isResetPasswordCompleted) {
            this.props.ResetPasswordClearState();
            return(
                <Redirect to={
                    {
                        pathname: location.pathname + 'confirmation',
                        state: {from: location}
                    }
                } />
            )
        }

        return(
            <ResetPasswordForm displayReturnToLogin={resetPassword.displayReturnToLogin} />
        );
    }
}

const ResetPasswordWithRouter = withRouter(ResetPassword);

const MapStateToProps = (state: {identityReducer: any}) => ({
    resetPassword: state.identityReducer
});

export default connect(MapStateToProps, {ResetPasswordClearState})(ResetPasswordWithRouter);