import React, { Component } from 'react';
import { Form, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

//Assets
import { GeneratePasswordValidationParameters, ClearFailValidation } from '../../Utils/B2BHelpers';
import { RegistrationPageContent } from '../../Language/Default_Settings';
import PasswordField from './../FormElements/PasswordField';
import ButtonField from './../FormElements/ButtonField';
import Cin7ConfirmationBox from '../PageElements/Cin7ConfirmationBox';

//Redux
import { connect } from 'react-redux';
import { CreatePassword } from './../../Redux/Actions/IdentityActions';
import {ClearIdentityErrorMessages} from './../../Redux/Actions/AuthActions';

interface componentProps {
    registration: {
        emailAddress: string;
        isUpdatingPassword: boolean;
        isFieldValidationActive: boolean;
        isRegistrationValidationActive: boolean;
        isWarningValidationActive: boolean;
        createAccountButtonLabel: string;
        registrationErrorMessage: string;
        isCreatePasswordCompleted: boolean;
        storeDomain?: string;
    };
    displayReturnToLogin?: boolean;
    CreatePassword: (emailAddress: string, newPassword: string, passwordScore: number) => void;
    ClearIdentityErrorMessages: () => void;
}

interface componentStates {
    newPasswordField: string;
}

interface componentStates extends Partial<componentProps> {
    passwordUpdated: boolean;
}


class CreateAccountForm extends Component<componentProps, componentStates> {

    constructor(props: componentProps) {
        super(props);
        this.state = {
            newPasswordField: "",
            passwordUpdated: props.registration.isCreatePasswordCompleted
        }
    }

   
    static getDerivedStateFromProps(props: componentProps, state: componentStates) {
        if(props.registration.isCreatePasswordCompleted !== state.passwordUpdated) {
            return {
                passwordUpdated: props.registration.isCreatePasswordCompleted
            }
        }
        return null;
    }
    componentDidMount(){
        this.props.ClearIdentityErrorMessages();
    }

    render() { 
        const { registration, displayReturnToLogin } = this.props;

        const ReturnToLoginLink: any = () => {
            return (
                <Link to={'/login'} className="login-pages-link">Continue to Login</Link>
            );
        }

        return (
            <>
            {registration.isRegistrationValidationActive && 
                <Cin7ConfirmationBox 
                    boxType={"error-box"}
                    boxIcon={"error-icon"}
                    boxMessage={registration.registrationErrorMessage}
                />
            }
            {registration.isWarningValidationActive && 
                <Cin7ConfirmationBox 
                    boxType={"warning-box"}
                    boxIcon={"warning-icon"}
                    boxMessage={registration.registrationErrorMessage}
                />
            }
            {displayReturnToLogin ? <ReturnToLoginLink /> :
                <Form className="cin7-form" onSubmit={this._onSubmit}>
                    <div className="section-legend">
                        {RegistrationPageContent.default_sectionLegend}
                    </div>
                    <PasswordField
                        fieldLabel="Password"
                        fieldId="NewPassword"
                        fieldName="newPasswordField"
                        passwordStrength={true}
                        formGroupClass={"register-meter-margin"}
                        fieldErrorId="Reset-Password-Validation"
                        onChange={this._onChange}
                    />
                    <ButtonField buttonLabel={registration.createAccountButtonLabel} buttonType="submit" disableButton={registration.isUpdatingPassword} />
                    <div className="cin7-form-tc">
                        {RegistrationPageContent.default_tc_0} <NavLink className="tc-link" href="https://www.cin7.com/legal/terms-of-use/" rel="noopener noreferrer" target="_blank">{RegistrationPageContent.default_tc_1}</NavLink> {RegistrationPageContent.default_tc_2} <NavLink className="tc-link" href="https://www.cin7.com/legal/privacy-policy/" rel="noopener noreferrer" target="_blank">{RegistrationPageContent.default_tc_3}</NavLink>.
                    </div>
                </Form>
            }
            </>
         );
    }

    //Functions

    _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let validationParameters = GeneratePasswordValidationParameters("NewPassword","Reset-Password-Validation", "cin7-password-meter");
        
        if(this.props.registration.isFieldValidationActive) {
            ClearFailValidation(validationParameters.inputField, 
                validationParameters.validationField, 
                validationParameters.passwordMeterContainer);
        }

        this.setState({
            [e.target.name]: e.target.value
        } as unknown as Pick<componentStates, keyof componentStates> );
    }

    _onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        let passwordMeterField = document.getElementById("Pass-Meter") as HTMLProgressElement;
        let passwordScore = 0;

        if(passwordMeterField) {
            passwordScore = passwordMeterField.value;
        }
        
        this.props.CreatePassword(this.props.registration.emailAddress, this.state.newPasswordField, passwordScore);
    }

}
//Redux Map Props
const MapStateToProps = (state: {identityReducer: any;}) => ({
    registration: state.identityReducer
});
 
export default connect(MapStateToProps, {CreatePassword, ClearIdentityErrorMessages})(CreateAccountForm);