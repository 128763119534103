import React from "react";
import { DecoratedRadioButton } from '@cin7/ui';
import { PaymentMethodType } from '../../../Enums/PaymentMethods/PaymentMethodType';
import { OnAccountPaymentIcon } from '../../../Images/Icons/Orders/OnAccountPaymentIcon';

interface OnAccountPaymentOptionButtonProps {
    onAccountMethodCode: PaymentMethodType,
    onAccountChecked: boolean,
    onClickEventHandler: () => void
}

export const OnAccountPaymentOptionButton: React.FC<OnAccountPaymentOptionButtonProps> = ({onAccountMethodCode, onAccountChecked, onClickEventHandler}) => {
    return (
        <DecoratedRadioButton
            icon={<OnAccountPaymentIcon />}
            message="Use your account to order now"
            title="Pay On Account"
            value={onAccountMethodCode}
            checked={onAccountChecked}
            onClick={() => {
                onClickEventHandler();
            }}
            css={null}
        />
    )
}