import * as types from './../Types/ProductDetailsTypes';
import { RefreshUserToken } from './../Actions/AuthActions';
import APIHost from './../../APIHost';
import axios from 'axios';
import ApiUtil from '../../Utils/ApiUtil';

const host = new APIHost();
const apiUtil = new ApiUtil();

export const GetProductDetails = (token: string, productId: number) => (dispatch: any, getState: any) => {
    var headers = apiUtil.DefaultHeaders(token);

    if (headers === null) {
        return;
    }

    dispatch({
        type: types.Fetching_Product_Details
    });

    const postBody = {
        moduleId: apiUtil.GetModuleId(),
        id: productId
    };
    
    axios.post(host.ProductsAPI("GetProductDetails"), postBody, headers).then(res => {
             dispatch({
            type: types.Fetch_Product_Details,
            payload: {
                product: res.data.product
            }
        });
    }).catch((error) => {
        if (error.response) {
            let errorStatus = error.response.status;

            switch (errorStatus) {
                case 404:
                    dispatch({
                        type: types.Fetch_Product_Error,
                        payload: {
                            message: 'This product is no longer available.',
                            productNotFound: true
                        }
                    });
                    break;
                case 401:
                    dispatch({
                        type: types.Fetch_Product_Error,
                        payload: {
                            message: ''
                        }
                    });
                    dispatch(RefreshUserToken());
                    break;                
                default:
                    dispatch({
                        type: types.Fetch_Product_Error,
                        payload: {
                            message: error.message
                        }
                    });
                    break;
            }
        } else {
            dispatch({
                type: types.Fetch_Product_Error,
                payload: {
                    message: error.message
                }
            });
        }
    });
}

export const ShowProductDetailsModal = (dispatch: any) => {
    dispatch({
        type: types.Show_Product_Details_Modal
    });
}


export const HideProductDetailsModal = (dispatch: any) => {
    dispatch({
        type: types.Hide_Product_Details_Modal
    });
}

export const ShowProductDetails = (productId: number) => (dispatch: any) => {
    dispatch({
        type: types.Set_Product_Id_To_Load,
        payload: productId
    });
    ShowProductDetailsModal(dispatch);
}
