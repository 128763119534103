import React, { Component } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import SideMenu from '../../Components/Global/SideMenu';
import { Header } from '../../Components/Global/Header';
import CustomPage1 from './Views/CustomPage1';
import CustomPage2 from './Views/CustomPage2';
import TermsAndConditions from './Views/TermsAndConditions';

import './Styles/_SellerContent.scss';

interface componentProps extends RouteComponentProps<any> {}

interface componentStates {
  pageHeading: string
}

class SellerContent extends Component<componentProps, componentStates> {
  constructor(props: componentProps){
    super(props);
    this.state = {
        pageHeading: props.location.pathname
    }
  }

  render() {
    return (
      <>
        <SideMenu />
        <div id="Body-Content">
          <Header />
          <Switch>
            <Route exact path={'/seller/page-1'} component={CustomPage1} />
            <Route exact path={'/seller/page-2'} component={CustomPage2} />
            <Route exact path={'/seller/terms-and-conditions'} component={TermsAndConditions} />
            <Redirect to={'/page-not-found'} />
          </Switch>
        </div>
      </>
    );
  }
}

export default SellerContent;
