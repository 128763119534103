import React from "react";
import Image from "../../../Components/Global/Image";

type Props = {
    logoImageUrl: string;
    storeName?: string;
};

const StoreHeading = (props: Props) => {
    if (props.logoImageUrl) {
        return (
            <div className="company-logo-container">
                <Image imagePath={props.logoImageUrl} altText="Store Brand Logo" logo={true} />
            </div>
        );
    } else {
        return <div className="company-logo-container">{props.storeName}</div>;
    }
};

export default StoreHeading;
