import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { UpdateDeliveryInstructions, UpdateDeliveryDate } from './../../../Redux/Actions/OrderActions';
import { StripHTMLFromInputs } from '../../../Utils/B2BHelpers';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { FormatDate } from '../../../Utils/OrderHelper';
import './Styles/_DeliveryInstructionsPanel.scss';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

interface componentProps {
    UpdateDeliveryInstructions: (deliveryInstructionsInput: string) => void;
    UpdateDeliveryDate: (deliveryDateInput: Date | null) => void;
    order: {
        orderDetails: {
            deliveryInstructions: string;
            deliveryDate: Date | null;
        }
    };
    identity: {
        moduleSettings: {
            hideDeliveryDateField: boolean;
        };
    };
    editMode: boolean;
}

interface componentStates { }

class DeliveryInstructionsPanel extends Component<componentProps, componentStates> {
    render() {
        
        const { order, identity, editMode } = this.props;

        var minDate = new Date();
        minDate.setDate(minDate.getDate() - 1);
        return (
            <div id="Delivery-Instructions-Panel">
                <div className="panel-container">
                    {!identity?.moduleSettings?.hideDeliveryDateField && (editMode || (!editMode && order.orderDetails.deliveryDate)) && <div className={`panel-content-group delivery-date ${!editMode ? "non-edit-mode" : ""}`}>
                        <div className="panel-group-label">{editMode ? "Preferred Delivery Date" : "Preferred Delivery Date:"}</div>
                        <div className="panel-group-field">
                            {editMode ?
                                <SemanticDatepicker value={order.orderDetails.deliveryDate != null ? new Date(order.orderDetails.deliveryDate) : null} onChange={this._onChangeDeliveryDate} clearOnSameDateClick={false} minDate={minDate} icon={"calendar alternate outline"} pointing={"top left"} showToday={false} showOutsideDays={true} /> : FormatDate(order.orderDetails.deliveryDate != null ? String(order.orderDetails.deliveryDate) : null)
                            }
                        </div>
                    </div>}
                    {(editMode || (!editMode && order.orderDetails.deliveryInstructions)) && <div className={`panel-content-group ${!editMode ? "non-edit-mode" : ""}`}>
                        <div className="panel-group-label">{editMode ? "Delivery Instructions" : "Delivery Instructions:"}</div>
                        <div className="panel-group-field">
                            {editMode ? <textarea maxLength={1000} value={order.orderDetails.deliveryInstructions} onChange={this._onChange} /> : order.orderDetails.deliveryInstructions}
                        </div>
                    </div>}
                </div>
            </div>
);
    }

    _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let targetInput = StripHTMLFromInputs(e.currentTarget.value);
        this.props.UpdateDeliveryInstructions(targetInput);
    }

    _onChangeDeliveryDate = (_: SyntheticEvent | undefined, data: any) => {
        this.props.UpdateDeliveryDate(data.value);
    }
}

const MapStateToProps = (state: { orderReducer: any; identityReducer: any; }) => ({
    order: state.orderReducer,
    identity: state.identityReducer,
});

export default connect(MapStateToProps, { UpdateDeliveryInstructions, UpdateDeliveryDate })(DeliveryInstructionsPanel);