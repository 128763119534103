import { useSelector } from "react-redux";
import { RootState } from "..";
import { FreightTierPriceType } from "../Redux/Reducers/App_Reducers/FreightReducer";

export const useFreightOptionsFetched = () => {
    return useSelector((state: RootState) => state.freightReducer.freightOptionsFetched);
};

export const useFreightOptions = () => {
    return useSelector((state: RootState) => state.freightReducer.freightOptions);
};

export const useCurrentFreightZone = () => {
    return useSelector((state: RootState) => state.freightReducer.freightZone);
};

export const useCurrentFreightTier = () => {
    return useSelector((state: RootState) => state.freightReducer.freightTier);
};

export const useCurrentFreightTierAttribute = () => {
    return useSelector((state: RootState) => state.freightReducer.freightTierAttribute);
};

export const useCurrentFreightPrice = () => {
    const tier = useCurrentFreightTier();
    const attribute = useCurrentFreightTierAttribute();
    if (tier?.id > 0) {
        if ((tier.type === FreightTierPriceType.Weight || tier.type === FreightTierPriceType.Value) && attribute?.id > 0) {
            return attribute.price ?? 0;
        }
        else if (tier.type === FreightTierPriceType.FlatRate) {
            return tier.price;
        }
    }
    return 0;
};

export const useCurrentFreightDescription = () => {
    const zone = useCurrentFreightZone();
    const tier = useCurrentFreightTier();

    let description = "";

    if (zone?.id > 0 && tier?.id > 0) {
        description = zone.name + " - " + tier.name;
    }

    return description;
};

export const useHasFreightOptions = () => {
    const fetched = useFreightOptionsFetched();
    const freightOptions = useFreightOptions();
    
    if (fetched && freightOptions?.length > 0) {
        return true;
    }

    return false;
};

export const useHasFilteredZones = () => {
    return useSelector((state: RootState) => state.freightReducer.freightZonesFiltered);
};
