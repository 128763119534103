import React, { SyntheticEvent, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ResetSideMenu, ToggleSideMenu } from "./../../Redux/Actions/HeaderActions";
import Image from "./Image";
import MiniCartTax from "../PageElements/MiniCartTax";
import HeaderSearch from "../Global/HeaderSearch";
import SearchModal from "./SearchModal";
import { MenuIcon } from "../../Images/Icons/Global/MenuIcon";
import { CartIcon } from "../../Images/Icons/Orders/CartIcon";
import CloseIcon from "../../Images/Icons/Global/CloseIcon";
import { FormatAmount } from "../../Utils/OrderHelper";
import { getCartItemsTotal, getOrderItemCount } from "../../Redux/Selectors/OrderSelectors";
import { getStoreLogo } from "../../Redux/Selectors/SettingsSelectors";
import PriceDisplay from "./PriceDisplay";
import { useIsWindowSmall } from "../../Utils/Global/UseWindowSizeHook";
import { RootState } from "../..";

import "./Styles/_Header.scss";

export const Header = () => {
    const [activeModal, setActiveModal] = useState(false);
    const maxTotalItems = 9999999;
    const totalCartItems = useSelector(getOrderItemCount);
    const cartItemsTotal = useSelector(getCartItemsTotal);
    const logoImageUrl = useSelector(getStoreLogo);
    const isMenuOpen = useSelector((state: RootState) => state.appSettingsReducer.isMenuOpen);
    const storeName = useSelector((state: RootState) => state.brandingReducer.storeName);
    const smallScreen = useIsWindowSmall();
    const history = useHistory();
    const dispatch = useDispatch();

    const toggleMenu = (e: SyntheticEvent) => {
        e.preventDefault();
        dispatch(ToggleSideMenu());
    };

    const toggleModal = (e?: SyntheticEvent) => {
        e?.preventDefault();

        setActiveModal(!activeModal);
    };

    const onClickLink = (e: SyntheticEvent, to: string) => {
        e.preventDefault();
        dispatch(ResetSideMenu());
        history.push(to);
    };

    const totalCartItemsDisplay = () => {
        if (totalCartItems > maxTotalItems) {
            return `${FormatAmount(maxTotalItems)}+`;
        } else {
            return FormatAmount(totalCartItems);
        }
    };

    return (
        <>
            <div className="main-header">
                <div className="nav-menu-toggle-container">
                    <div id="Open-Nav" className="menu-button" onClick={toggleMenu}>
                        {smallScreen && isMenuOpen ? <CloseIcon strokeColor={"#00326B"} /> : <MenuIcon />}
                    </div>
                </div>
                <div className="logo-container">
                    <div className={logoImageUrl ? "company-logo" : "company-store-name"}>
                        <Link className={logoImageUrl ? "logo-link" : "logo-link-store-name"} to={"/"} onClick={(e) => onClickLink(e, "/")}>

                            {logoImageUrl ? (<Image imagePath={logoImageUrl} altText={storeName} logo={true} />) : 
                            (<h2 className="store-name">{storeName}</h2>)}

                        </Link>
                    </div>
                </div>
                <div className="search-container">
                    <HeaderSearch closeModal={toggleModal} />
                </div>
                <div className="tax-container">
                    <MiniCartTax />
                </div>
                <div className="cart-container">
                    <div className="mini-cart-container">
                        <Link className="cart-link" to={"/cart"} onClick={(e) => onClickLink(e, "/cart")}>
                            <div className="mini-cart-order-quantity">{totalCartItemsDisplay()}</div>
                            <div className="cart-icon-container">
                                <CartIcon />
                            </div>
                            <div className="cart-order-quantity">
                                <div>
                                    {totalCartItemsDisplay()} Item{totalCartItems === 1 ? "" : "s"}
                                </div>
                                <div>{<PriceDisplay value={cartItemsTotal} />}</div>
                            </div>
                            <div className="cart-arrow-icon-container">
                                <i className="icon fas fa-chevron-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            {activeModal && <SearchModal closeModal={toggleModal} />}
        </>
    );
};
