import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as PageContent from '../../../Language/Default_Settings';
import CircleTickIcon from '../../../Images/Icons/Global/CircleTickIcon';
import { OrderCompletionSuccessful } from '../../../Redux/Actions/OrderActions';
import { GetUrlParameters } from '../../../Utils/B2BHelpers';
import { ApproveAndProcessOrder } from '../../../Domain/ApproveAndProcessOrder';
import './../Styles/_OrderCompletion.scss';
import { getFullOrder } from "../../../Redux/Selectors/OrderSelectors";
import { getIsCustomHomepageEnabled } from "../../../Redux/Selectors/SettingsSelectors";
import { getUserInfo, getUserInfoFetched } from "../../../Redux/Selectors/UserSelectors";

const OrderCompletion = () => {
    const dispatch = useDispatch();

    const fullOrder = useSelector(getFullOrder);
    const userInfo = useSelector(getUserInfo);

    useEffect(() => {
        document.title = `${PageContent.B2BPageTitles.root_title} - ${PageContent.B2BPageTitles.order_completion_title}`;
        dispatch(OrderCompletionSuccessful());

        const parameters = GetUrlParameters(["paid", "fullyPaidRedirect"]);

        if (parameters && parameters["paid"] && !parameters["fullyPaidRedirect"]) {
            ApproveAndProcessOrder(fullOrder.id);
        }
    }, [dispatch, fullOrder.id]);

    const isCustomHomepageEnabled = useSelector(getIsCustomHomepageEnabled);
    const isUserInfoFetched = useSelector(getUserInfoFetched);

    return (
        <>
            <div id="Order-Completion-Container">
                <div className="order-completion-content">
                    <div className="order-completion-icon"><CircleTickIcon strokeColor="#61A25E" /></div>
                    <div className="order-completion-title">{PageContent.OrderCompletionPageContent.default_orderSuccessMessage}</div>
                    <div className="order-completion-body">
                        <div className="order-reference-text">Your order number is<div className="order-reference">{fullOrder.reference}</div></div>
                        <div className="order-email-text">We will send an email confirmation to<div className="email-display">{userInfo.email}</div></div>
                    </div>
                    <div className="order-completion-action">
                        {(isUserInfoFetched && isCustomHomepageEnabled) ? (
                            <Link className="action-link" to={'/home/'}>{PageContent.OrderCompletionPageContent.default_returntohomepageButtonText}</Link>
                        ) : (
                            <Link className="action-link" to={'/products/'}>{PageContent.OrderCompletionPageContent.default_returnButtonText}</Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderCompletion;