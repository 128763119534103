class ApiUtil {
    DefaultHeaders(token?: string) {

        if (token) {
            return {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        }
        else {
            let persistedStorage = localStorage.getItem('persist:root');

            let authReducer;
    
            if (persistedStorage) {
                let persistedStorageObject = JSON.parse(persistedStorage);
                authReducer = JSON.parse(persistedStorageObject.authReducer);
            }
    
            if (authReducer) {
                if (authReducer.subscribedToken) {
                    return {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authReducer.subscribedToken}`
                        }
                    }
                }
                return null;
            }
        }
        
        return {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }

    GetModuleId() {
        let persistedStorage = localStorage.getItem('persist:root');

        let brandingReducer;

        if (persistedStorage) {
            let persistedStorageObject = JSON.parse(persistedStorage);
            brandingReducer = JSON.parse(persistedStorageObject.brandingReducer);
        }

        if (brandingReducer) {
            return brandingReducer.moduleId;
        }
        
        return 0;
    }

    GetRefreshToken() {
        let persistedStorage = localStorage.getItem('persist:root');

        let authReducer;

        if (persistedStorage) {
            let persistedStorageObject = JSON.parse(persistedStorage);
            authReducer = JSON.parse(persistedStorageObject.authReducer);
        }

        if (authReducer && authReducer.refreshToken) {
            return { "RefreshToken": authReducer.refreshToken };
        }
        
        return null;
    }
}

export default ApiUtil; 