import { BemHelper, Button, Typography } from '@cin7/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutFooter } from '../../../Components/PageElements/Orders/CheckoutFooter';
import { EmptyCart } from '../../../Components/PageElements/Orders/EmptyCart';
import { IssuesCard, IssuesCardVariant } from '../../../Components/PageElements/Orders/IssuesCard';
import OrderReviewDetailsList from '../../../Components/PageElements/Orders/OrderReviewDetailsList';
import { TotalsPanel } from '../../../Components/PageElements/Orders/TotalsPanel';
import { ProductCheckoutValidation, ProductCheckoutValidationResponse } from '../../../Domain/ProductCheckoutValidation';
import { CalculatePromotions, ClearCart, ResetCheckoutState, SetOrderQuantity } from './../../../Redux/Actions/OrderActions';
import { getOrderReducer } from '../../../Redux/Selectors/OrderSelectors';
import { B2BPageTitles } from '../../../Language/Default_Settings';

import '../Styles/_Cart.scss';

interface ProductStockWarning {
    productName: string,
    productCode: string,
    availableQuantity: number
}

interface OrderItem {
    productId: number,
    product: { allowOverselling: boolean, name: string },
    productOptions: ProductOption[]
}

interface ProductOption {
    productQuantity: number,
    optionId: number,
    sizeId: number | null,
    uomId: number | null,
    stock: number,
    code: string,
    uomSize: number | null,
}

const bem = new BemHelper('cart-page');

export const Cart: React.FC = () => {
    
    useEffect(() => {
        document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.order_cart_title}`;
      }, []);

    const [stockWarnings, setStockWarnings] = useState<ProductStockWarning[]>([]);
    const cart = useSelector(getOrderReducer);
    const cartItems = cart.orderItems;
    const dispatch = useDispatch();
    const updateOrderQuantity = (option: any, product: any, quantity: number) => {
        dispatch(SetOrderQuantity(option, product, quantity));
        dispatch(CalculatePromotions());
    };
    const clearCart = () => dispatch(ClearCart());

    useEffect(() => {
        dispatch(ResetCheckoutState());
    }, [dispatch]);

    const validateStock = async () => {

        const productIds = cartItems.filter((cartProduct: OrderItem) => !cartProduct.product.allowOverselling).map((product: OrderItem) => product.productId);

        let validationResult: ProductCheckoutValidationResponse[] = [];

        await Promise.all(productIds.map(async (productId: number) => {
            let result = await ProductCheckoutValidation(productId);
            validationResult.push(result);
        }));

        const stockWarnings: ProductStockWarning[] = [];

        if (validationResult) {
            validationResult.forEach((result) => {
                result.product.options.forEach((productResultOption: { optionId: number; sizeId: number | null; uomId: number | null; uomSize: number | null; stock: number; }) => {
                    const cartProduct = cartItems.find((p: OrderItem) => p.productId === result.product.id);
                    if (!cartProduct) return;
                    let cartProductOption = cartProduct.productOptions.find((option: ProductOption) => option.optionId === productResultOption.optionId && option.sizeId === productResultOption.sizeId && option.uomId === productResultOption.uomId);
                    if (!cartProductOption) return;
                    const remainingStock = productResultOption.uomId !== null && productResultOption.uomSize !== null ? Math.floor(productResultOption.stock / productResultOption.uomSize) : productResultOption.stock;
                    if (cartProductOption.productQuantity > remainingStock) {
                        cartProductOption.stock = productResultOption.stock;
                        updateOrderQuantity(cartProductOption, cartProduct.product, remainingStock);
                        stockWarnings.push({ availableQuantity: remainingStock, productCode: cartProductOption.code, productName: cartProduct.product.name });
                    }
                })
            })
            setStockWarnings(stockWarnings);
            if (stockWarnings.length !== 0) return false;
        }
        return true;
    }

    return (
        <>
            <div className={bem.block()}>
                <div className={bem.element('cart-inner-content')}>
                    {cartItems.length > 0 && <div className={bem.element('header')}>
                        <Typography variant={"h1"} className={bem.element('header-text')} >
                            Your Cart
                        </Typography>
                        <div className={bem.element('clear-cart-container')}><Button className="clear-cart-button" variant="secondary" onClick={() => { clearCart() }} >Remove all</Button></div>
                    </div>}
                    <div className={bem.element('checkout-totals-mobile')}>{cartItems.length > 0 && <TotalsPanel />}</div>
                    {stockWarnings.length === 0 && cartItems.length === 0 && (
                        <div className={bem.element('empty-cart-container')}><EmptyCart /></div>
                    )}
                    <div className={bem.element('error-list-container')}>
                        {stockWarnings.length > 0 && (
                            <div className={bem.element('issues-container')}>
                                <IssuesCard
                                    title={"Warning"}
                                    variant={IssuesCardVariant.Warning}
                                    sections={[
                                        {
                                            title: "Insufficient quantity - the following items will be updated with the currently available stock",
                                            issues:
                                                stockWarnings.filter(x => x.availableQuantity > 0).map((warning: ProductStockWarning, i: number) => {
                                                    return <li key={i}><Typography variant={"body1"} gutterBottom>{`${warning.productName} ${warning.productCode} - quantity updated to ${warning.availableQuantity}`}</Typography></li>
                                                })
                                        },
                                        {
                                            title: "No stock available - the following items will be removed from the order",
                                            issues:
                                                stockWarnings.filter(x => x.availableQuantity === 0).map((warning: ProductStockWarning, i: number) => {
                                                    return <li key={i}><Typography variant={"body1"} gutterBottom>{`${warning.productName} ${warning.productCode}`}</Typography></li>
                                                })
                                        }
                                    ]}
                                    action={<Button className="close-button" onClick={() => { setStockWarnings([]) }} >Close warning</Button>}
                                />
                            </div>
                        )}
                        <OrderReviewDetailsList />
                    </div>
                </div>
                {cartItems.length > 0 && <div className={bem.element('checkout-totals-desktop')}>
                    <TotalsPanel />
                    <CheckoutFooter ShouldContinue={validateStock} />
                </div>}
            </div>
            <div className="checkout-footer-mobile">{cartItems.length > 0 && <CheckoutFooter ShouldContinue={validateStock} />}</div>
        </>
    );
}