import React, { Component } from 'react';
class CircleIncrementIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fillColor: '#000'
        }
    }

    componentDidMount() {
        if(this.props.fillColor) {
            this.setState({
                fillColor: this.props.fillColor
            });
        }
    }

    render() { 
        const {fillColor} = this.state;
        
        const cls1 = {
            fill: fillColor
        }

        return (
            <svg xmlns="https://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 25 25">
                <title>Increment</title>
                <g id="Group_10967" data-name="Group 10967" transform="translate(-149.19 703.809)">
                    <path id="Path_5495" data-name="Path 5495" d="M162.44-698.546h-1.5v6.487h-6.487v1.5h6.487v6.488h1.5v-6.488h6.488v-1.5H162.44Z" style={cls1} />
                    <path id="Path_5496" data-name="Path 5496" d="M161.69-703.809a12.5,12.5,0,0,0-12.5,12.5,12.5,12.5,0,0,0,12.5,12.5,12.5,12.5,0,0,0,12.5-12.5A12.5,12.5,0,0,0,161.69-703.809Zm0,23.5a11.012,11.012,0,0,1-11-11,11.012,11.012,0,0,1,11-11,11.013,11.013,0,0,1,11,11A11.013,11.013,0,0,1,161.69-680.309Z" style={cls1} />
                </g>
            </svg>
         );
    }
}
 
export default CircleIncrementIcon;