import React, { Component, SyntheticEvent, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { IncrementOrderQuantity, DecrementOrderQuantity, SetOrderQuantity, CalculatePromotions } from '../../../Redux/Actions/OrderActions';
import { FormatAmount, UndoFormatAmount } from '../../../Utils/OrderHelper';

// Images
import CircleDecrementIcon from '../../../Images/Icons/CircleDecrementIcon';
import CircleIncrementIcon from '../../../Images/Icons/CircleIncrementIcon';

import './Styles/_ProductOptionQuantity.scss';

interface componentProps {
    order: {
        orderItems: any[];
    };
    option: any;
    product: any;
    tabIndex?: number;
    IncrementOrderQuantity: (productOption: any, product: any) => void;
    DecrementOrderQuantity: (productOption: any, product: any) => void;
    SetOrderQuantity: (productOption: any, product: any, quantity: number) => void;
    CalculatePromotions: () => void;
}
 
interface componentState {
    allowOverselling: boolean;
    quantity: number;
    hasFocus: boolean;
}

const remainingStock = (product: any, option: any, orderItems: any) => {
    let remaining: number = option.stock;

    if (option.uomId) {
        remaining = Math.floor(option.stock / option.uomSize);
    }

    const childItemProductInCart = orderItems.find((item: any) => item.productId === product.id);

    if (childItemProductInCart) {

        const childItemsInCart = childItemProductInCart.productOptions.filter((itemOption: any) => itemOption.optionId === option.optionId);

        if (childItemsInCart) {

            let normalQuantity = childItemsInCart.reduce((counter: number, item: any) => {
                return counter += item.uomId ? item.productQuantity * item.uomSize : item.productQuantity;
            }, 0);

            if (option.uomId) {
                remaining = Math.floor((option.stock - normalQuantity) / option.uomSize);
            }
            else {
                remaining -= normalQuantity;
            }
        }
    }

    return remaining;
}

class ProductOptionQuantity extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            allowOverselling: this.props.product.allowOverselling ?? true,
            quantity: this.getCartQuantity(),
            hasFocus: false
        };
    }

    getCartQuantity() {
        const { option, product } = this.props;
        const { orderItems } = this.props.order;
        let findIncrementGroupName = orderItems.findIndex((x: {productId: number}) => x.productId === product.id);

        if (findIncrementGroupName !== -1) {

            let orderProduct = orderItems[findIncrementGroupName].productOptions.findIndex((x: { optionId: number, sizeId: number, uomId: number }) => x.optionId === option.optionId && x.sizeId === option.sizeId && x.uomId === option.uomId);
            if (orderProduct !== -1) {
                return orderItems[findIncrementGroupName].productOptions[orderProduct].productQuantity
            }
        }  

        return 0;
    }

    getMaxQuantity() {
        const { option, product } = this.props;
        const { orderItems } = this.props.order;
        
        if (option.stock) {
            if (option.sizeId) {
                return option.stock;
            }
            else {
                return  this.getCartQuantity() + remainingStock(product, option, orderItems);
            }
        }
        else {
            return 0;
        }
    }

    updateQuantity() {
        this.setState({ quantity: this.getCartQuantity() });
    }

    setFocus(hasFocus: boolean) {
        this.setState({hasFocus});
    }
    
    render() {
        const { tabIndex } = this.props;
        const { allowOverselling, hasFocus, quantity: internalQuantity } = this.state;
        const quantity = hasFocus ? internalQuantity : this.getCartQuantity();

        let maxQuantity = this.getMaxQuantity();

        return (
            <div className="option-details-inner-child-actions">
                <button className="option-quantity-actions option-quantity-decrement" disabled={quantity <= 0} onClick={this._decrementQuantity}><CircleDecrementIcon fillColor='#4E4F4F' /></button>
                <input className="option-quantity" pattern="[0-9,]{0,9}" value={quantity ? FormatAmount(quantity) : 0} onChange={(e) => this._updateQuantity(e, this._inputQuantity)} tabIndex={tabIndex} onFocus={() => {this.setFocus(true); this.updateQuantity()}} onBlur={(e) => {this.setFocus(false); this._updateQuantity(e, this._updateInputQuantity)}} />
                <button className="option-quantity-actions option-quantity-increment" disabled={allowOverselling ? false : quantity >= Math.floor(maxQuantity)} onClick={this._incrementQuantity}><CircleIncrementIcon fillColor='#4E4F4F' /></button>
            </div>
        );
    }

    _incrementQuantity = (e: SyntheticEvent) => {
        e.preventDefault();
        const quantity = this.getCartQuantity();

        if (quantity >= 9999999) {
            return;
        }

        this.props.IncrementOrderQuantity(this.props.option, this.props.product);
        this.props.CalculatePromotions();
    }

    _decrementQuantity = (e: SyntheticEvent) => {
        e.preventDefault();
        const quantity = this.getCartQuantity();

        if (quantity <= 0) {
            return;
        }

        this.props.DecrementOrderQuantity(this.props.option, this.props.product);
        this.props.CalculatePromotions();
    }

    _updateQuantity = (e: ChangeEvent<HTMLInputElement>, updateState: (quantity: number) => void) => {
        e.preventDefault();

        let currentQuantity = UndoFormatAmount(e.target.value);

        if (Number(currentQuantity) > 9999999) {
            return;
        }

        if (Number(currentQuantity) < 0) {
            e.target.value = "0";
            return;
        }

        let maxQuantity = this.getMaxQuantity();

        if (!this.state.allowOverselling && currentQuantity > maxQuantity) {
            currentQuantity = maxQuantity > 0 ? maxQuantity : 0;
        }

        updateState(Number(currentQuantity));
    }

    _inputQuantity = (quantity: number) => {

        this.setState({
            quantity: quantity | 0
        });
    }

    _updateInputQuantity = (quantity: number) => {

        this.props.SetOrderQuantity(this.props.option, this.props.product, quantity);
        this.props.CalculatePromotions();
    }
}

const MapStateToProps = (state: { orderReducer: any }) => ({
    order: state.orderReducer
});

export default connect(MapStateToProps, {IncrementOrderQuantity,DecrementOrderQuantity,SetOrderQuantity,CalculatePromotions})(ProductOptionQuantity);