import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { GetTaxLabel } from '../../Utils/OrderHelper';
import { connect } from 'react-redux';

import './Styles/_MiniCartTax.scss';

interface componentProps extends Partial<RouteComponentProps> {
    identity: {
        userInfo: {
            priceTier: {
                currencyCode: string;
                taxStatus: string;
            }
        }
    };
}

class MiniCartTax extends Component<componentProps, {}> {
    render() {
        const { identity } = this.props;

        return (
            <div className="mini-cart-tax">
                {identity && identity.userInfo && identity.userInfo.priceTier &&
                    <div className="mini-cart-tax-type">
                        <div className="mini-cart-tax-currency">{identity.userInfo.priceTier.currencyCode}</div>
                        <div className="mini-cart-tax-variance">{GetTaxLabel(identity.userInfo.priceTier.taxStatus)}</div>
                    </div>}
            </div>
        );
    }
}

const MapStateToProps = (state: { identityReducer: any; }) => ({
    identity: state.identityReducer
});

export default connect(MapStateToProps)(MiniCartTax);