import axios from "axios";
import APIHost from "../APIHost";
import ApiUtil from "../Utils/ApiUtil";

export interface Product {
    id: number,
    options: { optionId: number, sizeId: number | null, uomId: number | null, uomSize: number | null, stock: number }[]
}

export interface ProductCheckoutValidationResponse {
    product: Product;
    success: boolean;
}

export async function ProductCheckoutValidation(productId: number): Promise<ProductCheckoutValidationResponse> {
    var headers = new ApiUtil().DefaultHeaders('');

    if (headers === null) {
        throw new Error ('Unauthenticated');
    }

    let result;

    try {
        result = await axios.post<ProductCheckoutValidationResponse>(new APIHost().ProductsAPI("GetCheckoutValidationDetail"), { id: productId, moduleId: new ApiUtil().GetModuleId() }, headers);
    } catch (e) {
        return Promise.reject(e);
    }

    return result.data;
}