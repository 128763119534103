import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';
import PasswordField from '../FormElements/PasswordField';
import ButtonField from '../FormElements/ButtonField';
import { GeneratePasswordValidationParameters, ClearFailValidation } from '../../Utils/B2BHelpers';
import { connect } from 'react-redux';
import {ResetUserPassword} from './../../Redux/Actions/IdentityActions';
import Cin7ConfirmationBox from '../PageElements/Cin7ConfirmationBox';


interface componentProps {
    resetPassword: {
        isResetPasswordValidationActive: boolean;
        isUpdatingPassword: boolean;
        resetPasswordButtonLabel: string;
        errorMessage: string;
    };
    displayReturnToLogin: boolean;
    ResetUserPassword: (newPassword: string, passwordScore: number) => void;
}

interface componentStates {
    newPasswordField: string;
}

class ResetPasswordForm extends Component<componentProps, componentStates> {

    constructor(props: componentProps) {
        super(props);
        this.state = {
            newPasswordField: ''
        }
    }

    
    //Functions
    _onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        let passwordMeterField = document.getElementById("Pass-Meter") as HTMLProgressElement;
        let passwordScore = 0;

        if(passwordMeterField) {
            passwordScore = passwordMeterField.value;
        }
        this.props.ResetUserPassword(this.state.newPasswordField, passwordScore);  
    }


    render(){

        const { resetPassword, displayReturnToLogin } = this.props;

        const ReturnToLoginLink: any = () => {
            return (
                <Link to={'/login'} className="login-pages-link">Continue to Login</Link>
            );
        }

        return (
            <>
            {resetPassword.errorMessage && 
                <Cin7ConfirmationBox 
                    boxType={"error-box"}
                    boxIcon={"error-icon"}
                    boxMessage={resetPassword.errorMessage}
                />
            }
            {displayReturnToLogin ? <ReturnToLoginLink /> :
                <Form className="cin7-form" onSubmit={this._onSubmit}>
                    <PasswordField
                        fieldLabel="New password"
                        fieldId="NewPassword"
                        fieldName="newPasswordField"
                        passwordStrength={true}
                        formGroupClass="register-meter-margin"
                        fieldErrorId="Reset-Password-Validation"
                        onChange={this._onChange}
                    />
                    <ButtonField buttonLabel={resetPassword.resetPasswordButtonLabel} buttonType="submit" disableButton={resetPassword.isUpdatingPassword} />
                </Form>
            }
            </>
        );
    }

    _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let validationParameters = GeneratePasswordValidationParameters("NewPassword","Reset-Password-Validation", "cin7-password-meter");

        if(this.props.resetPassword.isResetPasswordValidationActive) {
            ClearFailValidation(validationParameters.inputField, 
                validationParameters.validationField, 
                validationParameters.passwordMeterContainer);
        }

        this.setState({
            [e.target.name]: e.target.value
        }as unknown as Pick<componentStates, keyof componentStates>);
    }
}

const MapStateToProps = (state: {identityReducer: any;} ) => ({
    resetPassword: state.identityReducer
});


export default connect(MapStateToProps, {ResetUserPassword})(ResetPasswordForm);