import React, { SyntheticEvent } from "react";
import "./Styles/ContactUsFooter.scss";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { ResetContactUsForm } from "../../../Redux/Actions/BrandingActions";

export const ContactUsFooter = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleContactUsClick = (e: SyntheticEvent) => {
    dispatch(ResetContactUsForm());
    history.push("/contact-us");
  };

  return (
    <div className="contact-us-footer">
      <div className="contact-us-horizontal-line">&nbsp;</div>
      <div className="contact-us-text">Don't have an account?</div>
      <button
        className="default-button outline fullWidth"
        onClick={(e) => handleContactUsClick(e)}
      >
        Contact Us
      </button>
    </div>
  );
};
