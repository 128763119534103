import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Typography } from '@cin7/ui'
import './Styles/_EmptyCart.scss';
import { OrderReviewPageContent } from '../../../Language/Default_Settings';
import { useHistory } from 'react-router';
import { UploadBulkOrder } from './UploadBulkOrder';
import { BulkImportCSV } from '../../../Domain/BulkImportCSV';
import download from 'downloadjs';
import { CustomHompepageRoute, CategoriesRoute } from '../../../Routes';
import { getIsCustomHomepageEnabled } from "../../../Redux/Selectors/SettingsSelectors";
import { getUserInfoFetched } from "../../../Redux/Selectors/UserSelectors";

export const EmptyCart: React.FC = () => {
    const history = useHistory();
    const [showUploadBulkOrder, setShowUploadBulkOrder] = useState(false);
    const isCustomHomepageEnabled = useSelector(getIsCustomHomepageEnabled);
    const isUserInfoFetched = useSelector(getUserInfoFetched);
    
    return (
        <div className="empty-cart">
            <Typography variant={"h1"} gutterBottom>
                {OrderReviewPageContent.default_emptyCartTitle}
            </Typography>
            <Typography variant={"body1"} className="empty-cart__message" gutterBottom>
                {OrderReviewPageContent.default_emptyCartMessage}
            </Typography>
            <div className="empty-cart__actions">
                {(isUserInfoFetched && isCustomHomepageEnabled) ? (
                    <Button
                        className="empty-cart__return-homepage-button"
                        onClick={() => history.push(CustomHompepageRoute)}
                    >{OrderReviewPageContent.default_returntohomepageButtonLabel}</Button>
                ) : (
                    <Button
                        className="empty-cart__browse-products-button"
                        onClick={() => history.push(CategoriesRoute)}
                    >{OrderReviewPageContent.default_browseProductsButtonLabel}</Button>
                )}
                <Button 
                    variant={'secondary'} 
                    onClick={() => setShowUploadBulkOrder(true)} 
                >{OrderReviewPageContent.default_uploadBulkOrderButtonLabel}</Button>
            </div>
            {showUploadBulkOrder && <UploadBulkOrder downloadTemplate={() => {download('Sku, Barcode, Quantity, Size', 'template.csv', 'text/csv')}} onClose={() => setShowUploadBulkOrder(false)} importCSV={BulkImportCSV} />}
        </div>
    )
}