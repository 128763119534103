import { AppSettings } from "./App_Reducers/AppSettingsReducer";
import { AuthReducer } from "./App_Reducers/AuthReducer";
import { BrandingReducer } from "./App_Reducers/BrandingReducer";
import { IdentityReducer } from "./App_Reducers/IdentityReducer";
import { ProductListReducer } from "./App_Reducers/ProductListReducer";
import { ProductDetailsReducer } from "./App_Reducers/ProductDetailsReducer";
import { OrderReducer } from "./App_Reducers/OrderReducer";
import { ProcessCheckoutReducer } from "./App_Reducers/ProcessCheckoutReducer";
import { UserDetailsReducer } from "./App_Reducers/UserDetailsReducer";
import { CategoryReducer } from "./App_Reducers/CategoryReducer";
import { FreightReducer } from "./App_Reducers/FreightReducer";

export const IndexReducers = {
    appSettingsReducer: AppSettings,
    authReducer: AuthReducer,
    brandingReducer: BrandingReducer,
    identityReducer: IdentityReducer,
    productListReducer: ProductListReducer,
    productDetailsReducer: ProductDetailsReducer,
    processCheckoutReducer: ProcessCheckoutReducer,
    orderReducer: OrderReducer,
    userDetailsReducer: UserDetailsReducer,
    categoryReducer: CategoryReducer,
    freightReducer: FreightReducer,
};
