import { GetTenantImageUrl } from "../../Utils/LoginHelper";
import { CustomHomepageType } from "../Reducers/App_Reducers/IdentityReducer";

export const getStoreLogo = (state:any): string => {
    return GetTenantImageUrl(state.brandingReducer?.loginPageImageUrl);
}

export const getIsCustomHomepageEnabled = (state: any) : boolean => state.identityReducer?.moduleSettings?.customHomepage?.enabled;

export const getCustomHomepageSettings = (state: any) : CustomHomepageType => state.identityReducer?.moduleSettings?.customHomepage;
