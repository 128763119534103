import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getIsPaymentsPortalEnabled,
    getIsOnAccountOptionEnabled,
    getUserIsOnHold,
    getUserDepositPercentage,
    getUserRemainingCreditLimit,
} from "../../../Redux/Selectors/UserSelectors";
import { getCartItemsTotal, getPaymentMethod } from "../../../Redux/Selectors/OrderSelectors";
import { PayOnlinePaymentOptionButton } from "./PayOnlinePaymentOptionButton";
import { OnAccountPaymentOptionButton } from "./OnAccountPaymentOptionButton";
import { RadioGroup } from "@cin7/ui";
import { UpdateOrderPaymentMethod } from "../../../Redux/Actions/OrderActions";
import { PaymentMethodType } from "../../../Enums/PaymentMethods/PaymentMethodType";
import { getIsPayAccountOnHold } from "../../../Utils/OrderHelper";

import "./Styles/_PaymentPanel.scss";

export const PaymentPanel: React.FC = () => {
    const userIsOnHold = useSelector(getUserIsOnHold);
    const remainingCreditLimit = useSelector(getUserRemainingCreditLimit);
    const cartItemsTotal = useSelector(getCartItemsTotal);
    const isUserOnHold = getIsPayAccountOnHold(userIsOnHold, remainingCreditLimit, cartItemsTotal);

    const onAccountPaymentConfig = useSelector(getIsOnAccountOptionEnabled);
    const paymentPortalPaymentConfig = useSelector(getIsPaymentsPortalEnabled);
    const isPaymentsPortalEnabled = paymentPortalPaymentConfig.active;
    const isOnAccountOptionEnabled = onAccountPaymentConfig.active;
    const userDepositPercentage = useSelector(getUserDepositPercentage);

    const selectedPaymentMethod = useSelector(getPaymentMethod);

    const onAccountMethodCode = PaymentMethodType.OnAccount;
    const payOnlineMethodCode = PaymentMethodType.PayOnline;

    const displayOnAccountPaymentOptionFirst = onAccountPaymentConfig?.sort <= paymentPortalPaymentConfig?.sort;
    const displayOnAccountPaymentOption =
        !isUserOnHold && isOnAccountOptionEnabled && (!(userDepositPercentage >= 100) || !isPaymentsPortalEnabled);

    const getCurrentPaymentMethod = useCallback(() => {
        if (isUserOnHold && isPaymentsPortalEnabled) {
            return payOnlineMethodCode;
        }

        if (selectedPaymentMethod >= 0) {
            if (selectedPaymentMethod === payOnlineMethodCode && isPaymentsPortalEnabled) {
                return payOnlineMethodCode;
            }
            if (selectedPaymentMethod === onAccountMethodCode && isOnAccountOptionEnabled) {
                return onAccountMethodCode;
            }
        }

        if (isPaymentsPortalEnabled && (!displayOnAccountPaymentOptionFirst || !displayOnAccountPaymentOption)) {
            return payOnlineMethodCode;
        } else {
            return onAccountMethodCode;
        }
    }, [
        displayOnAccountPaymentOption,
        displayOnAccountPaymentOptionFirst,
        isOnAccountOptionEnabled,
        isPaymentsPortalEnabled,
        isUserOnHold,
        onAccountMethodCode,
        payOnlineMethodCode,
        selectedPaymentMethod,
    ]);

    const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethodType>(getCurrentPaymentMethod());

    const [onAccountChecked, setOnAccountChecked] = useState(currentPaymentMethod === onAccountMethodCode);
    const [payOnlineChecked, setPayOnlineChecked] = useState(currentPaymentMethod === payOnlineMethodCode);

    const dispatch = useDispatch();

    const updatePaymentMethod = useCallback(
        (paymentMethod: PaymentMethodType) => {
            dispatch(UpdateOrderPaymentMethod(paymentMethod));
        },
        [dispatch]
    );

    useEffect(() => {
        const paymentMethod = getCurrentPaymentMethod();
        setCurrentPaymentMethod(paymentMethod);
        updatePaymentMethod(paymentMethod);
        setPayOnlineChecked(!!paymentMethod);
        setOnAccountChecked(!paymentMethod);
    }, [setCurrentPaymentMethod, updatePaymentMethod, getCurrentPaymentMethod]);

    return (
        <div id="Payment-Panel">
            <div className="payment-title">Payment Method</div>
            {isUserOnHold && !isPaymentsPortalEnabled && (
                <div className="no-payment-methods-content">No payment methods available.</div>
            )}
            <RadioGroup>
                {displayOnAccountPaymentOptionFirst ? (
                    <>
                        {displayOnAccountPaymentOption && (
                            <OnAccountPaymentOptionButton
                                onAccountChecked={onAccountChecked}
                                onAccountMethodCode={onAccountMethodCode}
                                onClickEventHandler={() => {
                                    setOnAccountChecked(true);
                                    setPayOnlineChecked(false);
                                    updatePaymentMethod(onAccountMethodCode);
                                }}
                            />
                        )}
                        {isPaymentsPortalEnabled && !isUserOnHold && <div style={{ marginBottom: "1rem" }}></div>}
                        {isPaymentsPortalEnabled && (
                            <PayOnlinePaymentOptionButton
                                payOnlineChecked={payOnlineChecked}
                                payOnlineMethodCode={payOnlineMethodCode}
                                onClickEventHandler={() => {
                                    setPayOnlineChecked(true);
                                    setOnAccountChecked(false);
                                    updatePaymentMethod(payOnlineMethodCode);
                                }}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {isPaymentsPortalEnabled && (
                            <PayOnlinePaymentOptionButton
                                payOnlineChecked={payOnlineChecked}
                                payOnlineMethodCode={payOnlineMethodCode}
                                onClickEventHandler={() => {
                                    setPayOnlineChecked(true);
                                    setOnAccountChecked(false);
                                    updatePaymentMethod(payOnlineMethodCode);
                                }}
                            />
                        )}
                        {displayOnAccountPaymentOption && <div style={{ marginBottom: "1rem" }}></div>}
                        {displayOnAccountPaymentOption && (
                            <OnAccountPaymentOptionButton
                                onAccountChecked={onAccountChecked}
                                onAccountMethodCode={onAccountMethodCode}
                                onClickEventHandler={() => {
                                    setOnAccountChecked(true);
                                    setPayOnlineChecked(false);
                                    updatePaymentMethod(onAccountMethodCode);
                                }}
                            />
                        )}
                    </>
                )}
            </RadioGroup>
        </div>
    );
};
