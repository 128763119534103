import { FormControl, Grid, TextField } from "@cin7/ui";
import React, { SyntheticEvent, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import SidePageBanner from "../../Components/PageElements/Login/SidePageBanner";
import "./Styles/_ContactUs.scss";
import ReCAPTCHA from "react-google-recaptcha";
import {
  contactRequestEmailTypes,
  ReCaptchaValidationRequestTypes,
  SendContactUsRequestEmail,
  ValidateGoogleReCaptcha,
} from "../../Redux/Actions/BrandingActions";
import { Form } from "semantic-ui-react";
import { useHistory } from "react-router";
import { ValidateEmailAddress } from "../../Utils/B2BHelpers";
import StoreHeading from "./Views/StoreHeading";
import { GetTenantImageUrl } from "../../Utils/LoginHelper";
import * as types from "../../Redux/Types/BrandingTypes";
import APIHost from "../../APIHost";

export const ContactUsForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const brandingInfo = useSelector((state: RootState) => state.brandingReducer);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [nameHasValidationError, setNameHasValidationError] = useState(false);
  const [emailHasValidationError, setEmailHasValidationError] = useState(false);
  const [messageHasValidationError, setMessageHasValidationError] =
    useState(false);
  const [emailValidationErrorMessage, setEmailValidationErrorMessage] =
    useState("");
  const [recaptchaValidationError, setRecaptchaValidationError] =
    useState(false);
  const [recaptchaClicked, setRecaptchaClicked] =
    useState(false);
  const [reCaptchaTokenExpired, setReCaptchaTokenExpired] =
    useState(false);
  const [recaptchaValidationErrorMessage, setRecaptchaValidationErrorMessage] = useState("");

  const onRecaptchaChange = (value: any) => {
    setRecaptchaClicked(true);
    const data: ReCaptchaValidationRequestTypes = {
      token: value,
      moduleId: brandingInfo.moduleId,
    };
    dispatch(ValidateGoogleReCaptcha(data));
    setRecaptchaValidationError(false);
  };

  const onRecaptchaExpiry = () => {
    dispatch({
      type: types.Update_ReCaptcha_Validation_Result,
      payload: {
          success: false
      }
    })
    setRecaptchaClicked(false);
    setReCaptchaTokenExpired(true);
  }

  const validateMandatoryFields = () => {
    let hasValidationErrors = false;
    if (!name) {
      setNameHasValidationError(true);
      hasValidationErrors = true;
    }
    if (!message) {
      setMessageHasValidationError(true);
      hasValidationErrors = true;
    }
    if (!email) {
      setEmailHasValidationError(true);
      setEmailValidationErrorMessage("Enter your email address");
      hasValidationErrors = true;
    } else if (!ValidateEmailAddress(email)) {
      setEmailHasValidationError(true);
      setEmailValidationErrorMessage("Enter a valid email address");
      hasValidationErrors = true;
    }

    if(!recaptchaClicked){
      if(!reCaptchaTokenExpired){
        setRecaptchaValidationError(true);
        setRecaptchaValidationErrorMessage("This field is required");
      }      
      hasValidationErrors = true;
    }
    else if(recaptchaClicked && !brandingInfo.isReCaptchaValidated){
      setRecaptchaValidationError(true);
      setRecaptchaValidationErrorMessage("Unable to verify reCAPTCHA, please try again");
      hasValidationErrors = true;
    }

    return hasValidationErrors;
  };

  const resetMandatoryFieldsError = () => {
    setNameHasValidationError(false);
    setMessageHasValidationError(false);
    setEmailHasValidationError(false);
    setRecaptchaValidationError(false);
    setRecaptchaClicked(false);
    setReCaptchaTokenExpired(false);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const hasValidationErrors = validateMandatoryFields();
    if (hasValidationErrors) return;

    const data: contactRequestEmailTypes = {
      name,
      email,
      phone,
      companyName,
      message,
      moduleId: brandingInfo.moduleId,
    };

    dispatch(SendContactUsRequestEmail(data));
    resetMandatoryFieldsError();
  };

  const handleCancel = (e: SyntheticEvent) => {
    history.push("/login");
  };

  const displayEmailError = useMemo(() => {
    return (
      brandingInfo.isContactFormEmailRequested &&
      brandingInfo.isContactFormEmailRequestHasResponse &&
      !brandingInfo.contactUsEmailStatus
    );
  }, [
    brandingInfo.isContactFormEmailRequested,
    brandingInfo.contactUsEmailStatus,
    brandingInfo.isContactFormEmailRequestHasResponse,
  ]);

  let logoImageUrl = GetTenantImageUrl(brandingInfo.loginPageImageUrl);
  const apiHost = new APIHost();
  const appSettings = apiHost.GetEnvironmentConfig();

  return (
    <div id="Contact-Form">
      <div className="contact-form-container customer-page">
        <SidePageBanner customerBanner={brandingInfo.loginBannerImageUrl} />

        <div className="contact-us-section">
          <Form onSubmit={handleSubmit}>
            <div className="store-name">
              <StoreHeading
                logoImageUrl={logoImageUrl}
                storeName={brandingInfo.storeName}
              />
            </div>
            <div className="contact-us-body">
              <h2 className="header">Contact Us</h2>
              <Grid container spacing={2} className="contact-us-grid">
                <Grid item xs={12} sm={6} md={6} className="name">
                  <div className="panel-content-group">
                    <div
                      className={
                        nameHasValidationError
                          ? "panel-group-label-error"
                          : "panel-group-label"
                      }
                    >
                      Name*
                    </div>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        inputProps={{ className: "contact-us-name"}}
                        value={name}
                        placeholder="Type in your name"
                        onChange={(e) => {
                          setNameHasValidationError(false);
                          setName(e.target.value);
                        }}
                        error={nameHasValidationError}
                      />
                    </FormControl>
                    <div className="panel-group-field">
                      {nameHasValidationError && (
                        <div className="panel-group-error-text">
                          Enter your Name
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="phone-number">
                  <div className="panel-content-group">
                    <div className="panel-group-label">Phone Number</div>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        inputProps={{ className: "contact-us-phone"}}
                        value={phone}
                        placeholder="Type in your phone number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormControl>
                    <div className="panel-group-field"></div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="email-address">
                  <div className="panel-content-group">
                    <div
                      className={
                        emailHasValidationError
                          ? "panel-group-label-error"
                          : "panel-group-label"
                      }
                    >
                      Email*
                    </div>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        inputProps={{ className: "contact-us-email"}}
                        type="email"
                        value={email}
                        placeholder="Type in your email"
                        onChange={(e) => {
                          setEmailHasValidationError(false);
                          setEmail(e.target.value);
                        }}
                        error={emailHasValidationError}
                      />
                    </FormControl>
                    <div className="panel-group-field">
                      {emailHasValidationError && (
                        <div className="panel-group-error-text">
                          {emailValidationErrorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="company-name">
                  <div className="panel-content-group">
                    <div className="panel-group-label">Company Name</div>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        inputProps={{ className: "contact-us-companyName"}}
                        value={companyName}
                        placeholder="Type in your company name"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </FormControl>
                    <div className="panel-group-field"></div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="message">
                  <div className="panel-content-group">
                    <div
                      className={
                        messageHasValidationError
                          ? "panel-group-label-error"
                          : "panel-group-label"
                      }
                    >
                      Message*
                    </div>
                    <FormControl fullWidth>
                      <textarea
                        style={{
                          border: messageHasValidationError
                            ? "1px solid #AA0000"
                            : "",
                        }}
                        maxLength={2000}
                        className="contact-us-message"
                        value={message}
                        placeholder="Write your message..."
                        onChange={(e) => {
                          setMessageHasValidationError(false);
                          setMessage(e.target.value);
                        }}
                      />
                    </FormControl>
                    <div className="panel-group-field">
                      {messageHasValidationError && (
                        <div className="panel-group-error-text">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} className="reCaptcha">
                  <ReCAPTCHA
                    sitekey={appSettings.GoogleRecaptchaSiteKey}
                    onChange={onRecaptchaChange}
                    onExpired={onRecaptchaExpiry}
                  />
                  <div className="panel-group-field">
                      {recaptchaValidationError && (
                         <div className="panel-content-group">
                        <div className="panel-group-error-text">
                          {recaptchaValidationErrorMessage}
                        </div>
                        </div>
                      )}
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4} className="send-button">
                  <button
                    className="default-button filled  fullWidth contact-us-button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Send
                  </button>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className="cancel-button">
                  <button
                    className="default-button outline fullWidth contact-us-button"
                    onClick={(e) => handleCancel(e)}
                  >
                    Cancel
                  </button>
                </Grid>

                <Grid item xs={12} className="error-grid">
                  {displayEmailError && (
                    <div className="email-failed-error">
                      Unable to send your message. Please try to send the
                      message again.
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
