import { Button, Dialog, DialogContent, Typography } from '@cin7/ui';
import React, { useEffect, useRef, useState } from 'react';
import { OrderNotesModalContent } from '../../../Language/Default_Settings';
import { StripHTMLFromInputs } from '../../../Utils/B2BHelpers';

import './Styles/_AddNoteModal.scss';

export interface AddNoteModalProps {
    onCloseClick: VoidFunction;
    updateNote: (note: string) => void;
    deleteNote: () => void;
    note?: string;
}

export const AddNoteModal: React.FC<AddNoteModalProps> = (props) => {
    const {note = ""} = props;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [currentNote, setNote] = useState(note)

    useEffect(() => {
        textareaRef.current?.focus();
    }, [textareaRef])

    useEffect(() => {
        setNote(note);
    }, [note, setNote]);

    const updateNote = (note: string) => {
        if (note === "") props.deleteNote();
        else props.updateNote(StripHTMLFromInputs(note));
    }

    return (
        <Dialog fullWidth maxWidth="xs" open onClose={(e, reason) => props.onCloseClick()} className="add-note-modal">
            <DialogContent className="add-note-modal__content">
                <Typography variant="h1">{!!props.note ? OrderNotesModalContent.default_editNoteHeader : OrderNotesModalContent.default_addNoteHeader}</Typography>
                <textarea ref={textareaRef} data-testid="add-note-modal" className="add-note-modal__notes" maxLength={250} onChange={(e) => setNote(e.target.value)} value={currentNote} autoFocus />
                <div className="add-note-modal__action-buttons">
                    <Button variant="primary" onClick={() => {updateNote(currentNote); props.onCloseClick();}}>{OrderNotesModalContent.default_saveNoteButtonLabel}</Button>
                    <Button variant="secondary" onClick={props.onCloseClick}>{OrderNotesModalContent.default_cancelButtonLabel}</Button>
                    <Button variant="secondary" onClick={() => {props.deleteNote(); props.onCloseClick();}}>{OrderNotesModalContent.default_removeNoteButtonLabel}</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}