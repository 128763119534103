import React, { Component, SyntheticEvent } from "react";
import Cin7FormHeading from "./../../../Components/PageElements/Cin7FormHeading";
import SidePageBanner from "../../../Components/PageElements/Login/SidePageBanner";
import ErrorCrossIcon from "./../../../Images/Icons/ErrorCrossIcon";

interface componentProps {
    message?: string;
    hideBanner?: boolean;
    tryAgainAction?: (e: SyntheticEvent) => void;
}

class FailedView extends Component<componentProps, {}> {
    componentDidMount() {
        document.title = "Cin7 B2B - Oops!";
    }

    render() {
        const { message, hideBanner, tryAgainAction } = this.props;

        const FailedViewNotice: any = () => {
            return (
                <div className="side-image-theme-content">
                    <div className="side-image-theme-header">
                        <Cin7FormHeading
                            pageTitle={"Oh no!"}
                            pageTitleClass={"failed-message"}
                            pageSubtitle={message ? message : "Something went wrong."}
                            pageSubtitleClass={"failed-subtitle"}
                        />
                    </div>
                    <div className="side-image-theme-body">
                        <div className="side-image-theme-body-icon">
                            <ErrorCrossIcon />
                        </div>
                    </div>
                    <div className="side-image-theme-footer no-logo">
                        {tryAgainAction && (
                            <div className="cin7-form">
                                <div className="cin7-form-group form-group">
                                    <button
                                        type="submit"
                                        className="cin7-button default btn btn-secondary"
                                        onClick={(ev) => this._runAction(tryAgainAction, ev)}
                                    >
                                        Try Again
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        };

        if (hideBanner) {
            return <FailedViewNotice />;
        } else {
            return (
                <div id="Side-Image-Theme">
                    <div className="side-image-theme-container">
                        <SidePageBanner />
                        <FailedViewNotice />
                    </div>
                </div>
            );
        }
    }

    _runAction = (action: any, e: SyntheticEvent) => {
        e.preventDefault();
        action(e);
    };
}

export default FailedView;
