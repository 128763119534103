import React from 'react';

export const PayOnlinePaymentIcon: React.FC = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Pay Online</title>
            <path fillRule="evenodd" clipRule="evenodd" d="M40.2659 11.3181H46.5079C47.0601 11.3181 47.5079 11.7658 47.5079 12.3181C47.5079 12.8704 47.0601 13.3181 46.5079 13.3181H40.2735C39.6873 13.3275 39.1087 13.4518 38.5704 13.6841C38.03 13.9172 37.5409 14.2546 37.1309 14.6769C36.721 15.0991 36.3982 15.598 36.1811 16.1451C35.964 16.6921 35.8569 17.2766 35.8657 17.865L35.8661 17.8866C35.8606 18.7264 36.0858 19.5515 36.5175 20.2719C36.9484 20.9911 37.5685 21.5782 38.31 21.9693L43.986 24.8954C45.0531 25.4576 45.9452 26.3021 46.5652 27.3366C47.1834 28.3682 47.5073 29.5492 47.5019 30.7517C47.5133 31.5993 47.3582 32.4409 47.0456 33.2289C46.7316 34.0201 46.2647 34.7418 45.6717 35.3525C45.0787 35.9633 44.3712 36.4513 43.5896 36.7885C42.808 37.1258 41.9675 37.3057 41.1163 37.318L41.1019 37.3182L34.8719 37.3181C34.3196 37.3181 33.8719 36.8704 33.8719 36.3181C33.8719 35.7658 34.3196 35.3181 34.8719 35.3181H41.0942C41.6804 35.3087 42.259 35.1844 42.7973 34.9521C43.3377 34.719 43.8268 34.3816 44.2368 33.9594C44.6467 33.5371 44.9695 33.0382 45.1866 32.4912C45.4037 31.9441 45.5108 31.3597 45.502 30.7712L45.5016 30.75C45.5068 29.9102 45.2814 29.0851 44.8497 28.3647C44.4187 27.6456 43.7988 27.0583 43.0575 26.6668L37.3821 23.7411C36.3146 23.1792 35.422 22.3347 34.8019 21.3C34.1837 20.2683 33.8599 19.087 33.8658 17.8844C33.8545 17.0368 34.0095 16.1953 34.3222 15.4074C34.6362 14.6161 35.103 13.8945 35.696 13.2837C36.289 12.6729 36.9965 12.185 37.7781 11.8477C38.5598 11.5105 39.4002 11.3306 40.2514 11.3182L40.2659 11.3181Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.5078 6.31787C42.0601 6.31787 42.5078 6.76559 42.5078 7.31787V12.3179C42.5078 12.8702 42.0601 13.3179 41.5078 13.3179C40.9555 13.3179 40.5078 12.8702 40.5078 12.3179V7.31787C40.5078 6.76559 40.9555 6.31787 41.5078 6.31787Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.5078 35.3179C42.0601 35.3179 42.5078 35.7656 42.5078 36.3179V40.6819C42.5078 41.2342 42.0601 41.6819 41.5078 41.6819C40.9555 41.6819 40.5078 41.2342 40.5078 40.6819V36.3179C40.5078 35.7656 40.9555 35.3179 41.5078 35.3179Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.50781 13.3181C4.71216 13.3181 3.9491 13.6342 3.38649 14.1968C2.82388 14.7594 2.50781 15.5225 2.50781 16.3181V36.3181C2.50781 37.1138 2.82388 37.8768 3.38649 38.4394C3.9491 39.002 4.71216 39.3181 5.50781 39.3181H29.5078C30.0601 39.3181 30.5078 39.7658 30.5078 40.3181C30.5078 40.8704 30.0601 41.3181 29.5078 41.3181H5.50781C4.18173 41.3181 2.90996 40.7913 1.97228 39.8536C1.0346 38.916 0.507812 37.6442 0.507812 36.3181V16.3181C0.507812 14.992 1.0346 13.7203 1.97228 12.7826C2.90996 11.8449 4.18173 11.3181 5.50781 11.3181H27.5078C28.0601 11.3181 28.5078 11.7658 28.5078 12.3181C28.5078 12.8704 28.0601 13.3181 27.5078 13.3181H5.50781Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.507812 20.3181C0.507812 19.7658 0.955528 19.3181 1.50781 19.3181H27.5078C28.0601 19.3181 28.5078 19.7658 28.5078 20.3181C28.5078 20.8704 28.0601 21.3181 27.5078 21.3181H1.50781C0.955528 21.3181 0.507812 20.8704 0.507812 20.3181Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.50781 26.3181C6.50781 25.7658 6.95553 25.3181 7.50781 25.3181H23.5078C24.0601 25.3181 24.5078 25.7658 24.5078 26.3181C24.5078 26.8704 24.0601 27.3181 23.5078 27.3181H7.50781C6.95553 27.3181 6.50781 26.8704 6.50781 26.3181Z" fill="#00326B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.50781 30.3179C6.50781 29.7656 6.95553 29.3179 7.50781 29.3179H13.5078C14.0601 29.3179 14.5078 29.7656 14.5078 30.3179C14.5078 30.8702 14.0601 31.3179 13.5078 31.3179H7.50781C6.95553 31.3179 6.50781 30.8702 6.50781 30.3179Z" fill="#00326B" />
        </svg>
    );
}
