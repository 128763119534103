import React, {Component, SyntheticEvent} from 'react';
import ProductGalleryModal from './ProductGalleryModal';
import { GetProductImageUrl } from '../../../Utils/ProductsHelper';
import Image from '../../Global/Image';
import NextArrowIcon from '../../../Images/Icons/NavigationIcons/NextArrowIcon';
import PrevArrowIcon from '../../../Images/Icons/NavigationIcons/PrevArrowIcon';

//Styles
import './Styles/_ProductGallery.scss';

interface componentProps {
    productImages: any[];
}
 
interface componentState {
    imagePath: string;
    activeModal: boolean;
    selectedImage: number;
}
 
class ProductGallery  extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            imagePath: "",
            activeModal: false,
            selectedImage: 0
        };
    }

    componentDidMount(){
        if(this.props.productImages.length > 0) {
            let imageUrl = GetProductImageUrl(this.props.productImages[0].full);

            this.setState({
                imagePath: imageUrl
            });
        }
    }
    
    render() { 

        const {productImages} = this.props;
        const { imagePath, activeModal, selectedImage } = this.state;
        const totalImages = productImages.length - 1;

        const productOptions = productImages.map((item: any, index: number) => {
            let imageIndex = index + 1;
            let productThumbImageUrl = GetProductImageUrl(item.thumbnail);
            let productImageUrl = GetProductImageUrl(item.full);

            return (
                <div id="productImages" className={`product-options view-${imageIndex} ${index === 0 ? "active" : "inactive"}`} image-index={index} key={index} onClick={(e) => {this._switchImageView(e, productImageUrl)}}>
                    {<Image imagePath={productThumbImageUrl} altText={`product-${index}`} />}
                </div>
            )
        });

        const galleryModalProps = { 
            closeModal: this._toggleGalleryView,
            prevImageClick: this._prevImageClick,
            prevImage: this._prevImage,
            nextImageClick: this._nextImageClick,
            nextImage: this._nextImage,
            galleryImages: productImages,
            currentImage: selectedImage,
        }

        if (window.innerWidth > 768) {
            return ( 
                <div className="product-details-gallery">
                    <div id="Product-Gallery-Main" className="product-main-image">
                        <div id="Main-Image-View" onClick={this._toggleGalleryViewClick}>
                            <Image imagePath={imagePath} altText={`main-product-view`} />
                        </div>
                    </div>
                    <div id="Product-Gallery-Options" className={`product-variant-images ${productImages.length <= 2 ? "left-align" : "default"}`}>
                        {productOptions}
                    </div>
                    {activeModal && <ProductGalleryModal _props={galleryModalProps} />}
                </div>
            );
        }
        else {
            return ( 
                <div className="product-details-gallery">
                    <div id="Product-Gallery-Main" className="product-main-image">

                        <div className="prev-image" onClick={this._prevImageClick}>
                            {selectedImage !== 0 && <PrevArrowIcon />}
                        </div>
                        <div id="Main-Image-View" onClick={this._toggleGalleryViewClick}>
                            <Image imagePath={imagePath} altText={`main-product-view`} />
                        </div>
                        <div className="next-image" onClick={this._nextImageClick}>
                            {selectedImage < totalImages && <NextArrowIcon />}
                        </div>
                        <div id="Product-Gallery-Options-Mobile">
                            {productOptions}
                        </div>
                    </div>
                    {activeModal && <ProductGalleryModal _props={galleryModalProps} />}
                </div>
            );
         }
    }

    _switchImageView = (e:SyntheticEvent, productImage:string) => {
        e.preventDefault();
        let clickedSrcImage = productImage;
        let currentIndex = e.currentTarget.getAttribute('image-index');
        let thumbnailElements = document.querySelectorAll('.product-options.active');

        if(thumbnailElements) {
            thumbnailElements.forEach((item) => {
                if(!e.currentTarget.classList.contains('active')) {
                    item.classList.remove('active');
                    item.classList.add('inactive');
                }
            });
        }

        if(clickedSrcImage && currentIndex) {
            if(clickedSrcImage !== this.state.imagePath) {
                this.setState({
                    imagePath: clickedSrcImage,
                    selectedImage: parseInt(currentIndex)
                });

                e.currentTarget.classList.remove('inactive');
                e.currentTarget.classList.add('active');
            }
        }
    }

    _toggleGalleryViewClick = (e:SyntheticEvent) => {
        e.preventDefault();
        this._toggleGalleryView();
    }

    _toggleGalleryView = () => {
        this.setState(prevState => ({
            activeModal: !prevState.activeModal
        }));
    }

    _prevImageClick = (e:SyntheticEvent) => {
        e.preventDefault();
        this._prevImage();
    }

    _prevImage = () => {
        let currentIndex = this.state.selectedImage;

        if(currentIndex > 0) {
            let currentImages = document.querySelectorAll('#productImages');
            this.setState(prevState => ({
                selectedImage: prevState.selectedImage - 1,
                imagePath: GetProductImageUrl(this.props.productImages[prevState.selectedImage - 1].full)
            }));

            if(currentImages) {
                currentImages.forEach((item) => {
                    if(item.classList.contains('active')) {
                        item.classList.toggle('active');
                        item.classList.toggle('inactive');
                    }

                    let imageIndex = item.getAttribute('index-item');

                    if(imageIndex) {
                        if(parseInt(imageIndex) === (currentIndex - 1)) {
                            item.classList.toggle('active');
                            item.classList.toggle('inactive');
                        }
                    }
                })
            }
        }        
    }

    _nextImageClick = (e:SyntheticEvent) => {
        e.preventDefault();
        this._nextImage();
    }

    _nextImage = () => {
        let currentIndex = this.state.selectedImage;
        let currentImageCount = this.props.productImages.length-1;

        if(currentIndex < currentImageCount) {
            let currentImages = document.querySelectorAll('#productImages');
            this.setState(prevState => ({
                selectedImage: prevState.selectedImage + 1,
                imagePath: GetProductImageUrl(this.props.productImages[this.state.selectedImage + 1].full)
            }));

            if(currentImages) {
                currentImages.forEach((item) => {

                    if(item.classList.contains('active')) {
                        item.classList.toggle('active');
                        item.classList.toggle('inactive');
                    }

                    let imageIndex = item.getAttribute('index-item');

                    if(imageIndex) {
                        if(parseInt(imageIndex) === (currentIndex + 1)) {
                            item.classList.toggle('active');
                            item.classList.toggle('inactive');
                        }
                    }
                })
            }
        }        
    }
}
 
export default ProductGallery;