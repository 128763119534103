import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RemoveOrderProduct, CalculatePromotions } from '../../../Redux/Actions/OrderActions';
import { ShowProductDetails } from '../../../Redux/Actions/ProductDetailsActions';
import OrderDetailProductOption from './OrderDetailProductOption';
import PriceDisplay from '../../Global/PriceDisplay';
import { GetProductImageUrl, GroupByKey, GetTotalProductOptionPrice } from '../../../Utils/ProductsHelper';
import Image from '../../Global/Image';
import RemoveIcon from '../../../Images/Icons/Orders/RemoveIcon';

import './Styles/_OrderDetailProductTile.scss';

interface componentProps {
    item: any;
    RemoveOrderProduct: (productId: number, optionId: number | null, sizeId: number | null, uomId: number | null) => void;
    CalculatePromotions: () => void;
    ShowProductDetails: (id: number) => void;
}

interface componentState { }

class OrderDetailProductTile extends Component<componentProps, componentState> {

    render() {
        const { item, ShowProductDetails } = this.props;

        let product = item.product;

        let productImageUrl = GetProductImageUrl('');

        if (product.images && product.images[0]) {
            productImageUrl = GetProductImageUrl(product.images[0].thumbnail);
        }

        let totalProductPrice = 0;
        
        item.productOptions.forEach((options: { price: number; specialPrice: number; productQuantity: number; }) => {
            totalProductPrice += GetTotalProductOptionPrice(options);
        });

        let hasMultipleOptions = Array.from(item.productOptions).length > 1;

        const hasUomOptions = item.productOptions.find((option: any) => option.uomId > 0) !== undefined;

        let groupedOptions = hasUomOptions ? GroupByKey(item.productOptions, (itemOptions: any) => {
            return [itemOptions.optionId, itemOptions.option1]
        }) : GroupByKey(item.productOptions, (itemOptions: any) => {
            return [itemOptions.option1]
        });

        let hasMultipleOptionGroups = Array.from(groupedOptions).length > 1;
        const isSizeOption = item.productOptions[0]?.sizeId !== null;

        return (
            <div className="product-tile" key={item.productId}>
                <div className="product-tile-inner">
                    <div className="product-tile-inner-details-container">
                        <div className="product-image-container" onClick={() => ShowProductDetails(item.productId)}>
                            <span className="product-image">
                                <Image imagePath={productImageUrl} altText={`${product.styleCode}`} />
                            </span>
                        </div>
                        <div className="product-detail-container">
                            <div className="product-title" onClick={() => ShowProductDetails(item.productId)}>
                               {product.name}
                            </div>
                            <span className="product-code">{product.styleCode}</span>
                        </div>
                        <div className="product-action-container">
                            <span className="product-cart-delete" onClick={this._removeCartProduct}>
                                <RemoveIcon fillColor='#808081' />
                            </span>
                        </div>
                    </div>
                    <div className="product-options">
                        <div className="product-options-section">
                            <div className={`product-options-container ${hasMultipleOptions ? 'multiple-options' : ''}`}>
                                {groupedOptions.map((option: any) => (
                                    <OrderDetailProductOption key={`${option[0].optionId}${option[0].sizeId ?? option[0].uomId}`} isSizeOption={isSizeOption} product={product} optionGroup={option} hasMultipleOptions={hasMultipleOptions} hasMultipleOptionGroups={hasMultipleOptionGroups} />
                                ))}
                            </div>
                        </div>
                        {hasMultipleOptions && 
                            <div className="product-total-container">
                                <div className="product-total-items">Subtotal</div>
                                <div className="product-total-price">{<PriceDisplay value={totalProductPrice} />}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    _removeCartProduct = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.RemoveOrderProduct(this.props.item.productId, null, null, null);
        this.props.CalculatePromotions();
    }
}

const MapStateToProps = (state: { orderReducer: any }) => ({
    order: state.orderReducer
});

export default connect(MapStateToProps, { RemoveOrderProduct, CalculatePromotions, ShowProductDetails })(OrderDetailProductTile);