import React, { Component } from 'react';
import EmptyBox from '../../../Images/Icons/BoxIcons/EmptyBox';

import './Styles/_ProductNotFound.scss';

interface componentProps {
    message: string;
}

class ProductNotFound extends Component<componentProps, {}> {

    render() {
        const { message } = this.props;

        return (
            <div id="Product-Not-Found-Container">
                <EmptyBox strokeColor={'#212121'} />
                <h2 className="product-not-found-message">{message}</h2>
            </div>
        );
    }
}
 
export default ProductNotFound;