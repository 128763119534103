import * as types from "../../Types/IdentityTypes";
import * as authTypes from "../../Types/AuthTypes";
import * as text from "../../../Language/Default_Settings";
import { Redirect_To_Login_Page } from "../../Types/GlobalTypes";

export type IdentityStateType = {
    displayErrorView: boolean;
    emailAddress: string;
    storeName: string;
    errorMessage: string;
    forgotPasswordButtonLabel: string;
    isAuthenticating: boolean;
    isFieldValidationActive: boolean;
    isForgotPasswordLinkSent: boolean;
    isForgotPasswordValidationActive: boolean;
    isResetPasswordValidationActive: boolean;
    isResetPasswordCompleted: boolean;
    isSendingPasswordLink: boolean;
    isUpdatingPassword: boolean;
    isWarningMessageActive: boolean;
    isWarningValidationActive: boolean;
    isErrorMessageActive: boolean;
    isErrorValidationActive: boolean;
    loginButtonLabel: string;
    loginErrorMessage: string;
    resetPasswordButtonLabel: string;
    createAccountButtonLabel: string;
    isCreatePasswordCompleted: boolean;
    isRegistrationValidationActive: boolean;
    registrationErrorMessage: string;
    forgotPasswordErrorMessage: string;
    shouldRedirect: boolean;
    storeDomain: string;
    storeModuleId: 0;
    userId: string;
    userToken: string;
    emailToken: string;
    isEmailConfirmed: boolean;
    userInfo: {};
    branchInfo: {};
    moduleSettings: {
        termsAndConditionsContent: string;
        customPage1Title: string;
        customPage1Content: string;
        customPage2Title: string;
        customPage2Content: string;
        checkoutSummaryText: string;
        customHomepage: CustomHomepageType | null;
        productRetailPriceTier: RetailPriceTier;
        // more missing from here
    };
    isGettingUserDetailsAndStatus: boolean;
    displayReturnToLogin: boolean;
    tenantName: string;
    userStatus: number;
    userDetailsFetched: boolean;
};

export type PriceTier = {
    currencyCode: string;
    taxStatus: string;
    defaultTaxRate: number;
    compareToSpecialPrice: boolean;
}

export type CustomHomepageType = {
    enabled: boolean;
    banner: CustomHomepageBannerType | null;
    featuredCategoriesHeading: string | null;
    featuredCategories: FeaturedCategoryType[] | null;
}

export type CustomHomepageBannerType = {
    imageName: string;
    imageLink: string;
    imageAltText: string;
}

export type FeaturedCategoryType = {
    categoryID: number;
    imageName: string;
    imageAltText: string;
    sort: number;
}

export type RetailPriceTier = {
    label: string;
    value: string;
    currencyCode: string;
}

export const initialState: IdentityStateType = {
    displayErrorView: false,
    emailAddress: "",
    storeName: "",
    errorMessage: "",
    forgotPasswordButtonLabel: text.ForgotPasswordContent.default_buttonLabel,
    isAuthenticating: false,
    isFieldValidationActive: false,
    isForgotPasswordLinkSent: false,
    isForgotPasswordValidationActive: false,
    isResetPasswordValidationActive: false,
    isResetPasswordCompleted: false,
    isSendingPasswordLink: false,
    isUpdatingPassword: false,
    isWarningMessageActive: false,
    isWarningValidationActive: false,
    isErrorMessageActive: false,
    isErrorValidationActive: false,
    loginButtonLabel: text.LoginPageContent.default_buttonLabel,
    loginErrorMessage: "",
    resetPasswordButtonLabel: text.ResetPasswordContent.default_buttonLabel,
    createAccountButtonLabel: text.RegistrationPageContent.default_buttonLabel,
    isCreatePasswordCompleted: false,
    isRegistrationValidationActive: false,
    registrationErrorMessage: "",
    forgotPasswordErrorMessage: "",
    shouldRedirect: false,
    storeDomain: "", //Do we need this? maybe
    storeModuleId: 0,
    userId: "",
    userToken: "",
    emailToken: "",
    isEmailConfirmed: false,
    userInfo: {},
    branchInfo: {},
    moduleSettings: {
        termsAndConditionsContent: "",
        customPage1Title: "",
        customPage1Content: "",
        customPage2Title: "",
        customPage2Content: "",
        checkoutSummaryText: "",
        customHomepage: null,
        productRetailPriceTier: {
            label: "",
            value: "",
            currencyCode: ""
        }
    },
    isGettingUserDetailsAndStatus: false,
    displayReturnToLogin: false,
    tenantName: "",
    userStatus: 0,
    userDetailsFetched: false,
};

export const IdentityReducer = (
    state = initialState,
    action: { type: string; payload: any | undefined }
): IdentityStateType => {
    switch (action.type) {
        //Login Page
        case types.Authenticating_User:
            return {
                ...state,
                isAuthenticating: true,
                isFieldValidationActive: false,
                isWarningMessageActive: false,
                isErrorMessageActive: false,
                loginButtonLabel: text.LoginPageContent.isAuthenticating_buttonLabel,
                loginErrorMessage: "",
            };
        case types.Authentication_Warning:
            return {
                ...state,
                isAuthenticating: false,
                isFieldValidationActive: false,
                isWarningMessageActive: true,
                isErrorMessageActive: false,
                loginButtonLabel: text.LoginPageContent.default_buttonLabel,
                loginErrorMessage: action.payload.message,
            };
        case types.Authentication_Error:
            return {
                ...state,
                isAuthenticating: false,
                isFieldValidationActive: false,
                isWarningMessageActive: false,
                isErrorMessageActive: true,
                loginButtonLabel: text.LoginPageContent.default_buttonLabel,
                loginErrorMessage: action.payload.message,
            };
        case types.Login_Validation_Inputs_Failed:
            return {
                ...state,
                isFieldValidationActive: true,
                loginButtonLabel: text.LoginPageContent.default_buttonLabel,
            };
        case types.Login_Email_Address_Not_Valid:
            return {
                ...state,
                isAuthenticating: false,
                isFieldValidationActive: true,
                loginButtonLabel: text.LoginPageContent.default_buttonLabel,
            };
        case types.Login_Reset_Initial_State:
            return {
                ...state,
                isAuthenticating: false,
                isFieldValidationActive: false,
                loginButtonLabel: text.LoginPageContent.default_buttonLabel,
            };
        case types.Login_Get_User_Details:
            return {
                ...state,
                userId: action.payload.userId,
                storeModuleId: action.payload.moduleId,
                userInfo: action.payload.userInfo,
                branchInfo: action.payload.branchInfo,
                moduleSettings: action.payload.moduleSettings,
                tenantName: action.payload.tenantName,
                userDetailsFetched: true,
            };
        case types.Login_Reset_User_Details:
            return {
                ...state,
                userId: initialState.userId,
                storeModuleId: initialState.storeModuleId,
                userInfo: initialState.userInfo,
                branchInfo: initialState.branchInfo,
                moduleSettings: initialState.moduleSettings,
                tenantName: initialState.tenantName,
                userDetailsFetched: initialState.userDetailsFetched,
            };
        //Forgot Password Page
        case types.ForgotPassword_Send_Reset_Link:
            return {
                ...state,
                emailAddress: action.payload.emailAddress,
                isForgotPasswordLinkSent: action.payload.linkSent,
            };
        case types.ForgotPassword_Sending_Link:
            return {
                ...state,
                isSendingPasswordLink: true,
                forgotPasswordButtonLabel: text.ForgotPasswordContent.isSending_buttonLabel,
                forgotPasswordErrorMessage: "",
            };
        case types.ForgotPassword_Fail_Sending_Link:
            return {
                ...state,
                isSendingPasswordLink: false,
                forgotPasswordButtonLabel: text.ForgotPasswordContent.default_buttonLabel,
                forgotPasswordErrorMessage: action.payload.errorMessage,
            };
        case types.ForgotPassword_Email_Address_Not_Valid:
            return {
                ...state,
                isForgotPasswordValidationActive: true,
            };
        case types.ForgotPassword_Clear_State:
            return {
                ...state,
                isSendingPasswordLink: initialState.isSendingPasswordLink,
                isForgotPasswordLinkSent: initialState.isForgotPasswordLinkSent,
                forgotPasswordButtonLabel: initialState.forgotPasswordButtonLabel,
                forgotPasswordErrorMessage: "",
            };
        //Reset Pasword Page
        case types.ResetPassword_Updating_Password:
            return {
                ...state,
                isUpdatingPassword: true,
                resetPasswordButtonLabel: text.ResetPasswordContent.isUpdating_buttonLabel,
            };
        case types.ResetPassword_Password_Updated: {
            return {
                ...state,
                emailAddress: action.payload.emailAddress,
                isResetPasswordCompleted: action.payload.isResetPasswordCompleted,
                isUpdatingPassword: false,
                resetPasswordButtonLabel: text.ResetPasswordContent.default_buttonLabel,
                storeDomain: action.payload.storeDomain,
            };
        }
        case types.ResetPassword_Update_Password_Failed:
            return {
                ...state,
                isUpdatingPassword: false,
                resetPasswordButtonLabel: text.ResetPasswordContent.default_buttonLabel,
                errorMessage: action.payload.message,
                displayReturnToLogin: action.payload.displayReturnToLogin,
            };
        case types.ResetPassword_Password_Validation_Failed:
            return {
                ...state,
                isResetPasswordValidationActive: true,
            };
        case types.ResetPassword_Clear_State:
            return {
                ...state,
                isUpdatingPassword: initialState.isUpdatingPassword,
                isResetPasswordCompleted: initialState.isResetPasswordCompleted,
                resetPasswordButtonLabel: initialState.resetPasswordButtonLabel,
                errorMessage: "",
                displayReturnToLogin: initialState.displayReturnToLogin,
            };
        //Registration Page
        case types.Registration_Getting_User_Details: {
            return {
                ...state,
                isGettingUserDetailsAndStatus: true,
            };
        }
        case types.Registration_Get_Registered_User_Details: {
            return {
                ...state,
                userId: action.payload.userId,
                emailAddress: action.payload.emailAddress,
                storeName: action.payload.storeName,
                userToken: action.payload.userToken,
                storeModuleId: action.payload.storeModuleId,
            };
        }
        case types.Registration_Getting_User_Details_Failed: {
            return {
                ...state,
                isGettingUserDetailsAndStatus: false,
            };
        }
        case types.Registration_Confirm_User_Email: {
            return {
                ...state,
                isEmailConfirmed: action.payload.isEmailConfirmed,
            };
        }
        case types.Registration_Confirm_User_Email_Failed:
            return {
                ...state,
                isEmailConfirmed: false,
            };
        case types.Registration_Updating_Password:
            return {
                ...state,
                isUpdatingPassword: true,
                isFieldValidationActive: false,
                isRegistrationValidationActive: false,
                isWarningValidationActive: false,
                createAccountButtonLabel: text.RegistrationPageContent.process_buttonLabel,
            };
        case types.Registration_Password_Updated: {
            return {
                ...state,
                isFieldValidationActive: false,
                isCreatePasswordCompleted: action.payload.isCreatePasswordCompleted,
                isUpdatingPassword: false,
                createAccountButtonLabel: text.RegistrationPageContent.default_buttonLabel,
                storeDomain: action.payload.storeDomain,
            };
        }
        case types.Registration_Update_Password_Failed:
            return {
                ...state,
                isUpdatingPassword: false,
                isFieldValidationActive: false,
                isRegistrationValidationActive: false,
                isWarningValidationActive: true,
                registrationErrorMessage: action.payload.message,
                createAccountButtonLabel: text.RegistrationPageContent.default_buttonLabel,
                displayReturnToLogin: action.payload.displayReturnToLogin,
            };
        case types.Registration_Password_Validation_Failed:
            return {
                ...state,
                isFieldValidationActive: true,
                isRegistrationValidationActive: false,
                isWarningValidationActive: false,
            };
        case Redirect_To_Login_Page:
            return {
                ...state,
                shouldRedirect: action.payload.shouldRedirect,
            };
        case authTypes.Auth_Errors_Clear_State:
            return {
                ...state,
                isAuthenticating: initialState.isAuthenticating,
                loginButtonLabel: initialState.loginButtonLabel,
                errorMessage: initialState.errorMessage,
                isWarningValidationActive: initialState.isWarningValidationActive,
                isWarningMessageActive: initialState.isWarningMessageActive,
                isErrorMessageActive: initialState.isErrorMessageActive,
                loginErrorMessage: initialState.loginErrorMessage,
                isRegistrationValidationActive: initialState.isRegistrationValidationActive,
                registrationErrorMessage: "",
                displayReturnToLogin: initialState.displayReturnToLogin,
            };
        case types.Registration_Clear_State:
            return {
                ...state,
                isCreatePasswordCompleted: initialState.isCreatePasswordCompleted,
                shouldRedirect: initialState.shouldRedirect,
            };
        case types.Registration_Get_User_Status:
            return {
                ...state,
                userStatus: action.payload.userStatus,
                isGettingUserDetailsAndStatus: false,
            };
        case types.Registration_Get_User_Status_Failed:
            return {
                ...state,
                isGettingUserDetailsAndStatus: false,
            };
        default:
            return state;
    }
};
