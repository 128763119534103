import React, { Component } from 'react';
import { Form } from 'reactstrap';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginB2BUser, LogoutClearState, ClearIdentityErrorMessages } from '../../Redux/Actions/AuthActions';

import InputField from './../FormElements/InputField';
import PasswordField from './../FormElements/PasswordField';
import ButtonField from './../FormElements/ButtonField';
import Cin7ConfirmationBox from '../PageElements/Cin7ConfirmationBox';
import { GenerateTextInputValidationParameters, ClearFailValidation } from '../../Utils/B2BHelpers';

interface componentProps {
    login: {
        isAuthenticating: boolean;
        isFieldValidationActive: boolean;
        isErrorMessageActive: boolean;
        isWarningMessageActive: boolean;
        loginButtonLabel: string;
        loginErrorMessage: string;
    };
    auth: {
        errorMessage: string;
    }
    LoginB2BUser: (credentials: any) => void;
    ClearIdentityErrorMessages: () => void;
    LogoutClearState: () => void;
}

interface componentStates {
    emailAddressField: string | undefined;
    loginPasswordField: string | undefined;
    logoutMessage: string | undefined;
}

class LoginForm extends Component<componentProps, componentStates> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            emailAddressField: "",
            loginPasswordField: "",
            logoutMessage: this.props.auth.errorMessage
        }
    }

    componentDidMount() {
        this.props.ClearIdentityErrorMessages();
        this.props.LogoutClearState();
    }

    render(){

        const { login } = this.props;

        return(
            <>
            {login.isWarningMessageActive && 
                <Cin7ConfirmationBox 
                    boxType={"warning-box"}
                    boxIcon={"warning-icon"}
                    boxMessage={login.loginErrorMessage}
                />
            }
            {login.isErrorMessageActive && 
                <Cin7ConfirmationBox 
                    boxType={"error-box"}
                    boxIcon={"error-icon"}
                    boxMessage={login.loginErrorMessage}
                />
            }
            {this.state.logoutMessage &&
                <Cin7ConfirmationBox
                    boxType={"info-box"}
                    boxIcon={"info-icon"}
                    boxMessage={this.state.logoutMessage}
                />
            }
            <Form className="cin7-form" onSubmit={this._onSubmit}>
                <InputField 
                    fieldType="email"
                    fieldLabel="Email address" 
                    fieldId="EmailAddress" 
                    fieldIndex={0}
                    fieldName="emailAddressField" 
                    fieldErrorId="emailValidationError"
                    onChange={this._onChange}
                    onClear={this._onClear}
                />
                <PasswordField 
                    fieldLabel="Password" 
                    fieldId="LoginPassword"
                    fieldIndex={0}
                    fieldName="loginPasswordField"
                    fieldErrorId="passwordValidationError"
                    onChange={this._onChange}
                />
                <ButtonField buttonLabel={login.loginButtonLabel} buttonType="submit" disableButton={login.isAuthenticating} />
            </Form>
            <Link to={'/login/forgot-password'} className="login-pages-link">
                Forgot password?
            </Link>
            </>
        );
    }

    _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(this.props.login.isFieldValidationActive) {
            let emailValidationParameters = GenerateTextInputValidationParameters("EmailAddress", "emailValidationError");
            let passwordvalidationParameters = GenerateTextInputValidationParameters("LoginPassword", "passwordValidationError");
            ClearFailValidation(emailValidationParameters.inputField, emailValidationParameters.validationField, null);
            ClearFailValidation(passwordvalidationParameters.inputField, passwordvalidationParameters.validationField, null);
        }
        
        this.setState({
            [e.target.name]: e.target.value
        } as unknown as Pick<componentStates, keyof componentStates>);
    }

    _onClear = () => {
        this.setState({
            emailAddressField: ""
        })
    }

    _onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        let credentials = {
            emailAddress: this.state.emailAddressField,
            password: this.state.loginPasswordField
        }

        this.props.LoginB2BUser(credentials);
        
    }
}

const MapStateToProps = (state: { identityReducer: any; authReducer: any }) => ({
    login: state.identityReducer,
    auth: state.authReducer
});

export default connect(MapStateToProps, {LoginB2BUser,LogoutClearState, ClearIdentityErrorMessages})(LoginForm);