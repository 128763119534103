import axios from "axios";
import APIHost from "../APIHost";
import ApiUtil from "../Utils/ApiUtil";

export interface OrderOption {
    code: string,
    image: null,
    option1: string,
    option2: string,
    option3: string,
    optionId: number,
    price: number,
    quantityOrdered: number,
    productQuantity?: number,
    size: string,
    sizeId: number,
    sort: number,
    specialPrice: number,
    stock: number
}

export interface OrderItem {
    id: number;
    allowOverselling: boolean;
    brand: string;
    currencyCode: string;
    description: string;
    images: [],
    maxPrice: number,
    maxSpecialPrice: number,
    minPrice: number,
    minSpecialPrice: number,
    name: string,
    option1Name: string,
    option2Name: string,
    option3Name: string,
    options: null,
    orderOptions: OrderOption[],
    orderType: string,
    sizeRangeId: number,
    stock: number,
    styleCode: string,
    taxRate: null,
    taxStatus: number
}

export interface FailedReadRecord {
    row: string;
    rowIndex: number;
}

export interface FailedImportRecord {
    sku: string;
    barcode: string;
    size: string;
    quantity: number;
}

export interface BulkImportCSVResponse {
    orderItems: OrderItem[],
    failedReadRecords: FailedReadRecord[],
    failedImportRecords: FailedImportRecord[]
}

export async function BulkImportCSV(file: File): Promise<BulkImportCSVResponse> {
    var headers = new ApiUtil().DefaultHeaders('');

    if (headers === null) {
        throw new Error ('Unauthenticated');
    }

    var fileFormData = new FormData();
    fileFormData.append('CsvFile', file)
    fileFormData.append('ModuleId', new ApiUtil().GetModuleId())
    let result;

    try {
        result = await axios.post<BulkImportCSVResponse>(new APIHost().CheckoutAPI("BulkImportCSV"), fileFormData, headers);
    } catch (e) {
        return Promise.reject(e);
    }

    return result.data;
}