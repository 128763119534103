import * as types from '../Types/IdentityTypes';
import * as globalTypes from './../Types/GlobalTypes';
import * as b2bHelpers from '../../Utils/B2BHelpers';
import APIHost  from '../../APIHost';
import ApiUtil from '../../Utils/ApiUtil';
import { ValidationMessages } from '../../Language/Default_Settings';
import axios from 'axios';
import { RefreshUserToken } from './../Actions/AuthActions';
import { UpdateOrderPaymentMethod } from './OrderActions';

const config = {
    headers: {
        'Content-Type': 'application/json',
    }
};

const host = new APIHost();
const apiUtil = new ApiUtil();

interface loginActionsTypes {
    emailAddress: string;
    password?: string;
}

interface resetPasswordTypes {
    userId: string;
    userToken: string;
    storeModuleId: number | undefined;
}

interface confirmEmailTypes {
    userId: string;
    emailToken: string;
} 

interface registerDetailsTypes extends resetPasswordTypes {}

//Forgot Password Actions
export const SendResetPasswordLink = (credentials: loginActionsTypes) => (dispatch: any) => {
    let resetPasswordLinkData = {
        Email: credentials.emailAddress
    }

    let validationParameters = b2bHelpers.GenerateTextInputValidationParameters("EmailAddress", "emailValidationError");
    let isValidEmail = b2bHelpers.ValidateEmailAddress(credentials.emailAddress);

    if(isValidEmail) {

        dispatch({
            type: types.ForgotPassword_Sending_Link
        });

        axios.post(host.IdentityAPI("ForgotPassword"), resetPasswordLinkData, config).then(res => {

            dispatch({
                type: types.ForgotPassword_Send_Reset_Link,
                payload: {linkSent: res.data.success, emailAddress: credentials.emailAddress}
            });
        }).catch((error) => {
            if (error.message) {
                dispatch({
                    type: types.ForgotPassword_Fail_Sending_Link,
                    payload: {
                        errorMessage: error.message
                    }
                });
            }
            else {
                dispatch({
                    type: types.ForgotPassword_Fail_Sending_Link,
                    payload: {
                        errorMessage: "Something went wrong."
                    }
                });
            }
        });
    }
    else {
        b2bHelpers.DisplayFailValidation(validationParameters.inputField, validationParameters.validationField, null, ValidationMessages.invalid_EmailAddress);
        dispatch({
            type: types.ForgotPassword_Email_Address_Not_Valid,
            payload: {
                errorMessage: "Invalid email address."
            }
        });
    }    
}

//Clear Forgot Password State
export const ForgotPasswordClearState = () => (dispatch: any) => {
    dispatch({
        type: types.ForgotPassword_Clear_State
    });
}

//Reset Password Actions
export const ResetUserPassword = (newPassword: string, passwordScore: number) => (dispatch: any) => {

    let isPasswordAnEmail = b2bHelpers.ValidateEmailAddress(newPassword);
    let validationParameters = b2bHelpers.GeneratePasswordValidationParameters("NewPassword","Reset-Password-Validation", "cin7-password-meter");
    let isStrongPassword = b2bHelpers.ValidateStrongPassword(newPassword, passwordScore);

    if (isStrongPassword && newPassword && !isPasswordAnEmail) {

        let credentials = b2bHelpers.GetUrlParameters(["userId", "userToken", "storeModuleId"]);

        let resetPasswordData = {
            UserId: credentials.userId,
            Code: credentials.userToken,
            Password: newPassword,
            ModuleId: credentials.storeModuleId
        }

        dispatch({
            type: types.ResetPassword_Updating_Password
        });

        axios.post(host.IdentityAPI("ResetPassword"), resetPasswordData, config).then(res => {
            let data = res.data;
            if (data.success) {

                dispatch({
                    type: types.ResetPassword_Password_Updated,
                    payload: { storeDomain: data.storeDomain, isResetPasswordCompleted: data.success, emailAddress: data.emailAddress }
                });
            } else {
                let displayReturnToLogin = false;
                let errorDescription = data.errors[0].description;
                let errorCode = data.errors[0].code;

                if (errorCode === "InvalidToken") {
                    displayReturnToLogin = true;
                }

                dispatch({
                    type: types.ResetPassword_Update_Password_Failed,
                    payload: {
                        message: errorDescription,
                        displayReturnToLogin: displayReturnToLogin
                    }
                });
            }
        }).catch((error) => {
            let errorDescription = error.response?.data.errors[0].description;
            dispatch({
                type: types.ResetPassword_Update_Password_Failed,
                payload: {
                    message: errorDescription
                }
            });
        });
    }
    else if (isPasswordAnEmail === true) {
        b2bHelpers.DisplayFailValidation(validationParameters.inputField, validationParameters.validationField, validationParameters.passwordMeterContainer, ValidationMessages.error_emailAsPassword);
        dispatch({
            type: types.ResetPassword_Password_Validation_Failed
        });
    } else {
        b2bHelpers.DisplayFailValidation(validationParameters.inputField, validationParameters.validationField, validationParameters.passwordMeterContainer, ValidationMessages.error_PasswordValidation);
        dispatch({
            type: types.ResetPassword_Password_Validation_Failed
        });
    }

}

//Clear Reset Password State
export const ResetPasswordClearState = () => (dispatch: any) => {
    dispatch({
        type: types.ResetPassword_Clear_State
    });
}

//Confirm Email Actions
export const ConfirmUserEmail = (credentials: confirmEmailTypes) => (dispatch: any) => {

    if(credentials.userId) {

        let confirmEmailData = {
            UserId: credentials.userId,
            EmailToken: credentials.emailToken
        }

        axios.post(host.IdentityAPI("ConfirmEmail"), confirmEmailData, config).then(res => {
            let data = res.data;
            dispatch({
                type: types.Registration_Confirm_User_Email,
                payload: {emailConfirmed: data.emailConfirmed}
            });
        });
    }

    else {
        dispatch({
            type: types.Registration_Confirm_User_Email_Failed,
            payload: {emailConfirmed: false}
        });
    }
}

//Registration Actions
export const GetRegisteredUserDetails = (credentials: registerDetailsTypes) => (dispatch: any) => {

    if (credentials.userId) {

        dispatch({type: types.Registration_Getting_User_Details});

        let getUserEmail = {
            UserId: credentials.userId,
            ModuleId: credentials.storeModuleId
        }

        axios.post(host.IdentityAPI("GetRegisterDetails"), getUserEmail, config).then(res => {
            let data = res.data;

            dispatch({
                type: types.Registration_Get_Registered_User_Details,
                payload: {
                    emailAddress: data.emailAddress,
                    storeName: data.storeName,
                    userId: credentials.userId,
                    userToken: credentials.userToken,
                    storeModuleId: credentials.storeModuleId
                }
            });
        }).catch(() => {
            dispatch({
                type: types.Registration_Getting_User_Details_Failed
            });
        });
    }
    else {
        dispatch({
            type: globalTypes.Redirect_To_Login_Page,
            payload: {
                shouldRedirect: true
            }
        });
    }
}

export const CreatePassword = (emailAddress: string, newPassword: string, passwordScore: number) => (dispatch: any) => {
    let isPasswordAnEmail = b2bHelpers.ValidateEmailAddress(newPassword);
    let validationParameters = b2bHelpers.GeneratePasswordValidationParameters("NewPassword", "Reset-Password-Validation", "cin7-password-meter");
    let isStrongPassword = b2bHelpers.ValidateStrongPassword(newPassword, passwordScore);


    if (isStrongPassword && newPassword && !isPasswordAnEmail) {

        let createPasswordData = {
            Email: emailAddress,
            Password: newPassword
        }

        dispatch({
            type: types.Registration_Updating_Password
        });

        axios.post(host.IdentityAPI("CreatePassword"), createPasswordData, config).then(res => {
            let data = res.data;
            if (data.success) {
                dispatch({
                    type: types.Registration_Password_Updated,
                    payload: { isCreatePasswordCompleted: data.success }
                });
            } else {
                let displayReturnToLogin = false;
                let errorDescription = data.errors[0].description;
                let errorCode = data.errors[0].code;

                if (errorCode === "UserAlreadyHasPassword") {
                    displayReturnToLogin = true;
                    errorDescription = "Sorry, that link has expired.";
                }

                dispatch({
                    type: types.Registration_Update_Password_Failed,
                    payload: {
                        message: errorDescription,
                        displayReturnToLogin: displayReturnToLogin
                    }
                });
            }

        }).catch((error) => {
            let errorDescription = error.response.data.errors[0].description;

            dispatch({
                type: types.Registration_Update_Password_Failed,
                payload: {
                    message: errorDescription
                }
            });
        });
    }
    else if (isPasswordAnEmail) {
        b2bHelpers.DisplayFailValidation(validationParameters.inputField, validationParameters.validationField, validationParameters.passwordMeterContainer, ValidationMessages.error_emailAsPassword);
        dispatch({
            type: types.Registration_Password_Validation_Failed
        });
    } else {
        b2bHelpers.DisplayFailValidation(validationParameters.inputField, validationParameters.validationField, validationParameters.passwordMeterContainer, ValidationMessages.error_PasswordValidation);
        dispatch({
            type: types.Registration_Password_Validation_Failed
        });
    }
}

export const GetUserDetails = (token: string) => (dispatch: any) => {

    var headers = apiUtil.DefaultHeaders(token);

    if (headers === null) {
        return;
    }

    let postBody = {
        moduleId: apiUtil.GetModuleId()
    };

    axios.post(host.IdentityAPI("GetUserDetails"), postBody, headers).then(res => {
        let userInfo = res.data.userInfo;
        let branchInfo = res.data.branchInfo;
        let moduleSettings = res.data.moduleSettings;

        dispatch({
            type: types.Login_Get_User_Details,
            payload: {
                userInfo: userInfo,
                branchInfo: branchInfo,
                moduleSettings: moduleSettings,
                tenantName: res.data.tenantName
            }
        });

        dispatch(UpdateOrderPaymentMethod(undefined));
    }).catch((error) => {
        if (error.response) {
            let errorStatus = error.response.status;

            switch (errorStatus) {
                case 401:
                    dispatch(RefreshUserToken());
                    break;
                default:
                    dispatch({
                        type: globalTypes.Redirect_To_Login_Page,
                        payload: {
                            message: error.message
                        }
                    });
                    break;
            }
        } else {
            dispatch({
                type: globalTypes.Redirect_To_Login_Page,
                payload: {
                    message: error.message
                }
            });
        }
    });
}

export const RegistrationClearState = () => (dispatch: any) => {
    dispatch({
        type: types.Registration_Clear_State
    });
}

export const GetUserStatus = (credentials: registerDetailsTypes) => (dispatch: any) => {

    if (credentials.userId) {

        const params = {
            userId: credentials.userId,
            moduleId: credentials.storeModuleId
        };

        axios.get(host.IdentityAPI("GetUserStatus"), { params }).then(res => {
            const data = res.data;

            dispatch({
                type: types.Registration_Get_User_Status,
                payload: {
                    userStatus: data
                }
            });
        }).catch(() => {
            dispatch({
                type: types.Registration_Get_User_Status_Failed
            });
        });
    }
    else {
        dispatch({
            type: types.Registration_Get_User_Status_Failed
        });
    }
}