//Login Types
export const Authenticating_User = "Authenticating_User";
export const Authentication_Warning = "Authentication_Warning";
export const Authentication_Error = "Authentication_Error";
export const Login_Email_Address_Not_Valid = "Email_Address_Not_Valid";
export const Login_Reset_Initial_State = "Reset_Login_Initial_State";
export const Login_Validation_Inputs_Failed = "Login_Validation_Inputs_Failed";
export const Login_Get_User_Details = "Login_Get_User_Details";
export const Login_Reset_User_Details = "Login_Reset_User_Details";

//Forgot Password Types
export const ForgotPassword_Email_Address_Not_Valid = "ForgotPassword_Email_Address_Not_Valid";
export const ForgotPassword_Fail_Sending_Link = "ForgotPassword_Fail_Sending_Link";
export const ForgotPassword_Sending_Link = "ForgotPassword_Sending_Link";
export const ForgotPassword_Send_Reset_Link = "ForgotPassword_Send_Reset_Link";
export const ForgotPassword_Clear_State = "ForgotPassword_Clear_State";

//Reset Password Types
export const ResetPassword_Updating_Password = "ResetPassword_Updating_Password";
export const ResetPassword_Password_Updated = "ResetPassword_Password_Updated";
export const ResetPassword_Update_Password_Failed = "ResetPassword_Fail_Update_Password";
export const ResetPassword_Password_Validation_Failed = "ResetPassword_Password_Validation_Failed";
export const ResetPassword_Clear_State = "ResetPassword_Clear_State";

//Registration Types
export const Registration_Get_Registered_User_Details = "Registration_Get_Registered_User_Details";
export const Registration_Getting_User_Details = "Registration_Getting_User_Details";
export const Registration_Getting_User_Details_Failed = "Registration_Getting_User_Details_Failed";
export const Registration_Confirm_User_Email = "Registration_Confirm_User_Email";
export const Registration_Confirm_User_Email_Failed = "Registration_Confirm_User_Email_Failed";
export const Registration_Updating_Password = "Registration_Updating_Password";
export const Registration_Password_Updated = "Registration_Password_Updated";
export const Registration_Update_Password_Failed = "Registration_Update_Password_Failed";
export const Registration_Password_Validation_Failed = "Registration_Password_Validation_Failed";
export const Registration_Clear_State = "Registration_Clear_State";
export const Registration_Get_User_Status = "Registration_Get_User_Status";
export const Registration_Get_User_Status_Failed = "Registration_Get_User_Status_Failed";