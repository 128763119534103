import React, {Component, CSSProperties} from 'react';

interface componentProps {
    fillColor?: string;
    size?: number;
}

interface componentStates extends componentProps {}

class LoadingSpinnerIcon extends Component<componentProps,componentStates> {

    constructor(props: componentProps) {
        super(props);
        this.state = {
            fillColor: "#000"
        }
    }

    componentDidMount() {
        if (this.props.fillColor) {
            this.setState({
                fillColor: this.props.fillColor
            });
        }
    }

    render() {

        const { fillColor } = this.state;
        const {size = 48} = this.props;
        const cls1: CSSProperties = {
            fill: fillColor
        }

        return (
            <svg width={size} height={size} viewBox='0 0 48 48' fill="none" xmlns="https://www.w3.org/2000/svg">
                <path d="M9.90462 18.8697C12.738 11.085 21.3456 7.07122 29.1303 9.90461C31.2528 10.6771 33.0949 11.8789 34.5944 13.3761L37.4235 10.547C35.5235 8.64867 33.1887 7.12503 30.4984 6.14584C20.6378 2.55688 9.7348 7.64103 6.14585 17.5016C2.55689 27.3622 7.64104 38.2652 17.5016 41.8542C24.6719 44.4639 32.3934 42.4876 37.453 37.4235L34.6239 34.5944C30.6295 38.5947 24.5319 40.1563 18.8697 38.0954C11.085 35.262 7.07123 26.6544 9.90462 18.8697Z" style={cls1} />
            </svg>
        );
    }
}

export default LoadingSpinnerIcon;