import React from 'react';

export const BillingAddressIcon: React.FC = () => {
    return (
        <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Billing</title>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.6087 15.6522C2.37807 15.6522 2.1569 15.7438 1.99382 15.9069C1.83074 16.07 1.73913 16.2912 1.73913 16.5218V40H12.1739V16.5218C12.1739 16.2912 12.0823 16.07 11.9192 15.9069C11.7561 15.7438 11.535 15.6522 11.3043 15.6522H2.6087ZM0.764069 14.6772C1.25329 14.1879 1.91683 13.9131 2.6087 13.9131H11.3043C11.9962 13.9131 12.6597 14.1879 13.149 14.6772C13.6382 15.1664 13.913 15.8299 13.913 16.5218V40.8696C13.913 41.3499 13.5237 41.7392 13.0435 41.7392H0.869565C0.389318 41.7392 0 41.3499 0 40.8696V16.5218C0 15.8299 0.274844 15.1664 0.764069 14.6772Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.3921 0.615165C25.6564 0.499803 25.9453 0.451987 26.2327 0.47602C26.52 0.500054 26.797 0.595182 27.0385 0.752843C27.2799 0.910502 27.4784 1.12574 27.6161 1.37919C27.7537 1.63264 27.8261 1.91633 27.8268 2.20473L27.8269 2.20692L27.8269 16.5304C27.8269 17.0106 27.4375 17.4 26.9573 17.4C26.477 17.4 26.0877 17.0106 26.0877 16.5304L26.0877 2.2091L12.1747 8.39347L12.1747 14.7826C12.1747 15.2628 11.7854 15.6521 11.3051 15.6521C10.8249 15.6521 10.4355 15.2628 10.4355 14.7826V8.39126L10.4355 8.38908C10.4364 8.05143 10.5355 7.72133 10.7208 7.43906C10.9055 7.15776 11.1678 6.93609 11.4759 6.8009C11.4769 6.80043 11.478 6.79997 11.479 6.79951L25.3921 0.615165Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.9879 35.2921C4.31405 34.9659 4.7564 34.7827 5.21765 34.7827H8.69591C9.15715 34.7827 9.59951 34.9659 9.92566 35.2921C10.2518 35.6182 10.435 36.0606 10.435 36.5218V40.8697C10.435 41.3499 10.0457 41.7392 9.56547 41.7392C9.08522 41.7392 8.69591 41.3499 8.69591 40.8697V36.5218L5.21765 36.5218L5.21765 40.8697C5.21765 41.3499 4.82833 41.7392 4.34808 41.7392C3.86783 41.7392 3.47852 41.3499 3.47852 40.8697V36.5218C3.47852 36.0606 3.66174 35.6182 3.9879 35.2921Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 19.9999C0 19.5197 0.389318 19.1304 0.869565 19.1304H7.82609C8.30633 19.1304 8.69565 19.5197 8.69565 19.9999C8.69565 20.4802 8.30633 20.8695 7.82609 20.8695H0.869565C0.389318 20.8695 0 20.4802 0 19.9999Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.8691 9.56512C20.8691 9.08487 21.2585 8.69556 21.7387 8.69556H26.9561C27.4363 8.69556 27.8257 9.08487 27.8257 9.56512C27.8257 10.0454 27.4363 10.4347 26.9561 10.4347H21.7387C21.2585 10.4347 20.8691 10.0454 20.8691 9.56512Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.3906 13.0434C17.3906 12.5631 17.7799 12.1738 18.2602 12.1738H26.9558C27.4361 12.1738 27.8254 12.5631 27.8254 13.0434C27.8254 13.5236 27.4361 13.913 26.9558 13.913H18.2602C17.7799 13.913 17.3906 13.5236 17.3906 13.0434Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 23.4782C0 22.998 0.389318 22.6086 0.869565 22.6086H4.34783C4.82807 22.6086 5.21739 22.998 5.21739 23.4782C5.21739 23.9585 4.82807 24.3478 4.34783 24.3478H0.869565C0.389318 24.3478 0 23.9585 0 23.4782Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.7663 24.3479L31.302 24.3479C31.7822 24.3479 32.1715 24.7372 32.1715 25.2175C32.1715 25.6977 31.7822 26.087 31.302 26.087H27.7666C27.4268 26.0873 27.0976 26.2058 26.8355 26.4223C26.5734 26.6388 26.3947 26.9397 26.3302 27.2735C26.2657 27.6073 26.3193 27.9531 26.4819 28.2517C26.6444 28.5501 26.9055 28.7826 27.2206 28.9096C27.2209 28.9097 27.2204 28.9095 27.2206 28.9096L30.8094 30.3458C31.4994 30.6234 32.0728 31.1331 32.4285 31.7863C32.7842 32.4394 32.9015 33.196 32.7604 33.9262C32.6192 34.6565 32.2284 35.3148 31.655 35.7884C31.0815 36.262 30.3611 36.5213 29.6174 36.5218H26.0846C25.6043 36.5218 25.215 36.1325 25.215 35.6522C25.215 35.172 25.6043 34.7827 26.0846 34.7827H29.6161C29.9561 34.7824 30.2854 34.6639 30.5475 34.4474C30.8097 34.231 30.9883 33.93 31.0529 33.5962C31.1174 33.2624 31.0637 32.9166 30.9011 32.618C30.7387 32.3197 30.4775 32.0871 30.1624 31.9601C30.1622 31.96 30.1627 31.9602 30.1624 31.9601L26.5737 30.5239C25.8837 30.2463 25.3103 29.7366 24.9546 29.0835C24.5989 28.4303 24.4815 27.6737 24.6227 26.9435C24.7638 26.2132 25.1546 25.5549 25.7281 25.0813C26.3016 24.6077 27.0226 24.3484 27.7663 24.3479Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.6957 34.7827C29.176 34.7827 29.5653 35.172 29.5653 35.6523V37.3914C29.5653 37.8717 29.176 38.261 28.6957 38.261C28.2155 38.261 27.8262 37.8717 27.8262 37.3914V35.6523C27.8262 35.172 28.2155 34.7827 28.6957 34.7827Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.6957 22.6086C29.176 22.6086 29.5653 22.998 29.5653 23.4782V25.2173C29.5653 25.6976 29.176 26.0869 28.6957 26.0869C28.2155 26.0869 27.8262 25.6976 27.8262 25.2173V23.4782C27.8262 22.998 28.2155 22.6086 28.6957 22.6086Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.695 20.8695C23.4122 20.8695 19.1298 25.152 19.1298 30.4347C19.1298 35.7174 23.4122 39.9999 28.695 39.9999C33.9777 39.9999 38.2602 35.7174 38.2602 30.4347C38.2602 25.152 33.9777 20.8695 28.695 20.8695ZM17.3906 30.4347C17.3906 24.1915 22.4518 19.1304 28.695 19.1304C34.9382 19.1304 39.9993 24.1915 39.9993 30.4347C39.9993 36.6779 34.9382 41.7391 28.695 41.7391C22.4518 41.7391 17.3906 36.6779 17.3906 30.4347Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7817 0C15.2619 0 15.6512 0.389318 15.6512 0.869565V6.28C15.6512 6.76025 15.2619 7.14957 14.7817 7.14957C14.3014 7.14957 13.9121 6.76025 13.9121 6.28V0.869565C13.9121 0.389318 14.3014 0 14.7817 0Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.1738 40.8696C12.1738 40.3893 12.5631 40 13.0434 40H18.2608C18.741 40 19.1303 40.3893 19.1303 40.8696C19.1303 41.3498 18.741 41.7391 18.2608 41.7391H13.0434C12.5631 41.7391 12.1738 41.3498 12.1738 40.8696Z" fill="black" />
        </svg>

    );
}
