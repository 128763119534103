import React, { Component } from 'react';
class CircleDecrementIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fillColor: '#000'
        }
    }

    componentDidMount() {
        if(this.props.fillColor) {
            this.setState({
                fillColor: this.props.fillColor
            });
        }
    }

    render() { 
        const {fillColor} = this.state;
        
        const cls1 = {
            fill: fillColor
        }

        return (
            <svg xmlns="https://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 25 25">
                <title>Decrement</title>
                <g id="Group_10968" data-name="Group 10968" transform="translate(-112.19 703.809)">
                    <rect id="Rectangle_3178" data-name="Rectangle 3178" width="14.475" height="1.5" transform="translate(117.452 -692.059)" style={cls1} />
                    <path id="Path_5497" data-name="Path 5497" d="M124.69-703.809a12.5,12.5,0,0,0-12.5,12.5,12.5,12.5,0,0,0,12.5,12.5,12.5,12.5,0,0,0,12.5-12.5A12.5,12.5,0,0,0,124.69-703.809Zm0,23.5a11.012,11.012,0,0,1-11-11,11.012,11.012,0,0,1,11-11,11.013,11.013,0,0,1,11,11A11.013,11.013,0,0,1,124.69-680.309Z" style={cls1} />
                </g>
            </svg>
         );
    }
}
 
export default CircleDecrementIcon;