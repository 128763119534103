import APIHost from "../../APIHost";
import axios from "axios";
import * as headerTypes from "../Types/HeaderTypes";
import * as identityTypes from "../Types/IdentityTypes";
import * as brandingTypes from "../Types/BrandingTypes";
import * as productListTypes from "./../Types/ProductListTypes";
import * as productDetailsTypes from "./../Types/ProductDetailsTypes";
import * as types from "./../Types/AuthTypes";
import * as categoryTypes from "./../Types/CategoryTypes";
import * as freightTypes from "./../Types/FreightTypes";
import * as b2bHelpers from "../../Utils/B2BHelpers";
import ApiUtil from "../../Utils/ApiUtil";

interface loginActionsTypes {
  emailAddress: string;
  password: string;
}

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const authHost = new APIHost();
const apiUtil = new ApiUtil();

export const LoginB2BUser = (credentials: loginActionsTypes) => (
  dispatch: any
) => {
  let emailValidationParameters = b2bHelpers.GenerateTextInputValidationParameters(
    "EmailAddress",
    "emailValidationError"
  );
  let passwordvalidationParameters = b2bHelpers.GenerateTextInputValidationParameters(
    "LoginPassword",
    "passwordValidationError"
  );
  let isValidEmail = b2bHelpers.ValidateEmailAddress(credentials.emailAddress);

  if (credentials.emailAddress && credentials.password) {
    //Login Request - Processing phase
    dispatch({ type: identityTypes.Authenticating_User });

    let userCredentials = {
      Email: credentials.emailAddress,
      Password: credentials.password,
    };

    if (isValidEmail) {
      axios
        .post(authHost.AuthAPI("AuthorizeUser"), userCredentials, config)
        .then((res) => {

          dispatch({
            type: types.Authorize_B2B_User,
            payload: {
              isAuthorize: res.data.success,
              subscribedToken: res.data.token,
              refreshToken: res.data.refreshToken,
            },
          });
        })
        .catch((error) => {
          if (error.response) {
            let errorStatus = error.response.status;

            let errorDescription = "Error: Unable to login.";

            if (error.response.data) {
              errorDescription = error.response.data.errors[0].description;
            }

            if (errorStatus) {
                dispatch({
                  type: identityTypes.Authentication_Error,
                  payload: {
                    message: errorDescription,
                  },
                });
            }
          } else {
            if (error.message) {
              dispatch({
                type: identityTypes.Authentication_Error,
                payload: {
                  message: error.message,
                },
              });
            } else {
              dispatch({
                type: identityTypes.Authentication_Error,
                payload: {
                  message: "Something went wrong.",
                },
              });
            }
          }
        });
    } else {
      b2bHelpers.DisplayFailValidation(
        emailValidationParameters.inputField,
        emailValidationParameters.validationField,
        null,
        "Invalid email address"
      );
      dispatch({
        type: identityTypes.Login_Email_Address_Not_Valid,
      });
    }
  } else {
    if (!credentials.password && !credentials.emailAddress) {
      b2bHelpers.DisplayFailValidation(
        emailValidationParameters.inputField,
        emailValidationParameters.validationField,
        null,
        "Enter your email address"
      );
      b2bHelpers.DisplayFailValidation(
        passwordvalidationParameters.inputField,
        passwordvalidationParameters.validationField,
        null,
        "Enter your password"
      );
    } else if (!credentials.emailAddress) {
      b2bHelpers.DisplayFailValidation(
        emailValidationParameters.inputField,
        emailValidationParameters.validationField,
        null,
        "Enter your email address"
      );
    } else {
      b2bHelpers.DisplayFailValidation(
        passwordvalidationParameters.inputField,
        passwordvalidationParameters.validationField,
        null,
        "Enter your password"
      );
    }

    dispatch({
      type: identityTypes.Login_Validation_Inputs_Failed,
    });
  }
};

export const ClearIdentityErrorMessages = () => (dispatch: any) => {
  dispatch({
    type: types.Auth_Errors_Clear_State,
  });
};

//Clear Logout Error Message State
export const LogoutClearState = () => (dispatch: any) => {
  dispatch({
    type: types.Logout_Clear_State,
  });
};

export const RefreshUserToken = () => (dispatch: any) => {
  let refreshToken = apiUtil.GetRefreshToken();

  if (refreshToken === null) {
    dispatch(LogoutB2BUser());
    return;
  }

  axios
    .post(authHost.AuthAPI("RefreshUserToken"), refreshToken, config)
    .then((res) => {
      dispatch({
        type: types.Refresh_User_Token,
        payload: {
          isAuthorize: res.data.success,
          subscribedToken: res.data.token,
          refreshToken: res.data.refreshToken,
        },
      });
    })
    .catch(() => {
      dispatch(LogoutB2BUser("You have been logged out due to inactivity."));
    });
};

export const LogoutB2BUser = (errorMessage?: string) => (dispatch: any) => {
  let refreshToken = apiUtil.GetRefreshToken();

  var headers = apiUtil.DefaultHeaders();

  if (refreshToken !== null && headers !== null) {
    axios
      .post(authHost.AuthAPI("Logout"), refreshToken, headers)
      .then((res) => {})
      .catch(() => {});
  }

  dispatch({
    type: identityTypes.Login_Reset_Initial_State,
  });
  dispatch({
    type: identityTypes.Login_Reset_User_Details,
  });
  dispatch({
    type: headerTypes.Side_Menu_Reset_Status,
  });
  dispatch({
    type: brandingTypes.Reset_Brand_Info,
  });
  dispatch({
    type: productListTypes.Reset_Products_List,
  });
  dispatch({
    type: productListTypes.Reset_Brands_List,
  });
  dispatch({
    type: productListTypes.Reset_Products_Filter,
  });
  dispatch({
    type: productListTypes.Reset_Categories_List,
  });
  dispatch({
    type: productDetailsTypes.Hide_Product_Details_Modal,
  });
  dispatch({
      type: categoryTypes.Reset_Current_Category
  });
  dispatch({
      type: productListTypes.Reset_Category_Route_Objects
  });
  dispatch({
    type: freightTypes.Reset_Freight_Options
  });
  dispatch({
    type: types.Deauthorize_B2B_User,
    payload: {
      errorMessage: errorMessage || "",
    },
  });
};
