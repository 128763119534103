import { BemHelper, Button } from '@cin7/ui';
import React, { useState } from 'react';
import { DeliveryAddressIcon } from '../../../Images/Icons/Orders/DeliveryAddressIcon';
import { BillingAddressIcon } from '../../../Images/Icons/Orders/BillingAddressIcon';
import EditAddressModal from './EditAddressModal';

import './Styles/_AddressPanel.scss';

const bem = new BemHelper('address-panel');

export interface AddressPanelProps {
    title: string;
    variant?: 'delivery' | 'billing';
    address: {
        company: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        postCode: string;
        country: string;
    };
    firstName?: string;
    lastName?: string;
    UpdateAction?: (updatedAddress: any) => void;
}

export const AddressPanel: React.FC<AddressPanelProps> = (props) => {

    const [editModalOpen, setEditModalOpen] = useState(false);
    const { title, variant = 'billing', address, firstName, lastName, UpdateAction } = props;
    const addressIcon = variant === 'delivery' ? <DeliveryAddressIcon /> : <BillingAddressIcon />;

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    }

    return (
        <div className="address-panel">
            <div className={bem.element('panel-heading')}>
                <div className={bem.element('address-title')}>{title}</div>
            </div>
            <div className={bem.element('address-inner-content')}>
                <div className={bem.element('address-icon-container')}>{addressIcon}</div>
                <div className={bem.element('address-container')}>
                    {firstName && <span className={bem.element('address-first-name')}>{firstName} </span>}
                    {lastName && <span className={bem.element('address-last-name')}>{lastName}</span>}
                    <div className={bem.element('address-company')}>{address?.company}</div>
                    <div className={bem.element('address-item address-address1')}>{address?.address1}</div>
                    <div className={bem.element('address-item address-address2')}>{address?.address2}</div>
                    <div className={bem.element('address-item address-city')}>{address?.city}</div>
                    <div className={bem.element('address-item address-state')}>{address?.state}</div>
                    <div className={bem.element('address-item address-postcode')}>{address?.postCode}</div>
                    <div className={bem.element('address-item address-country')}>{address?.country}</div>
                </div>
                {variant === 'delivery' && <div className={bem.element('address-change')}>
                    <Button className={bem.element('address-change-button')} variant={"secondary"} onClick={toggleEditModal}>Edit Address</Button>
                </div>}
            </div>
            {editModalOpen &&
                <EditAddressModal title={title}
                    address={address}
                    firstName={firstName}
                    lastName={lastName}
                    CloseAction={toggleEditModal}
                    UpdateAction={UpdateAction} />}
        </div>
    );
}