import * as types from "./../../Types/UserDetailsTypes";

export type UserDetailsStateType = {
    orderHistory: {
        isLoading: boolean;
        orders: [];
        totalPages: number;
        message: string;
    };
};

const initialState: UserDetailsStateType = {
    orderHistory: {
        isLoading: false,
        orders: [],
        totalPages: 1,
        message: "",
    },
};

export const UserDetailsReducer = (
    state = initialState,
    action: { type: string; payload: any }
): UserDetailsStateType => {
    switch (action.type) {
        case types.Getting_Order_History:
            return {
                ...state,
                orderHistory: {
                    orders: state.orderHistory.orders,
                    isLoading: true,
                    message: "",
                    totalPages: state.orderHistory.totalPages,
                },
            };
        case types.Order_History_Success:
            return {
                ...state,
                orderHistory: {
                    isLoading: false,
                    orders: action.payload.orders,
                    totalPages: action.payload.totalPages,
                    message: "",
                },
            };
        case types.Order_History_Fail:
            return {
                ...state,
                orderHistory: {
                    isLoading: false,
                    message: action.payload.message,
                    orders: state.orderHistory.orders,
                    totalPages: state.orderHistory.totalPages,
                },
            };
        default:
            return state;
    }
};
