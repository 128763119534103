import React, { Component, CSSProperties } from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentStates extends componentProps {}

class OpenBox extends Component<componentProps, componentStates> {

    constructor(props:componentProps) {
        super(props);
        this.state = {
            strokeColor: "#000"
        }
    }

    componentDidMount() {
        if(this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }

    render() { 
        const { strokeColor } = this.state;
        
        const cls1: CSSProperties = {
            stroke: strokeColor
        }
          
        return ( 
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="https://www.w3.org/2000/svg">
                <path d="M18.75 8.25V30.75M18.75 8.25H29.25L32.3182 15L29.25 15.4091M18.75 8.25H6.74998L3.74997 15L6.74998 15.3529M18.75 8.25L16.5 16.5L6.74998 15.3529M18.75 8.25L21.0682 16.5L29.25 15.4091M18.75 30.75L6.74998 27.75V15.3529M18.75 30.75L29.25 27.75C29.25 27.15 29.25 19.2727 29.25 15.4091" style={cls1} strokeWidth="1.5" strokeLinejoin="round"/>
            </svg>

         );
    }
}
 
export default OpenBox;