import React, {Component, CSSProperties} from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentStates extends componentProps {}

class AlertCircleIcon extends Component<componentProps,componentStates> {

    constructor(props:componentProps) {
        super(props);
        this.state = {
            strokeColor: "#000"
        }
    }

    componentDidMount() {
        if(this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }

    render() {

        const { strokeColor } = this.state;
        
        const cls1: CSSProperties = {
            stroke: strokeColor
        }

        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                <title>Warning</title>
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" style={cls1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 8V12" style={cls1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 16H12.01" style={cls1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        )
    }
}

export default AlertCircleIcon;