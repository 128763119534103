import React from 'react';
import { useSelector } from 'react-redux';
import { OrderCheckoutPageContent } from '../../../Language/Default_Settings';
import { PriceDisplay } from '../../Global/PriceDisplay';
import { AccountOnHoldBanner } from '../Account/AccountOnHoldBanner';
import { getCartDiscountTotal, getOrderItemCount, getCartItemsTotal, getIsFetchingPromotions } from '../../../Redux/Selectors/OrderSelectors';
import { FormatAmount, useCartGrandTotal, useCartTaxTotal } from '../../../Utils/OrderHelper';
import { useCurrentFreightPrice, useCurrentFreightTier, useCurrentFreightTierAttribute } from '../../../Utils/FreightHelper';
import { FreightTierPriceType } from '../../../Redux/Reducers/App_Reducers/FreightReducer';
import './Styles/_TotalsPanel.scss';

const FreightPriceDisplay = () => {
    const selectedFreightTier = useCurrentFreightTier();
    const selectedFreightTierAttrubute = useCurrentFreightTierAttribute();
    const currentFreightPrice = useCurrentFreightPrice();

    if (selectedFreightTier.type === FreightTierPriceType.Undefined || 
        selectedFreightTier.type === FreightTierPriceType.ToBeConfirmed ||
        (selectedFreightTier.type === FreightTierPriceType.Weight && selectedFreightTierAttrubute?.price === null) || 
        (selectedFreightTier.type === FreightTierPriceType.Value && selectedFreightTierAttrubute?.price === null)) {
        return <>{"TBC"}</>;
    }
    else {
        return <PriceDisplay value={currentFreightPrice} />;
    }
};

export const TotalsPanel: React.FC = () => {

    const cartItemsTotal = useSelector(getCartItemsTotal);
    const cartGrandTotal = useCartGrandTotal();
    const taxTotal = useCartTaxTotal();
    const discountTotal = useSelector(getCartDiscountTotal);
    const totalItems = useSelector(getOrderItemCount);
    const calculatingPromotions = useSelector(getIsFetchingPromotions);

    return (
        <div className="totals-panel">
            <div className="account-on-hold-container"><AccountOnHoldBanner /></div>
            <div className="order-totals-content">
                <div className="totals-title">Order Summary</div>
                <div className="order-totals-container">
                    <div className="order-totals-price-container">
                        <div className="order-totals-price-row">
                            <div className="totals-label total-price-label">{FormatAmount(totalItems)} Item{totalItems === 1 ? '' : 's'}</div>
                            <div className="totals-value total-price-value">{<PriceDisplay value={cartItemsTotal + discountTotal} isLoading={calculatingPromotions} />}</div>
                        </div>
                        {
                            discountTotal > 0 &&
                            <div className="order-totals-price-row">
                                <div className="totals-label total-price-label">{OrderCheckoutPageContent.default_totalDiscountLabel}</div>
                                <div className="totals-value total-price-value discount">{<PriceDisplay value={discountTotal*-1} isLoading={calculatingPromotions} />}</div>
                            </div>
                        }                        
                        <div className="order-totals-price-row subtotal">
                            <div className="totals-label">Subtotal</div>
                            <div className="totals-value">{<PriceDisplay value={cartItemsTotal} isLoading={calculatingPromotions} />}</div>
                        </div>
                        <div className="order-totals-price-row">
                            <div className="totals-label">Shipping</div>
                            <div className="totals-value">{<FreightPriceDisplay />}</div>
                        </div>
                        <div className="order-totals-price-row">
                            <div className="totals-label total-price-label">{OrderCheckoutPageContent.default_totalTaxLabel}</div>
                            <div className="totals-value total-price-value">{<PriceDisplay value={taxTotal} isLoading={calculatingPromotions} />}</div>
                        </div>
                        
                        <div className="order-totals-price-row grand-total">
                            <div className="totals-label total-price-label grand-total">{OrderCheckoutPageContent.default_grandTotalLabel}</div>
                            <div className="totals-value total-price-value grand-total">{<PriceDisplay value={cartGrandTotal} isLoading={calculatingPromotions} />}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
