import React from 'react';

export const FreightOptionIcon: React.FC = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Freight to be confirmed</title>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0145 0.383537C20.3949 0.783953 20.3787 1.41691 19.9783 1.79728C17.3852 4.2606 15.337 7.23994 13.9658 10.5433C12.5955 13.8444 11.9317 17.3959 12.0171 20.9692C12.0998 23.1256 12.4711 25.2612 13.1211 27.3191C13.2874 27.8457 12.9954 28.4075 12.4687 28.5738C11.9421 28.7402 11.3803 28.4481 11.214 27.9215C10.5094 25.6909 10.1073 23.3758 10.0183 21.0383L10.0177 21.0244L10.0178 21.0244C9.92467 17.1695 10.6403 13.3379 12.1186 9.7765C13.5969 6.21511 15.8051 3.00301 18.6008 0.347251C19.0012 -0.033125 19.6342 -0.016879 20.0145 0.383537Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.67578 31C2.67578 30.4477 3.1235 30 3.67578 30H10.7678C11.3201 30 11.7678 30.4477 11.7678 31C11.7678 31.5523 11.3201 32 10.7678 32H3.67578C3.1235 32 2.67578 31.5523 2.67578 31Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.67578 11C2.67578 10.4477 3.1235 10 3.67578 10H20.9978C21.5501 10 21.9978 10.4477 21.9978 11C21.9978 11.5523 21.5501 12 20.9978 12H3.67578C3.1235 12 2.67578 11.5523 2.67578 11Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 21C0 20.4477 0.447715 20 1 20H20.998C21.5503 20 21.998 20.4477 21.998 21C21.998 21.5523 21.5503 22 20.998 22H1C0.447715 22 0 21.5523 0 21Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.9859 0.383537C22.3663 -0.016879 22.9993 -0.033125 23.3997 0.347251C26.1954 3.00301 28.4035 6.21511 29.8818 9.7765C31.3601 13.3379 32.0758 17.1695 31.9826 21.0244C31.9693 21.5765 31.5109 22.0133 30.9588 22C30.4067 21.9866 29.9699 21.5282 29.9832 20.9761C30.0696 17.4006 29.4058 13.8466 28.0346 10.5433C26.6635 7.23994 24.6153 4.2606 22.0222 1.79728C21.6218 1.41691 21.6055 0.783953 21.9859 0.383537Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.002 11C20.002 10.4477 20.4497 10 21.002 10H38.324C38.8762 10 39.324 10.4477 39.324 11C39.324 11.5523 38.8762 12 38.324 12H21.002C20.4497 12 20.002 11.5523 20.002 11Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.002 21C20.002 20.4477 20.4497 20 21.002 20H41C41.5522 20 42 20.4477 42 21C42 21.5523 41.5522 22 41 22H21.002C20.4497 22 20.002 21.5523 20.002 21Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.3768 4.4789C27.4556 2.82135 24.1473 1.96776 20.7889 2.00508C17.4304 2.0424 14.1419 2.96931 11.2583 4.69137C8.37469 6.41343 5.99911 8.86909 4.37357 11.8082C2.74802 14.7472 1.93061 18.0647 2.00461 21.4225C2.07862 24.7804 3.04139 28.0586 4.79483 30.9232C6.54828 33.7878 9.02974 36.1364 11.9864 37.7298C12.4726 37.9918 12.6543 38.5983 12.3923 39.0845C12.1303 39.5707 11.5238 39.7524 11.0376 39.4904C7.76972 37.7293 5.02705 35.1335 3.08903 31.9673C1.151 28.8012 0.0868908 25.1779 0.00509913 21.4666C-0.0766924 17.7553 0.826761 14.0886 2.62342 10.8402C4.42007 7.59174 7.04571 4.87759 10.2328 2.97426C13.42 1.07093 17.0547 0.0464553 20.7666 0.00520326C24.4786 -0.0360488 28.1352 0.907398 31.3638 2.73942C34.5925 4.57145 37.2778 7.22658 39.1462 10.4343C41.0146 13.642 41.9993 17.2877 42 20.9999C42.0001 21.5522 41.5525 22 41.0002 22.0001C40.4479 22.0002 40.0001 21.5526 40 21.0003C39.9994 17.6416 39.1084 14.3432 37.418 11.4409C35.7275 8.53871 33.2979 6.13645 30.3768 4.4789Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.8787 26.8787C17.4413 26.3161 18.2044 26 19 26H35C35.7957 26 36.5587 26.3161 37.1213 26.8787C37.6839 27.4413 38 28.2044 38 29V37C38 37.5523 37.5523 38 37 38C36.4477 38 36 37.5523 36 37V29C36 28.7348 35.8947 28.4804 35.7071 28.2929C35.5196 28.1054 35.2652 28 35 28H19C18.7348 28 18.4804 28.1054 18.2929 28.2929C18.1054 28.4804 18 28.7348 18 29V41.0038C17.9995 41.1428 18.0279 41.2803 18.0835 41.4077C18.1391 41.5351 18.2207 41.6494 18.323 41.7435C18.4252 41.8376 18.546 41.9094 18.6776 41.9542C18.8091 41.999 18.9486 42.0159 19.087 42.0038C19.6372 41.9557 20.1222 42.3628 20.1702 42.913C20.2183 43.4632 19.8112 43.9482 19.261 43.9962C18.8457 44.0325 18.4273 43.9818 18.0327 43.8473C17.638 43.7129 17.2757 43.4977 16.9689 43.2154C16.662 42.9331 16.4174 42.59 16.2506 42.2079C16.0841 41.8264 15.9987 41.4145 16 40.9983C16 40.9976 16 40.9969 16 40.9962L17 41L16 40.9983V29C16 28.2043 16.3161 27.4413 16.8787 26.8787Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M36 30C36 29.4477 36.4477 29 37 29H41C42.8565 29 44.637 29.7375 45.9497 31.0503C47.2625 32.363 48 34.1435 48 36V41C48 41.7956 47.6839 42.5587 47.1213 43.1213C46.5587 43.6839 45.7956 44 45 44H39.83C39.2777 44 38.83 43.5523 38.83 43C38.83 42.4477 39.2777 42 39.83 42H45C45.2652 42 45.5196 41.8946 45.7071 41.7071C45.8946 41.5196 46 41.2652 46 41V36C46 34.6739 45.4732 33.4021 44.5355 32.4645C43.5979 31.5268 42.3261 31 41 31H38V41C38 41.5523 37.5523 42 37 42C36.4477 42 36 41.5523 36 41V30Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41 29C41.5523 29 42 29.4477 42 30V35C42 35.2652 42.1054 35.5196 42.2929 35.7071C42.4804 35.8946 42.7348 36 43 36H47C47.5523 36 48 36.4477 48 37C48 37.5523 47.5523 38 47 38H43C42.2044 38 41.4413 37.6839 40.8787 37.1213C40.3161 36.5587 40 35.7956 40 35V30C40 29.4477 40.4477 29 41 29Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37 42C35.8954 42 35 42.8954 35 44C35 45.1046 35.8954 46 37 46C38.1046 46 39 45.1046 39 44C39 42.8954 38.1046 42 37 42ZM33 44C33 41.7909 34.7909 40 37 40C39.2091 40 41 41.7909 41 44C41 46.2091 39.2091 48 37 48C34.7909 48 33 46.2091 33 44Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22 42C20.8954 42 20 42.8954 20 44C20 45.1046 20.8954 46 22 46C23.1046 46 24 45.1046 24 44C24 42.8954 23.1046 42 22 42ZM18 44C18 41.7909 19.7909 40 22 40C24.2091 40 26 41.7909 26 44C26 46.2091 24.2091 48 22 48C19.7909 48 18 46.2091 18 44Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.8301 43C23.8301 42.4477 24.2778 42 24.8301 42H34.1701C34.7224 42 35.1701 42.4477 35.1701 43C35.1701 43.5523 34.7224 44 34.1701 44H24.8301C24.2778 44 23.8301 43.5523 23.8301 43Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.0001 5.10315e-09C21.5524 5.52586e-05 22.0001 0.447815 22 1.0001L21.998 21.0001C21.998 21.5524 21.5502 22.0001 20.9979 22C20.4457 21.9999 19.998 21.5522 19.998 20.9999L20 0.9999C20.0001 0.447615 20.4479 -5.51888e-05 21.0001 5.10315e-09Z" fill="black" />
        </svg>
    );
}
