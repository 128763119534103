import React, { ReactNode } from 'react';
import { BemHelper, Typography } from '@cin7/ui';

import './Styles/_IssuesCard.scss';

const bem = new BemHelper('issues-card');

export interface IssuesCardProps {
    variant: IssuesCardVariant;
    action?: ReactNode;
    title: string;
    sections: IssuesCardSection[]
    className?: string;
}

export interface IssuesCardSection {
    title: string,
    issues: ReactNode[];
}

export enum IssuesCardVariant {
    Error = 'error',
    Warning = 'warning'
}

export const IssuesCard: React.FC<IssuesCardProps> = (props) => {
    const { variant, action, title, sections, className } = props;
    return (
        <div className={bem.block(variant) + ` ${className || ''}`}>
            <div className={bem.element('issues')}>
                <Typography variant={"h2"} className={bem.element('issues-title')}>{title}</Typography>
                {sections.map((section, i) => {
                    if (section.issues.length === 0) return null;
                    return (
                        <div key={i}>
                            <Typography variant={"body1"} gutterBottom>{section.title}</Typography>
                            <ul className={bem.element('issues-list')}>
                                {section.issues}      
                            </ul>
                        </div>
                    )
                })}
            </div>
            {!!action && <div className={bem.element('action')}>{action}</div>}
        </div>
    )
}