import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider, B2BTheme } from "@cin7/ui";
//Layout
import Layout from "./Pages/Layout";
import RestrictedPage from "./Pages/Login/RestrictedPage";

//Page Views
import { Index } from "./Pages/Landing/Index";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Login/Register";
import PageNotFound from "./Pages/Notifications/Errors/PageNotFound";
import Products from "./Pages/Products/Products";
import SellerContent from "./Pages/SellerContent/SellerContent";
import Account from "./Pages/Account/Account";
import { CustomHomepage } from "./Pages/Landing/CustomHomepage";

//Notification Views
import FailedView from "./Pages/Notifications/Errors/FailedView";
import Orders from "./Pages/Orders/Orders";
import { ProductDetailsModal } from "./Components/Global/ProductDetailsModal";
import { ContactUs } from "./Pages/Login/ContactUs";
import { ContactUsConfirmation } from "./Pages/Login/ContactUsConfirmation";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from '@datadog/browser-logs';
import APIHost from "./APIHost";

class App extends Component<{}, {}> {

    componentDidMount() {
        const environment = APIHost.GetEnvironment() ?? undefined;
        if (environment && environment !== "development") {
            const clientToken = 'pubdb858fed3a5a24a2b1e13ef8c73896f6';
            datadogRum.init({
                applicationId: 'a4d267e2-769a-4255-95d5-58e000b351a7',
                clientToken: clientToken,
                site: 'us3.datadoghq.com',
                service: 'omni-b2b',
                env: environment,
                version: process.env.REACT_APP_RELEASE_VERSION,
                sessionSampleRate: 10,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
                allowedTracingUrls: [/https:\/\/((?!applicationinsights.azure).)*$/, "http://localhost:3000/"],
            });

            datadogRum.startSessionReplayRecording();

            datadogLogs.init({
                service: 'omni-b2b',
                env: environment,
                version: process.env.REACT_APP_RELEASE_VERSION,
                clientToken: clientToken,
                site: 'us3.datadoghq.com',
                forwardErrorsToLogs: true,
                sessionSampleRate: 30,
            });
        }
    }

    render() {
        return (
            <ThemeProvider theme={B2BTheme as any}>
                <Layout>
                    <Switch>
                        <Route exact path="/" component={Index} />
                        <Route path="/login" component={Login} />
                        <Route path="/contact-us" component={ContactUs} />
                        <Route path="/contact-us-confirmation" component={ContactUsConfirmation} />
                        <Route path="/registration" component={Register} />
                        <RestrictedPage path="/home" component={CustomHomepage} />
                        <RestrictedPage path="/products" component={Products} />
                        <RestrictedPage path="/orders" component={Orders} />
                        <RestrictedPage path="/cart" component={Orders} />
                        <RestrictedPage path="/checkout" component={Orders} />
                        <RestrictedPage path="/seller" component={SellerContent} />
                        <RestrictedPage path="/account" component={Account} />
                        <Route exact path="/something-went-wrong" component={FailedView} />
                        <Route path="/*" component={PageNotFound} />
                    </Switch>

                    <ProductDetailsModal />
                    <div className="clear-space"></div>
                </Layout>
            </ThemeProvider>
        );
    }
}

export default App;
