import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import LoginForm from "./../../../Components/AppForms/LoginForm";

// Returns true if the str string starts with the word string
function startsWith(str: string, word: string) {
    return str.lastIndexOf(word, 0) === 0;
}

interface componentProps extends RouteComponentProps<any> {
    signIn: {
        isAuthorize: boolean;
    };
}

class SignIn extends Component<componentProps, {}> {
    render() {
        const { signIn, location } = this.props;
        const state: any = location.state;
        if (signIn.isAuthorize) {
            if (state?.from?.pathname) {
                if (!startsWith(state.from.pathname, "/registration") && !startsWith(state.from.pathname, "/login")) {
                    return (
                        <Redirect
                            to={{
                                pathname: state.from.pathname,
                                search: state.from.search,
                                state: { from: location },
                            }}
                        />
                    );
                }
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location },
                        }}
                    />
                );
            }
        }
        return <LoginForm />;
    }
}
const SignInWithRouter = withRouter(SignIn);

const MapStateToProps = (state: { authReducer: any }) => ({
    signIn: state.authReducer,
});

export default connect(MapStateToProps, null)(SignInWithRouter);
