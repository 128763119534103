import React from "react";
import { CloseCircleIcon } from "@cin7/ui";

import "./Styles/_Chip.scss";

export interface ChipProps {
    label: string;
    onDelete?: VoidFunction;
    onClick?: VoidFunction;
    color?: string;
    deletable?: boolean;
    className?: string;
    variant?: "primary" | "secondary";
}

export const Chip = (props: ChipProps) => {
    const {
        label,
        onDelete,
        color = "#004FA9",
        deletable = false,
        className = "",
        onClick,
        variant = "primary",
    } = props;
    const styles =
        variant === "primary" ? { backgroundColor: color, color: "#fff" } : { border: `1px solid ${color}`, color };
    return (
        <div className={`chip ${className} chip--${variant}`} style={styles} onClick={onClick}>
            <div className="chip__label">{label}</div>
            {deletable && (
                <div className="chip__delete-icon" onClick={onDelete}>
                    <CloseCircleIcon />
                </div>
            )}
        </div>
    );
};
