import React, { Component } from 'react';

//Assets
import LockIcon from './../../Images/Icons/LockIcon';
import ErrorCrossIcon from './../../Images/Icons/ErrorCrossIcon';

interface componentProps {
    pageTitle: string;
    pageTitleClass?: string;
    pageSubtitle?: string;
    pageSubtitleClass?: string;
    enableIcon?: boolean;
    iconType?: string;
    iconValue?: string;
}

class Cin7FormHeading extends Component<componentProps, {}> {
    
    render() { 
        const { pageTitle, pageTitleClass, pageSubtitle, pageSubtitleClass, enableIcon, iconType} = this.props;

        const RenderIcon: any = (props: componentProps) => {
            switch (props.iconValue) {
                case "LockIcon":
                    return <LockIcon FillColor={'#002dff'} />;
                default:
                    return <ErrorCrossIcon />;
            }
        }

        return ( 
            <>
                {enableIcon && 
                    <div className="cin7-heading-icon-container">
                        <RenderIcon iconValue={iconType} />
                    </div>
                }

                <h2 className={`${enableIcon ? "icon-on-top": "icon-default"} ${pageTitleClass ? pageTitleClass : "title-default"}`}>
                    <strong>{pageTitle}</strong>
                </h2>

                {pageSubtitle && 
                    <h3>
                        <span className={`legend ${pageSubtitleClass}`}>
                            {pageSubtitle}
                        </span>
                    </h3>
                }
            </>
         );
    }
}
 
export default Cin7FormHeading;