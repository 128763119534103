import React, { Component } from 'react';

import './Styles/_Loading.scss';

interface componentProps {
    takeOver?: boolean;
    inLine?: boolean;
    text?: string;
}

class Loading extends Component<componentProps, {}> {

    render() {
        const { inLine, text, takeOver } = this.props;

        return (
            <div className={`loading-container ${inLine ? "" : "full-screen"} ${takeOver ? "take-over" : ""}`}>
                <div className="full-screen-background">
                    <div className="loading-dots">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="loading-label">{text ? text : 'Loading...'}</div>
                </div>
            </div>
        );
    }
}

export default Loading;