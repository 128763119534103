import { Button, Input, Dialog, DialogContent, Select } from '@cin7/ui';
import React, { Component } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { OrderCheckoutPageContent } from '../../../Language/Default_Settings';
import { countryList } from '../../../Utils/CountriesList';
import Cin7ConfirmationBox from '../Cin7ConfirmationBox';

import './Styles/_EditAddressModal.scss';

interface componentProps {
    title: string;
    address: {
        company: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        postCode: string;
        country: string;
    };
    firstName?: string;
    lastName?: string;
    CloseAction: () => void;
    UpdateAction?: (updatedAddress: any) => void;
}

interface componentStates {
    isEditWindowOpen: boolean;
    firstName?: string;
    lastName?: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postCode: string;
    country: string;
}

class EditAddressModal extends Component<componentProps, componentStates> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            isEditWindowOpen: false,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            company: this.props.address.company,
            address1: this.props.address.address1,
            address2: this.props.address.address2,
            city: this.props.address.city,
            state: this.props.address.state,
            postCode: this.props.address.postCode,
            country: this.props.address.country
        };
    }

    render() {
        const { title, CloseAction, UpdateAction } = this.props;
        const { firstName, lastName, company, address1, address2, city, state, postCode, country } = this.state;

        let countries: { key: string; label: string; value: string }[] = [];

        countryList.forEach((item) => {
            countries.push({
                key: item,
                label: item,
                value: item,
            });
        });

        if (!countries.some((item) => item.value === this.state.country)) {
            countries.push({
                key: this.state.country,
                label: this.state.country,
                value: this.state.country,
            });
        }

        return (
            <Dialog onClose={(e, reason) => CloseAction()} open>
                <DialogContent>
                    <div className="edit-address-title">Edit {title}</div>
                    <Cin7ConfirmationBox
                        boxType={"info-box"}
                        boxIcon={"info-icon"}
                        boxMessage={OrderCheckoutPageContent.default_editDeliveryAddressMessageLabel}
                    />
                    <div className="edit-address-container">
                        <Input
                            id={"FirstName"}
                            label={"First Name"}
                            value={firstName}
                            onChange={this._onChangeFirstName}
                        />
                        <Input
                            id={"LastName"}
                            label={"Last Name"}
                            value={lastName}
                            onChange={this._onChangeLastName}
                        />
                        <Input
                            id={"AddressCompany"}
                            label={"Company Name"}
                            value={company}
                            onChange={this._onChangeCompany}
                        />
                        <Input
                            id={"AddressAddress1"}
                            label={"Address 1"}
                            value={address1}
                            onChange={this._onChangeAddress1}
                        />
                        <Input
                            id={"AddressAddress2"}
                            label={"Address 2"}
                            value={address2}
                            onChange={this._onChangeAddress2}
                        />
                        <Input id={"AddressCity"} label={"City"} value={city} onChange={this._onChangeCity} />
                        <Input
                            id={"AddressState"}
                            label={"State/County"}
                            value={state}
                            onChange={this._onChangeState}
                        />
                        <Input
                            id={"AddressPostcode"}
                            label={"Post Code"}
                            value={postCode}
                            onChange={this._onChangePostCode}
                        />
                        <Form>
                            <FormGroup>
                                <Select
                                    label="Country"
                                    options={countries}
                                    defaultValue={{ label: country, value: country }}
                                    onChange={this._onChangeCountry}
                                    openUpwards
                                    id="AddressCountry"
                                />
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="action-buttons">
                        <Button
                            id="Cancel-Button"
                            className="address-action-clear"
                            onClick={CloseAction}
                            variant={"secondary"}
                        >
                            Cancel
                        </Button>
                        {UpdateAction && (
                            <Button id="Save-Button" className="address-action-save" onClick={this._updateAddress}>
                                Save
                            </Button>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    _onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            firstName: e.target.value
        });
    }

    _onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            lastName: e.target.value
        });
    }

    _onChangeCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            company: e.target.value
        });
    }

    _onChangeAddress1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            address1: e.target.value
        });
    }

    _onChangeAddress2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            address2: e.target.value
        });
    }

    _onChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            city: e.target.value
        });
    }

    _onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            state: e.target.value
        });
    }

    _onChangePostCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            postCode: e.target.value
        });
    }

    _onChangeCountry = (e: {value: string} | null) => {
        this.setState({
            country: String(e?.value)
        });
    }

    _updateAddress = () => {
        if (this.props.UpdateAction) {
            var deliveryAddress = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                company: this.state.company,
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                postCode: this.state.postCode,
                country: this.state.country
            }
            this.props.UpdateAction(deliveryAddress);

            this.props.CloseAction();
        }
    }
}

export default EditAddressModal;