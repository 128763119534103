import React from 'react';
import { useSelector } from 'react-redux';
import { getUserCurrency, getRetailPriceTierCurrency } from '../../Redux/Selectors/UserSelectors';
import { FormatMoney } from '../../Utils/OrderHelper';
import LoadingSpinner from './LoadingSpinner';

interface RetailPriceDisplayProps {
    value: number;
    isLoading?: boolean;
};

export const RetailPriceDisplay = (props: RetailPriceDisplayProps) => {

    const {
        value,
        isLoading,
    } = props;

    const userCurrencyCode = useSelector(getUserCurrency);
    const retailCurrencyCode = useSelector(getRetailPriceTierCurrency)

    if (isLoading) {
        return <LoadingSpinner size={26} />;
    }

    if (retailCurrencyCode && (userCurrencyCode === retailCurrencyCode)) {
        return <>RRP: {FormatMoney(value, retailCurrencyCode)}<br /></>;
    }
    else {
        return null;
    }
};

export default RetailPriceDisplay;
