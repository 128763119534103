import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BulkImportCSVResponse, FailedImportRecord, FailedReadRecord, OrderItem, OrderOption } from '../../../Domain/BulkImportCSV';
import { UploadBulkOrderContent } from '../../../Language/Default_Settings';
import { CalculatePromotions, ClearCart, IncrementOrderQuantity } from '../../../Redux/Actions/OrderActions';
import { getOrderItemCount } from '../../../Redux/Selectors/OrderSelectors';
import { ConfirmBulkOrderModal } from './ConfirmBulkOrderModal';
import { UploadBulkOrderModal } from './UploadBulkOrderModal';

export interface UploadBulkOrderProps {
    onClose: VoidFunction;
    importCSV: (file: File) => Promise<BulkImportCSVResponse>;
    downloadTemplate: VoidFunction;
    onImportSuccess?: VoidFunction
}

const getStockIssues = (orderItems: OrderItem[]) => {
    const stockErrors: string[] = [];
    const stockWarnings: string[] = [];
    orderItems.forEach(orderItem => {
        orderItem.orderOptions.forEach(option => {
            if (orderItem.allowOverselling || option.quantityOrdered <= option.stock) {
                return;
            }
            if (option.stock > 0) {
                stockWarnings.push(`${orderItem.name} ${option.code} - quantity is now ${option.stock}`)
            } else {
                stockErrors.push(`${orderItem.name} ${option.code}`)
            }
        })
    })

    return { stockErrors, stockWarnings }
}

interface ImportIssues {
    error?: string,
    importErrors: FailedImportRecord[],
    readErrors: FailedReadRecord[],
    stockErrors: string[],
    stockWarnings: string[],
}

export const UploadBulkOrder: React.FC<UploadBulkOrderProps> = ({ onClose, importCSV, downloadTemplate, onImportSuccess }) => {
    const [showUploadBulkOrder, setShowUploadBulkOrder] = useState(true);
    const [showConfirmBulkOrder, setShowConfirmBulkOrder] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [importIssues, setImportIssues] = useState<ImportIssues>({ error: undefined, importErrors: [], readErrors: [], stockErrors: [], stockWarnings: []});
    const [importedOrder, setImportedOrder] = useState<OrderItem[]>([]);
    const canceledUploadRef = useRef({canceled: false});
    
    const dispatch = useDispatch();
    const orderItemCount = useSelector(getOrderItemCount);
    const clearCart = () => dispatch(ClearCart());
    const calculatePromotions = () => dispatch(CalculatePromotions());
    const addToCart = (option: OrderOption, product: OrderItem, quantity: number) => dispatch(IncrementOrderQuantity(option, product, quantity));
    const addImportedOrderToCart = (importedOrder: OrderItem[]) => {
        importedOrder.forEach(orderItem => {
            orderItem.orderOptions.forEach(option => {
                if (orderItem.allowOverselling) {
                    return addToCart(option, orderItem, option.quantityOrdered);
                }
                if (option.quantityOrdered <= option.stock) {
                    addToCart(option, orderItem, option.quantityOrdered);
                } else if (option.stock > 0) {
                    addToCart(option, orderItem, option.stock);
                }
            })
        })
        calculatePromotions();
        onImportSuccess?.();
    }
    const cancelUpload = () => canceledUploadRef.current = { canceled: true };
    
    return (
        <>
            {showUploadBulkOrder && (
                <UploadBulkOrderModal 
                    onDownloadTemplateClick={downloadTemplate}
                    onCloseClick={() => { 
                        setShowUploadBulkOrder(false); 
                        cancelUpload(); 
                        onClose();
                    }} 
                    onUploadCancel={() => { setUploading(false); cancelUpload(); }}
                    onUpload={(files) => {
                        if (files.length > 0) {
                            if (!files[0].name.endsWith('.csv')) {
                                setImportIssues({ ...importIssues, error: `File type must be .csv, this file is ${files[0].name}`})
                                return;
                            }
                            setImportIssues({ ...importIssues, error: undefined });
                            setUploading(true); 
                            importCSV(files[0]).then((response: BulkImportCSVResponse) => {
                                setUploading(false)
                                if (canceledUploadRef.current.canceled) {
                                    canceledUploadRef.current = { canceled: false };
                                    return;
                                }
                                const { failedReadRecords, failedImportRecords, orderItems } = response;
                                setImportedOrder(orderItems);
                                const stockIssues = getStockIssues(response.orderItems);
                                setImportIssues({ ...importIssues, importErrors: failedImportRecords, readErrors: failedReadRecords, ...stockIssues });
                               
                                const hasErrors = failedImportRecords.length > 0 
                                    || failedReadRecords.length > 0 
                                    || stockIssues.stockErrors.length > 0 
                                    || stockIssues.stockWarnings.length > 0;

                                const shouldShowConfirmationModal = hasErrors || orderItemCount > 0 ;

                                if (orderItems.length === 0 && !hasErrors) {
                                    setImportIssues({...importIssues, error: UploadBulkOrderContent.default_fileError});
                                    return;
                                }
                                
                                setShowUploadBulkOrder(false); 

                                if (shouldShowConfirmationModal) {
                                    setImportedOrder(response.orderItems);
                                    setShowConfirmBulkOrder(true);
                                } else {
                                    addImportedOrderToCart(response.orderItems);
                                    onClose();
                                } 
                            }).catch(() => {
                                setUploading(false);
                                setImportIssues({...importIssues, error: UploadBulkOrderContent.default_fileError});
                            })
                        }
                    }} 
                    errorMessage={importIssues.error}
                    uploading={uploading} 
                />
            )}
            {showConfirmBulkOrder && (
                <ConfirmBulkOrderModal 
                    onCloseClick={() => { setShowConfirmBulkOrder(false); onClose(); }} 
                    onAddToCart={() => {
                        if (importedOrder) addImportedOrderToCart(importedOrder);
                        onClose();
                    }} 
                    onReplaceCart={() => {
                        clearCart();
                        if (importedOrder) addImportedOrderToCart(importedOrder)
                        onClose();
                    }} 
                    onReuploadClick={() => { setShowConfirmBulkOrder(false); setShowUploadBulkOrder(true) }} 
                    importErrors={importIssues.importErrors} 
                    csvReadErrors={importIssues.readErrors} 
                    stockWarnings={importIssues.stockWarnings}
                    stockErrors={importIssues.stockErrors}
                    hasSuccessfullRows={importedOrder?.length > 0}
                />
            )}
        </>
    )
}