import React, { Component } from "react";
import MoreActionsDropdown from "./MoreActionsDropdown";

import "./Styles/_MoreActionsPanel.scss";

interface componentProps {
    actions?: any;
}

class MoreActionsPanel extends Component<componentProps> {
    render() {
        const { actions } = this.props;

        return (
            <>
                <div id="Global-Return-Panel">
                    <MoreActionsDropdown actions={actions} />
                </div>
            </>
        );
    }
}

export default MoreActionsPanel;
