import React, { Component } from 'react';

interface componentProps {
    fillColor?: string;
}

interface componentState {
    fillColor: string;
}

class ClearInputBoldIcon extends Component<componentProps, componentState> {
    constructor(props: Readonly<componentProps>) {
        super(props);
        this.state = {
            fillColor: '#000'
        }
    }

    componentDidMount() {
        if(this.props.fillColor) {
            this.setState({
                fillColor: this.props.fillColor
            });
        }
    }
    
    render() {
        const { fillColor } = this.state;
        
        const cls1 = {
            stroke: fillColor,
            fill: fillColor
        }
        
        return (
            <svg width="24" height="24" fill="none" xmlns="https://www.w3.org/2000/svg">
                <title>Clear</title>
                <path d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-7.414-5.414L12 9.172 9.414 6.586a2 2 0 1 0-2.828 2.828L9.172 12l-2.586 2.586a2 2 0 1 0 2.828 2.828L12 14.828l2.586 2.586a2 2 0 1 0 2.828-2.828L14.828 12l2.586-2.586a2 2 0 1 0-2.828-2.828z" style={cls1} strokeWidth="2" />
            </svg>
         );
    }
}
 
export default ClearInputBoldIcon;