import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { DisplayFilterMenu } from "../../../Redux/Actions/ProductListActions";

import "./Styles/_ProductFilterBackground.scss";

const ProductFilterBackground = () => {
    const productsList = useSelector((state: RootState) => state.productListReducer);
    const dispatch = useDispatch();

    return productsList.filter?.filterMenuOpen ? (
        <div className="clear-bg" onClick={() => dispatch(DisplayFilterMenu(false))}></div>
    ) : null;
};

export default ProductFilterBackground;
