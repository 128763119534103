import React, { Component } from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentState {
    strokeColor: string;
}

class ExpandArrowIcon extends Component<componentProps, componentState> {
    constructor(props: Readonly<componentProps>) {
        super(props);
        this.state = {
            strokeColor: '#000'
        }
    }

    componentDidMount() {
        if(this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }

    render() { 
        const {strokeColor} = this.state;
        
        const cls1 = {
            stroke: strokeColor
        }

        return (
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="https://www.w3.org/2000/svg">
                <title>Expand</title>
                <path d="M36 18L24 29L12 18" style={cls1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}
 
export default ExpandArrowIcon;