import React, { Component } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { CalculatePromotions } from "../../Redux/Actions/OrderActions";

interface componentProps extends Partial<RouteComponentProps> {
    userRestrictions?: {
        isAuthorize: boolean;
    };
    orderedItems: any[];
    component: React.ComponentType<any>;
    path?: string;
    CalculatePromotions: () => void;
}

class RestrictedPage extends Component<componentProps, {}> {

    async componentDidMount(){
        if(this.props.userRestrictions?.isAuthorize && this.props.orderedItems.length > 0) {
            await this.props.CalculatePromotions();
        }
    }

    render() {
        const {userRestrictions, component: Component, ...rest} = this.props;

        const renderRoute = (props: any) => {
            if(userRestrictions!.isAuthorize) {
                return(
                    <Component {...props} />
                );
            }
            else {
                return (
                    <Redirect to={
                        {
                            pathname: '/login',
                            state: {from: props.location}
                        }
                    } />
                )
            }
        }
        
        return(
            <Route {...rest} render={renderRoute} />
        )
    }
}

const MapStateToProps = (state: {authReducer: any, orderReducer: any}) => ({
    userRestrictions: state.authReducer,
    orderedItems: state.orderReducer?.orderItems
});

export default connect(MapStateToProps, {CalculatePromotions})(RestrictedPage);