import React from "react";

interface ComponentProps {
    strokeColor: string;
}

export const GenericIcon: React.FC<ComponentProps> = (props) => {
    const { strokeColor } = props;
    return (
        <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g stroke-linecap="round" stroke-width="1.5" stroke={strokeColor} fill="none" stroke-linejoin="round">
                <path d="M5.25,20.246c-0.82842,0 -1.5,-0.67158 -1.5,-1.5v-16.5c0,-0.82842 0.67158,-1.5 1.5,-1.5h16.5c0.82842,0 1.5,0.67158 1.5,1.5v16.5c0,0.82842 -0.67158,1.5 -1.5,1.5Z"></path>
                <path d="M20.25,23.246h-18h-6.55671e-08c-0.828427,-3.62117e-08 -1.5,-0.671573 -1.5,-1.5c0,0 0,0 0,0v-18"></path>
                <path d="M8.25,6.75h10.5"></path>
                <path d="M8.25,9.75h10.5"></path>
                <path d="M8.25,12.75h7.5"></path>
            </g>
        </svg>
    );
};
