import axios from "axios";
import APIHost from "../APIHost";
import ApiUtil from "../Utils/ApiUtil";

export interface ProductName {
    id: number;
    name: string;
    styleCode: string;
}

export interface ProductNameSearchResponse {
    products: ProductName[];
}

export async function ProductNameSearch(searchCriteria: string): Promise<ProductNameSearchResponse> {
    var headers = new ApiUtil().DefaultHeaders('');

    if (headers === null) {
        throw new Error ('Unauthenticated');
    }

    let result;

    try {
        result = await axios.post<ProductNameSearchResponse>(new APIHost().ProductsAPI("GetProductNames"), { keyword: searchCriteria, moduleId: new ApiUtil().GetModuleId() }, headers);
    } catch (e) {
        return Promise.reject(e);
    }

    return result.data;
}