import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Header } from "../../Components/Global/Header";
import Loading from "../../Components/Global/Loading";
import NavigationMenu from "../../Components/Global/NavigationMenu";
import SideMenu from "../../Components/Global/SideMenu";
import { CustomHomepageContent } from "../../Components/PageElements/CustomHomepage/CustomHomepageContent";
import { getIsCustomHomepageEnabled } from "../../Redux/Selectors/SettingsSelectors";
import { getUserInfoFetched } from "../../Redux/Selectors/UserSelectors";
import { CategoriesRoute } from "../../Routes";
import { useIsWindowSmall } from "../../Utils/Global/UseWindowSizeHook";

export const CustomHomepage = () => {
    const smallScreen = useIsWindowSmall();
    const isCustomHomepageEnabled = useSelector(getIsCustomHomepageEnabled);
    const isUserInfoFetched = useSelector(getUserInfoFetched);

    if (isUserInfoFetched && !isCustomHomepageEnabled) {
        return <Redirect to={CategoriesRoute} />;
    }
    else {
        return (
            <>
                <SideMenu />
                <div id="Body-Content">
                    <Header />
                    {!isUserInfoFetched && <Loading inLine />}
                    {isUserInfoFetched &&
                        <div className={"content-container"}>
                            {!smallScreen && <NavigationMenu />}
                            <CustomHomepageContent />
                        </div>}
                </div>
            </>
        );
    }
};