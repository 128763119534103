import React, {Component, SyntheticEvent} from 'react';
import CloseIcon from '../../../Images/Icons/Global/CloseIcon';
import NextArrowIcon from '../../../Images/Icons/NavigationIcons/NextArrowIcon';
import PrevArrowIcon from '../../../Images/Icons/NavigationIcons/PrevArrowIcon';
import Image from '../../Global/Image';
import { GetProductImageUrl } from '../../../Utils/ProductsHelper';

import './Styles/_ProductGalleryModal.scss';

interface componentProps {
    _props: {
    closeModal: () => void,
    prevImageClick: (e: SyntheticEvent) => void,
    prevImage: () => void,
    nextImageClick: (e: SyntheticEvent) => void,
    nextImage: () => void,
    galleryImages: any[],
    currentImage: number,
    }
}
 
interface componentState {
    totalImages: number;
    galleryIndex: number;
}

class ProductGalleryModal extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            totalImages: 0,
            galleryIndex: this.props._props.currentImage
        };
        this._handleKeyPress = this._handleKeyPress.bind(this);
    }

    componentDidMount() {
        if (this.props._props.galleryImages) {
            this.setState({
                totalImages: this.props._props.galleryImages.length - 1
            });
        }
        document.addEventListener("keydown", this._handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyPress, false);
    }

    render() {

        const { _props } = this.props;
        const { totalImages } = this.state;
        
        const renderGalleryOptions = _props.galleryImages.map((item: any, index: number) => {
            let productImageUrl = GetProductImageUrl(item.full);
            return (
                <div className={`gallery-image-modal ${index === _props.currentImage ? "active" : "inactive"}`} key={index} index-item={index}>
                    <Image imagePath={productImageUrl} altText={`product-${index}`} />
                </div>
            );
        });
        
        return (
            <>
                <div id="Product-Gallery-Modal">
                    <div className="modal-overlay" onClick={_props.closeModal}></div>
                    <div className="gallery-container">
                        <div className="gallery-close-container" onClick={_props.closeModal}>
                            <CloseIcon strokeColor={'#fff'} />
                        </div>
                        <div id="Product-Gallery-View" className="gallery-list-content">
                            {renderGalleryOptions}
                        </div>
                        {_props.currentImage < totalImages && <div className="next-image" onClick={_props.nextImageClick}>
                            <NextArrowIcon />
                        </div>}
                        {_props.currentImage !== 0 && <div className="prev-image" onClick={_props.prevImageClick}>
                            <PrevArrowIcon />
                        </div>}
                    </div>
                </div>
            </>
        );
    }

    _handleKeyPress = (event: KeyboardEvent) => {
        if (event.code === "Escape") {
            this.props._props.closeModal();
        }
        else if (event.code === "ArrowLeft") {
            this.props._props.prevImage();
        }
        else if (event.code === "ArrowRight") {
            this.props._props.nextImage();
        }
    }
}

export default ProductGalleryModal;
