import PromotionOrderLine from '../Interfaces/PromoMatrix/PromotionOrderLine';
import ContractPricePromotionDisplay from "../Interfaces/PromoMatrix/ContractPricePromotionDisplay";
import StandardPromotionDisplay from "../Interfaces/PromoMatrix/StandardPromotionDisplay";
import { CartItem } from '../Redux/Reducers/App_Reducers/OrderReducer';
import { ProductDetailsOption } from '../Redux/Reducers/App_Reducers/ProductDetailsReducer';
import { getContractPrice } from './ProductsHelper';
import AppliedOrderLineDiscount from '../Interfaces/PromoMatrix/AppliedOrderLineDiscount';

export default class PromotionsHelper {

    static MapOrderItemsForPromotions(orderItems : CartItem[], compareToSpecialPrice: boolean
    ): PromotionOrderLine[]{
        const overallArray : PromotionOrderLine[] = [];
        orderItems.forEach(x => {
            if(x.productOptions){
                x.productOptions.forEach((productOption : ProductDetailsOption) => {
                    const {price, retailPrice, specialPrice, optionId, sizeId, productCategories, 
                        uomId, sizeRangeId, uomSize, baseUnitPrice} = productOption;
            
                    let productQuantity = productOption.productQuantity;
                    if(!productQuantity){ productQuantity = 0;}
            
                    let unitPrice = price ? price : 0;
                    if( uomId && uomSize && baseUnitPrice){
                        unitPrice = baseUnitPrice * uomSize;
                    }
            
                    overallArray.push({
                        price: unitPrice,
                        retailPrice:  0, // can be used to compare price column as a discount to retail
                        uomPrice: uomId ? price : 0,
                        quantity: productQuantity,
                        specialPrice: specialPrice && !Number.isNaN(specialPrice) ? specialPrice : 0,
                        lineDiscount: 0,
                        productId: x.productId,
                        priceBreakId: !Number.isNaN(optionId) ? optionId : 0,
                        stockId: sizeId ? sizeId : uomId ? uomId : 0, 
                        skuRangeId: sizeRangeId && sizeRangeId > 0 ? sizeRangeId : undefined,
                        categoryIdArray: "",
                        productCategories: productCategories,
                        appliedDiscounts:  [],
                        total: 0,
                        uomSize: uomSize
                    });
                })
            }
        });
        return overallArray;
    }

    static AssignRowIdToOrderedLineItems(lineItems: PromotionOrderLine[]): void{
        lineItems.forEach((x, i) => x.rowId = i);
    }

    static MapBasketBackToOrderItems(basket: PromotionOrderLine[], orderItems: CartItem[]) {
        if (!basket || !orderItems) {
            return orderItems;
        }
        orderItems.forEach(orderItem => {
            basket.forEach(basketLineItem => {
                if(!orderItem.productOptions){ return }
                const matchingProductOption = PromotionsHelper.GetMatch(orderItem.productOptions, basketLineItem)
                if (matchingProductOption) {
                    if(!matchingProductOption.productQuantity){
                        matchingProductOption.productQuantity = 0;
                    }
                    matchingProductOption.lineItemDiscount = basketLineItem.lineDiscount;
                    matchingProductOption.contractPrice = getContractPrice(basketLineItem.appliedDiscounts);
                    if(matchingProductOption.lineItemDiscount > 0){
                        let matchingPromo = basketLineItem.appliedDiscounts
                        .filter((x : AppliedOrderLineDiscount) => x.discount === matchingProductOption.lineItemDiscount);
                        if(matchingPromo?.length > 0){
                            matchingProductOption.discountSource = matchingPromo[0].discountSource
                        }
                    }
                }
            });
        });
        
        return orderItems;
    }

    static GetMatch(productOptions:ProductDetailsOption[], lineItem: PromotionOrderLine  ){
        return productOptions.find(x => {
            if(x.optionId !== lineItem.priceBreakId){
                return false;
            }
            if(x.sizeId){
                return x.sizeId === lineItem.stockId;
            }
            if(x.uomId){
                return x.uomId === lineItem.stockId;
            }
            return !x.uomId && !lineItem.stockId;  
        });
    }

    static ShouldShowPromotionDescriptions(promoDescriptions : StandardPromotionDisplay[]|ContractPricePromotionDisplay[]){
        return Array.isArray(promoDescriptions) && promoDescriptions.length > 0
    }

    static ShouldShowContractPriceDescriptions(contractPricePromoDescriptions : ContractPricePromotionDisplay[]){
        return this.ShouldShowPromotionDescriptions(contractPricePromoDescriptions) && 
        !(contractPricePromoDescriptions.length === 1 && contractPricePromoDescriptions[0].quantityRange === "0+")
    }
}