
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import "./Styles/_ContactUs.scss";
import { ResetContactUsForm } from "../../Redux/Actions/BrandingActions";
import { Redirect} from "react-router";
import { ContactUsForm } from "./ContactUsForm";

export const ContactUs = (reload: boolean) => {
  const brandingInfo = useSelector((state: RootState) => state.brandingReducer);

  if (brandingInfo?.contactUsEmailStatus) {
    ResetContactUsForm();
    return (
      <Redirect
        to={{
          pathname: "/contact-us-confirmation",
        }}
      />
    );
  }

  return <ContactUsForm />;
};

