import { BemHelper, Typography } from '@cin7/ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TotalsPanel } from '../../../Components/PageElements/Orders/TotalsPanel';
import { CheckoutFooter } from '../../../Components/PageElements/Orders/CheckoutFooter';
import { EmptyCart } from '../../../Components/PageElements/Orders/EmptyCart';
import { B2BPageTitles } from '../../../Language/Default_Settings';
import { getOrderErrorMessage, getOrderReducer, getPaymentUrl, getShouldRedirectToConfirmation, 
    getShouldRedirectToPayment, getPaymentFailed } from '../../../Redux/Selectors/OrderSelectors';
import { Delivery } from '../../../Components/PageElements/Orders/Delivery';
import { Billing } from '../../../Components/PageElements/Orders/Billing';
import { Redirect } from 'react-router';
import Cin7ConfirmationBox from '../../../Components/PageElements/Cin7ConfirmationBox';
import { ResetCheckoutState, ResetOrderPaymentRedirect, ForceStopDisplayingPlacingOrder, 
    SetOrderFailAndErrorMessage, SetPaymentFailed } from '../../../Redux/Actions/OrderActions';
import { GetUrlParameters } from '../../../Utils/B2BHelpers';
import { RootState } from "../../..";

import "../Styles/_Checkout.scss";

const bem = new BemHelper("checkout-page");

export const Checkout = () => {
    const cart = useSelector(getOrderReducer);
    const cartItems = cart.orderItems;
    const cartItemsCount = cartItems.length;
    const genericPaymentError = "Payment Failed. Please try again.";
    const cartError = useSelector(getOrderErrorMessage);
    const shouldRedirectToPayment = useSelector(getShouldRedirectToPayment);
    const shouldRedirectToConfirmation = useSelector(getShouldRedirectToConfirmation);
    const moduleSettings = useSelector((state: RootState) => state.identityReducer.moduleSettings);
    const hasCustomCheckoutSummary = !!moduleSettings.checkoutSummaryText;
    const paymentUrl = useSelector(getPaymentUrl);
    const didPaymentFail = useSelector(getPaymentFailed);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.order_checkout_title}`;
        const parameters = GetUrlParameters(["redirectToCheckout"]);

        if (parameters && parameters["redirectToCheckout"]) {
            dispatch(ForceStopDisplayingPlacingOrder());
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(ResetCheckoutState());
    }, [dispatch]);

    useEffect(() => {
        if (shouldRedirectToPayment && paymentUrl) {
            window.location.href = paymentUrl;
            dispatch(ResetOrderPaymentRedirect());
        }
    }, [dispatch, shouldRedirectToPayment, paymentUrl]);

    useEffect(() => {
        const parameters = GetUrlParameters(["failed", "msg"]);
        if(parameters && parameters["failed"] && parameters["msg"]){
            dispatch(SetPaymentFailed(true));
            dispatch(SetOrderFailAndErrorMessage(decodeURIComponent(parameters["msg"])));
        }
    }, [dispatch, cartError]);
    
    const CustomCheckoutSummary = () => {
        if (hasCustomCheckoutSummary) {
            return (
            <div className="checkout-summary">
                <aside className="summary-text">{moduleSettings.checkoutSummaryText}</aside>
            </div>)
        } else return null;
    };

    if (shouldRedirectToConfirmation) {
        return (
            <Redirect to={
                {
                    pathname: '/orders/complete'
                }
            } />
        );
    }
    else {
        return (
            <>
                <div className={bem.block()}>
                    <div className={bem.element('checkout-inner-content')}>
                        {
                            cartItemsCount > 0 && 
                            <div className={bem.element('header')}>
                                <Typography variant={"h1"} className={bem.element('header-text')} >
                                    Checkout
                                </Typography>
                            </div>
                        }
                        {cartError && (
                            <div className={bem.element("error-container")}>
                                <Cin7ConfirmationBox
                                    boxType={"error-box"}
                                    boxIcon={"error-icon"}
                                    boxMessage={didPaymentFail ? genericPaymentError : cartError}
                                    boxSubMessage={didPaymentFail ? cartError : ""}
                                />
                            </div>
                        )}
                        <div className={bem.element("checkout-totals-mobile")}>
                            {cartItemsCount > 0 && <TotalsPanel />}
                            <CustomCheckoutSummary />
                        </div>
                        {cartItemsCount === 0 && (
                            <div className={bem.element('empty-cart-container')}><EmptyCart /></div>
                        )}
                        {
                            cartItemsCount > 0 && 
                            <>
                                <Delivery />
                                <Billing />
                                <div className={bem.element('checkout-totals-footer')}><CheckoutFooter displayCompleteButton /></div>
                            </>
                        } 
                    </div>
                    {cartItemsCount > 0 && (
                        <div className={bem.element("checkout-totals-desktop")}>
                            <TotalsPanel />
                            <CheckoutFooter displayCompleteButton />
                            <CustomCheckoutSummary />
                        </div>
                    )}
                </div>
                <div className="checkout-footer-mobile">{cartItemsCount > 0 && <CheckoutFooter displayCompleteButton />}</div>
            </>
        );
    }
};
