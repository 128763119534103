import React, {Component} from 'react';
import PriceDisplay from '../../Global/PriceDisplay';
import { connect } from 'react-redux';

import './Styles/_ProductOptionPrice.scss';
import { ProductDetailsOption } from '../../../Redux/Reducers/App_Reducers/ProductDetailsReducer';

type ProductDetailsReducerProduct = {
    minRangeContractPrice: number;
    maxRangeContractPrice: number;
}

interface componentProps {
    option: ProductDetailsOption;
    forceHideSpecialPrice?: boolean;
    hidePriceWhileFetchingPromotionCalculations: boolean;
    productDetailsReducerProduct: ProductDetailsReducerProduct;
}
 
interface componentState {}
 
class ProductOptionPrice extends Component<componentProps, componentState> {

    static defaultProps = {
        forceHideSpecialPrice: false
    }

    constructor(props: componentProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { 
            option, 
            forceHideSpecialPrice, 
            hidePriceWhileFetchingPromotionCalculations, 
            productDetailsReducerProduct 
        } = this.props;
        const hidePriceWhenNotAddingProductDetailsReducerProductToCalcPromos = 
            !hidePriceWhileFetchingPromotionCalculations 
            && (Boolean(productDetailsReducerProduct?.minRangeContractPrice 
            && productDetailsReducerProduct?.maxRangeContractPrice) || option.contractPrice);
        const hidePriceWhenAddingProductDetailsReducerProductToCalcPromos = 
        hidePriceWhileFetchingPromotionCalculations && 
            (Boolean(productDetailsReducerProduct?.minRangeContractPrice && productDetailsReducerProduct?.maxRangeContractPrice) 
            || option.contractPrice); 

        let price = option.contractPrice ? option.contractPrice : option.price;

        if(option.contractPrice && option.uomSize){
            price*= option.uomSize;
        }

        if (option.specialPrice && !forceHideSpecialPrice && !option.contractPrice) {
            return (
                <div className="product-details-option-price">{
                    hidePriceWhenNotAddingProductDetailsReducerProductToCalcPromos ? <></> :
                    <><span className="special-price">{<PriceDisplay value={option.specialPrice} />}</span> <span className="original-price">{<PriceDisplay value={option.price} />}</span></>
                }
                </div>
            );
        } else {
            return (
                <div className="product-details-option-price">
                    {hidePriceWhenAddingProductDetailsReducerProductToCalcPromos ? <></> : <PriceDisplay value={price ?? 0} />}
                </div>
            );
        }
    }
}

const MapStateToProps = (state: {orderReducer: any; productDetailsReducer : any;}) => ({
    hidePriceWhileFetchingPromotionCalculations: state.orderReducer?.hidePriceWhileFetchingPromotionCalculations,
    productDetailsReducerProduct: state.productDetailsReducer?.product
});
 
export default connect(MapStateToProps, {})(ProductOptionPrice);