import React, { Component, CSSProperties } from 'react';

interface componentProps {
    strokeColor?: string;
}

interface componentStates extends componentProps { }

class EmptyBox extends Component<componentProps, componentStates> {

    constructor(props: componentProps) {
        super(props);
        this.state = {
            strokeColor: "#000"
        }
    }

    componentDidMount() {
        if (this.props.strokeColor) {
            this.setState({
                strokeColor: this.props.strokeColor
            });
        }
    }

    render() {
        const { strokeColor } = this.state;

        const cls1: CSSProperties = {
            stroke: strokeColor
        }

        return (
            <svg width="328" height="328" viewBox="0 0 328 328" fill="none" xmlns="https://www.w3.org/2000/svg">
                <path d="M163.364 159.559L70.3987 116.078L163.364 80.2715L256.329 115.44L163.364 159.559Z" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M256.329 169.151V235.01L163.364 279.131V159.559" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M70.3987 169.791V235.65L163.364 279.132" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M163.364 80.2718L209.846 50.8584" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M256.329 115.44L301.596 86.0952" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M209.846 50.8584L301.596 86.0954" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M163.364 80.2714L120.574 48.8687" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M70.3986 116.079L26.4039 84.1074" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M120.574 48.8687L26.4039 84.1073" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M163.364 159.559L204.305 194.087" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M256.329 115.44L297.104 149.329" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M204.305 194.088L297.104 149.329" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M70.3986 116.079L28.5343 148.689" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M163.364 159.559L122.114 192.81" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M28.5343 148.689L122.114 192.81" style={cls1} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default EmptyBox;