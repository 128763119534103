import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { UpdatePoNumber } from './../../../Redux/Actions/OrderActions';
import { StripHTMLFromInputs } from '../../../Utils/B2BHelpers';

import './Styles/_InvoiceParticularPanel.scss';

interface componentProps {
    UpdatePoNumber: (poNumberInput: string) => void;
    order: {
        orderDetails: {
            poNumber: string;
        }
    }
    editMode: boolean;
}

interface componentStates { }

class InvoiceParticularPanel extends Component<componentProps, componentStates> {
    render() {
        const { order, editMode } = this.props;
        
        return (
            <div id="Invoice-Particular-Panel">
                <div className="panel-container">
                    {(editMode || (!editMode && order.orderDetails.poNumber)) && <div className={`panel-content-group ${!editMode ? "non-edit-mode" : ""}`}>
                        <div className="panel-group-label">{editMode ? "Purchase Order Number" : "Purchase Order Number:"}</div>
                        <div className="panel-group-field">
                            {editMode ?
                                <Input maxLength={255} onChange={this._onChangePoNumber} value={order.orderDetails.poNumber} /> : order.orderDetails.poNumber
                            }
                        </div>
                    </div>}
                </div>
            </div>
        );
    }

    _onChangePoNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        let targetInput = StripHTMLFromInputs(e.currentTarget.value);
        
        this.props.UpdatePoNumber(targetInput);
    }
}

const MapStateToProps = (state: {orderReducer: any}) => ({
    order: state.orderReducer
});

export default connect(MapStateToProps, {UpdatePoNumber})(InvoiceParticularPanel);