import * as types from "../../Types/BrandingTypes";

export type BrandingStateType = {
    brandFound: boolean;
    storeName: string;
    moduleId: number;
    loginPageImageUrl: string;
    loginBannerImageUrl: string;
    isGettingBrandInfo: boolean;
    contactFormEnabled: boolean;
    contactUsEmailStatus: boolean;
    isContactFormEmailRequested: boolean;
    isContactFormEmailRequestHasResponse: boolean;
    isReCaptchaValidated: boolean;
};

const initialState: BrandingStateType = {
    brandFound: false,
    storeName: "",
    moduleId: 0,
    loginPageImageUrl: "",
    loginBannerImageUrl: "",
    isGettingBrandInfo: false,
    contactFormEnabled: false,
    contactUsEmailStatus: false,
    isContactFormEmailRequested: false,
    isContactFormEmailRequestHasResponse: false,
    isReCaptchaValidated: false,
};

export const BrandingReducer = (
    state = initialState,
    action: { type: string; payload: any | undefined }
): BrandingStateType => {

    switch (action.type) {
        case types.Display_Store_Brand_Info:
            return {
                ...state,
                storeName: action.payload.storeName,
                moduleId: action.payload.moduleId,
                loginPageImageUrl: action.payload.loginPageImageUrl,
                loginBannerImageUrl: action.payload.loginBannerImageUrl,
                brandFound: true,
                isGettingBrandInfo: false,
                contactFormEnabled: action.payload.contactFormEnabled,
            };
        case types.Display_Store_Brand_Not_Found:
            return {
                ...state,
                storeName: initialState.storeName,
                moduleId: 0,
                loginPageImageUrl: action.payload.loginPageImageUrl,
                loginBannerImageUrl: action.payload.loginBannerImageUrl,
                brandFound: false,
                isGettingBrandInfo: false,
                contactFormEnabled: action.payload.contactFormEnabled,
            };
        case types.Reset_Brand_Info:
            return {
                ...state,
                storeName: initialState.storeName,
                moduleId: 0,
                loginPageImageUrl: "",
                loginBannerImageUrl: "",
                brandFound: false,
                isGettingBrandInfo: false,
                contactFormEnabled: false,
            };
        case types.Getting_Brand_Info:
            return {
                ...state,
                isGettingBrandInfo: true,
            };
        case types.Update_Contact_Form_Email_Status:
            return {
                ...state,
                contactUsEmailStatus: action.payload.success,
            };
        case types.Reset_Contact_Form_Email_Status:
            return {
                ...state,
                contactUsEmailStatus: false,
                isContactFormEmailRequested: false,
                isContactFormEmailRequestHasResponse: false,
                isReCaptchaValidated: false,
            };
        case types.Contact_Form_Email_Requested:
            return {
                ...state,
                isContactFormEmailRequested: true,
            };
        case types.Contact_Form_Email_Request_Has_Response:
            return {
                ...state,
                isContactFormEmailRequestHasResponse: true,
            };
        case types.Update_ReCaptcha_Validation_Result:
            return {
                ...state,
                isReCaptchaValidated: action.payload.success,
            };
        default:
            return state;
    }
};
