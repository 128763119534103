import React, { Component } from "react";
import { connect } from "react-redux";
import { UpdateProductsFilter, DisplayFilterMenu, ProductsListType } from "../../../Redux/Actions/ProductListActions";
import { GetCategoriesList } from "../../../Redux/Actions/ProductListActions";
import {
    Button,
    Checkbox,
    Input,
    Accordion,
    CloseCircleIcon,
    CloseIcon,
    SearchIcon,
    AccordionDetails,
    AccordionSummary,
    Typography,
    FormControlLabel,
} from "@cin7/ui";

import "./Styles/_ProductsListBrandFilter.scss";

interface componentProps {
    productsList: ProductsListType;
    auth: {
        subscribedToken: string;
    };
    UpdateProductsFilter: (filter: { brandIds?: number[]; categoryIds?: number[] }) => void;
    DisplayFilterMenu: (toggledByUser: boolean) => void;
}

interface componentState {
    brands: any[];
    brandIds: number[];
    brandSearchParameter: string;
}

class ProductsListBrandFilter extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            brands: this.props.productsList.brands,
            brandIds: this.props.productsList.filter.brandIds,
            brandSearchParameter: "",
        };
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.productsList.brands !== this.props.productsList.brands) {
            this.setState({
                brands: this.props.productsList.brands,
            });
        }

        if (prevProps.productsList.filter.brandIds !== this.props.productsList.filter.brandIds) {
            this.setState({
                brandIds: this.props.productsList.filter.brandIds,
            });
        }
    }

    render() {
        const { productsList } = this.props;
        const { brands, brandIds, brandSearchParameter } = this.state;
        return (
            <>
                <div id="Filter-Sidebar" className={productsList.filter.filterMenuOpen ? "active" : ""}>
                    <div
                        id="Products-List-Brand-Filter"
                        className={`${productsList.filter.filterMenuOpen ? " active" : ""}`}
                    >
                        {productsList.filter.filterMenuOpen && (
                            <div className="products-filter">
                                <h2>Filter</h2>
                                <div
                                    className={"filter-close-icon"}
                                    onClick={() => this.props.DisplayFilterMenu(false)}
                                >
                                    <CloseIcon />
                                </div>

                                <div className="filter-action-buttons">
                                    <Button
                                        id={"Filter-Action-Apply"}
                                        className="filter-action-apply"
                                        onClick={this._applyFilter}
                                    >
                                        Apply Filter
                                    </Button>
                                    <Button
                                        id={"Filter-Action-Clear"}
                                        className="filter-action-clear"
                                        variant={"secondary"}
                                        onClick={this._clearFilter}
                                    >
                                        Clear
                                    </Button>
                                </div>
                                <div className="filters-heading">
                                    Filters<span>&nbsp;</span>
                                </div>
                                <div className="filters">
                                    {productsList.brands.length > 0 ? (
                                        <Accordion elevation={0} defaultExpanded>
                                            <AccordionSummary>
                                                <Typography variant={"h4"}>Brand</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="brand-filter filter">
                                                    <Input
                                                        id={"Brand-Search-Box"}
                                                        className="brand-search-box"
                                                        onChange={(e) => this._onSearchBrand(e.target.value)}
                                                        value={brandSearchParameter}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <div className="input-icon">
                                                                    <SearchIcon />
                                                                </div>
                                                            ),
                                                            endAdornment: brandSearchParameter && (
                                                                <div
                                                                    className="input-icon"
                                                                    onClick={() => this._onSearchBrand("")}
                                                                >
                                                                    <CloseCircleIcon />
                                                                </div>
                                                            ),
                                                        }}
                                                    />
                                                    <div className="brands-list">
                                                        {brands.map((brand: any) => (
                                                            <FormControlLabel
                                                                key={brand.id}
                                                                value="top"
                                                                control={
                                                                    <Checkbox
                                                                        key={brand.id}
                                                                        id={brand.id.toString()}
                                                                        value={brand.id}
                                                                        checked={brandIds.includes(brand.id)}
                                                                        onChange={this._onChangeBrand}
                                                                    />
                                                                }
                                                                label={brand.name}
                                                                labelPlacement="end"
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }

    _clearFilter = () => {
        this.setState({
            brands: this.props.productsList.brands,
            brandIds: [],
            brandSearchParameter: "",
        });

        const filter = {
            brandIds: [],
        };
        this.props.UpdateProductsFilter(filter);
    };

    _applyFilter = () => {
        const filter = {
            brandIds: this.state.brandIds,
        };

        this.props.UpdateProductsFilter(filter);
        this.props.DisplayFilterMenu(false);
    };

    _onChangeBrand = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        var brands: number[] = [];

        if (checked) {
            brands.push(Number(e.target.value));

            this.setState({
                brandIds: this.state.brandIds.concat(brands),
            });
        } else {
            var currentBrandIds = this.state.brandIds.slice();
            var index = currentBrandIds.findIndex((x) => x.valueOf() === Number(e.target.value));
            currentBrandIds.splice(index, 1);

            brands = currentBrandIds;

            this.setState({
                brandIds: brands,
            });
        }
    };

    _onSearchBrand = (value: string | number) => {
        var brands: any[] = this.props.productsList.brands.filter((x: { name: string }) =>
            x.name.toLowerCase().includes(String(value).toLowerCase())
        );

        this.setState({
            brands: brands,
            brandSearchParameter: String(value),
        });
    };
}

const MapStateToProps = (state: { productListReducer: any; authReducer: any }) => ({
    productsList: state.productListReducer,
    auth: state.authReducer,
});

export default connect(MapStateToProps, {
    UpdateProductsFilter,
    GetCategoriesList,
    DisplayFilterMenu,
})(ProductsListBrandFilter);
