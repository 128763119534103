import React from 'react';

interface ComponentProps {
    strokeColor: string;
}

export const UserIcon: React.FC<ComponentProps> = (props) => {
    const { strokeColor } = props;
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="https://www.w3.org/2000/svg">
            <path d="M19.5 21.5L4.5 21.5V16C4.5 16 5 15 8 13.8794C11 12.7588 13 12.7588 16 13.8794C19 15 19.5 16 19.5 16V21.5Z" stroke={strokeColor} strokeLinejoin="round" />
            <path d="M15.5 6.5C15.5 4.567 13.933 3 12 3C10.067 3 8.5 4.567 8.5 6.5C8.5 8.433 10.067 10 12 10C13.933 10 15.5 8.433 15.5 6.5Z" stroke={strokeColor} />
        </svg>
    );
}