import React, { Component } from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';

//Assets
import ClearInputIcon from './../../Images/Icons/ClearInputIcon';

interface componentProps {
    fieldClass?: string;
    fieldErrorId?: string;
    formGroupClass?: string;
    fieldId: string;
    fieldIndex?: number;
    fieldLabel?: string;
    fieldName?: string;
    fieldType?: string | any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
}

interface componentStates extends Partial<componentProps> {}

class InputField extends Component<componentProps, componentStates> {


    render() { 
        
        const {fieldErrorId, fieldId, fieldIndex, fieldName, fieldType, fieldClass, fieldLabel, formGroupClass} = this.props;

        return ( 
            <>
            <FormGroup className={`cin7-form-group ${formGroupClass ? formGroupClass : "default-group"}`}>
                <Input 
                    type={fieldType ? fieldType : "text"}
                    id={fieldId} 
                    name={fieldName} 
                    className={`${fieldClass ? fieldClass : "default-input"} cin7-input input-field`} 
                    placeholder={" "}
                    onFocus={this._inputOnFocus} 
                    onBlur={this._inputOnBlur} 
                    onChange={this._onChange} 
                    tabIndex={fieldIndex}
                />
                <Label className="cin7-float-label" for={fieldId}>{fieldLabel ? fieldLabel : "Default Label"}</Label>
                <Button className="cin7-custom-input-icon clear-icon" onClick={this._onClear} tabIndex={-1}><ClearInputIcon /></Button>
            </FormGroup>
            <span id={fieldErrorId} className="validation-message hide"></span>
            </>
         );
    }

    _inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        let targetFormGroup: any | undefined = e.currentTarget.parentNode;
        targetFormGroup.classList.add("active");
    }

    _inputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        let targetFormGroup: any | undefined = e.currentTarget.parentNode;

        if(!e.currentTarget.value) {
            targetFormGroup.classList.remove("active");
        }
    }

    _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(e);

        let targetFormGroup: any | undefined = e.currentTarget.parentNode;
        let clearInputButton = targetFormGroup.querySelector(".cin7-custom-input-icon");

        if(clearInputButton !== null){
            if(e.currentTarget.value) {
                clearInputButton.classList.add("active");
            }
            else {
                clearInputButton.classList.remove("active");
            }
        }
    }

    _onClear = (e:React.MouseEvent<any, MouseEvent>) => {
        e.preventDefault();

        let targetFormGroup = e.currentTarget.parentNode;
        let targetInput = targetFormGroup.querySelector(".cin7-input");
        
        targetInput.value = '';

        e.currentTarget.classList.remove("active");
        targetFormGroup.classList.remove("active");

        this.props.onClear();

        targetInput.focus();
    }

}
 
export default InputField;