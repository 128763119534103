import * as React from "react";
import ContractPricePromotionDisplay from "../../../Interfaces/PromoMatrix/ContractPricePromotionDisplay";

import "./Styles/_ProductContractPricePromotion.scss"

interface IProductContractPricePromotionsProps {
    descriptions : ContractPricePromotionDisplay[];
    showHeaderTitle? : boolean;
}

export const ProductContractPricePromotion : React.FC<IProductContractPricePromotionsProps> = ({descriptions, showHeaderTitle=false}) => {
    
    return (
        <>
        { descriptions.length > 0 && 
            <div data-testid="promo-list-container" className="promotions-list-container">
                {showHeaderTitle && <div className="promotion-description header">Volume Pricing</div>}
                <div className="contract-pricing-container">
                    <div className="header">Quantity</div>
                    <div className="header">Price</div>
                </div>
                {descriptions.map((cpd:ContractPricePromotionDisplay, i:number) => {
                    return (
                        <div className="contract-pricing-container" key={`contract-price-container-${i}`}>
                            <div className="promotion-description-quantity" key={`contract-quantity-${i}`}>{cpd.quantityRange}</div>
                            <div className="promotion-description-price" key={`contract-price-${i}`}>{cpd.price}</div>
                        </div>
                    )
                })}
            </div>
        }
        </>
    )
}