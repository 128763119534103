import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { ForgotPasswordClearState } from '../../../Redux/Actions/IdentityActions';
import ForgotPasswordForm from './../../../Components/AppForms/ForgotPasswordForm';

interface componentProps extends RouteComponentProps<any> {
    forgotPassword: {
        isForgotPasswordLinkSent: boolean
    }
    ForgotPasswordClearState: () => void;
}

class ForgotPassword extends Component<componentProps, {}> {

    componentDidMount() {
        this.props.ForgotPasswordClearState();
    }

    render(){

        const { forgotPassword, location } = this.props;

        if (forgotPassword.isForgotPasswordLinkSent) {
            this.props.ForgotPasswordClearState();
            return (
                <Redirect to={
                    {
                        pathname: location.pathname + '/confirmation',
                        state: {from: location}
                    }
                } />
            )
        }

        return(
            <ForgotPasswordForm />
        );
    }
}

const ForgotPasswordWithRouter = withRouter(ForgotPassword);

const MapStateToProps = (state: {identityReducer: any}) => ({
    forgotPassword: state.identityReducer
});

export default connect(MapStateToProps, {ForgotPasswordClearState})(ForgotPasswordWithRouter);