import React, { Component } from 'react';

interface componentProps {}

interface componentStates extends componentProps {}

class Cin7Logo extends Component<componentProps,componentStates> {
    render() {

        return (
            <svg width="1103px" height="432px" viewBox="0 0 1103 432" version="1.1" xmlns="https://www.w3.org/2000/svg">
                <title>Cin7</title>
                <g id="cin7-blue" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(1.000000, 0.000000)">
                    <g id="cin7" transform="translate(50.000000, 82.000000)">
                        <path d="M458.7166,45.4808 C504.3966,45.4808 530.0566,73.1148 535.9766,108.3608 L481.2746,108.3608 C478.1736,99.0558 470.2786,93.1338 458.7166,93.1338 C438.9796,93.1338 427.9826,110.8988 427.9826,134.8658 C427.9826,158.5518 438.9796,176.5978 458.7166,176.5978 C470.2786,176.5978 478.1736,170.6768 481.2746,161.0898 L535.9766,161.0898 C530.0566,196.6178 504.3966,224.2518 458.7166,224.2518 C408.2436,224.2518 372.9976,186.7498 372.9976,134.8658 C372.9976,82.9838 408.2436,45.4808 458.7166,45.4808" id="Fill-1" fill="#002DFF"></path>
                        <polygon id="Fill-3" fill="#002DFF" points="577.428 221.432 632.131 221.432 632.131 48.301 577.428 48.301"></polygon>
                        <polygon id="Fill-5" fill="#002DFF" points="835.662 221.432 790.828 221.432 727.666 134.866 727.666 221.432 674.937 221.432 674.937 48.301 725.974 48.301 782.933 128.946 782.933 48.301 835.662 48.301"></polygon>
                        <polygon id="Fill-6" fill="#002DFF" points="953.245 221.432 898.261 221.432 943.376 94.826 875.985 94.826 875.985 48.301 1001.744 48.301 1001.744 83.547"></polygon>
                        <polygon id="Fill-7" fill="#7F96FF" points="101.4935 274.5824 129.0095 287.8334 137.0185 291.6894 94.8525 106.9704 63.2385 106.9704"></polygon>
                        <polygon id="Fill-8" fill="#7F96FF" points="30.4481 240.3695 65.9721 257.4765 31.6211 106.9705 0.0001 106.9705"></polygon>
                        <polygon id="Fill-12" fill="#7F96FF" points="177.9017 193.7904 158.0837 106.9704 126.4687 106.9704 162.0927 263.0524"></polygon>
                        <polygon id="Fill-9" fill="#002DFF" points="215.3431 270.058 184.5311 284.896 243.4531 26.743 264.7771 53.472"></polygon>
                        <polygon id="Fill-10" fill="#002DFF" points="276.9955 240.3695 246.1565 255.2205 286.1005 80.2115 307.4435 106.9735"></polygon>
                        <polygon id="Fill-11" fill="#002DFF" points="85.3094 0.0003 63.9824 26.7423 188.6014 26.7423 182.4974 53.4843 42.6564 53.4843 21.3294 80.2283 176.3934 80.2283 170.2904 106.9703 -0.0006 106.9703 6.1034 133.7123 164.1864 133.7123 129.0094 287.8333 153.7204 299.7323 222.1334 0.0003"></polygon>
                    </g>
                </g>
            </svg>
        );
    }
}

export default Cin7Logo;