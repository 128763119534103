import React, { useCallback, useEffect } from 'react';
import * as PageContent from '../../../Language/Default_Settings';
import { FreightOptionIcon } from '../../../Images/Icons/Orders/FreightOptionIcon';
import {
    GetFreightOptions,
    ResetSelectedFreightTier,
    ResetSelectedFreightTierAttribute,
    ResetSelectedFreightZone,
    SetSelectedFreightTier,
    SetSelectedFreightTierAttribute,
    SetSelectedFreightZone,
    SetFilteredFreightZones
} from '../../../Redux/Actions/FreightActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../..';
import { FormControlLabel, Radio, RadioGroup } from '@cin7/ui';
import { useCurrentFreightZone, useCurrentFreightTier, useHasFreightOptions, useHasFilteredZones } from '../../../Utils/FreightHelper';
import { FormatMoney } from '../../../Utils/OrderHelper';
import { getUserCurrency } from '../../../Redux/Selectors/UserSelectors';
import { getCartItemsHaveWeight, getCartItemsTotal, getCartItemsWeightTotal } from '../../../Redux/Selectors/OrderSelectors';
import { FreightTierPriceType, FreightTierType, FreightZoneType } from '../../../Redux/Reducers/App_Reducers/FreightReducer';

import './Styles/_FreightPanel.scss';

const FreightPanel = () => {
    const dispatch = useDispatch();

    const auth = useSelector((state: RootState) => state.authReducer);
    const freightReducer = useSelector((state: RootState) => state.freightReducer);
    const userCurrencyCode = useSelector(getUserCurrency);
    const cartItemsHaveWeight = useSelector(getCartItemsHaveWeight);
    const cartWeight = useSelector(getCartItemsWeightTotal);
    const cartTotal = useSelector(getCartItemsTotal);

    const getAvailableTierAttribute = useCallback((tier: FreightTierType) => {
        return tier.conditions?.find((condition) => ((tier.type === FreightTierPriceType.Weight && ((cartWeight >= condition.minimum && cartWeight <= condition.maximum) || (cartWeight >= condition.minimum && condition.maximum === null))) ||
        (tier.type === FreightTierPriceType.Value && ((cartTotal >= condition.minimum && cartTotal <= condition.maximum) || (cartTotal >= condition.minimum && condition.maximum === null)))));
    }, [cartWeight, cartTotal]);

    // On load, fetch the freight options if not already done
    useEffect(() => {
        if (freightReducer.freightOptionsFetched) return;
        dispatch(GetFreightOptions(auth.subscribedToken));
    }, [dispatch, auth, freightReducer.freightOptionsFetched]);

    useEffect(() => {
        if (freightReducer.freightTierAttribute?.id < 0) return;
        const tier = freightReducer.freightTier;
        const availableCondition = getAvailableTierAttribute(tier);
        // Reset the tier if cart items no longer have weight or the weight has changed so that the tier price is no longer valid.
        // Reset the tier if cart value is 0 or the value has changed so that the tier price is no longer valid.
        if ((tier.type === FreightTierPriceType.Weight && !cartItemsHaveWeight) || 
            (tier.type === FreightTierPriceType.Value && cartTotal <= 0) ||
            freightReducer.freightTierAttribute?.id !== availableCondition?.id) {
            dispatch(ResetSelectedFreightTier());
        }
    }, [dispatch, getAvailableTierAttribute, cartItemsHaveWeight, freightReducer.freightTier, freightReducer.freightTierAttribute, cartTotal]);


    // Check if a zone has only weight tiers
    const getOnlyWeightTiers = (zone: FreightZoneType) => {
        let onlyWeightTiers = zone.tiers?.some(tier => {
            return tier.type !== FreightTierPriceType.Weight;
        })
        return onlyWeightTiers;
    };

    // If the cart is missing weight remove zones with only weight tiers
    let filteredZones = freightReducer.freightOptions;
    if (!cartItemsHaveWeight) {
        filteredZones = filteredZones.filter(zone => getOnlyWeightTiers(zone));
    }

    useEffect(() => {
        dispatch(SetFilteredFreightZones(filteredZones.length > 0 ? true : false));
    }, [dispatch, filteredZones.length]);

    const selectedZone = useCurrentFreightZone();
    const selectedTier = useCurrentFreightTier();
    const hasFreightOptions = useHasFreightOptions();
    const hasFilteredZones = useHasFilteredZones();

    const handleChangeZone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const zoneId = (Number)((event.target as HTMLInputElement).value);
        const zone = freightReducer.freightOptions.find(o => o.id === zoneId);
        if (zone) {
            dispatch(SetSelectedFreightZone(zone));
            dispatch(ResetSelectedFreightTier());
        }
        else {
            dispatch(ResetSelectedFreightZone());
            dispatch(ResetSelectedFreightTier());
        }
    };

    const handleChangeTier = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tierId = (Number)((event.target as HTMLInputElement).value);
        const zone = freightReducer.freightOptions.find(o => o.id === selectedZone.id);
        if (zone?.tiers) {
            const tier = zone.tiers.find(o => o.id === tierId);
            if (tier) {
                dispatch(SetSelectedFreightTier(tier));
                const availableAttribute = getAvailableTierAttribute(tier);
                if ((tier.type === FreightTierPriceType.Weight || tier.type === FreightTierPriceType.Value) && availableAttribute) {
                    dispatch(SetSelectedFreightTierAttribute(availableAttribute));
                }
                else {
                    dispatch(ResetSelectedFreightTierAttribute());
                }
            }
            else {
                dispatch(ResetSelectedFreightTier());
            }
        }
        else {
            dispatch(ResetSelectedFreightZone());
            dispatch(ResetSelectedFreightTier());
        }
    };

    const getPriceText = (tier: FreightTierType) => {
        if ((tier.type === FreightTierPriceType.Weight || tier.type === FreightTierPriceType.Value) && tier.conditions) {
            const availableCondition = getAvailableTierAttribute(tier);
            if (availableCondition) {
                return availableCondition.price === null ? "To Be Confirmed" : FormatMoney(availableCondition.price, userCurrencyCode);
            }
        }
        return tier.type === FreightTierPriceType.ToBeConfirmed ? "To Be Confirmed" : FormatMoney(tier.price, userCurrencyCode);
    };

    const FreightOptionsContent = () => {        

        if (hasFreightOptions && hasFilteredZones) {

            return (
                <RadioGroup value={selectedZone.id} onChange={handleChangeZone} className={`freight-options-container ${hasFreightOptions && selectedTier.id < 0 && 'validation-message'}`}>
                    {filteredZones.map((zone) => {
                        return (
                            <div key={zone.id} className='freight-zone-option'>
                                <FormControlLabel value={zone.id} control={<Radio />} label={zone.name} />
                                {selectedZone.id === zone.id &&
                                    <RadioGroup value={selectedTier.id} onChange={handleChangeTier} className="freight-tiers-container">
                                        {zone.tiers && zone.tiers.map((tier) => {
                                            const priceText = getPriceText(tier);
                                            if (tier.type === FreightTierPriceType.Weight && !cartItemsHaveWeight) {
                                                return null;
                                            }
                                            else {
                                                return (
                                                    <div key={tier.id} className='freight-tier-option'>
                                                        <FormControlLabel value={tier.id} control={<Radio />} label={`${tier.name} - ${priceText}`} />
                                                    </div>
                                                );
                                            }
                                        })}
                                    </RadioGroup>}
                            </div>
                        );
                    })}
                </RadioGroup>
            );
        }
        else {
            return (
                <div className="freight-inner-content">
                    <FreightOptionIcon />
                    <div className="freight-option">
                        <div className="freight-label">{'TBC'}</div>
                        <div className="freight-description">{'Delivery information to be confirmed'}</div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div id="Freight-Panel">
            <div className="freight-title">{PageContent.OrderCheckoutPageContent.default_freightLabel}
                {hasFreightOptions && selectedTier.id < 0 && hasFilteredZones &&
                    <span className='freight-validation-message'>Select a freight option</span>}</div>
            {<FreightOptionsContent />}
        </div>
    );
};

export default FreightPanel;