import React, { SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import { SearchIcon } from "../../Images/Icons/Global/SearchIcon";
import { ResetSideMenu } from "../../Redux/Actions/HeaderActions";

import "./Styles/_HeaderSearch.scss";

type Props = {
    closeModal: (e: SyntheticEvent) => void;
};

const HeaderSearch = (props: Props) => {
    const dispatch = useDispatch();

    const onClickSearchBox = (e: SyntheticEvent) => {
        props.closeModal(e);
        dispatch(ResetSideMenu());
    };

    return (
        <div className="header-search-container" id="Header-Search" onClick={(e) => onClickSearchBox(e)}>
            <SearchIcon />
            <div className="search-label">Search</div>
        </div>
    );
};

export default HeaderSearch;
