import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getIsCustomHomepageEnabled } from "../../Redux/Selectors/SettingsSelectors";
import { getUserInfoFetched } from "../../Redux/Selectors/UserSelectors";
import { CategoriesRoute, CustomHompepageRoute } from "../../Routes";

export const Index = () => {
    const isCustomHomepageEnabled = useSelector(getIsCustomHomepageEnabled);
    const isUserInfoFetched = useSelector(getUserInfoFetched);

    if (!isUserInfoFetched || isCustomHomepageEnabled) {
        return <Redirect to={CustomHompepageRoute} />;
    }
    else {
        return <Redirect to={CategoriesRoute} />;
    }
};
