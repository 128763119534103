import * as React from "react";
import { Accordion, AccordionSummary, Typography, AccordionDetails,  } from "@cin7/ui";
import ContractPricePromotionDisplay from "../../../Interfaces/PromoMatrix/ContractPricePromotionDisplay";
import StandardPromotionDisplay from "../../../Interfaces/PromoMatrix/StandardPromotionDisplay";
import { ProductStandardPromotion } from "./ProductStandardPromotion";
import { ProductContractPricePromotion } from "./ProductContractPricePromotion";
import PromotionsHelper from "../../../Utils/PromotionsHelper";

import "./Styles/_ProductPromotionsSection.scss"

export interface IProductPromotionsSectionProps {
    contractPricePromoDescriptions : ContractPricePromotionDisplay[];
    standardPromotionDescriptions : StandardPromotionDisplay[];
}

export const ProductPromotionsSection : React.FC<IProductPromotionsSectionProps> = ({contractPricePromoDescriptions, standardPromotionDescriptions}) => {

    return (
        <div className="product-promotions-section-wrapper">
                { PromotionsHelper.ShouldShowContractPriceDescriptions(contractPricePromoDescriptions) ?
                    <Accordion elevation={0}>
                        <AccordionSummary><Typography variant={"h4"}>Volume Pricing</Typography></AccordionSummary>
                        <AccordionDetails>
                                <ProductContractPricePromotion descriptions={contractPricePromoDescriptions} />
                        </AccordionDetails>
                    </Accordion>  :
                    PromotionsHelper.ShouldShowPromotionDescriptions(standardPromotionDescriptions) && contractPricePromoDescriptions.length === 0  ?
                    <Accordion elevation={0}>
                        <AccordionSummary><Typography variant={"h4"}>
                            {standardPromotionDescriptions ? standardPromotionDescriptions.length : 0} Available {standardPromotionDescriptions.length > 1 ? "Promotions" : "Promotion"}</Typography></AccordionSummary>
                        <AccordionDetails>
                        <ProductStandardPromotion descriptions={standardPromotionDescriptions} />
                        </AccordionDetails>
                    </Accordion>
                    : 
                    <></>
                } 
        </div>
    )
}