import React from 'react';

export const EmptyCategoryImage = () => {
    return (
        <svg width="340" height="340" viewBox="0 0 340 340" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34 170C34 206.069 48.3285 240.662 73.8335 266.167C99.3384 291.671 133.931 306 170 306C206.069 306 240.662 291.671 266.167 266.167C291.671 240.662 306 206.069 306 170C306 133.931 291.671 99.3384 266.167 73.8335C240.662 48.3285 206.069 34 170 34C133.931 34 99.3384 48.3285 73.8335 73.8335C48.3285 99.3384 34 133.931 34 170Z" fill="#F0F2FF" />
            <path d="M132.086 275.07C132.086 276.626 136.617 278.117 144.683 279.217C152.749 280.317 163.689 280.935 175.096 280.935C186.503 280.935 197.443 280.317 205.509 279.217C213.575 278.117 218.106 276.626 218.106 275.07C218.106 273.515 213.575 272.023 205.509 270.923C197.443 269.823 186.503 269.205 175.096 269.205C163.689 269.205 152.749 269.823 144.683 270.923C136.617 272.023 132.086 273.515 132.086 275.07Z" fill="#D6DAFF" />
            <path d="M106.355 238.554C106.354 239.105 106.461 239.652 106.67 240.162C106.88 240.672 107.188 241.135 107.577 241.526C107.966 241.917 108.429 242.227 108.938 242.438C109.447 242.65 109.993 242.759 110.544 242.76H232.002C233.113 242.759 234.178 242.317 234.963 241.532C235.749 240.746 236.19 239.682 236.191 238.571V102.435L200.6 66.8369H110.544C109.433 66.8369 108.368 67.2782 107.582 68.0638C106.797 68.8493 106.355 69.9148 106.355 71.0257V238.554Z" fill="white" stroke="#4550E5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M122.223 113.951H219.973V207.791H122.223V113.951Z" fill="#D6DAFF" stroke="#4550E5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M143.445 149.968C143.445 153.09 144.686 156.085 146.894 158.293C149.102 160.501 152.097 161.742 155.22 161.742C158.342 161.742 161.337 160.501 163.545 158.293C165.753 156.085 166.994 153.09 166.994 149.968C166.994 146.845 165.753 143.85 163.545 141.642C161.337 139.434 158.342 138.193 155.22 138.193C152.097 138.193 149.102 139.434 146.894 141.642C144.686 143.85 143.445 146.845 143.445 149.968Z" fill="white" stroke="#4550E5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M219.979 189.04V199.958C219.979 202.032 219.156 204.021 217.689 205.487C216.222 206.954 214.233 207.778 212.159 207.778H130.043C127.969 207.778 125.98 206.954 124.513 205.487C123.047 204.021 122.223 202.032 122.223 199.958V192.583L142.262 175.042C143.381 174.048 144.839 173.522 146.335 173.572C147.831 173.623 149.251 174.247 150.3 175.314L158.341 183.359C159.074 184.092 160.068 184.503 161.105 184.503C162.142 184.503 163.136 184.092 163.869 183.359L188.689 158.539C189.28 157.941 189.994 157.48 190.783 157.189C191.572 156.898 192.415 156.784 193.252 156.856C194.096 156.898 194.92 157.13 195.662 157.535C196.404 157.939 197.046 158.505 197.539 159.192L219.979 189.04Z" fill="white" stroke="#4550E5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M236.193 102.435H204.501C203.466 102.432 202.474 102.019 201.743 101.286C201.012 100.553 200.602 99.5601 200.602 98.5249V66.8369L236.193 102.435Z" fill="#D6DAFF" stroke="#4550E5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};
