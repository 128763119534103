import React from "react";

interface ComponentProps {
    strokeColor: string;
}

export const LogoutIcon: React.FC<ComponentProps> = (props) => {
    const { strokeColor } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill={strokeColor}>
            <g>
                <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
            <g>
                <g>
                    <polygon points="5,5 12,5 12,3 3,3 3,21 12,21 12,19 5,19" />
                    <polygon points="21,12 17,8 17,11 9,11 9,13 17,13 17,16" />
                </g>
            </g>
        </svg>
    );
};
