import React from 'react';
import { connect } from 'react-redux';
import StockIndicator from './StockIndicator';

import './Styles/_StockIndicator.scss';

interface UOMStockIndicatorProps {
    product?: any;
    productOption?: any;
    order: {
        orderItems: any;
    };
}

const remainingStock = (product: any, option: any, orderItems: any) => {
    let remaining: number = option.stock;

    if (option.uomId) {
        remaining = Math.floor(option.stock / option.uomSize);
    }

    const childItemProductInCart = orderItems.find((item: any) => item.productId === product.id);

    if (childItemProductInCart) {

        const childItemsInCart = childItemProductInCart.productOptions.filter((itemOption: any) => itemOption.optionId === option.optionId);

        if (childItemsInCart) {

            let normalQuantity = childItemsInCart.reduce((counter: number, item: any) => {
                return counter += item.uomId ? item.productQuantity * item.uomSize : item.productQuantity;
            }, 0);

            if (option.uomId) {
                remaining = Math.floor((option.stock - normalQuantity) / option.uomSize);
            }
            else {
                remaining -= normalQuantity;
            }
        }
    }

    return remaining;
}

export const UOMStockIndicator: React.FC<UOMStockIndicatorProps> = (
    props
) => {

    const { productOption, product } = props;
    const { orderItems } = props.order;

    const option = { ...productOption };

    if (product.allowOverselling) {
        return (
            <StockIndicator product={product} productOption={option} />
        );
    }
    else {
        return (
            <StockIndicator product={product} productOption={option} remainingOptionStock={remainingStock(product, option, orderItems)} />
        );
    }
}

const MapStateToProps = (state: { identityReducer: any; orderReducer: any; }) => ({
    identity: state.identityReducer,
    order: state.orderReducer
});

export default connect(MapStateToProps, {})(UOMStockIndicator);