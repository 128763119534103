import React, { Component, SyntheticEvent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { NavLink } from "reactstrap";
import { connect } from "react-redux";
import download from "downloadjs";
import { History } from "history";
import { Button, UploadSimpleIcon } from "@cin7/ui";

import QuoteIcon from "../../Images/Icons/Account/QuoteIcon";
import { UserIcon } from "../../Images/Icons/Account/UserIcon";
import { LogoutIcon } from "../../Images/Icons/Account/LogoutIcon";
import { GenericIcon } from "../../Images/Icons/Account/GenericIcon";
import { LogoutB2BUser } from "./../../Redux/Actions/AuthActions";
import { GetUserDetails } from "./../../Redux/Actions/IdentityActions";
import { ToggleSideMenu } from "./../../Redux/Actions/HeaderActions";
import { B2BPageTitles } from "../../Language/Default_Settings";
import { UploadBulkOrder } from "../PageElements/Orders/UploadBulkOrder";
import { BulkImportCSV } from "../../Domain/BulkImportCSV";
import { OrderReviewRoute } from "../../Routes";
import NavigationMenu from "./NavigationMenu";

import "./Styles/_SideMenu.scss";

interface componentProps {
    history: History;
    header: {
        isMenuOpen: boolean;
    };
    identity: {
        userInfo: {
            firstName: string;
            lastName: string;
            company: string;
        };
        moduleSettings: {
            termsAndConditionsContent: string;
            customPage1Title: string;
            customPage1Content: string;
            customPage2Title: string;
            customPage2Content: string;
        };
    };
    auth: {
        subscribedToken: string;
    };
    ToggleSideMenu: () => void;
    GetUserDetails: (token: string) => void;
    LogoutB2BUser: () => void;
}

class SideMenu extends Component<
    RouteComponentProps & componentProps,
    { showBulkOrder: boolean; smallScreen: boolean | undefined; height: number }
> {
    constructor(props: RouteComponentProps & componentProps) {
        super(props);
        this.state = { showBulkOrder: false, smallScreen: undefined, height: 0 };
    }

    componentDidMount() {
        if (!this.props.identity.userInfo.firstName && !this.props.identity.userInfo.company) {
            this.props.GetUserDetails(this.props.auth.subscribedToken);
        }

        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.auth.subscribedToken !== this.props.auth.subscribedToken) {
            this.props.GetUserDetails(this.props.auth.subscribedToken);
        }
    }

    handleResize = () => {
        this.setState({ height: window.innerHeight });
        if (window.innerWidth < 768) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    };

    render() {
        const { header, identity } = this.props;
        const hasNameAndCompany =
            identity.userInfo.company && (identity.userInfo.firstName || identity.userInfo.lastName);
        const stickyContainerHeight = document.querySelector(".account-sticky-container")?.clientHeight;

        const ProfileInfo = (
            <div className="profile-info">
                <div className="profile-name">
                    {identity.userInfo.firstName} {identity.userInfo.lastName}
                    {hasNameAndCompany ? " — " : ""}
                    <span className="profile-company">{identity.userInfo.company}</span>
                </div>
            </div>
        );

        const TermsContainer = (
            <div className="terms-container">
                <NavLink href="https://www.cin7.com/legal/terms-of-use/" target="_blank" rel="noopener noreferrer">
                    Powered by Cin7
                </NavLink>
            </div>
        );

        const AccountLinks = (
            <>
                <div className="account-link my-account">
                    <Link to={"/account/details"} onClick={this._closeMenuOnNavigate}>
                        <UserIcon strokeColor={"#00326b"} />
                        <span className="label">My Account</span>
                    </Link>
                </div>
                <div className="account-link order-history">
                    <Link to={"/account/orders"} onClick={this._closeMenuOnNavigate}>
                        <QuoteIcon strokeColor={"#00326b"} />
                        <span className="label">Order History</span>
                    </Link>
                </div>
                <div className="account-link bulk-upload">
                    <Link
                        to={"#"}
                        onClick={() => {
                            this.setState({ showBulkOrder: true });
                            this.props.ToggleSideMenu();
                        }}
                    >
                        <div className="upload-icon">
                            <UploadSimpleIcon />
                        </div>
                        <span className="label">Upload a Bulk Order</span>
                    </Link>
                </div>
            </>
        );

        const Desktop = () => {
            return (
                <>
                    <div className="account-content">
                        {ProfileInfo}
                        <div className="account-links">
                            {AccountLinks}

                            <div className="account-logout">
                                <Button className="logout-button" onClick={this._logoutUser} variant="secondary">
                                    Log out
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="nav-footer-content">
                        {identity.moduleSettings && identity.moduleSettings.customPage1Content && (
                            <div className="footer-item">
                                <Link to={"/seller/page-1"} onClick={this._closeMenuOnNavigate}>
                                    {identity.moduleSettings.customPage1Title
                                        ? identity.moduleSettings.customPage1Title
                                        : B2BPageTitles.custom_page_1_title}
                                </Link>
                            </div>
                        )}
                        {identity.moduleSettings && identity.moduleSettings.customPage2Content && (
                            <div className="footer-item">
                                <Link to={"/seller/page-2"} onClick={this._closeMenuOnNavigate}>
                                    {identity.moduleSettings.customPage2Title
                                        ? identity.moduleSettings.customPage2Title
                                        : B2BPageTitles.custom_page_2_title}
                                </Link>
                            </div>
                        )}
                        {identity.moduleSettings && identity.moduleSettings.termsAndConditionsContent && (
                            <div className="footer-item">
                                <Link to={"/seller/terms-and-conditions"} onClick={this._closeMenuOnNavigate}>
                                    {B2BPageTitles.seller_terms_and_conditions_title}
                                </Link>
                            </div>
                        )}
                        {TermsContainer}
                    </div>
                </>
            );
        };

        const Mobile = () => {
            return (
                <>
                    <div className="account-content">
                        {ProfileInfo}
                        <div className="account-links">
                            {AccountLinks}

                            {identity.moduleSettings && identity.moduleSettings.customPage1Content && (
                                <div className="account-link">
                                    <Link to={"/seller/page-1"} onClick={this._closeMenuOnNavigate}>
                                        <GenericIcon strokeColor={"#00326b"} />
                                        <span className="label">
                                            {identity.moduleSettings.customPage1Title
                                                ? identity.moduleSettings.customPage1Title
                                                : B2BPageTitles.custom_page_1_title}
                                        </span>
                                    </Link>
                                </div>
                            )}
                            {identity.moduleSettings && identity.moduleSettings.customPage2Content && (
                                <div className="account-link">
                                    <Link to={"/seller/page-2"} onClick={this._closeMenuOnNavigate}>
                                        <GenericIcon strokeColor={"#00326b"} />
                                        <span className="label">
                                            {identity.moduleSettings.customPage2Title
                                                ? identity.moduleSettings.customPage2Title
                                                : B2BPageTitles.custom_page_2_title}
                                        </span>
                                    </Link>
                                </div>
                            )}
                            {identity.moduleSettings && identity.moduleSettings.termsAndConditionsContent && (
                                <div className="account-link">
                                    <Link to={"/seller/terms-and-conditions"} onClick={this._closeMenuOnNavigate}>
                                        <GenericIcon strokeColor={"#00326b"} />
                                        <span className="label">{B2BPageTitles.seller_terms_and_conditions_title}</span>
                                    </Link>
                                </div>
                            )}

                            <div className="account-link logout">
                                <Link to={""} onClick={this._logoutUser}>
                                    <LogoutIcon strokeColor={"#00326b"} />
                                    <span className="label">Log Out</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {TermsContainer}
                </>
            );
        };

        return (
            <>
                <div id="Sidebar" className={header.isMenuOpen ? "active" : ""}>
                    <div
                        className="nav-content"
                        style={{
                            paddingBottom:
                                this.state.smallScreen && this.state.height <= 700 ? 0 : stickyContainerHeight,
                        }}
                    >
                        {this.state.smallScreen && <NavigationMenu />}
                        <div className={`${this.state.smallScreen ? "account-sticky-container" : "plain-div"}`}>
                            {this.state.smallScreen ? <Mobile /> : <Desktop />}
                        </div>
                    </div>
                </div>
                {header.isMenuOpen && !this.state.smallScreen && (
                    <div className="shadow-bg" onClick={this._toggleMenu}></div>
                )}
                {this.state.showBulkOrder && (
                    <UploadBulkOrder
                        downloadTemplate={() => {
                            download("Sku, Barcode, Quantity, Size", "template.csv", "text/csv");
                        }}
                        onClose={() => this.setState({ showBulkOrder: false })}
                        importCSV={BulkImportCSV}
                        onImportSuccess={() => {
                            this.props.history.push(OrderReviewRoute);
                        }}
                    />
                )}
            </>
        );
    }

    _closeMenuOnNavigate = () => {
        this.props.ToggleSideMenu();
    };

    _toggleMenu = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.ToggleSideMenu();
    };

    _logoutUser = (e: SyntheticEvent) => {
        e.preventDefault();
        this.props.LogoutB2BUser();
    };
}

const MapStateToProps = (state: { appSettingsReducer: any; identityReducer: any; authReducer: any }) => ({
    header: state.appSettingsReducer,
    identity: state.identityReducer,
    auth: state.authReducer,
});

const RouterWrapped = withRouter(SideMenu);
export default connect(MapStateToProps, { ToggleSideMenu, LogoutB2BUser, GetUserDetails })(RouterWrapped);
