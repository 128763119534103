import React, { Component } from "react";
import EmailIcon from "./../../Images/Icons/EmailIcon";
import CircleTickIcon from "./../../Images/Icons/Global/CircleTickIcon";
import AlertCircleIcon from "../../Images/Icons/Global/AlertCircleIcon";
import "./Styles/_Cin7ConfirmationBox.scss";

interface componentProps {
    boxMessage: string;
    boxSubMessage?: string;
    boxIcon?: string;
    boxType?: string;
}

class Cin7ConfirmationBox extends Component<componentProps, {}> {
    render() {
        const { boxIcon, boxMessage, boxType, boxSubMessage } = this.props;

        const RenderConfirmationIcon = (props: any) => {
            switch (props.iconType) {
                case "email-icon":
                    return <EmailIcon />;
                case "error-icon":
                    return <AlertCircleIcon strokeColor="#C42651" />;
                case "warning-icon":
                    return <AlertCircleIcon strokeColor="#F2C94C" />;
                case "info-icon":
                    return <AlertCircleIcon strokeColor="#00326b" />;
                default:
                    return <CircleTickIcon strokeColor="#61A25E" />;
            }
        };

        return (
            <div className={`confirmation-box-content ${boxType ? boxType : "default-box"}`}>
                {boxIcon && (
                    <div className={`confirmation-box-icon ${boxIcon}`}>
                        <RenderConfirmationIcon iconType={boxIcon} />
                    </div>
                )}
                <div className="confirmation-box-message">
                    <span className="main-message">{boxMessage}</span>
                    {boxSubMessage && <span className="sub-message">{boxSubMessage}</span>}
                </div>
            </div>
        );
    }
}

export default Cin7ConfirmationBox;
