import { CategoryType } from "../../Components/PageElements/Products/ProductCategoryFilter";
import { getAllDescendents } from "../../Utils/CategoriesHelper";
import * as types from "./../Types/CategoryTypes";
import { UpdateProductsFilter } from "./ProductListActions";

export const ChangeCurrentCategory = (newCategory: CategoryType) => (dispatch: any) => {
    dispatch({
        type: types.Change_Current_Category,
        payload: {
            category: newCategory,
        },
    });
    dispatch(
        UpdateProductsFilter({
            categoryIds: newCategory.id === -1 ? [] : [newCategory.id, ...getAllDescendents(newCategory)],
        })
    );
};

export const ResetCurrentCategory = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Current_Category,
    });
};

export const SetGoBack = (goBack: boolean) => (dispatch: any) => {
    dispatch({
        type: types.Go_Back,
        payload: {
            goBack: goBack,
        },
    });
};
