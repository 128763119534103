import React, {Component, CSSProperties} from 'react';

interface componentProps {
    circleOpacity?: number;
    fillColorCirlce?: string;
    fillColorArrow?: string;
}

interface componentStates extends componentProps {}

class NextArrowIcon extends Component<componentProps,componentStates> {

    constructor(props:componentProps) {
        super(props);
        this.state = {
            circleOpacity: .703,
            fillColorCirlce: "#e7f3fd",
            fillColorArrow: "#373839"
        }
    }

    componentDidMount() {
        if(this.props.fillColorCirlce) {
            this.setState({
                fillColorCirlce: this.props.fillColorCirlce
            });
        }

        if(this.props.fillColorArrow) {
            this.setState({
                fillColorArrow: this.props.fillColorArrow
            })
        }

        if(this.props.circleOpacity) {
            this.setState({
                circleOpacity: this.props.circleOpacity
            })
        }
    }

    render() {

        const { fillColorCirlce, fillColorArrow, circleOpacity } = this.state;
        
        const cls1: CSSProperties = {
            fill: fillColorCirlce,
            opacity: circleOpacity
        }

        const cls2: CSSProperties = {
            fill: fillColorArrow
        }

        return (
            <svg xmlns="https://www.w3.org/2000/svg" id="Group_11059" width="50" height="50" data-name="Group 11059" viewBox="0 0 50 50">
                <title>Next</title>
                <circle id="Ellipse_32" cx="25" cy="25" r="25" style={cls1} data-name="Ellipse 32"/>
                <path id="Path_5549" d="M1314.772-44.2a1.056 1.056 0 0 1 1.492-.008l12.555 12.405-12.555 12.4a1.055 1.055 0 0 1-.743.3 1.053 1.053 0 0 1-.749-.313 1.055 1.055 0 0 1 .007-1.492l11.037-10.9-11.037-10.9a1.055 1.055 0 0 1-.007-1.492z" style={cls2} data-name="Path 5549" transform="translate(-1294.607 56.807)"/>
            </svg>

        )
    }
}

export default NextArrowIcon;