import React, { Component, SyntheticEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { StaticContext } from "react-router";

import { Dialog } from "@cin7/ui";

import { ResetProductsList, GetProductsList, GetBrandsList } from "../../../Redux/Actions/ProductListActions";
import { B2BPageTitles } from "../../../Language/Default_Settings";
import ProductsListItems from "../../../Components/PageElements/Products/ProductsListItems";
import { GetUrlParameters } from "../../../Utils/B2BHelpers";
import ProductDetailsModal from "../../../Components/PageElements/Products/ProductDetailsModal";
import { ProductsListType } from "../../../Redux/Actions/ProductListActions";

import "../Styles/_ProductsList.scss";

interface LocationState {
    productId?: string;
}

interface componentProps {
    productsList: ProductsListType;
    auth: {
        subscribedToken: string;
    };
    ResetProductsList: () => void;
    GetProductsList: (
        token: string,
        page: number,
        keywords?: string,
        filters?: { brandIds?: number[]; categoryIds?: number[] }
    ) => void;
    GetBrandsList: (token: string) => void;
}

interface componentState {
    searchParameter: string;
    productDetailId: number | null;
    brandIds: number[];
}

class ProductsList extends Component<
    RouteComponentProps<{}, StaticContext, LocationState> & componentProps,
    componentState
> {
    constructor(props: RouteComponentProps<{}, StaticContext, LocationState> & componentProps) {
        super(props);
        const productDetailId = !!props.location.state?.productId ? parseInt(props.location.state.productId) : null;
        this.state = {
            searchParameter: "",
            productDetailId,
            brandIds: [],
        };
    }

    componentDidMount() {
        this.props.ResetProductsList();

        this._setDefaultPageTitle();

        if (!this.props.productsList.brandsFetched) {
            this.props.GetBrandsList(this.props.auth.subscribedToken);
        }

        let parameters = GetUrlParameters(["query"]);

        if (parameters.query) {
            this.setState({
                searchParameter: decodeURIComponent(parameters.query),
            });
        }
        this.props.GetProductsList(this.props.auth.subscribedToken, 1, parameters.query, {
            brandIds: this.props.productsList.filter.brandIds,
            categoryIds: this.props.productsList.filter.categoryIds,
        });
    }

    componentDidUpdate(prevProps: any) {
        this._setDefaultPageTitle();

        if (prevProps.auth.subscribedToken !== this.props.auth.subscribedToken) {
            this.props.GetProductsList(this.props.auth.subscribedToken, 1, this.state.searchParameter, {
                brandIds: this.props.productsList.filter.brandIds,
                categoryIds: this.props.productsList.filter.categoryIds,
            });
        }

        let parameters = GetUrlParameters(["query"]);

        let refreshProductsList = false;

        let searchParameter = this.state.searchParameter;

        if (prevProps.location.state?.productId !== this.props.location.state?.productId) {
            const productDetailId = !!this.props.location.state?.productId
                ? parseInt(this.props.location.state.productId)
                : null;
            this.setState({ productDetailId });
        }

        if (parameters.query) {
            if (parameters.query !== this.state.searchParameter) {
                this.props.ResetProductsList();
                this.setState({
                    searchParameter: parameters.query,
                });
                refreshProductsList = true;
                searchParameter = parameters.query;
            }
        } else {
            if (this.state.searchParameter !== "") {
                this.setState({
                    searchParameter: "",
                    productDetailId: null,
                });
                searchParameter = "";
                refreshProductsList = true;
            }
        }

        if (prevProps.productsList.filter.brandIds !== this.props.productsList.filter.brandIds) {
            this.props.ResetProductsList();
            this.setState({
                brandIds: this.props.productsList.filter.brandIds,
            });
            refreshProductsList = true;
        }

        if (
            JSON.stringify(prevProps.productsList.filter.categoryIds) !==
            JSON.stringify(this.props.productsList.filter.categoryIds)
        ) {
            this.props.ResetProductsList();
            refreshProductsList = true;
        }

        if (refreshProductsList) {
            this.props.GetProductsList(this.props.auth.subscribedToken, 1, searchParameter, {
                brandIds: this.props.productsList.filter.brandIds,
                categoryIds: this.props.productsList.filter.categoryIds,
            });
        }
    }

    render() {
        const { searchParameter, productDetailId } = this.state;
        return (
            <>
                {productDetailId && (
                    <Dialog
                        open
                        fullWidth
                        onClose={(e, reason) => this._setProductDetailId(null, null)}
                        className="product"
                        maxWidth={"lg"}
                    >
                        <div className="product-details-modal-content">
                            <ProductDetailsModal productId={productDetailId} CloseAction={this._setProductDetailId} />
                        </div>
                    </Dialog>
                )}
                <div id="Products-List-Container" className={productDetailId ? "product-detail-open" : ""}>
                    <ProductsListItems
                        searchParameter={searchParameter}
                        setProductDetailId={this._setProductDetailId}
                    />
                </div>
            </>
        );
    }

    _setProductDetailId = (e: SyntheticEvent | null, id: number | null) => {
        e?.preventDefault();

        this.setState({ productDetailId: id });
    };

    _setDefaultPageTitle() {
        document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.products_list_title}`;
    }
}

const MapStateToProps = (state: { productListReducer: any; authReducer: any }) => ({
    productsList: state.productListReducer,
    auth: state.authReducer,
});

export default connect(MapStateToProps, {
    ResetProductsList,
    GetProductsList,
    GetBrandsList,
})(ProductsList);
