import React, { Component } from 'react';
import { connect } from 'react-redux';
import { B2BPageTitles } from '../../../Language/Default_Settings';
import ReturnPanel from '../../../Components/Global/ReturnPanel';
import HtmlBlock from '../../../Components/Global/HtmlBlock';

interface componentProps {
  identity: {
    moduleSettings: {
      termsAndConditionsContent: string;
    }
  };
}

interface componentState {}

class TermsAndConditions extends Component<componentProps, componentState> {
  componentDidMount() {
    this.setPageTitle();
  }

  componentDidUpdate() {
    this.setPageTitle();
  }

  render() {
    const { identity } = this.props;

    return (
      <>
        <ReturnPanel title={B2BPageTitles.seller_terms_and_conditions_title} />
        <div id="Seller-Content-Container">
          <div id="Seller-Content">
            <HtmlBlock content={identity.moduleSettings.termsAndConditionsContent} />
          </div>
        </div>
      </>
    );
  }

  setPageTitle() {
    document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.seller_terms_and_conditions_title}`;
  }
}

const MapStateToProps = (state: {identityReducer: any;}) => ({
  identity: state.identityReducer
});

export default connect(MapStateToProps,{})(TermsAndConditions);
