import React, { Component } from 'react';

interface componentProps {
    title: string;
}

class DynamicTitleSection extends Component<componentProps,{}> {

    render() {

        const { title } = this.props;

        let dynamicClass = "";

        if (title) {
            if (title.length > 70) {
                dynamicClass = "title-lg";
            } else if (title.length > 50) {
                dynamicClass = "title-md";
            }
        }

        const DynamicTitle = () => {            
            return(
                <span className={dynamicClass}>{title}</span>
            );
        }

        return <DynamicTitle />
    }    
}

export default DynamicTitleSection;