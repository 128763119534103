import axios from "axios";
import * as types from "./../Types/FreightTypes";
import APIHost from "./../../APIHost";
import ApiUtil from "../../Utils/ApiUtil";
import { FreightTierAttributeType, FreightTierType, FreightZoneType } from "../Reducers/App_Reducers/FreightReducer";

const host = new APIHost();
const apiUtil = new ApiUtil();

type PostBody = {
    moduleId: number;
};

export type FreightType = {
    freightOptions: FreightType[];
};

export const ResetFreightSelection = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Freight_Options,
    });
};

export const GetFreightOptions = (token: string) => (dispatch: any) => {
    const headers = apiUtil.DefaultHeaders(token);
    if (headers === null) {
        return;
    }

    const postBody: PostBody = {
        moduleId: apiUtil.GetModuleId(),
    };

    axios
        .post(host.CheckoutAPI("Freight"), postBody, headers)
        .then((res) => {
            dispatch({
                type: types.Get_Freight_Options,
                payload: {
                    freightOptionsFetched: res.data.success,
                    freightOptions: res.data.freightZones,
                },
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const SetSelectedFreightZone = (selectedZone: FreightZoneType) => (dispatch: any) => {
    dispatch({
        type: types.Set_Selected_Freight_Zone,
        payload: {
            freightZone: selectedZone,
        },
    });
};

export const ResetSelectedFreightZone = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Selected_Freight_Zone,
    });
};

export const SetSelectedFreightTier = (selectedTier: FreightTierType) => (dispatch: any) => {
    dispatch({
        type: types.Set_Selected_Freight_Tier,
        payload: {
            freightTier: selectedTier,
        },
    });
};

export const ResetSelectedFreightTier = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Selected_Freight_Tier,
    });
};

export const SetSelectedFreightTierAttribute = (selectedAttribute: FreightTierAttributeType) => (dispatch: any) => {
    dispatch({
        type: types.Set_Selected_Freight_Tier_Attribute,
        payload: {
            freightTierAttribute: selectedAttribute,
        },
    });
};

export const ResetSelectedFreightTierAttribute = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Selected_Freight_Tier_Attribute,
    });
};

export const SetFilteredFreightZones = (hasFilteredZones: boolean) => (dispatch: any) => {
    dispatch({
        type: types.Set_Filtered_Freight_Zones,
        payload: {
            freightZonesFiltered: hasFilteredZones,
        },
    });
};

export const ResetFilteredFreightZones = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Filtered_Freight_Zones,
    });
};
