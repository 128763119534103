import React, { Component, SyntheticEvent } from "react";
import Image from "../../Global/Image";
import { GetProductImageUrl } from "../../../Utils/ProductsHelper";
import ProductPrice from "../../../Components/PageElements/Products/ProductPrice";
import DynamicTitleSection from "../../../Components/PageElements/DynamicTitleSection";
import StockIndicator from "./StockIndicator";
import ArrowRightIcon from "../../../Images/Icons/Products/ArrowRightIcon";
import ProductPromotionsBanner from "./ProductPromotionsBanner";

import "./Styles/_ProductTile.scss";

interface componentProps {
    product: any;
    OpenAction: (e: SyntheticEvent, productId: number | null) => void;
}

class ProductTile extends Component<componentProps, {}> {
    render() {
        const { product, OpenAction } = this.props;

        let productImageUrl = GetProductImageUrl(product.image);

        if (product.images && product.images.length > 0) {
            productImageUrl = GetProductImageUrl(product.images[0].medium);
        }

        return (
            <div className="product-tile" key={product.id}>
                <div className="product-tile-inner">
                    {product.hasPromotions && (
                        <ProductPromotionsBanner OpenAction={(e: any) => OpenAction(e, product.id)} />
                    )}
                    <div
                        id={`product-${product.id}`}
                        className="product-link"
                        onClick={(e) => {
                            OpenAction(e, product.id);
                        }}
                    >
                        <div className="product-image-container">
                            <Image imagePath={productImageUrl} altText={`${product.styleCode}`} />
                        </div>
                        <div className="product-detail-container">
                            <section>
                                <div className="product-title">
                                    <DynamicTitleSection title={product.name} />
                                </div>
                                <div className="product-code">{product.styleCode}</div>
                                <div className="product-price">
                                    <ProductPrice product={product} />
                                </div>
                            </section>
                            <div className="product-stock-option-container">
                                <StockIndicator product={product} />
                                <div className="product-options">{product.hasMultipleOptions && "More Options"}</div>
                            </div>
                        </div>
                        <div className="product-action-container">
                            <div className="product-arrow-right">
                                <ArrowRightIcon strokeColor="#808081" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductTile;
