import React, { Component, SyntheticEvent } from 'react';
import {RouteComponentProps} from 'react-router-dom'
import { withRouter } from 'react-router';

import CaretDownIcon from '../../Images/Icons/Global/CaretDownIcon';

import './Styles/_MoreActionsDropdown.scss';

interface componentProps extends RouteComponentProps<any> {
    actions: {
        name: string;
        action: (e: SyntheticEvent) => void;
    }[]
}

interface componentState {
    dropdownOpen: boolean;
}

class MoreActionsDropdown extends Component<componentProps,componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    render() {
        const { dropdownOpen } = this.state;
        const { actions } = this.props;

        if (actions) {
            return (
                <div className="actions-menu">
                    <div className="actions-icon-container">
                        <div className="actions-icon" onClick={this._toggleActionsMenu}>More <CaretDownIcon strokeColor="#393939" /></div>
                    </div>
                    {dropdownOpen &&
                        <>
                            <div className="dropdown-cover" onClick={this._closeMenu}></div>
                            <div className="dropdown-menu-body">
                                {actions.map(
                                    (action: any) => <button onClick={(ev) => this._runAction(action.action, ev)} key={action.name}>{action.name}</button>
                                )}
                            </div>
                        </>
                    }
                </div>
            );
        }
        else {
            return <div className="actions-menu no-actions"></div>;
        }
    }

    _runAction = (action: any, e: SyntheticEvent) => {
        e.preventDefault();
        action(e);
        this._closeMenu();
    }

    _closeMenu = () => {
        this.setState({
            dropdownOpen: false
        });
    }

    _toggleActionsMenu = (e: SyntheticEvent) => {
        e.preventDefault();

        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    
}

export default withRouter(MoreActionsDropdown);