import React from 'react';

export const MenuIcon: React.FC = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M8.32035 14.4H37.1204C37.6503 14.4 38.0804 13.9709 38.0804 13.44C38.0804 12.9091 37.6503 12.48 37.1204 12.48H8.32035C7.79043 12.48 7.36035 12.9091 7.36035 13.44C7.36035 13.9709 7.78947 14.4 8.32035 14.4Z" fill="#00326B" />
            <path d="M8.32035 35.52H37.1204C37.6503 35.52 38.0804 35.0909 38.0804 34.56C38.0804 34.0291 37.6503 33.6 37.1204 33.6H8.32035C7.79043 33.6 7.36035 34.0291 7.36035 34.56C7.36035 35.0909 7.78947 35.52 8.32035 35.52Z" fill="#00326B" />
            <path d="M8.32035 24.96H37.1204C37.6503 24.96 38.0804 24.5309 38.0804 24C38.0804 23.4692 37.6503 23.04 37.1204 23.04H8.32035C7.79043 23.04 7.36035 23.4692 7.36035 24C7.36035 24.5309 7.78947 24.96 8.32035 24.96Z" fill="#00326B" />
        </svg>
    );
}