import * as types from './../../Types/OrderTypes';
import { ProductDetailsOption } from './ProductDetailsReducer';

export type OrderItemNote = {
    optionId: number,
    productId: number,
    uomId: number | null,
    notes: string | null
}

export type ProductData = {
    id: number,
    name: string,
    styleCode: string,
    images: string [],
    taxRate: number
}

export type CartItem = {
    product : ProductData,
    productId: number,
    productOptions: ProductDetailsOption [],
    totalItems: number
}

export type OrderStateType = {
  orderItems: CartItem[],
  orderItemNotes: OrderItemNote[],
  isLoading: boolean,
  reference: string,
  id: number,
  shouldRedirect: boolean,
  shouldRedirectToPayment: boolean,
  shouldContinueDisplayingPlacingOrder: boolean;
  errorMessage: string,
  orderDetails: {
      poNumber: string, 
      deliveryInstructions: string,
      deliveryDate: Date | null,
      deliveryAddress: any | null,
      paymentMethod: string | null,
  },
  isFetchingReorderDetails: boolean,
  isReorderComplete: boolean,
  reorderErrorMessage: string,
  reorderItemsCount: number,
  reorderUnavailableItemsCount: number,
  hidePriceWhileFetchingPromotionCalculations: boolean,
  isFetchingPromotions: boolean,
  paymentFailed: boolean
}

export const initialState: OrderStateType = {
    orderItems: [],
    orderItemNotes: [],
    isLoading: false,
    reference: '',
    id: 0,
    shouldRedirect: false,
    shouldRedirectToPayment: false,
    shouldContinueDisplayingPlacingOrder: false,
    errorMessage: '',
    orderDetails: {
        poNumber: '',
        deliveryInstructions: '',
        deliveryDate: null,
        deliveryAddress: null,
        paymentMethod: null
    },
    isFetchingReorderDetails: false,
    isReorderComplete: false,
    reorderErrorMessage: '',
    reorderItemsCount: 0,
    reorderUnavailableItemsCount: 0,
    hidePriceWhileFetchingPromotionCalculations: false,
    isFetchingPromotions: false,
    paymentFailed: false
};


export const OrderReducer = (state = initialState, action: {type: string, payload: any | undefined}): OrderStateType => {

    switch(action.type) {
        case types.Increment_Order_Quantity:
            let groupOptions:any = {
                productId: action.payload.product.id,
                product: {
                    id: action.payload.product.id,
                    name: action.payload.product.name,
                    styleCode: action.payload.product.styleCode,
                    images: action.payload.product.images,
                    taxRate: action.payload.product.taxRate,
                    allowOverselling: action.payload.product.allowOverselling
                },
                productOptions: [],
                totalItems: 0
            }
            
            type OptionId = {optionId: number, sizeId: number, uomId: number};
            // Keep the product option ordering rather than appending to the cart options
            const sortOptionsFunc = (optionA: OptionId, optionB: OptionId) => {
                const findIndexOfOption = (option: OptionId) => 
                    (action.payload.product.options ?? [])
                    .findIndex((sortedOptions: OptionId) => 
                        sortedOptions.optionId === option.optionId 
                        && sortedOptions.sizeId === option.sizeId 
                        && sortedOptions.uomId === option.uomId);
                const optionAIndex = findIndexOfOption(optionA);
                const optionBIndex = findIndexOfOption(optionB);
                if (optionAIndex > optionBIndex) return 1;
                if (optionAIndex < optionBIndex) return -1;
                return 0;
            }

            let findIncrementGroupName = state.orderItems.findIndex((x: {productId: number}) => x.productId === action.payload.product.id);
            if(findIncrementGroupName === -1){
                let cartProduct = action.payload.productOption;
                cartProduct.productQuantity = action.payload.increment;
                groupOptions.totalItems = action.payload.increment;

                groupOptions.productOptions = [...groupOptions.productOptions, cartProduct].sort(sortOptionsFunc);
                return {
                    ...state,
                    orderItems: [...state.orderItems, groupOptions]
                }
            }

            let incrementOrderProduct = state.orderItems[findIncrementGroupName]
                .productOptions.findIndex((x: ProductDetailsOption) => 
                    x.optionId === action.payload.productOption.optionId 
                    && x.sizeId === action.payload.productOption.sizeId 
                    && x.uomId === action.payload.productOption.uomId);
            
            if(incrementOrderProduct === -1) {
                let cartProduct = action.payload.productOption;
                cartProduct.productQuantity = action.payload.increment;

                state.orderItems[findIncrementGroupName].totalItems += action.payload.increment;
                state.orderItems[findIncrementGroupName].productOptions = [...state.orderItems[findIncrementGroupName].productOptions, cartProduct].sort(sortOptionsFunc);

                return {
                    ...state
                }
            }
            state.orderItems[findIncrementGroupName].productOptions[incrementOrderProduct].productQuantity += action.payload.increment;
            state.orderItems[findIncrementGroupName].totalItems += action.payload.increment;

            return {
                ...state
            };
        case types.Decrement_Order_Quantity:

            let findDecrementGroupName = state.orderItems.findIndex((x: {productId: number}) => x.productId === action.payload.product.id);
            
            if(findDecrementGroupName === -1) {
                return {
                    ...state
                };
            }

            let decrementOrderProduct = state.orderItems[findDecrementGroupName].productOptions.findIndex((x: ProductDetailsOption) => 
                x.optionId === action.payload.productOption.optionId 
            && x.sizeId === action.payload.productOption.sizeId 
            && x.uomId === action.payload.productOption.uomId);
            
            if (decrementOrderProduct === -1) {
                return {
                    ...state
                };
            }

            state.orderItems[findDecrementGroupName].productOptions[decrementOrderProduct].productQuantity -= 1;
            state.orderItems[findDecrementGroupName].totalItems -= 1;

            if (state.orderItems[findDecrementGroupName].totalItems <= 0) {
                // Remove product if quantity is 0.
                state.orderItems.splice(findDecrementGroupName, 1);
            }
            else if (state.orderItems[findDecrementGroupName].productOptions[decrementOrderProduct].productQuantity <= 0) {
                // Remove product option if quantity is 0.
                state.orderItems[findDecrementGroupName].productOptions.splice(decrementOrderProduct, 1);
            }

            return {
                ...state
            };
        case types.Set_Order_Quantity:
            var setGroupOptions:any = {
                productId: action.payload.product.id,
                product: {
                    id: action.payload.product.id,
                    name: action.payload.product.name,
                    styleCode: action.payload.product.styleCode,
                    images: action.payload.product.images,
                    taxRate: action.payload.product.taxRate,
                    allowOverselling: action.payload.product.allowOverselling
                },
                productOptions: [],
                totalItems: 0
            }

            let findSetGroupName = state.orderItems.findIndex((x: {productId: number}) => x.productId === action.payload.product.id);

            if (findSetGroupName === -1) {

                if (action.payload.quantity <= 0) {
                    return {
                        ...state
                    }
                }

                let cartProduct = action.payload.productOption;
                cartProduct.productQuantity = action.payload.quantity;
                setGroupOptions.totalItems += action.payload.quantity;

                setGroupOptions.productOptions = [...setGroupOptions.productOptions, cartProduct];
                
                return {
                    ...state,
                    orderItems: [...state.orderItems, setGroupOptions]
                }
            }

            let setOrderProduct = state.orderItems[findSetGroupName].productOptions.findIndex((x: ProductDetailsOption) => 
                x.optionId === action.payload.productOption.optionId 
            && x.sizeId === action.payload.productOption.sizeId 
            && x.uomId === action.payload.productOption.uomId);
            
            if (setOrderProduct === -1) {

                if (action.payload.quantity <= 0) {
                    return {
                        ...state
                    }
                }

                let cartProduct = action.payload.productOption;
                cartProduct.productQuantity = action.payload.quantity;

                state.orderItems[findSetGroupName].totalItems += action.payload.quantity;
                state.orderItems[findSetGroupName].productOptions = [...state.orderItems[findSetGroupName].productOptions, cartProduct];

                return {
                    ...state
                }
            }

            let setQuantity = action.payload.quantity - state.orderItems[findSetGroupName].productOptions[setOrderProduct].productQuantity;

            state.orderItems[findSetGroupName].productOptions[setOrderProduct].productQuantity += setQuantity;
            state.orderItems[findSetGroupName].totalItems += setQuantity;

            if (state.orderItems[findSetGroupName].totalItems <= 0) {
                // Remove product if quantity is 0.
                state.orderItems.splice(findSetGroupName, 1);
            }
            else if (state.orderItems[findSetGroupName].productOptions[setOrderProduct].productQuantity <= 0) {
                // Remove product option if quantity is 0.
                state.orderItems[findSetGroupName].productOptions.splice(setOrderProduct, 1);
            }

            return {
                ...state
            };
        case types.Remove_Order_Product:
            let findRemoveGroupIndex = state.orderItems.findIndex((x: {productId: number}) => x.productId === Number(action.payload.productId));

            if (findRemoveGroupIndex === -1) {
                return {
                    ...state
                };
            }

            if (action.payload.optionId === null) {
                state.orderItems.splice(findRemoveGroupIndex, 1);
                return {
                    ...state
                };
            }

            let findRemoveOptionIndex = state.orderItems[findRemoveGroupIndex].productOptions.findIndex((x: ProductDetailsOption) => 
                x.optionId === action.payload.optionId 
            && x.sizeId === action.payload.sizeId 
            && x.uomId === action.payload.uomId);

            if (findRemoveOptionIndex === -1) {
                return {
                    ...state
                };
            }

            state.orderItems[findRemoveGroupIndex].productOptions.splice(findRemoveOptionIndex, 1);

            let totalOptionQuantity = 0;

            // Recalculate total options quantity for the product.
            state.orderItems[findRemoveGroupIndex].productOptions.forEach((option: {productQuantity: number}) => {
                totalOptionQuantity += option.productQuantity
            });            

            let quantityDifference = totalOptionQuantity - state.orderItems[findRemoveGroupIndex].totalItems;

            /// INVESTIGATE
            //state.orderItems[findRemoveGroupIndex].productOptions.productQuantity = totalOptionQuantity;

            state.orderItems[findRemoveGroupIndex].totalItems += quantityDifference;

            return {
                ...state
            };
        case types.Clear_Cart:
            return {
                ...state,
                orderItems: initialState.orderItems,
                orderItemNotes: initialState.orderItemNotes
            };
        case types.Update_Purchase_Order_Number:

            state.orderDetails.poNumber = action.payload.poNumber;

            return {
                ...state
            };
        case types.Update_Delivery_Date:

            state.orderDetails.deliveryDate = action.payload.deliveryDate;

            return {
                ...state
            };
        case types.Update_Delivery_Instructions:

            state.orderDetails.deliveryInstructions = action.payload.deliveryInstructions;

            return {
                ...state
            };
        case types.Creating_Order:
            return {
                ...state,
                isLoading: true,
                shouldContinueDisplayingPlacingOrder: true,
                errorMessage: initialState.errorMessage,
                paymentFailed: initialState.paymentFailed
            };
        case types.Create_Order_Success:
            state.orderDetails.poNumber = '';
            state.orderDetails.deliveryInstructions = '';
            state.orderDetails.deliveryDate = null;
            state.orderDetails.deliveryAddress = null;
            state.orderDetails.paymentMethod = null;

            return {
                ...state,
                isLoading: false,
                reference: action.payload.reference,
                id: action.payload.id
            };
        case types.Create_Order_Fail:
            return {
                ...state,
                isLoading: false,
                shouldContinueDisplayingPlacingOrder: false,
                errorMessage: action.payload.errorMessage
            };
        case types.Redirect_To_Confirmation:
            return {
                ...state,
                shouldRedirect: action.payload.shouldRedirect
            };
        case types.Redirect_To_Payment:
            return {
                ...state,
                shouldRedirectToPayment: action.payload.shouldRedirectToPayment
            };
        case types.Order_Completion_Successful:
            return {
                ...state,
                shouldRedirect: false,
                shouldRedirectToPayment: false,
                isLoading: false,
                shouldContinueDisplayingPlacingOrder: false,
                paymentFailed: initialState.paymentFailed
            };
        case types.Update_Delivery_Address:

            state.orderDetails.deliveryAddress = {
                firstName: action.payload.deliveryAddress.firstName,
                lastName: action.payload.deliveryAddress.lastName,
                company: action.payload.deliveryAddress.company,
                address1: action.payload.deliveryAddress.address1,
                address2: action.payload.deliveryAddress.address2,
                city: action.payload.deliveryAddress.city,
                state: action.payload.deliveryAddress.state,
                postCode: action.payload.deliveryAddress.postCode,
                country: action.payload.deliveryAddress.country
            };

            return {
                ...state
            };
        case types.Update_Order_Item_Notes:
            const orderItemNoteExists = state.orderItemNotes.some((orderItemNote) => orderItemNote.optionId === action.payload.orderItemOptionId && orderItemNote.productId === action.payload.productId && orderItemNote.uomId === action.payload.uomId)

            if (orderItemNoteExists) {
                return {
                    ...state,
                    orderItemNotes: state.orderItemNotes.map(orderItemNote => {
                        if (orderItemNote.optionId !== action.payload.orderItemOptionId || orderItemNote.productId !== action.payload.productId || orderItemNote.uomId !== action.payload.uomId) {
                            return orderItemNote;
                        }

                        return {...orderItemNote, notes: action.payload.notes}
                    })
                }
            }

            return {
                ...state,
                orderItemNotes: [
                    ...state.orderItemNotes,
                    {optionId: action.payload.orderItemOptionId, productId: action.payload.productId, uomId: action.payload.uomId, notes: action.payload.notes}
                ]
            }
        case types.Delete_Order_Item_Notes:
            return {
                ...state,
                orderItemNotes: state.orderItemNotes.filter(itemNote => itemNote.optionId !== action.payload.orderItemOptionId || itemNote.productId !== action.payload.productId || itemNote.uomId !== action.payload.uomId)
            }
        case types.Fetching_Reorder_Details:
            return {
                ...state,
                isFetchingReorderDetails: true,
                isReorderComplete: initialState.isReorderComplete,
                reorderErrorMessage: initialState.reorderErrorMessage
            };
        case types.Reorder_Details_Success:
            return {
                ...state,
                isFetchingReorderDetails: initialState.isFetchingReorderDetails,
                isReorderComplete: true,
                reorderErrorMessage: initialState.reorderErrorMessage,
                reorderItemsCount: action.payload.itemsCount,
                reorderUnavailableItemsCount: action.payload.unavailableItemsCount,
            };
        case types.Reorder_Details_Fail:
            return {
                ...state,
                isFetchingReorderDetails: initialState.isFetchingReorderDetails,
                isReorderComplete: initialState.isReorderComplete,
                reorderErrorMessage: action.payload.message
            };
        case types.Reorder_Details_Reset:
            return {
                ...state,
                isFetchingReorderDetails: initialState.isFetchingReorderDetails,
                isReorderComplete: initialState.isReorderComplete,
                reorderErrorMessage: initialState.reorderErrorMessage,
                reorderItemsCount: initialState.reorderItemsCount,
                reorderUnavailableItemsCount: initialState.reorderUnavailableItemsCount,
            };
        case types.Calculate_Promotions:
        return {
            ...state,
            hidePriceWhileFetchingPromotionCalculations: action.payload.addProductDetailsReducerProduct,
            isFetchingPromotions: action.payload.isLoading,
            }
        case types.Calculated_Promotions:
            return {
                ...state,
                orderItems: action.payload,
                hidePriceWhileFetchingPromotionCalculations: false,
                isFetchingPromotions: false,
            }
        case types.Reset_Checkout_State:
            return {
                ...state,
                errorMessage: initialState.errorMessage,
                shouldRedirect: initialState.shouldRedirect,
                shouldRedirectToPayment: initialState.shouldRedirectToPayment,
                isLoading: initialState.isLoading,
                shouldContinueDisplayingPlacingOrder: initialState.shouldContinueDisplayingPlacingOrder,
                paymentFailed: initialState.paymentFailed
            };
        case types.Update_Order_Payment_Method:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    paymentMethod: action.payload.paymentMethod
                }
            }
        case types.Force_Stop_Display_Placing_Order_Message:
            return {
                ...state,
                shouldContinueDisplayingPlacingOrder: false
            }
        case types.Set_Payment_Failed:
            return {
                ...state,
                paymentFailed : action.payload
            }
        default: 
            return state;
    }
}